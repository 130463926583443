define("front/templates/document/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("model.thumbHref")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" >\n									");
      return buffer;
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n								Κλείσιμο\n								");
      }

      data.buffer.push("<div class=\"row\">\n	<div class=\"col-xs-12\">\n		<div class=\"widget-box\">\n			<div class=\"widget-header\">\n				<h3 class=\"widget-title smaller\">Έγγραφο ");
      stack1 = helpers._triageMustache.call(depth0, "model.title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n			</div>\n			<div class=\"widget-body\">\n				<div class=\"widget-main\">\n					<form class=\"form-horizontal\" role=\"form\">\n\n						<div class=\"form-group\">\n							<label for=\"document_date\" class=\"col-sm-3 control-label\">Ημ/νία:</label>\n							<div class=\"col-sm-6\">\n								<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.date", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n							</div>\n							\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"title\" class=\"col-sm-3 control-label\">Τίτλος:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n							</div>\n							\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Σημειώσεις:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.remarks", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Κατηγορία:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.category.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Υποκατηγορία:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.subcategory.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Αρχείο:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">\n									");
      stack1 = helpers['if'].call(depth0, "model.thumbHref", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									<span class=\"blue link\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "downloadFile", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">");
      stack1 = helpers._triageMustache.call(depth0, "model.filename", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" <i class=\"ace-icon fa fa-download bigger-130\"></i></span></p>\n							</div>\n						</div>\n						<div class=\"form-group\">\n							<div class=\"col-sm-offset-3 col-sm-6\">\n								\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "personID", options) : helperMissing.call(depth0, "link-to", "person", "personID", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n							\n						</div>\n					</form>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });