define("front/controllers/person/transition", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      person_histories: null,
      user: null,
      user_current_unit: null,


      actions: {
        transition: function () {
          var self = this;
          var data = Ember.Object.create({
            user_id: this.get("user"),
            pers_id: this.get("person.id"),
            comments: this.get("comments"),
            pehi_id: this.get("activeHistory.id"),
            unit_id: this.get("user_current_unit.id"),
            date: this.get("date")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/transition",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Η αλαγή φάσης ολοκληρώθηκε με επιτυχία", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("model.person.id"));
          }, function (error) {
            Notify.alert(error.responseJSON.message, {
              closeAfter: 10000
            });
          });
        }
      }
    });
  });