define("front/templates/appointments", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"row\">\n	<div class=\"col-sm-12\">\n		<div class=\"page-header\">\n		    <h1>Διαχείριση Προγραμματισμού\n		     	<small><i class=\"ace-icon fa fa-angle-double-right\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "user_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>\n		    </h1>\n		</div>\n		<div class=\"space-20\"></div>\n		<div id='appointments' class=\"col-sm-12\">\n\n		</div>\n	</div>\n</div>\n\n");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "appointments-calendar", {hash:{
        'valueBinding': ("obs")
      },hashTypes:{'valueBinding': "STRING"},hashContexts:{'valueBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      return buffer;
      
    });
  });