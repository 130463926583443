define("front/routes/form/build", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var formModel = this.modelFor("form");

        return Ember.RSVP.hash({
          form: formModel,
          formParams: this.store.find("formParameter", {
            form_id: formModel.id,
            params_only: 1
          }),
          groups: this.store.find("formGroup", {
            form_id: formModel.id,
            groups_only: 1
          })
        });
      },

      deactivate: function () {
        //aparaitito gia to focus out provlima tou autocomplete
        this.controllerFor("form.build").set("selectedParameterId", null);
        this.controllerFor("form.build").set("selectedSubParameterId", null);
      },

      actions: {
        reloadBuild: function () {
          this.refresh();
        }
      }
    });
  });