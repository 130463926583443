define("front/routes/relationship", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        var personModel = this.modelFor("person");

        return Ember.RSVP.hash({
          person: personModel.person,
          relationTypes: this.store.find("relation-type"),
          relationship: this.store.find("relationship", params.rels_id)
        });
      },


      setupController: function (controller, model) {
        this._super(controller, model);
      } });
  });