define("front/routes/person/ascertainment", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {


      setupController: function (controller, model) {
        this._super(controller, model);

        var person_model = this.modelFor("person");

        var person_history = person_model.person_histories;
        var person = person_model.person;


        controller.set("person_history", person_history);
        controller.set("person", person);
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
      } });
  });