define("front/controllers/pehi/edit-event", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      type_id: null,

      url: "",

      event: (function () {
        return this.get("model.closure") || this.get("model.withdrawal") || this.get("model.serviceInterrupt") || this.get("model.graduation") || this.get("model.release") || this.get("model.transition") || this.get("model.transport") || this.get("model.completion") || this.get("model.transfer") || this.get("model.assignment") || this.get("model.serviceStatement");
      }).property("model.withdrawal", "model.closure", "model.serviceInterrupt", "model.graduation", "model.release", "model.transition", "model.transport", "model.completion", "model.transfer", "model.assignment", "model.serviceStatement"),
      type: (function () {
        if (!Ember.empty(this.get("model.closure"))) {
          return "closure";
        }
        if (!Ember.empty(this.get("model.withdrawal"))) {
          return "withdrawal";
        }
        if (!Ember.empty(this.get("model.serviceInterrupt"))) {
          return "serviceInterrupt";
        }
        if (!Ember.empty(this.get("model.graduation"))) {
          return "graduation";
        }
        if (!Ember.empty(this.get("model.release"))) {
          return "release";
        }
        if (!Ember.empty(this.get("model.transition"))) {
          return "transition";
        }
        if (!Ember.empty(this.get("model.transport"))) {
          return "transport";
        }
        if (!Ember.empty(this.get("model.completion"))) {
          return "completion";
        }
        if (!Ember.empty(this.get("model.transfer"))) {
          return "transfer";
        }
        if (!Ember.empty(this.get("model.assignment"))) {
          return "assignment";
        }
        if (!Ember.empty(this.get("model.serviceStatement"))) {
          return "serviceStatement";
        }
      }).property("model.withdrawal", "model.closure", "model.serviceInterrupt", "model.graduation", "model.release", "model.transition", "model.transport", "model.completion", "model.transfer", "model.assignment", "model.serviceStatement"),
      startEvent: (function () {
        // return this.get('model.assignment');
        return ["assignment", "serviceStatement"].includes(this.get("type"));
      }).property("type"),

      endEvent: (function () {
        return ["withdrawal", "closure", "serviceInterrupt", "graduation", "release", "transport", "completion", "transfer"].includes(this.get("type"));
      }).property("type"),

      canBeSaved: (function () {
        if (this.get("type") === "transition") {
          return true;
        }

        return false;
      }).property("type"),

      formatedDate: (function () {
        return moment(this.get("model.date")).format("DD/MM/YYYY");
      }).property("model.date"),

      showChange: (function () {
        if (this.get("startEvent")) {
          return true;
        }
        if (this.get("endEvent")) {
          if (this.get("type") == "completion") {
            if (!this.get("obsUnit.compCanClose")) {
              return false;
            }
          }
          return true;
        }
        return false;
      }).property("obsUnit", "type", "startEvent", "endEvent"),

      types: (function () {
        var _this = this;
        var types = [{ type: "closure", text: "Κλείσιμο", evtType: "end" }, { type: "withdrawal", text: "Αποχώρηση", evtType: "end" }, { type: "graduation", text: "Αποφοίτηση", evtType: "end" }, { type: "completion", text: "Ολοκλήρωση", evtType: "end" }, { type: "serviceInterrupt", text: "Διακοπή Παροχής Υπηρεσίας", evtType: "end" }, { type: "release", text: "Αποφυλάκιση", evtType: "end" }, { type: "transport", text: "Μεταγωγή", evtType: "end" }, { type: "transfer", text: "Παραπομπή", evtType: "end" }, { type: "assignment", text: "Ανάθεση", evtType: "start" }, { type: "serviceStatement", text: "Παροχή Υπηρεσίας", evtType: "start" }, { type: "transition", text: "Αλλαγή Φάσης", evtType: "middle" }];

        var filterType = undefined;
        if (this.get("startEvent")) {
          filterType = "start";
        } else if (this.get("endEvent")) {
          filterType = "end";
        } else {
          filterType = "middle";
        }

        var events = types.filter(function (type) {
          return type.type !== _this.get("type") && type.evtType === filterType;
        });

        if (!this.get("obsUnit.compCanClose")) {
          events = events.reject(function (type) {
            return type.type == "completion";
          });
        }

        if (!this.get("obsUnit.isPrison")) {
          events = events.filter(function (type) {
            return type.type !== "release" && type.type !== "transport";
          });
        }

        return events;
      }).property("obsUnit", "type", "startEvent", "endEvent"),

      actions: {

        deactivate: function () {
          this.controllerFor("pehi.edit-event").get("model").rollback();
        },

        changeType: function () {
          var data = {
            event_id: this.get("model.id"),
            event_to_type: this.get("selectedType.type"),
            event_from_type: this.get("type")
          };
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/changeEventType",
            type: "POST",
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
          }).then(function (response) {
            self.set("showSpinner", false);
            Notify.success("Η αποθήκευση έγινε με επιτυχία.", {
              closeAfter: 10000
            });


            self.set("model", event);

            self.send("reloadEvent");
            // self.transitionToRoute('pehi-admin');
          }, function () {
            self.set("showSpinner", false);
            Notify.warning("Η αποθήκευση απέτυχε", {
              closeAfter: 10000
            });
          });
        },

        goToEvent: function (event) {
          this.transitionToRoute("event", event.get("id"));
        },

        updateEvent: function (event) {
          this.set("showSpinner", true);
          event.set("date", moment(event.get("f_date"), "DD/MM/YYYY").format("YYYY-MM-DD"));
          var self = this;
          event.save().then(function (response) {
            self.set("showSpinner", false);
            Notify.success("Η αποθήκευση έγινε με επιτυχία.", {
              closeAfter: 10000
            });

            self.send("reloadPerson");
            self.transitionToRoute("pehi-admin");
          }, function (e) {
            self.set("showSpinner", false);

            Notify.warning("Η αποθήκευση απέτυχε " + e.responseJSON.message, {
              closeAfter: 10000
            });
            self.controllerFor("pehi.edit-event").get("model").rollback();
            self.controllerFor("pehi.edit-event").get("event").rollback();

            self.send("reloadPerson");
            self.transitionToRoute("pehi.edit-event", event.get("id"));
          });
        },

        "delete": function () {
          this.send("showModal", "pehi/delete-event", { personEvent: this.get("model") });
        } }
    });
  });