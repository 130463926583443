define("front/templates/relationship/delete-modal", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n	\n	<div class=\"col-xs-12\">\n		Να διαγραφεί η Σχέση ;\n	</div>\n\n\n");
      }

      stack1 = (helper = helpers['my-modal'] || (depth0 && depth0['my-modal']),options={hash:{
        'title': ("Διαγραφή Σχέσης"),
        'okText': ("Διαγραφή"),
        'closeText': ("Κλείσιμο"),
        'ok': ("deleteGroup"),
        'close': ("removeModal")
      },hashTypes:{'title': "STRING",'okText': "STRING",'closeText': "STRING",'ok': "STRING",'close': "STRING"},hashContexts:{'title': depth0,'okText': depth0,'closeText': depth0,'ok': depth0,'close': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "my-modal", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });