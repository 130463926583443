define("front/routes/event/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("event");
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("element_history", model.event);
        controller.set("person", model.person);
        controller.set("user", this.controllerFor("application").get("currentUser"));
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        controller.set("subcategory", model.event.get("subcategory"));

        if (!Ember.empty(model.event.get("transfer"))) {
          controller.set("transfer", model.event.get("transfer"));
          if (model.event.get("transfer.isCanceled")) {
            controller.set("cancelReasons", model.cancelReasons);
          }
        }
        if (!Ember.empty(model.event.get("completion"))) {
          controller.set("completion", model.event.get("completion"));
        }
        if (!Ember.empty(model.event.get("withdrawal"))) {
          controller.set("withdrawal", model.event.get("withdrawal"));
        }
        if (!Ember.empty(model.event.get("graduation"))) {
          controller.set("graduation", model.event.get("graduation"));
        }
        if (!Ember.empty(model.event.get("appointment"))) {
          controller.set("appointment", model.event.get("appointment"));
        }
        if (!Ember.empty(model.event.get("assignment"))) {
          controller.set("assignment", model.event.get("assignment"));
        }
        if (!Ember.empty(model.event.get("release"))) {
          controller.set("release", model.event.get("release"));
        }
        if (!Ember.empty(model.event.get("transport"))) {
          controller.set("transport", model.event.get("transport"));
        }
        if (!Ember.empty(model.event.get("transition"))) {
          controller.set("transition", model.event.get("transition"));
        }
        if (!Ember.empty(model.event.get("serviceStatement"))) {
          controller.set("serviceStatement", model.event.get("serviceStatement"));
        }
        if (!Ember.empty(model.event.get("serviceInterrupt"))) {
          controller.set("serviceInterrupt", model.event.get("serviceInterrupt"));
        }
        if (!Ember.empty(model.event.get("closure"))) {
          controller.set("closure", model.event.get("closure"));
        }
      },

      deactivate: function () {
        //alert(this.controllerFor("person.edit"));
        this.set("controller.transfer", null);
        this.set("controller.completion", null);
        this.set("controller.graduation", null);
        this.set("controller.subcategory", null);
        this.set("controller.withdrawal", null);
        this.set("controller.appointment", null);
        this.set("controller.assignment", null);
        this.set("controller.transition", null);
        this.set("controller.transport", null);
        this.set("controller.release", null);
        this.set("controller.serviceStatement", null);
        this.set("controller.serviceInterrupt", null);
        this.set("controller.closure", null);

        if (this.controllerFor("event.index").get("model.event.transfer")) {
          this.controllerFor("event.index").get("model.event.transfer").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.completion")) {
          this.controllerFor("event.index").get("model.event.completion").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.graduation")) {
          this.controllerFor("event.index").get("model.event.graduation").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.appointment")) {
          this.controllerFor("event.index").get("model.event.appointment").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.withdrawal")) {
          this.controllerFor("event.index").get("model.event.withdrawal").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.assignment")) {
          this.controllerFor("event.index").get("model.event.assignment").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.release")) {
          this.controllerFor("event.index").get("model.event.release").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.transition")) {
          this.controllerFor("event.index").get("model.event.transition").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.transport")) {
          this.controllerFor("event.index").get("model.event.transport").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.serviceStatement")) {
          this.controllerFor("event.index").get("model.event.serviceStatement").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.serviceInterrupt")) {
          this.controllerFor("event.index").get("model.event.serviceInterrupt").rollback();
        }

        if (this.controllerFor("event.index").get("model.event.closure")) {
          this.controllerFor("event.index").get("model.event.closure").rollback();
        }
      },

      actions: {
        reloadEvent: function () {
          var self = this;
          this.modelFor("event").event.reload().then(function () {
            self.refresh();
            self.send("reloadPerson");
          });
        }
      }
    });
  });