define("front/controllers/form-complete/form-complete-multiple", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ObjectController.extend({

      setIdCode: (function () {
        var id = this.get("id");

        return "answerCode" + id;
      }).property("id"),

      setIdText: (function () {
        var id = this.get("id");

        return "answerText" + id;
      }).property("id"),

      isSelection: (function () {
        if (this.get("is_the_answer") === 1) {
          return true;
        } else {
          return false;
        }
      }).property("is_the_answer")

    });
  });