define("front/controllers/parameters/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["parameters/index"],

      getName: (function () {
        var self = this;

        this.store.find("Question", this.get("content")).then(function (question) {
          self.set("parameterName", question.get("text"));
        });
      }).observes("content"),

      parameterName: null,

      actions: {
        deleteParameter: function () {
          var self = this;
          var quesId = this.get("content");

          this.store.find("question", quesId).then(function (question) {
            question.destroyRecord().then(function () {
              self.toggleProperty("controllers.parameters/index.doReRender");

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");

            })["catch"](function (response) {
              question.rollback();
              //self.send('removeModal');
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή της Παραμέτρου είναι αδύνατη!");
            });
          });
        }
      }


    });
  });