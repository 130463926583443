define("front/controllers/pehi", 
  ["ember-notify","front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];
    var UserMixin = __dependency3__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, UserMixin, {
      canValidate: false,

      hasEndDate: (function () {
        if (!this.get("endDate") || this.get("endDate") === "") {
          return false;
        }
        return true;
      }).property("endDate"),

      hasTransfer: (function () {
        var _this = this;


        var pehis = this.get("pehis");

        var sortedPehis = pehis.filter(function (h) {
          return h.get("type") === "1";
        }).sort(function (a, b) {
          return new Date(b.get("startDate")) - new Date(a.get("startDate"));
        });

        var previousHistoryIndex = sortedPehis.findIndex(function (h) {
          return h.get("id") === _this.get("model.id");
        }) + 1;

        if (previousHistoryIndex >= sortedPehis.length) return false;

        var previousHistory = sortedPehis[previousHistoryIndex];

        var checkTrans = previousHistory.get("historyevents").filter(function (e) {
          return e.get("transfer");
        });

        if (checkTrans.length === 0) {
          return false;
        }

        return checkTrans[0];


      }).property("model", "startDate"),

      actions: {

        saveStartDate: function () {
          var self = this;
          var data = Ember.Object.create({
            pehi_id: this.get("id"),
            date: this.get("formatedStartDate")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/pehiStartDate",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            // self.set('startDate', moment(self.get('formatedStartDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

            Notify.success("Το ιστορικό άλλαξε με επιτυχία ", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.send("reloadPerson");
          }, function (response) {
            // self.set('formatedStartDate', moment(self.get('startDate')).format('DD/MM/YYYY') );
            self.send("reloadPerson");

            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        goToPehi: function (pehi_id) {
          this.transitionToRoute("pehi", pehi_id);
        },

        saveEndDate: function () {
          var self = this;
          var data = Ember.Object.create({
            pehi_id: this.get("id"),
            date: this.get("formatedEndDate")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/pehiEndDate",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            //  self.set('endDate', moment(self.get('formatedEndDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

            Notify.success("Το ιστορικό άλλαξε με επιτυχία ", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.send("reloadPerson");
          }, function (response) {
            self.send("reloadPerson");
            // self.set('formatedEndDate', moment(self.get('endDate')).format('DD/MM/YYYY') );
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });