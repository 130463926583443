define("front/controllers/form-parameter/edit", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend({

      hasSubParameter: (function () {
        if (this.get("subParameterSwitch") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("subParameterSwitch"),



      actions: {
        filterSubParameters: function (autocomplete, term) {
          var self = this;
          //alert(term);
          if (term.length > 2) {
            this.store.find("question", { searchString: term, form_id: this.get("model.form.id") }).then(function (questions) {
              self.set("filteredSubParameters", questions);
            }, function (reason) {});
          } else {
            self.set("filteredSubParameters", null);
          }
        },

        selectSubParameter: function (selection) {
          this.set("selectedSubParameter", selection.value);
        },

        updateFormParameter: function () {
          this.set("showSpinner", true);

          //$('.autcomp input').val('');

          var self = this;

          var data = {
            group_id: self.get("selectedGroup.id"),
            numbering: self.get("numbering"),
            parent_id: self.get("selectedSubParameterId"),
            hasSubParameter: self.get("subParameterSwitch"),
            sn: self.get("sn") };

          $.ajax({
            url: ENV.APP.backendUrl + "/formParameters/" + self.get("model.formParameter.id"),
            type: "PUT",
            data: JSON.stringify(data),
            contentType: "application/json"
          }).then(function (response) {
            Notify.success("Η αποθήκευση έγινε με επιτυχία", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.set("showSpinner", false);
            self.transitionToRoute("form.build", self.get("model.formParameter.form.id"));
          }, function (error) {
            Notify.warning("Η αποθήκευση απέτυχε.", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.set("showSpinner", false);
          });
        }
      }
    });
  });