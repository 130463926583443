define("front/tests/unit/services/selected-unit-test", 
  ["ember-qunit"],
  function(__dependency1__) {
    "use strict";
    var moduleFor = __dependency1__.moduleFor;
    var test = __dependency1__.test;

    moduleFor("service:selected-unit", "SelectedUnitService", {});

    // Replace this with your real tests.
    test("it exists", function () {
      var service = this.subject();
      ok(service);
    });
    // Specify the other units that are required for this test.
    // needs: ['service:foo']
  });