define("front/routes/helper-test", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Route.extend({
      model: function () {
        return {
          name: "rebmE"
        };
      }
    });
  });