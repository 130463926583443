define("front/controllers/parameters/param-answer", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ObjectController.extend({

      isTextAnswer: (function () {
        if (this.get("type") === 1 || this.get("type") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isTextAreaAnswer: (function () {
        if (this.get("type") === 2 || this.get("type") === "2") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isRadioAnswer: (function () {
        if (this.get("type") === 3 || this.get("type") === "3") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isMultipleAnswer: (function () {
        if (this.get("type") === 4 || this.get("type") === "4") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isDateAnswer: (function () {
        if (this.get("type") === 5 || this.get("type") === "5") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isNumberAnswer: (function () {
        if (this.get("type") === 6 || this.get("type") === "6") {
          return true;
        } else {
          return false;
        }
      }).property("type")

    });
  });