define("front/routes/person/termination-reference", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return Ember.RSVP.hash({
          person: this.modelFor("person"),
          units: this.store.find("unit")
        });
      },
      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("remarks", null);
        controller.set("estimation", null);

        controller.set("person", model.person.person);
        controller.set("person_histories", model.person.person_histories);
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        controller.set("user", this.controllerFor("application").get("currentUserId"));
        controller.set("out_kethea", true);
      }
    });
  });