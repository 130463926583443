define("front/views/element-scrolling", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      didInsertElement: function () {
        $("#element-scrolling").ace_scroll({
          size: 400
        });
      }
    });
  });