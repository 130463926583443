define("front/templates/person/merge-warning", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n	<div class=\"text-warning bigger-110 orange\">\n		<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n		Προσοχή! Με την ενέργεια αυτή τα ΟΛΑ στοιχεία του φακέλου του μέλους <u>");
      stack1 = helpers._triageMustache.call(depth0, "content.person_from.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</u>\n		θα ανήκουν στο μέλος <u>");
      stack1 = helpers._triageMustache.call(depth0, "content.person_to.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</u>\n	</div>\n");
      return buffer;
      }

      stack1 = (helper = helpers['my-modal'] || (depth0 && depth0['my-modal']),options={hash:{
        'title': ("Προγραμματισμος"),
        'okText': ("Συγχώνευση"),
        'closeText': ("Ακύρωση"),
        'ok': ("merge"),
        'close': ("closeModal")
      },hashTypes:{'title': "STRING",'okText': "STRING",'closeText': "STRING",'ok': "STRING",'close': "STRING"},hashContexts:{'title': depth0,'okText': depth0,'closeText': depth0,'ok': depth0,'close': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "my-modal", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });