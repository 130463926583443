define("front/templates/pehi/create-event", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', escapeExpression=this.escapeExpression;


      data.buffer.push("    <div class=\"widget-box\">\n        <div class=\"widget-header\">\n        <h4 class=\"widget-title smaller\">Δημιουργία Συμβάντος</h4>\n        </div>\n\n        <div class=\"widget-body\">\n            <div class=\"widget-main\">\n                 <form  role=\"form\">\n                    <div class=\"form-group row\">\n                        <label for=\"reason\" class=\"col-sm-4\">Αλλαγή σε τύπο:</label>\n                        <div class=\"col-sm-8\">\n                            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("types"),
        'class': ("form-control"),
        'optionLabelPath': ("content.text"),
        'optionValuePath': ("content.type"),
        'prompt': ("Επιλογή Τύπου:"),
        'selectionBinding': ("selectedType")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                        </div>\n                    </div>\n\n                    <button type=\"submit\" class=\"btn btn-success btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "create", "selectedType", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">Δημιουργία</button>\n\n                </form>\n            </div>\n        </div>\n    </div>\n");
      return buffer;
      
    });
  });