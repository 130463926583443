define("front/serializers/document", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        category: { embedded: "always" },
        subcategory: { embedded: "always" },
        person: { embedded: "always" }
      }
    });
  });