define("front/routes/person-form-groups", 
  ["simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Ember = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");

        var self = this;
        var form_group_id = params.form_group_id;

        var str = form_group_id.split("_");

        var person_form_id = str[0];
        var group_id = str[1];

        var personFormPromise = this.store.find("personForm", person_form_id);
        var groupPromise = this.store.find("formGroup", group_id);

        return Ember.RSVP.all([personFormPromise, groupPromise]).then(function (results) {
          return Ember.RSVP.hash({
            personForm: results[0],
            single_group: results[1].get("id"),
            form: self.store.find("form", results[0].get("form.id")),
            groups: self.store.find("formGroup", {
              form_id: results[0].get("form.id"),
              person_form_id: results[0].get("id"),
              form_group_id: results[1].get("id"),
              results: "true"
            }),
            person: self.store.find("person", results[0].get("person.id"))
          });
        });
      },

      setupController: function (controller, model) {
        this.controllerFor("form-complete").set("personForm", model.personForm);
        this.controllerFor("form-complete").set("person", model.person);
        this.controllerFor("form-complete").set("form", model.form);
        this.controllerFor("form-complete").set("groups", model.groups);
        this.controllerFor("form-complete").set("parameters", model.parameters);
        this.controllerFor("form-complete").set("onlyParameter", false);
        this.controllerFor("form-complete").set("single_group", model.single_group);
      },

      renderTemplate: function () {
        this.render("form-complete", {
          controller: "form-complete"
        });
      },

      deactivate: function () {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
        this.controllerFor("form-complete").set("single_group", "");
      }



    });
  });