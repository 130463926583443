define("front/controllers/reports/unit", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      needs: ["application"],

      actions: {
        printReport: function () {
          var self = this;

          self.set("canValidate", false);
          self.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/reports/unit/" + self.get("currentUnit.id"), {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + self.get("session.access_token"),
              date_from: self.get("dateFrom"),
              date_to: self.get("dateTo")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });