define("front/templates/documents/document-upload", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      


      data.buffer.push("<span class=\"btn btn-success fileinput-button\">\n	<i class=\"glyphicon glyphicon-plus\"></i>\n	<span>Επιλογή αρχείου...</span>\n	<!-- The file input field used as target for the file upload widget -->\n	<input id=\"fileupload\" type=\"file\" name=\"files[]\" multiple>\n</span>\n<br>\n<br>\n<!-- The global progress bar -->\n<div id=\"progress\" class=\"progress\">\n	<div class=\"progress-bar progress-bar-success\"></div>\n</div>\n<!-- The container for the uploaded files -->\n<div id=\"files\" class=\"files\">\n	<p id=\"uploaded_filename\"></p>\n</div>\n<div id=\"upload_error\">\n	\n</div>\n");
      
    });
  });