define("front/controllers/form/build", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      filterParameters: null,

      canValidate: false,

      doReRender: false,

      validations: {
        selectedParameterId: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Παράμετρο." }
        }
      },

      hasSubParameter: (function () {
        if (this.get("subParameterSwitch") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("subParameterSwitch"),

      actions: {

        gotoShowFormParam: function (formParamId) {
          this.transitionToRoute("form-parameter.index", formParamId);
        },

        gotoEditFormParam: function (formParamId) {
          this.transitionToRoute("form-parameter.edit", formParamId);
        },

        filterParameters: function (autocomplete, term) {
          var self = this;
          if (term.length > 2) {
            this.store.find("question", { searchString: term }).then(function (questions) {
              self.set("filteredParameters", questions);
            }, function (reason) {});
          } else {
            self.set("filteredParameters", null);
          }
        },

        selectParameter: function (selection) {
          this.set("selectedParameter", selection.value);
          //alert(this.get('selectedParameter'));
        },

        filterSubParameters: function (autocomplete, term) {
          var self = this;
          //alert(term);
          if (term.length > 2) {
            this.store.find("question", { searchString: term, form_id: this.get("model.form.id") }).then(function (questions) {
              self.set("filteredSubParameters", questions);
            }, function (reason) {});
          } else {
            self.set("filteredSubParameters", null);
          }
        },

        selectSubParameter: function (selection) {
          this.set("selectedSubParameter", selection.value);
          //alert(this.get('selectedParameter'));
        },

        saveFormParameter: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);

          $(".autcomp input").val("");

          var self = this;

          this.validate().then(function () {
            var data = Ember.Object.create({
              parameter_id: self.get("selectedParameterId"),
              group_id: self.get("selectedGroup.id"),
              form_id: self.get("model.form.id"),
              numbering: self.get("numbering"),
              parent_id: self.get("selectedSubParameterId") });

            $.ajax({
              url: ENV.APP.backendUrl + "/formParameters",
              type: "POST",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Η αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.set("canValidate", false);

              self.set("numbering", null);
              //self.set('selectedGroup', null);
              self.set("selectedParameterId", null);
              self.set("selectedSubParameterId", null);
              self.set("filteredParameters", null);
              self.set("filteredSubParameters", null);

              self.set("showSpinner", false);

              self.send("reloadBuild");
            }, function (error) {
              Notify.warning("Η αποθήκευση απέτυχε.", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.set("canValidate", false);

              self.set("numbering", null);
              //self.set('selectedGroup', null);
              self.set("selectedParameterId", null);
              self.set("selectedSubParameterId", null);
              self.set("filteredParameters", null);
              self.set("filteredSubParameters", null);

              self.set("showSpinner", false);
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }

    });
  });