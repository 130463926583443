define("front/models/document", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      title: attr(),
      remarks: attr(),
      date: attr(),
      filename: attr(),
      fileHref: attr(),
      thumbHref: attr(),
      user_create: attr(),
      category: DS.belongsTo("category"),
      subcategory: DS.belongsTo("subcategory"),
      person: DS.belongsTo("person") });
  });