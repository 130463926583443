define("front/controllers/participation", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend({

      aa: (function () {
        return this.parentController.indexOf(this);
      }).property("parentController.[]"),

      actions: {
        save: function () {
          this.set("parentController.hideRadio", true);
          var model = this.get("model");
          var newDate = moment(model.get("date")).format("DD/MM/YYYY");
          var self = this;

          var data = Ember.Object.create({
            pers_id: this.get("model.persId"),
            date: newDate,
            unit_id: this.get("model.unit_id"),
            pres_id: this.get("model.pres_id"),
            presence: this.get("model.presence")
          });

          if (model.get("pres_id") === null) {
            $.ajax({
              url: ENV.APP.backendUrl + "/participations",
              async: true,
              type: "POST",
              data: JSON.stringify(data)
            }).then(function (response) {
              self.set("model.pres_id", response.participation.id);
              self.set("parentController.hideRadio", false);
            }, function (error) {
              Notify.warning("Η απουσία δεν καταχωρήθηκε", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.set("model.presence", "0");
              self.set("model.pres_id", null);
              self.set("parentController.hideRadio", false);
            });
          } else {
            if (self.get("model.presence") === "0") {
              $.ajax({
                url: ENV.APP.backendUrl + "/participations/" + model.get("pres_id"),
                type: "DELETE" }).then(function () {
                self.set("model.pres_id", null);
                self.set("parentController.hideRadio", false);
              }, function () {
                Notify.warning("Η απουσία δεν καταχωρήθηκε", {
                  closeAfter: 10000 // or set to null to disable auto-hiding
                });
                self.set("parentController.hideRadio", false);
              });
            } else {
              $.ajax({
                url: ENV.APP.backendUrl + "/participations/update/" + model.get("pres_id"),
                type: "POST",
                data: JSON.stringify(data)
              }).then(function (response) {
                model.set("presence", self.get("model.presence"));
                self.set("parentController.hideRadio", false);
              }, function () {
                Notify.warning("Η απουσία δεν καταχωρήθηκε", {
                  closeAfter: 10000 // or set to null to disable auto-hiding
                });
                if (data.presence === "1") {
                  model.set("presence", "0");
                } else {
                  model.set("presence", "1");
                }
                self.set("parentController.hideRadio", false);
              });
            }
          }
        }
      }
    });
  });