define("front/templates/ithaki-helplines/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                  <div class=\"col-sm-6 col-sm-offset-3\">\n                    <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.consultant_id.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                  </div>\n                  ");
      return buffer;
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                          <div class=\"col-sm-6 col-sm-offset-3\">\n                            <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.caller_city.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                          </div>\n                          ");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                  <div class=\"col-sm-6 col-sm-offset-3\">\n                                    <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.consultant.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                                  </div>\n                                  ");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                          <div class=\"col-sm-6 col-sm-offset-3\">\n                            <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.consultant.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                          </div>\n                          ");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                          <div class=\"col-sm-6 col-sm-offset-3\">\n                            <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.person_city.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                          </div>\n                          ");
      return buffer;
      }

    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                              <div class=\"col-sm-6 col-sm-offset-3\">\n                                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.person_region.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                              </div>\n                              ");
      return buffer;
      }

    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                              <div class=\"col-sm-6 col-sm-offset-3\">\n                                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.person_substance.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                              </div>\n                              ");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                              <div class=\"col-sm-6 col-sm-offset-3\">\n                                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.person_psycho.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                              </div>\n                              ");
      return buffer;
      }

    function program17(depth0,data) {
      
      
      data.buffer.push("\n                    Επιστροφή\n                  ");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">ΦΟΡΜΑ ΤΗΛΕΦΩΝΙΚΗΣ ΓΡΑΜΜΗΣ 1145</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n        <form class=\"form-horizontal\" role=\"form\">\n          <div class=\"row\">\n            <fieldset class=\"col-sm-6\">\n              <div class=\"form-group\">\n                <label class=\"col-sm-3 control-label\">Σύμβουλος</label>\n                <div class=\"col-sm-6\">\n                  ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("consultants"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control"),
        'prompt': ("Σύμβουλος"),
        'selection': ("consultant")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                  </div>\n                  ");
      stack1 = helpers['if'].call(depth0, "errors.consultant_id.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n\n              ");
      data.buffer.push(escapeExpression((helper = helpers['date-form-group'] || (depth0 && depth0['date-form-group']),options={hash:{
        'label': ("Ημερομηνία"),
        'value': ("date"),
        'error': ("errors.date"),
        'name': ("date")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-form-group", options))));
      data.buffer.push("\n              ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Διάρκεια"),
        'value': ("duration"),
        'error': ("errors.duration"),
        'name': ("duration")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n             \n            </fieldset>\n            <fieldset class=\"col-sm-6\">\n             \n            </fieldset>\n          </div>\n\n            <div class=\"row\" id=\"player_info\">\n                <fieldset class=\"col-sm-12\">\n                    <legend>Α. ΣΤΟΙΧΕΙΑ ΚΑΛΟΥΝΤΟΣ</legend>\n                    ");
      data.buffer.push(escapeExpression((helper = helpers['ithaki-radio-button-group'] || (depth0 && depth0['ithaki-radio-button-group']),options={hash:{
        'label': ("1. Έχει ξαναεπικοινωνήσει: "),
        'groupValue': ("call_recontact"),
        'name': ("caller_recontact"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "ithaki-radio-button-group", options))));
      data.buffer.push("\n                    ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("2. Φύλο: "),
        'elements': ("gender_enum"),
        'groupValue': ("caller_gender"),
        'name': ("caller_gender"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n                    ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("3. Ηλικία (99: Άγνωστο): "),
        'value': ("caller_age"),
        'error': ("errors.caller_age"),
        'name': ("caller_age")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                    \n                    <div class=\"mixed\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("4. Ιδιότητα: "),
        'elements': ("relation_enum"),
        'groupValue': ("caller_relation"),
        'name': ("caller_relation"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n\n                      <div class=\"form-group\">\n                        <label  class=\"col-sm-3 control-label\"></label>\n                        <div class=\"col-sm-6\">\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε: "),
        'value': ("caller_relation_other"),
        'error': ("errors.caller_relation_other"),
        'name': ("caller_relation_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                        </div>\n\n                      </div>\n                      \n                    </div>\n\n\n\n                     <div class=\"form-group\">\n                        <label class=\"col-sm-3 control-label\">5. Τόπος διαμονής: </label>\n                        <div class=\"col-sm-3\">\n                          ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("cities"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control combobox"),
        'prompt': ("Νομός"),
        'selection': ("caller_city")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                          </div>\n                          ");
      stack1 = helpers['if'].call(depth0, "errors.caller_city.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                      </div>\n\n                      <div class=\"form-group\">\n                            <label class=\"col-sm-3 control-label\"></label>\n                            <div class=\"col-sm-3\">\n                                  ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("regions"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control combobox"),
        'prompt': ("Περιφέρεια"),
        'selection': ("caller_region")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                  </div>\n                                  ");
      stack1 = helpers['if'].call(depth0, "errors.caller_region.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                              </div>\n                </fieldset>\n            </div>\n\n            <div class=\"row\" id=\"player_info\">\n                <fieldset class=\"col-sm-12\">\n                    <legend>Β. ΣΤΟΙΧΕΙΑ ΑΤΟΜΟΥ ΠΟΥ ΑΝΤΙΜΕΤΩΠΙΖΕΙ ΠΡΟΒΛΗΜΑ</legend>\n                    ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("1. Ηλικία (99: Άγνωστο): "),
        'value': ("person_age"),
        'error': ("errors.person_age"),
        'name': ("person_age")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                    ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("2. Φύλο: "),
        'elements': ("gender_enum"),
        'groupValue': ("person_gender"),
        'name': ("person_gender"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n                    <div class=\"form-group\">\n                        <label class=\"col-sm-3 control-label\">3. Υπηκοότητα: </label>\n                        <div class=\"col-sm-3\">\n                          ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("citizenships"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.text"),
        'classNames': ("form-control combobox"),
        'prompt': ("Χώρα"),
        'selection': ("person_citizenship")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                          </div>\n                          ");
      stack1 = helpers['if'].call(depth0, "errors.person_citizenship.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                      </div>\n                      \n                      <div class=\"mixed\">  \n                       ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("4. Οικογενειακή κατάσταση: "),
        'elements': ("marital_status_enum"),
        'groupValue': ("person_marital_status"),
        'name': ("marital_status"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n                         <div class=\"form-group\">\n                          <label  class=\"col-sm-3 control-label\"></label>\n                          <div class=\"col-sm-6\">\n                            ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε: "),
        'value': ("person_marit_status_other"),
        'error': ("errors.person_marit_status_other"),
        'name': ("person_marit_status_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                          </div>\n\n                        </div>\n                        \n                      </div>\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['ithaki-radio-button-group'] || (depth0 && depth0['ithaki-radio-button-group']),options={hash:{
        'label': ("5. Έχει παιδιά: "),
        'groupValue': ("person_children"),
        'name': ("person_is_psycho"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "ithaki-radio-button-group", options))));
      data.buffer.push("\n\n                      <div class=\"mixed\">\n                        ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("6. Εργασιακή κατάσταση: "),
        'elements': ("job_status_enum"),
        'groupValue': ("person_job_status"),
        'name': ("person_job_status"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n\n                        <div class=\"form-group\">\n                          <label  class=\"col-sm-3 control-label\"></label>\n                          <div class=\"col-sm-6\">\n                            ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε: "),
        'value': ("person_job_status_other"),
        'error': ("errors.person_job_status_other"),
        'name': ("person_status_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                          </div>\n\n                        </div>\n                        \n                      </div>\n\n                      <div class=\"form-group\">\n                        <label class=\"col-sm-3 control-label\">7. Τόπος διαμονής: </label>\n                        <div class=\"col-sm-3\">\n                          ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("cities"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control combobox"),
        'prompt': ("Νομός"),
        'selection': ("person_city")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                          </div>\n                          ");
      stack1 = helpers['if'].call(depth0, "errors.person_city.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                      </div>\n\n                      <div class=\"form-group\">\n                        <label class=\"col-sm-3 control-label\"></label>\n                        <div class=\"col-sm-3\">\n                              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("regions"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control combobox"),
        'prompt': ("Περιφέρεια"),
        'selection': ("person_region")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                              </div>\n                              ");
      stack1 = helpers['if'].call(depth0, "errors.person_region.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                          </div>\n\n                          <div class=\"mixed\">\n                            ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("8. Τύπος εξάρτησης: "),
        'elements': ("dependency_enum"),
        'groupValue': ("person_dependency"),
        'name': ("person_dependency"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n                            <div class=\"form-group\">\n                            <label  class=\"col-sm-3 control-label\"></label>\n                            <div class=\"col-sm-6\">\n                              ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε: "),
        'value': ("person_dependency_other"),
        'error': ("errors.person_dependency_other"),
        'name': ("person_dependency_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                            </div>\n\n                          </div>\n                        \n                      </div>\n                          <div class=\"form-group\">\n                            <label class=\"col-sm-3 control-label\">Αν χρήση ναρκωτικών ουσιών. Κύρια ουσία χρήσης: </label>\n                            <div class=\"col-sm-3\">\n                              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("substances"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.text"),
        'classNames': ("form-control combobox"),
        'prompt': ("Ουσία"),
        'selection': ("person_substance")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                              </div>\n                              ");
      stack1 = helpers['if'].call(depth0, "errors.person_substance.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                          </div>\n\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['ithaki-radio-button-group'] || (depth0 && depth0['ithaki-radio-button-group']),options={hash:{
        'label': ("10. Έχει κάποιο ψυχολογικό/ψυχιατρικό πρόβλημα: "),
        'groupValue': ("person_is_psycho"),
        'name': ("person_is_psycho"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "ithaki-radio-button-group", options))));
      data.buffer.push("\n\n                          <div class=\"form-group\">\n                            <label class=\"col-sm-3 control-label\">11. Αν ναι, τι τύπου: </label>\n                            <div class=\"col-sm-3\">\n                              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("psychos"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.text"),
        'classNames': ("form-control combobox"),
        'prompt': ("Ψυχιατρικά"),
        'selection': ("person_psycho")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                              </div>\n                              ");
      stack1 = helpers['if'].call(depth0, "errors.person_psycho.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                          </div>\n\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['ithaki-radio-button-group'] || (depth0 && depth0['ithaki-radio-button-group']),options={hash:{
        'label': ("12. Έχει φυλακιστεί ποτέ: "),
        'groupValue': ("person_prison"),
        'name': ("person_prison"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "ithaki-radio-button-group", options))));
      data.buffer.push("\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("13. Πόσους μήνες έχεις φυλακισθεί συνολικά στη ζωή σου: "),
        'value': ("person_prison_long_ago"),
        'error': ("errors.person_prison_long_ago"),
        'name': ("person_prison_long_ago")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                </fieldset>\n            </div>\n\n            <div class=\"row\" id=\"player_info\">\n                <fieldset class=\"col-sm-12\">\n                    <legend>Γ.ΥΠΗΡΕΣΙΑ ΠΟΥ ΔΟΘΗΚΕ</legend>\n                    \n                    <div class=\"mixed\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("1. Τύπος Υπηρεσίας: "),
        'elements': ("service_type_enum"),
        'groupValue': ("service_type"),
        'name': ("service_type"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n                      \n                      <div class=\"form-group\">\n                        <label  class=\"col-sm-3 control-label\"></label>\n                        <div class=\"col-sm-6\">\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε: "),
        'value': ("service_type_other"),
        'error': ("errors.service_type_other"),
        'name': ("service_type_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                        </div>\n\n                      </div>\n                    </div>\n                    \n                    <div class=\"mixed\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group'] || (depth0 && depth0['radio-button-group']),options={hash:{
        'label': ("2. Υπηρεσία Παραπομπής: "),
        'elements': ("service_transfer_enum"),
        'groupValue': ("service_transfer"),
        'name': ("service_transfer"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group", options))));
      data.buffer.push("\n\n                      <div class=\"form-group\">\n                        <label  class=\"col-sm-3 control-label\"></label>\n                        <div class=\"col-sm-6\">\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Εάν Άλλο συμπληρώστε:"),
        'value': ("service_transfer_other"),
        'error': ("errors.service_transfer_other"),
        'name': ("service_transfer_other")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n                        </div>\n\n                      </div>\n                    </div>\n\n\n                      <div class=\"form-group col-sm-12\">\n                        <label  class=\"col-sm-3 control-label\">3. Παρατηρήσεις/σχόλια</label>\n                         <div class=\"col-sm-6\">\n                            ");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'value': ("service_remarks"),
        'class': ("form-control"),
        'rows': (5)
      },hashTypes:{'value': "ID",'class': "STRING",'rows': "INTEGER"},hashContexts:{'value': depth0,'class': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n                        </div>\n                      </div>\n\n\n\n                </fieldset>\n            </div>\n\n          <div class=\"space-20\"></div>\n          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"form-group\">\n                <div class=\"col-sm-1\">\n                  <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n                </div>\n                <div class=\"col-sm-11\">\n                  <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                  ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(17, program17, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "ithaki-helplines.index", options) : helperMissing.call(depth0, "link-to", "ithaki-helplines.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n              </div>\n            </div>\n          </div>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });