define("front/models/category", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Category = DS.Model.extend({
      name: attr(),
      subcategories: DS.hasMany("subcategory")
    });

    __exports__["default"] = Category;
  });