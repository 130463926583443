define("front/components/program-structure", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({

      selectedProgram: null,
      programs: null,
      structures: null,
      selectedStructure: null,

      init: function () {
        this._super();

        this._setInitials(this.get("unit"));
      },

      progObs: (function () {
        if (this.get("selectedProgram")) {
          this._setStructures(this.get("selectedProgram"), null);
        }
      }).observes("selectedProgram"),

      strObs: (function () {
        console.log(this.selectedStructure);
        this.set("unit.prId", this.selectedProgram);
        this.set("unit.strId", this.selectedStructure);
      }).observes("selectedStructure", "unit"),

      _setInitials: function (unit) {
        var _this = this;
        var self = this;

        this.get("targetObject.store").find("program").then(function (programs) {
          _this.set("programs", programs);

          var selectedProgram = _this.get("unit.prId") ? _this.get("unit.prId") : programs.get("firstObject.id");
          _this.set("selectedProgram", selectedProgram);

          _this._setStructures(selectedProgram, _this.get("unit.strId"));
        });
      },

      _setStructures: function (prId, strId) {
        var _this2 = this;
        this.get("targetObject.store").find("structure", { program_id: prId }).then(function (structures) {
          _this2.set("structures", structures);

          var selectedStructure = strId ? strId : structures.get("firstObject.id");
          _this2.set("selectedStructure", selectedStructure);
        });
      }
    });
  });