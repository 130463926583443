define("front/views/notifications-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      didInsertElement: function () {
        if (this.get("controller.model.notifications")) {
          var data = this.get("controller.model.notifications").getEach("data");

          this.$().dataTable({
            bProcessing: true,
            aaData: data,
            iDisplayLength: 4,
            aoColumns: [{ sTitle: "Ημ/νία", mData: "date" }, { sTitle: "Περιγραφή", mData: "description" }]
          });
        }
      }
    });
  });