define("front/views/presence-radio", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      tagName: "input",
      type: "radio",
      attributeBindings: ["name", "type", "value", "checked:checked"],
      click: function () {
        var controller = this.get("controller");
        this.set("selection", this.$().val());
        if (this.get("selection") !== controller.get("model.presence")) {
          controller.send("save");
        }
      },
      checked: (function () {
        return this.get("value") === this.get("selection");
      }).property("value", "selection")
    });
  });