define("front/serializers/relationship", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        pers2: { embedded: "always" },
        pers1: { embedded: "always" },
        relationtype1: { embedded: "always" },
        relationtype2: { embedded: "always" }
      }
    });
  });