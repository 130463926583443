define("front/routes/physfiles/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return Ember.RSVP.hash({
          person: this.modelFor("person").person,
          physfile: this.store.find("physfile", params.physfile_id) });
      },

      setupController: function (controller, model) {
        controller.set("person", model.person);
        controller.set("physfile", model.physfile);

        controller.set("user", this.controllerFor("application").get("currentUserId"));
        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        console.log(model.person.get("isAddict"));
        if (model.person.get("isAddict") === "1") {
          controller.set("property", "isAddict");
        }
        if (model.person.get("isFamily") === "1") {
          controller.set("property", "isFamily");
        }
        if (model.person.get("isOther") === "1") {
          controller.set("property", "isOther");
        }
      },

      deactivate: function () {
        this.controllerFor("physfiles.edit").get("physfile").rollback();
      }

    });
  });