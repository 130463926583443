define("front/controllers/person/ascertainment", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.ObjectController.extend({

      person_history: null,
      person: null,
      user_current_unit: null,

      identicalUnits: (function () {
        if (this.get("person.currentUnit.id") === this.get("user_current_unit.id")) {
          return true;
        } else {
          return false;
        }
      }).property("person.currentUnit.id", "user_current_unit.id"),

      groupedResults: (function () {
        var result = [];

        this.get("person_history").forEach(function (item) {
          var indexDate = moment(item.get("startDate"), "YYYY-MM-DD HH:mm:ss").year();
          var hasType = result.findBy("year", indexDate);

          if (!hasType) {
            result.pushObject(Ember.Object.create({
              year: indexDate,
              contents: []
            }));
          }

          result.findBy("year", indexDate).get("contents").pushObject(item);
        });

        return result;
      }).property("person_history.[]"),

      has_history: (function () {
        if (this.get("person_history") !== null) {
          return true;
        } else {
          return false;
        }
      }).property("person_history")
    });
  });