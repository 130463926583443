define("front/models/choice", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Choice = DS.Model.extend({

      code: attr(),
      text: attr(),
      remarks: attr(),
      type: attr(),
      quesId: attr(),
      is_the_answer: attr(),
      the_answer_text: attr()

    });

    __exports__["default"] = Choice;
  });