define("front/routes/pehi", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.store.find("pehi-event", params.pehi_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", this.modelFor("person").person);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("pehis", this.modelFor("pehi-admin"));
        controller.set("hasEndEvent", this._checkEndDate(model, this.modelFor("pehi-admin")));
        controller.set("hasStartEvent", this._checkStartDate(model, this.modelFor("pehi-admin")));
      },

      _checkStartDate: function (model, pehis) {
        var startEvent = model.get("historyevents").filter(function (i) {
          return i.get("assignment.id") || i.get("serviceStatement.id");
        }).length !== 0;

        if (model.get("type") === "2") return startEvent;

        var sortedPehis = pehis.filter(function (h) {
          return h.get("type") === "1";
        }).sort(function (a, b) {
          return new Date(b.get("startDate")) - new Date(a.get("startDate"));
        });

        var previousHistoryIndex = sortedPehis.findIndex(function (h) {
          return h.get("id") === model.get("id");
        }) + 1;

        if (previousHistoryIndex >= sortedPehis.length) return startEvent;

        var previousHistory = sortedPehis[previousHistoryIndex];

        var checkTrans = previousHistory.get("historyevents").filter(function (e) {
          return e.get("transfer");
        }).length > 0;

        return startEvent || checkTrans;
      },

      _checkEndDate: function (model, pehis) {
        return model.get("historyevents").filter(function (i) {
          var end = i.get("transfer.id") || i.get("graduation.id") || i.get("withdrawal.id") || i.get("transport.id") || i.get("release.id") || i.get("closure.id") || i.get("serviceInterrupt.id");


          if (end) return end;

          var comp = i.get("completion.id");

          if (comp) {
            return pehis.filter(function (ph) {
              return ph.get("historyevents").filter(function (i) {
                if (i.get("completion.id")) {
                  return i.get("completion.id") === comp;
                }
                return false;
              });
            }).get("firstObject.unit_comp_can_close");
          }

          return false;
        }).length !== 0;
      },

      deactivate: function () {
        var model = this.controllerFor("pehi").get("model");
        model.rollback();
      }
    });
  });