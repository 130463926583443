define("front/templates/helplines/report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n            <h3>ΓΕΝΙΚΗ ΑΝΑΦΟΡΑ</h3>\n            <p>\n            Τα στοιχεία απεικονίζουν το χρονικό διάστημα απο ");
      stack1 = helpers._triageMustache.call(depth0, "dateFrom", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" έως ");
      stack1 = helpers._triageMustache.call(depth0, "dateTo", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" λειτουργίας της Τηλεφωνικής Γραμμής για τα Τυχερά Παιχνίδια 1114.\n            </p>\n\n            <p>\n            Η Τηλεφωνική γραμμή δέχθηκε συνολικά <b>");
      stack1 = helpers._triageMustache.call(depth0, "report.count", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήσεις από όλη την Ελλάδα στο διάστημα από <b>");
      stack1 = helpers._triageMustache.call(depth0, "dateFrom", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> έως <b>");
      stack1 = helpers._triageMustache.call(depth0, "dateTo", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b>. Απο αυτές:\n            </p>\n            <ol>\n              <li>\n                Οι <b>");
      stack1 = helpers._triageMustache.call(depth0, "report.gambling_calls_count", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλησεις αφορούσαν προβλήματα για τον τζόγο, εκ των οποίων:\n                <ul>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.first_calls", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις ήταν πρώτη επικοινωνία προς την τηλεφωνική γραμμή</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.repeated_calls", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήσεις ήταν από άτομα που είχαν επικοινωνήσει τουλάχιστον μία φορά στο παρελθόν με την τηλεφωνική γραμμή</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.uknown_calls", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις ήταν άγνωστο αν αφορούσε πρώτη ή επαναλαμβανόμενη επικοινωνία με την τηλεφωνική γραμμή</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.caller_is_player", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις ήταν από παίκτες</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.caller_is_not_player", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις ήταν από συγγενικά και φιλικά πρόσωπα</li>\n                </ul>\n              </li>\n\n              <li>\n                Συγκεκριμένα, για τους ιδιοκτήτες πρακτορείου ΟΠΑΠ\n                <ul>\n                  <li><b>2.1 Επαγγελματική συσχέτιση του παίκτη με τα τυχερά παιχνίδια.</b></li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_is_agent", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/κλήσεις αφορούσαν ιδιοκτήτη πρακτορείου ΟΠΑΠ</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_works", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/κλήσεις αφορούσαν εργαζόμενο σε πρακτορείο ΟΠΑΠ</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_vlt_agent", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν παίκτη ιδιοκτήτη καταστήματος VLTs</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_vlt_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν παίκτη εργαζόμενο σε κατάστημα VLTs</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_casino_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν παίκτη εργαζόμενο σε καζίνο</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_bet_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν παίκτη εργαζόμενο σε στοιχηματική εταιρεία</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_caller_no_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν παίκτη που δεν εργάζεται σε χώρο τζόγου</li>\n\n                  <li><b>2.2 Επαγγελματική συσχέτιση συγγενικών προσώπων με τυχερά παιχνίδια.</b></li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_is_agent", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/κλήσεις αφορούσαν ιδιοκτήτη πρακτορείου ΟΠΑΠ</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_works", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/κλήσεις αφορούσαν εργαζόμενο σε πρακτορείο ΟΠΑΠ</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_vlt_agent", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν συγγενή ιδιοκτήτη καταστήματος VLTs</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_vlt_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν συγγενή εργαζόμενο σε κατάστημα VLTs</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_casino_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν συγγενή εργαζόμενο σε καζίνο</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_bet_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν συγγενή εργαζόμενο σε στοιχηματική εταιρεία</li>\n                  <li><b>");
      stack1 = helpers._triageMustache.call(depth0, "report.opap_relative_no_worker", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν συγγενή που δεν εργάζεται σε χώρο τζόγου</li>\n                </ul>\n              </li>\n\n              <li>\n                <b>");
      stack1 = helpers._triageMustache.call(depth0, "report.other_calls_count", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</b> κλήση/εις αφορούσαν εξάρτηση από το αλκοόλ, το ίντερνετ ή άλλα ψυχολογικά προβλήματα\n              </li>\n            </ol>\n            <div class=\"space-10\"></div>\n            <button class=\"btn btn-purple\"");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printGeneralReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n            <div class=\"space-10\"></div>\n          ");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">ΓΡΑΜΜΗ ΨΥΧΟΛΟΓΙΚΗΣ ΥΠΟΣΤΗΡΙΞΗΣ ΓΙΑ ΤΟΝ ΤΖΟΓΟ 1114 - Αναφορά</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("showReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n          <hr />\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-10 col-sm-offset-1\">\n          ");
      stack1 = helpers['if'].call(depth0, "report", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });