define("front/templates/error", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n												<i class=\"ace-icon fa fa-tachometer\"></i>\n												Κεντρική σελίδα\n											");
      }

      data.buffer.push("<div class=\"page-content-area\">\n						<div class=\"row\">\n							<div class=\"col-xs-12\">\n								<!-- PAGE CONTENT BEGINS -->\n\n								<!-- #section:pages/error -->\n								<div class=\"error-container\">\n									<div class=\"well\">\n										<h1 class=\"grey lighter smaller\">\n											<span class=\"blue bigger-125\">\n												<i class=\"ace-icon fa fa-sitemap\"></i>\n												");
      stack1 = helpers._triageMustache.call(depth0, "stack", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n											</span>\n											");
      stack1 = helpers._triageMustache.call(depth0, "message", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										</h1>\n\n										<hr />\n										<h3 class=\"lighter smaller\">Αυτο πού ψάχνετε δεν υπάρχει</h3>\n\n										<div>\n\n											<div class=\"space\"></div>\n											<h4 class=\"smaller\">Δοκιμάστε τα εξής:</h4>\n\n											<ul class=\"list-unstyled spaced inline bigger-110 margin-15\">\n												<li>\n													<i class=\"ace-icon fa fa-hand-o-right blue\"></i>\n													Βεβαιωθείτε πως αυτό που ψάχνετε υπάρχει\n												</li>\n\n												<li>\n													<i class=\"ace-icon fa fa-hand-o-right blue\"></i>\n													Διαβάστε τις οδηγίες\n												</li>\n\n												<li>\n													<i class=\"ace-icon fa fa-hand-o-right blue\"></i>\n													Ενημερώστε τον διαχειριστή\n												</li>\n											</ul>\n										</div>\n\n										<hr />\n										<div class=\"space\"></div>\n\n										<div class=\"center\">\n									\n											");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "dashboard", options) : helperMissing.call(depth0, "link-to", "dashboard", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										</div>\n									</div>\n								</div>\n\n								<!-- /section:pages/error -->\n\n								<!-- PAGE CONTENT ENDS -->\n							</div><!-- /.col -->\n						</div><!-- /.row -->\n					</div><!-- /.page-content-area -->");
      return buffer;
      
    });
  });