define("front/views/persons/simplemerge-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.persons"),

      didInsertElement: function () {
        var self = this;
        this.$().on("click", "#selectPerson", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("selectPerson", id);
        });

        var data;
        if (this.get("controller.persons")) {
          data = this.get("controller.persons").getEach("data");
        }

        this.$().dataTable({
          bProcessing: true,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          bAutoWidth: false,
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Επώνυμο", mData: "lastName" }, { sTitle: "Όνομα", mData: "firstName" }, { sTitle: "Κωδ. Φυσ. Φακ.", mData: "displayPhysfiles" }, { sTitle: "Κωδ. ΠΡΟ.ΚΕ.ΚΑ.", mData: "ketheaCode" }, { sTitle: "Ημ.Γεν.", mData: "birthDate" }, { sTitle: "Πατρώνυμο", mData: "fatherName" }, { sTitle: "Τρεχ. κατάσταση", mData: function (data, type, row) {
              if (data.currentUnit) {
                return data.currentUnit._data.name;
              } else {
                return null;
              }

            } },
          /*{
          	"mdata": null,
          	"mRender": function ( data, type, row ) {
          			return "<button class='edit-button btn btn-info' data-value='"+row.id+"'>edit</button>&nbsp;<button class='delete-button btn btn-danger' data-value='"+row.id+"'>delete</button>";
          	}
          	},*/
          {
            mdata: null,
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue' id='selectPerson' href='#' title='Επιλογή' data-value='" + row.id + "'><i class='ace-icon fa fa-hand-o-right bigger-130'></i></a></div>";
            }

          }]
        });
      }
    });
  });