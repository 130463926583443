define("front/routes/trpr-report", 
  ["simple-auth/mixins/authenticated-route-mixin","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return [];
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("user_name", this.controllerFor("application").get("currentUser.name"));
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
      }

    });
  });