define("front/templates/person/transition", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n  <div class=\"row\">\n    <div class=\"col-xs-12 col-md-12\">\n\n        <div class=\"widget-box\">\n          <div class=\"widget-header\">\n            <h4 class=\"widget-title smaller\">Αλλαγή Φάσης - ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n          </div>\n          <div class=\"widget-body\">\n            <div class=\"widget-main\">\n\n              <form class=\"form-horizontal\" role=\"form\">\n\n            ");
      data.buffer.push(escapeExpression((helper = helpers['date-form-group'] || (depth0 && depth0['date-form-group']),options={hash:{
        'label': ("Ημερομηνία"),
        'value': ("date"),
        'error': ("errors.date"),
        'name': ("date")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-form-group", options))));
      data.buffer.push("\n\n            ");
      data.buffer.push(escapeExpression((helper = helpers['textarea-form-group'] || (depth0 && depth0['textarea-form-group']),options={hash:{
        'label': ("Σχόλια"),
        'value': ("comments"),
        'error': ("errors.comments"),
        'name': ("comments"),
        'rows': (5)
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING",'rows': "INTEGER"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea-form-group", options))));
      data.buffer.push("\n\n            <div class=\"form-group\">\n              <div class=\"col-sm-offset-2 col-sm-6\">\n\n                <button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "transition", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αλλαγή Φάσης</button>\n                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n              </div>\n              <div class=\"col-sm-2\">\n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n              </div>\n            </div>\n\n\n\n            </div>\n          </div>\n        </div>\n\n    </div>\n  </div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n                  Ακύρωση\n                ");
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n  <div class=\"page-header\">\n    <h1>");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Αλλαγή Φάσης</small></h1>\n\n  </div>\n\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Ο χρήστης δεν έχει δικαίωμα να πραγματοποιήσει την ενέργεια αυτή\n  </div>\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });