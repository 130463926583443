define("front/templates/form-complete/date", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"input-group date1\">\n	");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker-base", {hash:{
        'value': ("the_answer_text"),
        'id': ("setId"),
        'name': ("setName")
      },hashTypes:{'value': "ID",'id': "ID",'name': "ID"},hashContexts:{'value': depth0,'id': depth0,'name': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n	<span class=\"input-group-addon\"><i class=\"fa fa-calendar\">\n	</i></span>\n</div>");
      return buffer;
      
    });
  });