define("front/models/person_group", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var PersonGroup = DS.Model.extend({
      name: attr(),
      user_id: attr(),
      persons: DS.hasMany("person"),
      hashGroupId: (function () {
        return "#group_" + this.get("id");
      }).property("id"),
      groupId: (function () {
        return "group_" + this.get("id");
      }).property("id"),
      isSystemGroup: (function () {
        var id = this.get("id");

        if (id === "100000001" || id === "100000002" || id === "100000003") {
          return true;
        }
        return false;
      }).property("id"),

      isUnitGroup: (function () {
        var id = this.get("id");

        if (id === "100000001") {
          return true;
        }
        return false;
      }).property("id")
    });

    __exports__["default"] = PersonGroup;
  });