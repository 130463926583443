define("front/controllers/person/revoke-reference", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      needs: ["application", "person"],
      person_histories: null,
      person: null,
      user_id: null,
      user_current_unit: null,
      cancelReasons: null,
      cancelReason: null,
      actions: {
        revoke: function () {
          var data = Ember.Object.create({
            user_id: this.get("user_id"),
            pers_id: this.get("model.person.id"),
            date: moment().format("DD/MM/YYYY"),
            unit_id: this.get("user_current_unit.id"),
            canr_id: this.get("cancelReason.id")
          });

          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/assignmentRevoke",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Η παραπομπή  ακυρώθηκε με επιτυχία", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            var notification = self.get("controllers.application.notifications").filterBy("pevn_id", self.get("transfer.pevn_id")).get("firstObject");
            self.set("controllers.application.notifications_unseen_cnt", self.get("controllers.application.notifications_unseen_cnt") - 1);
            if (notification) {
              self.get("controllers.application.notifications").removeObject(notification);
            }

            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("model.person.id"));
          }, function () {
            Notify.error("Η παραπομπή δεν ακυρώθηκε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });