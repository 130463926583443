define("front/controllers/users/edit", 
  ["ember-notify","front/mixins/user","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];
    var ENV = __dependency3__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, UserMixin, {
      canValidate: false,

      validations: {
        password: {
          confirmation: true
        }
      },

      selectedUnits: (function () {
        return this.get("units");
      }).property("units"),

      sortedPrograms: (function () {
        return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
          sortProperties: ["name"],
          sortAscending: true,
          content: this.get("model.programs")
        });
      }).property("model.programs"),


      actions: {

        addUnitsToUser: function () {
          var self = this;

          //var units = this.get('units').map(function (x) { return x.toJSON(); });
          var units = [];
          this.get("units").forEach(function (unit) {
            units.addObject(unit.get("_data"));
          });
          var data = Ember.Object.create({
            units: units
          });


          $.ajax({
            url: ENV.APP.backendUrl + "/users/" + self.get("user.id") + "/updateUnits",
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Οι υπηρεσίες προστέθηκαν", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
            self.set("canValidate", false);
            self.set("showSpinner", false);
            self.send("reload");
            self.transitionToRoute("users.edit", self.get("user.id"));
          }, function (error) {
            Notify.alert(error.responseJSON.message, {
              closeAfter: 10000
            });
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        },

        changePassword: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);

          this.set("canValidate", true);

          var self = this;
          var data = Ember.Object.create({
            password: this.get("password") });

          this.validate().then(function () {
            $.ajax({
              url: ENV.APP.backendUrl + "/users/" + self.get("user.id") + "/changePassword",
              type: "POST",
              dataType: "json",
              contentType: "application/json",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            }, function (error) {
              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        },

        saveData: function () {
          var self = this;

          var user = this.get("user");
          user.set("unit_id", this.get("currentUnit.id"));

          user.save().then(function () {
            self.transitionToRoute("users.index");
            Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
              closeAfter: 10000
            });
            self.set("canValidate", false);
            self.set("showSpinner", false);
          }, function (error) {
            Notify.alert(error.responseJSON.message, {
              closeAfter: 10000
            });
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }
    });
  });