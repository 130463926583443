define("front/templates/components/program-structure", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"form-group\">\n    <label for=\"currentUnit\" class=\"col-sm-3 control-label\">Κέντρα:\n    </label>\n    <div class=\"col-sm-6\">\n    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("programs"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'valueBinding': ("selectedProgram")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'valueBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'valueBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n    </div>\n\n    \n    <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n</div>\n\n<div class=\"form-group\">\n    <label for=\"currentUnit\" class=\"col-sm-3 control-label\">Δομές:\n    </label>\n    <div class=\"col-sm-6\">\n    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("structures"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'valueBinding': ("selectedStructure")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'valueBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'valueBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n    </div>\n    \n    <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n</div>");
      return buffer;
      
    });
  });