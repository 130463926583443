define("front/templates/person-group/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n	<div class=\"alert alert-danger\">Δεν μπορείτε να διαχειριστείτε ομάδα συστήματος</div>\n");
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"widget-box widget-color-blue\">\n	<div class=\"widget-header\">\n		<h4 class=\"widget-title smaller\">Διαχείριση Ομάδας - ");
      stack1 = helpers._triageMustache.call(depth0, "model.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n	</div>\n	<div class=\"widget-body\">\n		<div class=\"widget-main\">\n			<form class=\"form-horizontal\" role=\"form\">\n				\n				<div class=\"form-group\">\n					<label for=\"name\" class=\"col-sm-3 control-label\">Όνομα:</label>\n					\n					<div class=\"col-sm-5\">\n						");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("model.name"),
        'id': ("name")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n					</div>\n					\n					<div class=\"col-sm-6 col-sm-offset-3\">\n						");
      stack1 = helpers['if'].call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</div>\n				</div>\n\n				<div class=\"form-group\">\n					<div class=\"col-sm-offset-2 col-sm-1\">\n						<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n					</div>\n					<div class=\"col-sm-6\">\n						\n						<button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n						\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person-groups", options) : helperMissing.call(depth0, "link-to", "person-groups", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						\n					</div>\n				</div>\n\n			</form>\n		</div>\n	</div>\n</div>\n\n\n<div class=\"space-20\"></div>\n\n<div class=\"widget-box widget-color-blue\">\n	<div class=\"widget-header\">\n		<h4 class=\"widget-title smaller\">Διαχείριση Μελών Ομάδας - ");
      stack1 = helpers._triageMustache.call(depth0, "model.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n	</div>\n	<div class=\"widget-body\">\n		<div class=\"widget-main\">\n			<div class=\"row\">\n				<div class=\"col-sm-6\">\n					<div class=\"widget-box widget-color-blue col-sm-12\" id=\"person-groups\">\n						<div class=\"widget-header\">\n							<h4 class=\"widget-title bigger lighter\">\n								Μέλη Δομής\n							</h4>\n						</div>\n			\n						<div class=\"widget-body\">\n							<div class=\"dd sender\">\n								<ul class=\"dd-list\">\n									");
      stack1 = helpers.each.call(depth0, "person", "in", "remainingPersons", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("		\n								</ul>\n							</div>\n						</div>\n					</div>\n				</div>\n\n				<div class=\"col-sm-6\">\n					<div class=\"widget-box widget-color-blue col-sm-12\" id=\"person-groups\">\n						<div class=\"widget-header\">\n							<h4 class=\"widget-title bigger lighter\">\n								Μέλη Ομάδας\n							</h4>\n						</div>\n			\n						<div class=\"widget-body\">\n							<div class=\"dd target\">\n								");
      stack1 = helpers['if'].call(depth0, "selectedPersons", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(15, program15, data),fn:self.program(11, program11, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n");
      return buffer;
      }
    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n						");
      return buffer;
      }

    function program6(depth0,data) {
      
      
      data.buffer.push("Επιστροφή");
      }

    function program8(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n										");
      stack1 = (helper = helpers['drag-element'] || (depth0 && depth0['drag-element']),options={hash:{
        'item': ("person"),
        'elementName': ("person.fullName")
      },hashTypes:{'item': "ID",'elementName': "ID"},hashContexts:{'item': depth0,'elementName': depth0},inverse:self.noop,fn:self.program(9, program9, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "drag-element", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									");
      return buffer;
      }
    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n										<li class=\"dd-item drag-element\">\n											<div class=\"dd-handle\">\n												");
      stack1 = helpers._triageMustache.call(depth0, "person.lastName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "person.firstName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "person.ketheaCode", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n												<a href=\"#\" class=\"green pull-right\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "clickAddPerson", "person.id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n													<i class=\"fa ace-icon fa-arrow-right bigger-130\"></i>\n												</a>\n											</div>\n										</li>\n										");
      return buffer;
      }

    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<ul class=\"dd-list\">\n										");
      stack1 = helpers.each.call(depth0, "person", "in", "selectedPersons", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									</ul>\n								");
      return buffer;
      }
    function program12(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n											");
      stack1 = (helper = helpers['drop-element'] || (depth0 && depth0['drop-element']),options={hash:{
        'dropAction': ("addPerson"),
        'elementName': ("person.fullName")
      },hashTypes:{'dropAction': "STRING",'elementName': "ID"},hashContexts:{'dropAction': depth0,'elementName': depth0},inverse:self.noop,fn:self.program(13, program13, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "drop-element", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										");
      return buffer;
      }
    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n												<li class=\"dd-item\"");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("person.domId")
      },hashTypes:{'id': "ID"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n													<div class=\"dd-handle\">\n														");
      stack1 = helpers._triageMustache.call(depth0, "person.lastName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ");
      stack1 = helpers._triageMustache.call(depth0, "person.firstName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "person.ketheaCode", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n														<a href=\"#\" class=\"red pull-right\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "removePerson", "person.id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n															<i class=\"glyphicon ace-icon glyphicon-remove bigger-130\"></i>\n														</a>\n													</div>\n												</li>\n											");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n									<ul class=\"dd-empty\">\n										");
      stack1 = (helper = helpers['drop-element'] || (depth0 && depth0['drop-element']),options={hash:{
        'dropAction': ("addPerson"),
        'elementName': ("warning")
      },hashTypes:{'dropAction': "STRING",'elementName': "STRING"},hashContexts:{'dropAction': depth0,'elementName': depth0},inverse:self.noop,fn:self.program(16, program16, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "drop-element", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									</ul>\n								");
      return buffer;
      }
    function program16(depth0,data) {
      
      
      data.buffer.push("\n											<li class=\"alert dd-item alert-warning\" id=\"emptyWarn\">Προσθέστε μέλη στην ομάδα</li>\n										");
      }

      stack1 = helpers['if'].call(depth0, "isSystemGroup", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });