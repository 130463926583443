define("front/controllers/person-groups", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({
      actions: {
        deleteGroup: function (id) {
          this.send("showModal", "person-groups/delete-modal", id);
        }
      }
    });
  });