define("front/controllers/person/search-officials", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      actions: {
        gotoOfficialDoc: function (id) {} }
    });
    //this.transitionTo('form-complete', id, this.get('person.id'));
  });