define("front/routes/medical-records", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function (params) {
        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        return this.store.find("medical_record", { pers_id: person_id });
      },

      setupController: function (controller, model) {
        controller.set("medicalRecords", model);
        controller.set("person", this.modelFor("person").person);
      },
      actions: {
        goTo: function (medicalRecord) {
          this.transitionTo("medical-record.edit", medicalRecord.get("id"));
        },

        routeRefresh: function () {
          this.refresh();
        }
      }
    });
  });