define("front/mixins/person", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Mixin.create({

      isAdmin: (function () {
        //mocked for testing purpose
        return this.get("isAdmin");
      }).property("isAdmin"),

      state: (function () {
        var state = [];

        if (this.get("person.isAddict")) {
          state.push("Μέλος");
        }

        if (this.get("person.isFamily")) {
          state.push("Οικογένεια");
        }

        if (this.get("person.isOther")) {
          state.push("Άλλο");
        }

        return state.join();
      }).property("person.isAddict", "person.isFamily", "person.isOther"),

      canEditData: (function () {
        var user_create = this.get("person.user_create");
        var admin = this.get("isAdmin");
        var cu = this.get("user_id");

        if (admin) {
          return true;
        }

        if (user_create === cu) {
          return true;
        }
        return false;
      }).property("isAdmin", "person.user_create", "user_id"),

      isLocked: (function () {
        var locked = this.get("person.locked");

        if (locked === "1") {
          return true;
        }

        return false;
      }).property("person.locked"),

      lockAdmin: (function () {
        var locked = this.get("person.locked");
        var admin = this.get("isAdmin");

        if (admin) {
          return false;
        }

        if (locked === "1") {
          return true;
        }

        return false;
      }).property("person.locked", "isAdmin"),

      hasPhysfiles: (function () {
        if (Ember.empty(this.get("person.physfiles"))) {
          return false;
        } else {
          return true;
        }
      }).property("person.physfiles"),

      hasSameProgram: (function () {}).property("person.currentUnit", "user_current_unit"),

      activeHistory: (function () {
        var ah = this.get("person_histories").filterBy("unit_id", this.get("user_current_unit.id")).filterBy("type", "1").filterBy("endDate", null);

        if (!Ember.empty(ah)) {
          return ah.get("firstObject");
        } else {
          return false;
        }
      }).property("person_histories"),

      hasService: (function () {
        var servc = this.get("person_histories").filterBy("unit_id", this.get("user_current_unit.id")).filterBy("type", "2").filterBy("endDate", null);

        if (!Ember.empty(servc)) {
          return true;
        } else {
          return false;
        }
      }).property("person_histories"),

      hasCurrentUnit: (function () {
        var pcu = this.get("person.currentUnit");

        if (!Ember.empty(pcu)) {
          return true;
        } else {
          return false;
        }
      }).property("person.currentUnit"),

      unit_imported: (function () {
        var t = this.get("transfers").filterBy("unitTo", this.get("user_current_unit.id"));

        if (!Ember.empty(t)) {
          return t.get("firstObject");
          //return true;
        } else {
          return false;
        }
      }).property("transfers", "user_current_unit.id"),

      unit_physical: (function () {
        var t = this.get("transfers").filterBy("unitTo", this.get("selectedUnit.id"));

        if (!Ember.empty(t)) {
          return t.get("firstObject");
          //return true;
        } else {
          return false;
        }
      }).property("transfers", "user_current_unit.id"),

      imported: (function () {
        var t = this.get("transfers");

        if (!Ember.empty(t)) {
          return true;
        } else {
          return false;
        }
      }).property("transfers"),

      available_units: (function () {
        var u = this.get("units");
        if (!Ember.empty(u)) {
          return u.rejectBy("id", this.get("user_current_unit.id"));
        } else {
          return [];
        }
      }).property("units", "user_current_unit"),



      complex: (function () {
        if (this.get("hasCurrentUnit") === false && this.get("imported") === false) {
          return true;
        } else {
          return false;
        }
      }).property("hasCurrentUnit", "imported"),

      identicalUnits: (function () {
        if (this.get("person.currentUnit.id") === this.get("user_current_unit.id")) {
          return true;
        } else {
          return false;
        }
      }).property("person.currentUnit.id", "user_current_unit.id")
    });
  });