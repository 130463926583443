define("front/templates/transitional-presences", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n  <div class=\"col-sm-12\">\n    <div class=\"page-header\">\n        <h1>Μεταβατικό Σχολείο\n          <small><i class=\"ace-icon fa fa-angle-double-right\"></i>&nbsp;");
      stack1 = helpers._triageMustache.call(depth0, "current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>\n          <div class=\"pull-right\">\n            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary btn-sm pull-right")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "trpr-report", options) : helperMissing.call(depth0, "link-to", "trpr-report", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </div>\n        </h1>\n    </div>\n  </div>\n  <div class=\"space-20\"></div>\n  <div id='participations' class=\"col-sm-12\">\n    <div class=\"clearfix\">\n      <div class=\"pull-left\">\n        <div class=\"form-group\">\n          <label for=\"document_date\" class=\"col-sm-1 control-label\">Ημέρα:</label>\n          <div class=\"col-sm-3\">\n            <div class=\"input-group date\" id=\"date-today\">\n              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("presenceDate"),
        'id': ("presenceDate")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n              <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n            </div>\n          </div>\n        </diV>\n      </div>\n    </div>\n    <div class=\"hr dotted\"></div>\n  </div>\n  <div class=\"col-sm-12\">\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n\n\n        <h5 class=\"widget-title bigger lighter\">\n          <i class=\"glyphicon glyphicon-check\"></i>\n          Παρουσιολόγιο\n        </h5>\n      </div>\n\n      <div class=\"widget-body\">\n        <div class=\"widget-main no-padding\">\n          <table class=\"table table-striped table-bordered table-hover\">\n            <thead class=\"thin-border-bottom\">\n              <tr>\n                <td>\n                  A/A\n                </td>\n                <td>\n                  <i class=\"ace-icon fa fa-user\"></i>\n                  Ονοματεπώνυμο\n                </td>\n                <td>\n                  <i class=\"glyphicon glyphicon-barcode\"></i>\n                  Κωδικός\n                </td>\n\n                <td>\n                  <i class=\"glyphicon glyphicon-check\"></i>\n                  Παρουσία\n                </td>\n              </tr>\n            </thead>\n            <tbody>\n              ");
      stack1 = helpers.each.call(depth0, "transitional-presence", "in", "controller", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </tbody>\n          </table>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("Αναφορά");
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n                <tr>\n                  <td>");
      data.buffer.push(escapeExpression((helper = helpers['add-index'] || (depth0 && depth0['add-index']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "transitional-presence.aa", options) : helperMissing.call(depth0, "add-index", "transitional-presence.aa", options))));
      data.buffer.push("</td>\n                  <td>");
      stack1 = helpers._triageMustache.call(depth0, "transitional-presence.person_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                  <td>");
      stack1 = helpers._triageMustache.call(depth0, "transitional-presence.person_kethea_code", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                  <td>\n                    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': ("0"),
        'groupValue': ("transitional-presence.presence_type"),
        'changed': ("delete")
      },hashTypes:{'value': "STRING",'groupValue': "ID",'changed': "STRING"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': ("1"),
        'groupValue': ("transitional-presence.presence_type")
      },hashTypes:{'value': "STRING",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(7, program7, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    <div class=\"input-group\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("text"),
        'value': ("transitional-presence.presence_hours"),
        'disabled': ("transitional-presence.hour_dis"),
        'classNames': ("form-control col-sm-2")
      },hashTypes:{'type': "STRING",'value': "ID",'disabled': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'value': depth0,'disabled': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n                      <span class=\"input-group-btn\">\n                      <button class=\"btn btn-success btn-sm\" type=\"button\" title=\"Αποθήκευση\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n                        <span class=\"fa fa-floppy-o\"></span>\n                      </button>\n                    </span>\n                    </div>\n                  </td>\n                </tr>\n\n              ");
      return buffer;
      }
    function program5(depth0,data) {
      
      
      data.buffer.push("Απών");
      }

    function program7(depth0,data) {
      
      
      data.buffer.push("Παρών");
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Η συγκεκριμένη υπηρεσία (");
      stack1 = helpers._triageMustache.call(depth0, "current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("), δεν έχει μεταβατικό σχολείο\n  </div>\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "current_unit.haveTransitionalPresences", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(9, program9, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });