define("front/controllers/expedition-approaches/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["expedition-approaches/index"],

      getName: (function () {
        var self = this;

        this.store.find("expedition-approach", this.get("content")).then(function (response) {
          self.set("expeditionApproachName", response.get("date") + " - " + response.get("comments"));
        });
      }).observes("content"),

      parameterName: null,

      actions: {
        deleteExpedition: function () {
          var self = this;
          var expdId = this.get("content");

          this.store.find("expedition-approach", expdId).then(function (response) {
            response.destroyRecord().then(function () {
              self.toggleProperty("controllers.expedition-approaches/index.doReRender");

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");

              self.send("reloadExpeditions");
            })["catch"](function (response) {
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή  είναι αδύνατη!");
            });
          });
        }
      }


    });
  });