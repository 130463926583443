define("front/templates/person/termination", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n          <form class=\"form-horizontal\" role=\"form\">\n\n            <div class=\"form-group\">\n              <label for=\"unit_assignment_date\" class=\"col-sm-2 control-label\">Ημ/νία αποχώρησης:</label>\n              <div class=\"col-sm-6\">\n                <div class=\"input-group date\" id=\"date-today\">\n                  ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("date"),
        'id': ("unit_assignment_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                  <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n                </div>\n              </div>\n              <div class=\"col-sm-12\"></div>\n              <div class=\"col-sm-6 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"reason\" class=\"col-sm-2 control-label\">Λόγος αποχώρησης:</label>\n              <div class=\"col-sm-9\">\n                ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("withdrawalReasons"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή λόγου:"),
        'selectionBinding': ("selectedReason")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n              </div>\n              <div class=\"col-sm-9 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.selectedReason.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"estimation\" class=\"col-sm-2 control-label\">Εκτίμηση προσωπικού:</label>\n              <div class=\"col-sm-9\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("estimation"),
        'id': ("estimation"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n              </div>\n              <div class=\"col-sm-9 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.estimation.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"remarks\" class=\"col-sm-2 control-label\">Παρατηρήσεις:</label>\n              <div class=\"col-sm-9\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("remarks"),
        'id': ("remarks"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n              </div>\n              <div class=\"col-sm-9 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.remarks.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <div class=\"col-sm-offset-2 col-sm-4\">\n\n                <button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "terminate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποχώρηση</button>\n                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                \n              </div>\n              <div class=\"col-sm-2\">\n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n              </div>\n            </div>\n          </form>\n          ");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedReason.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.estimation.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program8(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.remarks.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program10(depth0,data) {
      
      
      data.buffer.push("\n                Ακύρωση\n                ");
      }

    function program12(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n          <div class=\"alert alert-danger\">\n            Το μέλος δεν ανήκει στην υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "user_current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<br>\n          </div>\n          ");
      return buffer;
      }

      data.buffer.push("<div class=\"row\">\n  <div class=\"col-sm-12 col-md-12 col-xs-12\">\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n        <h4 class=\"widget-title smaller\">Διακοπή παρακολούθησης του μέλους ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" απο την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n      </div>\n      <div class=\"widget-body\">\n        <div class=\"widget-main\">\n          ");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(12, program12, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </div>\n      </div>\n    </div>\n  </div>\n</div>");
      return buffer;
      
    });
  });