define("front/templates/helplines/player-report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n            <div class=\"range\"><h3>");
      stack1 = helpers._triageMustache.call(depth0, "dateFrom", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "dateTo", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3></div>\n            <div class=\"space-10\"></div>\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Φύλο\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  ");
      stack1 = helpers._triageMustache.call(depth0, "player_age_phrase", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </p>\n                <p id=\"mc\">\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['pie-chart'] || (depth0 && depth0['pie-chart']),options={hash:{
        'data': ("player_gender"),
        'elementId': ("genderPie")
      },hashTypes:{'data': "ID",'elementId': "STRING"},hashContexts:{'data': depth0,'elementId': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "pie-chart", options))));
      data.buffer.push("\n                </p>\n\n                <p>\n                  <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Φύλο</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "pg", "in", "player_gender", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n                </p>\n              </div>\n\n            </div>\n          ");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "pg.label", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "pg.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Ηλικία\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                Ο μέσος όρος ηλικίας των παικτών τυχερών παιχνιδιών είναι ");
      stack1 = helpers._triageMustache.call(depth0, "report.player_age.player_age_avg", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών. Η μικρότερη ηλικία είναι ");
      stack1 = helpers._triageMustache.call(depth0, "report.player_age.player_min_age", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών και η μεγαλύτερη ");
      stack1 = helpers._triageMustache.call(depth0, "report.player_age.player_max_age", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών.\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Οικογενειακή Κατάσταση\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Η οικογενειακή κατάσταση των παικτών τυχερών παιχνιδιών παρουσιάζεται στο γράφημα που ακολουθεί\n                </p>\n                \n                <p>\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['bar-chart'] || (depth0 && depth0['bar-chart']),options={hash:{
        'data': ("player_status"),
        'elementId': ("playerStat"),
        'yTitle': ("Ποσοστό (%)")
      },hashTypes:{'data': "ID",'elementId': "STRING",'yTitle': "STRING"},hashContexts:{'data': depth0,'elementId': depth0,'yTitle': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "bar-chart", options))));
      data.buffer.push("\n                </p>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Μορφωτικό Επίπεδο\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Στον πίνακα φαίνεται το μορφωτικό επίπεδο των παικτών, σύμφωνα με τους καλούντες στην τηλεφωνική γραμμή\n                </p>\n\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Μορφωτικό Επίπεδο</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "education", "in", "player_education", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Στατιστικά Στοιχεία Μέσου Προσέγγισης Παιχνιδιού\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                   Οι καλούντες αναφέρθηκαν στο μέσο που χρησιμοποιούν οι παίκτες για να προσεγγίσουν τα τυχερά παιχνίδια, τα ποσοστά φαίνονται στα παρακάτω γραφήματα\n                </p>\n                \n                <h5>Παιχνίδι</h5>\n                <p>\n                  <div class=\"row\">\n                  <div class=\"col-sm-10 col-sm-offset-1\">\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['bar-chart'] || (depth0 && depth0['bar-chart']),options={hash:{
        'data': ("player_game"),
        'elementId': ("pgiStat"),
        'yTitle': ("Ποσοστό (%)")
      },hashTypes:{'data': "ID",'elementId': "STRING",'yTitle': "STRING"},hashContexts:{'data': depth0,'elementId': depth0,'yTitle': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "bar-chart", options))));
      data.buffer.push("\n  \n                  </div>\n                  </div>\n                </p>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Είδος Τυχερού παιχνιδιού\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n\n                <p>\n                  Τα είδη των τυχερών παιχνιδιών που επιλέγουν οι παίκτες, σύμφωνα με τα άτομα που επικοινώνησαν με την τηλεφωνική γραμμή, φαίνονται στον πίνακα που ακολουθεί. Οι παίκτες μπορεί να παίζουν περισσότερα από ένα τυχερά παιχνίδια και έτσι το άθροισμα των ποσοστών όλων των τυχερών παιχνιδιών δεν ισούται με 100%\n                </p>\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th> Είδος Τυχερού παιχνιδιού</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "kind", "in", "player_kind", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Συχνότητα ενασχόλησης\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Η συχνότητα ενασχόλησης των παικτών με τα τυχερά παιχνίδια, όπως παρουσιάζεται από τους ανθρώπους που απευθύνθηκαν στην τηλεφωνική γραμμή, παρουσιάζεται στο παρακάτω γράφημα\n                </p>\n                <p>\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['bar-chart'] || (depth0 && depth0['bar-chart']),options={hash:{
        'data': ("player_frequency"),
        'elementId': ("pfStat"),
        'yTitle': ("Ποσοστό (%)")
      },hashTypes:{'data': "ID",'elementId': "STRING",'yTitle': "STRING"},hashContexts:{'data': depth0,'elementId': depth0,'yTitle': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "bar-chart", options))));
      data.buffer.push("\n                </p>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Ύψος Εβδομαδιαίου Ποσού\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Το ύψος του εβδομαδιαίου ποσού που διατίθεται στα τυχερά παιχνίδια από τους παίκτες, φαίνεται στον πίνακα που ακολουθεί\n                </p>\n\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th> Εβδομαδιαίο Ποσό</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "amount", "in", "player_amount", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Τύπος Υπηρεσιών\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Ο τύπος της υπηρεσίας που παρείχε η τηλεφωνική γραμμή είναι Συμβουλευτική σε ποσοστό ");
      stack1 = helpers._triageMustache.call(depth0, "report.call_type.consult", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" % και Πληροφορίες σε ποσοστό ");
      stack1 = helpers._triageMustache.call(depth0, "report.call_type.info", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" %. Επιπλέον, στo ");
      stack1 = helpers._triageMustache.call(depth0, "report.call_transfer.transfer", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("% των τηλεφωνημάτων έγινε παραπομπή σε δημόσιους φορείς\n                </p>\n\n                <p>\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['pie-chart'] || (depth0 && depth0['pie-chart']),options={hash:{
        'data': ("call_type"),
        'elementId': ("typePie")
      },hashTypes:{'data': "ID",'elementId': "STRING"},hashContexts:{'data': depth0,'elementId': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "pie-chart", options))));
      data.buffer.push("\n                </p>\n\n                <p>\n                   <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Τύπος Υπηρεσίας</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                      <tr>\n                        <td>Συμβουλευτική</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "report.call_type.consult", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n\n                      <tr>\n                        <td>Πληροφορίες</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "report.call_type.info", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                  </tbody>\n                </table>\n                </p>\n\n                <p>\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['pie-chart'] || (depth0 && depth0['pie-chart']),options={hash:{
        'data': ("call_transfer"),
        'elementId': ("transferPie")
      },hashTypes:{'data': "ID",'elementId': "STRING"},hashContexts:{'data': depth0,'elementId': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "pie-chart", options))));
      data.buffer.push("\n                </p>\n                 <p>\n                   <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Παραπομπή</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                      <tr>\n                        <td>Έγινε Παραπομπή</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "report.call_transfer.transfer", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n\n                      <tr>\n                        <td>Δεν Έγινε Παραπομπή</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "report.call_transfer.no_transfer", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                  </tbody>\n                </table>\n                </p>\n              </div>\n\n            </div>\n\n          ");
      return buffer;
      }
    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "education.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "education.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "kind.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "kind.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "amount.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "amount.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\" id=\"player-report\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">ΓΡΑΜΜΗ ΨΥΧΟΛΟΓΙΚΗΣ ΥΠΟΣΤΗΡΙΞΗΣ ΓΙΑ ΤΑ ΤΥΧΕΡΑ ΠΑΙΧΝΙΔΙΑ 1114 - Αναφορά του Παίκτη</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("showReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n        </div>\n        <div class=\"space-10\"></div>\n          <div class=\"col-sm-10 col-sm-offset-1\">\n            <button class=\"btn btn-purple\"");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n          </div>\n        <div class=\"space-10\"></div>\n      </div>\n      <div class=\"row\" id=\"printable\">\n        <div class=\"col-sm-10 col-sm-offset-1\">          \n          <style  TYPE=\"text/css\">\n            table{\n                  width:50% !important;\n                  margin-left: auto;\n                  margin-right: auto;\n                }\n            @media print {\n                /* Your styles here */\n               .widget-header {\n                  background: none;\n                  border-width: 0;\n                  border-bottom: 1px solid #DCE8F1;\n                  color: #4383B4;\n                  padding-left: 3px;\n              }\n\n                #printable h4{\n                  color: #478fca !important;\n                }\n\n                table{\n\n                  page-break-inside:avoid;\n                  width:50% !important;\n                  margin-left: auto;\n                  margin-right: auto;\n                }\n\n                table tr{\n                  border: solid thin #333;\n                  page-break-inside:avoid; page-break-after:auto \n                }\n\n                table, th, td\n                {\n                  border-collapse:collapse;\n                  border: 1px solid black;\n                 \n                }\n                \n                td{\n                  width: 50%;\n                }\n\n                td.center{\n                  text-align: center;\n                }           \n\n            }\n          </style>\n          ");
      stack1 = helpers['if'].call(depth0, "player_gender", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n          ");
      stack1 = helpers['if'].call(depth0, "report", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </div>\n \n       \n      </div>\n    </div>\n  </div>\n</div>");
      return buffer;
      
    });
  });