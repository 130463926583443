define("front/models/helpline", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      call_request: attr(),
      consultant_id: attr(),
      consultant_name: attr(),
      duration: attr(),
      date: attr(),
      code: attr(),
      region: DS.belongsTo("region"),
      first: attr(),
      months_from_first: attr(),
      caller_gender: attr(),
      caller_age: attr(),
      caller_relation: attr(),
      player_agent: attr(),
      relative_agent: attr(),
      player_gender: attr(),
      player_age: attr(),
      player_status: attr(),
      player_education: attr(),
      player_request: attr(),
      game_internet: attr(),
      game_opap: attr(),
      game_club: attr(),
      game_vlt: attr(),
      game_illegal: attr(),
      kind_sport_bets: attr(),
      kind_casino: attr(),
      kind_kino: attr(),
      kind_joker: attr(),
      kind_lotto: attr(),
      kind_extra5: attr(),
      kind_super3: attr(),
      kind_machines: attr(),
      kind_cards: attr(),
      kind_dice: attr(),
      kind_stock_exchange: attr(),
      kind_bingo: attr(),
      kind_vlt: attr(),
      kind_animal_bets: attr(),
      kind_scrats: attr(),
      kind_other: attr(),
      month_frequency: attr(),
      weekly_amount: attr(),
      type: attr(),
      transfer: attr(),
      summary: attr() });
  });