define("front/controllers/form-complete", 
  ["ember-notify","front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];
    var UserMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(UserMixin, {
      needs: ["application"],

      hasGroups: (function () {
        if (!Ember.isEmpty(this.get("groups")) && this.get("groups") !== true) {
          //console.log(this.get('groups'));
          return true;
        } else {
          //console.log('no');
          return false;
        }
      }).property("groups"),

      showPrintButton: (function () {
        if (!Ember.isEmpty(this.get("personForm"))) {
          return true;
        } else {
          return false;
        }
      }).property("onlyParameter", "personForm"),

      readonly: (function () {
        if (!Ember.isEmpty(this.get("personForm.user_create"))) {
          return !(this.get("isAdministrator") || this.get("user.id") === this.get("personForm.user_create") || this.get("personForm.form.isUnique"));
        } else {
          return false;
        }
      }).property("personForm", "user", "personForm.form.unique"),

      selfComplete: (function () {
        if (!Ember.isEmpty(this.get("personForm.selfComplete"))) {
          if (this.get("personForm.selfComplete") === "1") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }).property("personForm"),

      actions: {

        setInput: function (id, value) {
          $("#" + id).val(value).focus();
        },

        printForm: function () {
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/print-form", {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              pfor_id: self.get("personForm.id"),
              single_group_id: self.get("single_group"),
              only_parameter: self.get("onlyParameter")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        printOfficial: function () {
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/personFile?pers_id=" + self.get("person.id") + "&doc_name=" + response.officialDocument, {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + self.session.get("access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        saveForm: function () {
          $("#saveButton").attr("disabled", "disabled");

          var onlyParameter;
          if (this.get("onlyParameter") === true) {
            onlyParameter = "true";
          } else {
            onlyParameter = "false";
          }

          var data = {
            person_id: this.get("person.id"),
            form_id: this.get("form.id"),
            form_date: this.get("formDate"),
            personForm_id: this.get("personForm.id"),
            onlyParameter: onlyParameter,
            form_user_id: this.get("selectedFormUser.id"),
            self_complete: this.get("selfComplete"),
            program: this.get("controllers.application.selectedUnit.prId") ? this.get("controllers.application.selectedUnit.prId") : null
          };


          var hasData = false;
          $("#my_form *").filter(":input").each(function () {
            if ($(this).hasClass("formUser") || $(this).hasClass("selfComplete")) {
              return;
            }

            if ($(this).attr("id")) {
              var res = $(this).attr("id").split("_");
              var type = res[0];
              var question_id = res[1];
              var answer_id = res[2];



              if (type === 3 || type === "3") {
                if ($(this).is(":checked")) {
                  if (data[question_id] !== undefined && data[question_id] !== "" && $(this).attr("data-sin") === "0") {
                    Notify.warning("Παρακαλω επιλέξτε μία απάντηση ανα ερώτηση.", {
                      closeAfter: 10000 // or set to null to disable auto-hiding
                    });

                    $("#saveButton").removeAttr("disabled");
                    hasData = false;
                    return false;
                  }
                  if (data[question_id] !== undefined && data[question_id] !== "" && $(this).attr("data-sin") === "1") {
                    data[question_id] = data[question_id] + "$$" + type + "*" + answer_id;
                  } else {
                    data[question_id] = type + "*" + answer_id;
                  }
                  hasData = true;
                }
              } else {
                if ($(this).val()) {
                  if (data[question_id] !== undefined && data[question_id] !== "") {
                    Notify.warning("Παρακαλω επιλέξτε μία απάντηση ανα ερώτηση.", {
                      closeAfter: 10000 // or set to null to disable auto-hiding
                    });

                    $("#saveButton").removeAttr("disabled");
                    hasData = false;
                    return false;
                  }

                  data[question_id] = type + "*" + answer_id + "*" + $(this).val();
                  hasData = true;
                }
              }

              //alert($(this).attr('id'));
            }
          });

          if (!Ember.$.isEmptyObject(data) && hasData) {
            var self = this;
            $.ajax({
              url: ENV.APP.backendUrl + "/formComplete",
              type: "POST",
              data: JSON.stringify(data),
              contentType: "application/json",
              dataType: "json"
            }).then(function (response) {
              Notify.success("Η αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              if (!self.get("form.isOfficial")) {
                self.transitionToRoute("person", self.get("person.id"));
              } else {
                self.send("showModal", "print-modals/print-wait");

                $.fileDownload(ENV.APP.backendUrl + "/personFile?pers_id=" + self.get("person.id") + "&doc_name=" + response.officialDocument, {
                  httpMethod: "GET",
                  data: {
                    Authorization: "Bearer " + self.session.get("access_token") }
                }).done(function () {
                  $(".modal").modal("hide");
                }).fail(function () {
                  $(".modal").modal("hide");
                });

                $("#saveButton").removeAttr("disabled");
              }
            }, function (response) {
              $("#saveButton").removeAttr("disabled");
              var error = response.responseJSON.error.message;
              //console.log(response.responseJSON.error.message);
              Notify.warning("Αποτυχία αποθήκευσης " + error, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
            });
          }
        }
      }

    });
  });