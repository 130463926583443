define("front/controllers/trpr-report", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      needs: ["application"],

      sortedList: (function () {
        return Ember.ArrayProxy.extend(Ember.SortableMixin).create({
          sortProperties: ["pers_last_name"],
          sortAscending: true,
          content: this.get("persons")
        });
      }).property("persons"),

      canValidate: false,

      validations: {
        dateFrom: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία από." }
        },
        dateTo: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία έως." }
        }
      },

      actions: {
        showReport: function () {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/reportTransPresences",
            type: "GET",
            data: {
              date_from: self.get("dateFrom"),
              date_to: self.get("dateTo"),
              unit_id: self.get("currentUnit.id")
            }
          }).then(function (response) {
            self.set("persons", response.transitionalReport);
          });
        }
      }
    });
  });