define("front/templates/users/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n  <div class=\"row\">\n    <div class=\"col-sm-4 col-xs-6\">\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h3 class=\"widget-title smaller\">Δημογραφικά Χρήστη</h3>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main\">\n            <form class=\"form-horizontal\" role=\"form\">\n              ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Όνομα"),
        'value': ("user.firstname"),
        'error': ("errors.user.firstname"),
        'name': ("firstname")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n              ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Επώνυμο"),
        'value': ("user.lastname"),
        'error': ("errors.user.lastname"),
        'name': ("lastname")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n              ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("username"),
        'value': ("user.username"),
        'error': ("errors.user.username"),
        'name': ("username")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n\n              <div class=\"col-sm-offset-1\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers['multiselect-checkboxes'] || (depth0 && depth0['multiselect-checkboxes']),options={hash:{
        'options': ("roles"),
        'labelProperty': ("name"),
        'selection': ("user.roles")
      },hashTypes:{'options': "ID",'labelProperty': "STRING",'selection': "ID"},hashContexts:{'options': depth0,'labelProperty': depth0,'selection': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "multiselect-checkboxes", options))));
      data.buffer.push("\n              </div>\n\n              <div class=\"form-group\">\n                <div class=\"col-sm-1\">\n                  <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n                </div>\n                <div class=\"col-sm-11\">\n                  <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveData", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                </div>\n              </div>\n\n            </form>\n          </div>\n        </div>\n      </div>\n      <div class=\"space-20\"></div>\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h3 class=\"widget-title smaller\">Αλλαγή Κωδικού Χρήστη</h3>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main\">\n            <form class=\"form-horizontal\" role=\"form\">\n              ");
      data.buffer.push(escapeExpression((helper = helpers['password-form-group'] || (depth0 && depth0['password-form-group']),options={hash:{
        'label': ("Password"),
        'value': ("password"),
        'error': ("errors.password"),
        'name': ("password")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "password-form-group", options))));
      data.buffer.push("\n              ");
      data.buffer.push(escapeExpression((helper = helpers['password-form-group'] || (depth0 && depth0['password-form-group']),options={hash:{
        'label': ("Επαλήθευση Password"),
        'value': ("passwordConfirmation"),
        'error': ("errors.passwordConfirmation"),
        'name': ("passwordConfirmation")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "password-form-group", options))));
      data.buffer.push("\n              <div class=\"form-group\">\n                <div class=\"col-sm-1\">\n                  <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n                </div>\n                <div class=\"col-sm-11\">\n                  <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "changePassword", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                </div>\n              </div>\n\n            </form>\n          </div>\n        </div>\n      </div>\n\n\n\n    </div>\n    <div class=\"col-sm-8 col-xs-6\">\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h3 class=\"widget-title smaller\">Υπηρεσίες που ανήκει ο Χρήστης</h3>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main\">\n            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "users/user-units-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n          </div>\n        </div>\n      </div>\n      <div class=\"space-20\"></div>\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h3 class=\"widget-title smaller\">Προσθήκη σε υπηρεσία</h3>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main\">\n            ");
      stack1 = helpers.each.call(depth0, "program", "in", "programs", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n            <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "addUnitsToUser", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n            <button class=\"btn btn-danger\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "reload", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Καθάρισμα</button>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n              <div class=\"panel-group\" id=\"accordion\" role=\"tablist\" aria-multiselectable=\"true\">\n                <div class=\"panel panel-default\">\n                  <div class=\"panel-heading\" role=\"tab\">\n                    <h4 class=\"panel-title\">\n                      <a role=\"button\" data-toggle=\"collapse\" data-parent=\"#accordion\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'href': ("program.href")
      },hashTypes:{'href': "STRING"},hashContexts:{'href': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" aria-expanded=\"false\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'aria-controls': ("program.id")
      },hashTypes:{'aria-controls': "STRING"},hashContexts:{'aria-controls': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n                       ");
      stack1 = helpers._triageMustache.call(depth0, "program.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                      </a>\n                    </h4>\n                  </div>\n                  <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("program.id")
      },hashTypes:{'id': "STRING"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"panel-collapse collapse\" role=\"tabpanel\" aria-labelledby=\"headingOne\">\n                    <div class=\"panel-body\">\n                     ");
      data.buffer.push(escapeExpression((helper = helpers['multiselect-checkboxes'] || (depth0 && depth0['multiselect-checkboxes']),options={hash:{
        'options': ("program.units"),
        'labelProperty': ("name"),
        'selection': ("units")
      },hashTypes:{'options': "ID",'labelProperty': "STRING",'selection': "ID"},hashContexts:{'options': depth0,'labelProperty': depth0,'selection': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "multiselect-checkboxes", options))));
      data.buffer.push("\n                    </div>\n                  </div>\n                </div>\n              </div>\n            ");
      return buffer;
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n<div class=\"space-10\"></div>\n<div class=\"alert alert-danger\">\n  Ο χρήστης δεν έχει δικαίωμα να δεί τα δεδομένα\n</div>\n");
      }

      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });