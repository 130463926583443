define("front/controllers/transitional-presences", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ArrayController.extend({
      itemController: "transitional-presence",

      dateChange: (function () {
        this.send("anotherDay", this.presenceDate);
      }).observes("presenceDate"),


      actions: {
        anotherDay: function (date) {
          var self = this;
          var transitional_presences = Ember.A();

          $.ajax({
            url: ENV.APP.backendUrl + "/transitionalPresences",
            type: "GET",
            data: { day: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"), unit_id: self.get("current_unit.id") }
          }).then(function (response) {
            self.set("content", transitional_presences.addObjects(response.transitionalPresences));
          });
        }
      }
    });
  });