define("front/routes/person/pehi-admin", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function (params) {
        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        return this.store.find("pehi_event", { pers_id: person_id });
      },

      setupController: function (controller, model) {
        controller.set("pehi_events", model);
        controller.set("person", this.modelFor("person").person);
      }
    });
  });