define("front/models/person_history", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var PersonHistory = DS.Model.extend({
      startDate: attr(),
      endDate: attr(),
      type: attr(),
      unit_name: attr(),
      unit_id: attr(),
      pers_id: attr(),
      structure_name: attr(),
      program_name: attr(),
      program_id: attr(),
      status: (function () {
        if (this.get("type") === "1") {
          return "Μέλος";
        } else if (this.get("type") === "2") {
          return "Υπηρεσία";
        }
      }).property("type")
    });

    __exports__["default"] = PersonHistory;
  });