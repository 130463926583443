define("front/components/my-modal", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({

      actions: {
        ok: function () {
          //this.$('.modal').modal('hide');
          this.sendAction("ok");
        }
      },
      show: (function () {
        this.$(".modal").modal().on("hidden.bs.modal", (function () {
          this.sendAction("close");
        }).bind(this));
      }).on("didInsertElement")
    });
  });