define("front/controllers/helplines/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["helplines/index"],

      getName: (function () {
        var self = this;

        this.store.find("helpline", this.get("content")).then(function (response) {
          self.set("helplineName", response.get("date") + " - " + response.get("code"));
        });
      }).observes("content"),

      parameterName: null,

      actions: {
        deleteHelpline: function () {
          var self = this;
          var callId = this.get("content");

          this.store.find("helpline", callId).then(function (response) {
            response.destroyRecord().then(function () {
              //self.toggleProperty('controllers.expedition-exchanges/index.doReRender');

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");

              self.send("reloadHelplines");
            })["catch"](function (response) {
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή  είναι αδύνατη!");
            });
          });
        }
      }


    });
  });