define("front/controllers/helplines/caller-report", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      report: undefined,

      caller_relations: (function () {
        var caller = this.get("report") ? this.get("report.caller_relation") : null;

        if (caller) {
          return caller = $.map(caller, function (el) {
            return el;
          });
        }
      }).property("report"),

      caller_regions: (function () {
        var caller = this.get("report") ? this.get("report.caller_region") : null;

        if (caller) {
          return caller = $.map(caller, function (el) {
            return el;
          });
        }
      }).property("report"),

      caller_recontacts: (function () {
        var caller = this.get("report") ? this.get("report.caller_recontacts") : null;

        if (caller) {
          return caller = $.map(caller, function (el) {
            return el;
          });
        }
      }).property("report"),

      caller_age_phrase: (function () {
        var caller = this.get("report") ? this.get("report.caller_gender") : null;

        if (caller) {
          var total = this.get("report").count;

          var males = (caller.male / total * 100).toFixed(1);
          var females = (caller.female / total * 100).toFixed(1);

          if (females > males) {
            return "Τα άτομα τα οποία τηλεφώνησαν στη Γραμμή Τηλεφωνικής Υποστήριξης για τον Τζόγο είναι  γυναίκες σε ποσοστό " + females + "%, έναντι " + males + "% των ανδρών.";
          } else if (males > females) {
            return "Τα άτομα τα οποία τηλεφώνησαν στη Γραμμή Τηλεφωνικής Υποστήριξης για τον Τζόγο είναι  άντρες σε ποσοστό " + males + "%, έναντι " + females + "% των γυναικών.";
          } else {
            return "Το ποσοστό αντρών και γυναικών τα οποία τηλεφώνισαν είναι το ίδιο Ανδρες: " + males + "% Γυναίκες: " + females + "%";
          }
        }
      }).property("report"),

      caller_gender: (function () {
        var caller = this.get("report") ? this.get("report.caller_gender") : null;

        if (caller) {
          var total = this.get("report").count;

          var data = [{ label: "Άνδρες", value: (caller.male / total * 100).toFixed(1) }, { label: "Γυναίκες", value: (caller.female / total * 100).toFixed(1) }, { label: "Άγνωστο", value: (caller.uknown_gender / total * 100).toFixed(1) }];

          return data;
        }

        return [];
      }).property("report"),

      actions: {
        printReport: function () {
          var w = window.open();
          w.document.write($("#printable").html());
          w.print();
          w.close();
        },

        showReport: function () {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/helplines/callerReport",
            data: { date_from: self.get("dateFrom"), date_to: self.get("dateTo") }
          }).then(function (report) {
            //self.set('content', report.helplineReport);
            self.set("report", report.helplineCallerReport);
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });