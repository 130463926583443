define("front/controllers/form-complete/form-complete-answer", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ObjectController.extend({

      isTheAnswer: (function () {
        if (this.get("is_the_answer") === 1) {
          return true;
        } else {
          return false;
        }
      }).property("is_the_answer"),

      setName: (function () {
        var quesId = this.get("quesId");
        return "question_" + quesId;
      }).property("quesId"),

      setNameRadio: (function () {
        if (this.get("quesSingularity") === "1") {
          return "radio_" + Math.floor(Math.random() * 1000 + 1);
        } else {
          var quesId = this.get("quesId");
          return "question_" + quesId;
        }
      }).property("quesId", "quesSingularity"),

      setSingularity: (function () {
        if (this.get("quesSingularity") === "1") {
          return "1";
        } else {
          var quesId = this.get("quesId");
          return "0";
        }
      }).property("quesSingularity"),

      setId: (function () {
        var quesId = this.get("quesId");
        var answerId = this.get("id");
        var type = this.get("type");
        return type + "_" + quesId + "_" + answerId;
      }).property("quesId", "id", "type"),

      setRadioValue: (function () {
        return "answer_" + this.get("id");
      }).property("id"),

      isTextAnswer: (function () {
        if (this.get("type") === 1 || this.get("type") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isTextAreaAnswer: (function () {
        if (this.get("type") === 2 || this.get("type") === "2") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isRadioAnswer: (function () {
        if (this.get("type") === 3 || this.get("type") === "3") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isMultipleAnswer: (function () {
        if (this.get("type") === 4 || this.get("type") === "4") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isDateAnswer: (function () {
        if (this.get("type") === 5 || this.get("type") === "5") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isNumberAnswer: (function () {
        if (this.get("type") === 6 || this.get("type") === "6") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      isComboAnswer: (function () {
        if (this.get("type") === 7 || this.get("type") === "7") {
          return true;
        } else {
          return false;
        }
      }).property("type"),

      setDrop2: (function () {
        if (this.get("remarks") === "1") {
          return 9;
        }
        if (this.get("remarks") === "2") {
          return 99;
        }
        if (this.get("remarks") === "3") {
          return 999;
        }
        if (this.get("remarks") === "4") {
          return 9999;
        }
      }).property("remarks"),

      setDrop1: (function () {
        if (this.get("remarks") === "1") {
          return 8;
        }
        if (this.get("remarks") === "2") {
          return 88;
        }
        if (this.get("remarks") === "3") {
          return 888;
        }
        if (this.get("remarks") === "4") {
          return 8888;
        }
      }).property("remarks")

    });
  });