define("front/components/pie-chart", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({

      elementId: "pieChart",
      classNames: [""],
      name: "Pie Chart",
      size: 150,
      text: "",
      height: 250,

      dataChange: (function () {
        if (this.get("data.length")) {
          this._drawPie(this._getData(this.get("data")));
        }
      }).observes("data.length"),

      didInsertElement: function didInsertElement() {
        var _ref;
        (_ref = this)._super.apply(_ref, arguments);

        this._drawPie(this._getData(this.get("data")));
      },

      _getData: function (data) {
        return data.map(function (p) {
          return {
            name: p.label,
            y: parseFloat(p.value)
          };
        });
      },

      _drawPie: function (data) {
        var self = this;
        var elId = "#" + this.get("elementId");

        $(elId).highcharts({
          chart: {
            height: self.get("height"),
            type: "pie"
          },
          title: {
            text: self.get("title")
          },
          credits: {
            enabled: false
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                connectorColor: "silver",
                style: {
                  fontSize: "12pt;"
                } },
              size: self.get("size")
            }
          },
          series: [{
            name: self.get("size"),
            colorByPoint: true,
            data: data
          }]
        });
      }
    });
  });