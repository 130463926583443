define("front/mixins/user", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.Mixin.create({
      needs: ["application"],

      user: (function () {
        return this.get("controllers.application.currentUser");
      }).property("controllers.application.currentUser"),

      isVolunteer: (function () {
        if (!this.get("controllers.application.currentUser")) {
          return false;
        }
        var role = this.get("controllers.application.currentUser.roles").filterBy("name", "Volunteer");
        if (role.length > 0) {
          return true;
        } else {
          return false;
        }
      }).property("controllers.application.currentUser"),

      isAdministrator: (function () {
        if (!this.get("controllers.application.currentUser")) {
          return false;
        }
        var role = this.get("controllers.application.currentUser.roles").filterBy("name", "Admin");
        if (role.length > 0) {
          return true;
        } else {
          return false;
        }
      }).property("controllers.application.currentUser"),


      isSuperAdmin: (function () {
        if (!this.get("controllers.application.currentUser")) {
          return false;
        }
        var role = this.get("controllers.application.currentUser.roles").filterBy("name", "Superadmin");
        if (role.length > 0) {
          return true;
        } else {
          return false;
        }
      }).property("controllers.application.currentUser"),

      currentUnit: (function () {
        return this.get("user_current_unit");
      }).property("user_current_unit"),

      canAssign: (function () {
        return this.get("currentUnit.canAssign");
      }).property("currentUnit.canAssign"),

      canComplete: (function () {
        return this.get("currentUnit.canComplete");
      }).property("currentUnit.canComplete"),

      canTransit: (function () {
        return this.get("currentUnit.programCode") === ENV.APP.diavasiCode;
      }).property("currentUnit.id"),

      canGraduate: (function () {
        return this.get("currentUnit.canGraduate");
      }).property("currentUnit.canGraduate"),

      canClose: (function () {
        return this.get("currentUnit.canClose");
      }).property("currentUnit.canClose"),

      isPrison: (function () {
        return this.get("currentUnit.isPrison");
      }).property("currentUnit.isPrison"),

      canViewElements: (function () {
        if (!this.get("controllers.application.currentUser")) {
          return false;
        }
        var role = this.get("controllers.application.currentUser.roles").filterBy("name", "Superadmin");
        if (role.length > 0) {
          return true;
        } else {
          return this.get("sameProgram");
        }
      }).property("isSuperAdmin", "sameProgram"),

      hasAdminRole: (function () {
        return this.get("isAdministrator") || this.get("isSuperAdmin");
      }).property("controllers.application.currentUser")
    });
  });