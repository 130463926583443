define("front/views/appointments-calendar", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({


      didInsertElement: function () {
        var self = this;
        var controller = self.get("controller");
        var calendar = $("#appointments").fullCalendar({
          timezone: "local",
          defaultView: "agendaWeek",
          header: {
            left: "prev,next today",
            center: "title",
            right: "month,agendaWeek,agendaDay"
          },
          slotDuration: "00:60:00",
          events: function (start, end, timezone, callback) {
            controller.get("store").find("appointment", { unit_id: controller.get("ucu.id"), start: moment(start).format("DD/MM/YYYY"), end: moment(end).format("DD/MM/YYYY") }).then(function (response) {
              controller.set("model", response);

              var events = [];
              response.forEach(function (item, index, enumerable) {
                var el = Ember.Object.create({
                  id: item.get("id"),
                  title: item.get("person") ? item.get("person.fullName") + " (" + item.get("userFullName") + ") *** " + item.get("title") + " ***" : " (" + item.get("userFullName") + ") *** " + item.get("title") + " ***",
                  creator: item.get("creator_name"),
                  start: moment(item.get("start")).format("YYYY-MM-DD HH:mm:ss"),
                  color: item.get("color"),
                  end: moment(item.get("end")).format("YYYY-MM-DD HH:mm:ss")
                });

                events.pushObject(el);
              });

              callback(events);
            });
          },

          editable: true,

          droppable: true,

          eventDrop: function (event, delta, revertFunc) {
            /*var check =  moment(event.start).format('YYYY-MM-DD');
            var today =  moment().format('YYYY-MM-DD');
            if(check >= today){*/

            var appointment = controller.get("model").findBy("id", event.id);
            appointment.set("start", moment(event.start).format("DD/MM/YYYY HH:mm:ss"));
            appointment.set("end", moment(event.end).format("DD/MM/YYYY HH:mm:ss"));
            appointment.save().then(function (response) {}, function (error) {
              revertFunc();
            });
            /*}else{
              revertFunc();
            }*/
            //appointment.set('')
            //controller.send('showModal', 'appointments/edit-modal', appointment);
          },

          selectable: true,
          selectHelper: true,
          select: function (start, end) {
            /* var check =  moment(start).format('YYYY-MM-DD');
             var today =  moment().format('YYYY-MM-DD');
             if(check >= today)
             {*/
            var obj = Ember.Object.create({
              start: moment(start).format("DD/MM/YYYY HH:mm:ss"),
              end: moment(end).format("DD/MM/YYYY HH:mm:ss"),
              users: controller.get("store").find("user", { unit_id: controller.get("ucu.id") }),
              //users: controller.get('store').find('user'),
              defaultUser: controller.get("currentUser")
            });

            var ev = controller.send("showModal", "appointments/new-modal", obj);
            /*}
            else
            {
              var text = "Δεν μπορείτε να καταχωρήσετε ραντεβού στο παρελθόν";
              controller.send('showModal', 'appointments/error-modal', text);
            }*/

          },

          eventRender: function (event, element) {
            $(element).tooltip({ title: event.title, html: true, container: "body" });
          },

          eventClick: function (event) {
            var appointment = controller.get("model").findBy("id", event.id);

            controller.get("store").find("user", { unit_id: controller.get("ucu.id") }).then(function (response) {
              controller.send("showModal", "appointments/edit-modal", { appointment: appointment, users: response });
            });
          },

          eventResize: function (event, delta, revertFunc) {
            var appointment = controller.get("model").findBy("id", event.id);
            appointment.set("start", moment(event.start).format("DD/MM/YYYY HH:mm:ss"));
            appointment.set("end", moment(event.end).format("DD/MM/YYYY HH:mm:ss"));
            appointment.save().then(function (response) {}, function (error) {
              revertFunc();
            });
          }
        });
      }
    });
  });