define("front/controllers/programs/index", 
  ["ember","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(UserMixin, {
      actions: {
        gotoProgram: function (id) {
          this.transitionTo("program", id);
        }
      }
    });
  });