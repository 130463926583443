define("front/routes/persons/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {



      model: function () {
        // the model for this route is a new empty Ember.Object
        return Em.Object.create({});
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("isMember", true);
        controller.set("firstName", "");
        controller.set("lastName", "");
        controller.set("firstName2", "");
        controller.set("lastName2", "");
        controller.set("motherName", "");
        controller.set("fatherName", "");
        controller.set("birthDate", "");
        controller.set("searchResults", "");
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("errors", {});
        this.store.find("nationality").then(function (response) {
          controller.set("nationalities", response);
          //console.log(response.filterBy('id', '44').get('firstObject'));
          controller.set("nationality", response.filterBy("id", "23").get("firstObject"));
        });
      },

      renderTemplate: function () {
        this.render("person.edit", {
          controller: "persons/new"
        });
      }
    });
  });