define("front/routes/ithaki-helplines/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return Ember.RSVP.hash({
          cities: this.store.find("region", { helpline: 1 }),
          regions: this.store.find("region", { helpline: 2 }),
          citizenships: this.store.find("tdi-citizenship"),
          substances: this.store.find("tdi-substance"),
          psychos: this.store.find("tdi-psycho"),
          helpline: this.store.find("ithaki-helpline", params.call_id)
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("helpline", model.helpline);
        controller.set("content", model.helpline);

        controller.set("cities", model.cities);
        controller.set("regions", model.regions);
        controller.set("citizenships", model.citizenships);
        controller.set("substances", model.substances);
        controller.set("psychos", model.psychos);

        this.store.find("user", { unit_id: this.controllerFor("application").get("selectedUnit.id") }).then(function (response) {
          controller.set("consultants", response);
          controller.set("consultant", response.filterBy("id", model.helpline.get("consultant_id")).get("firstObject"));
        });


        // var hideRadio = model.helpline.get('call_request') === 0 ? false : true;

        //controller.set('hideRadio', hideRadio);
      },

      deactivate: function () {
        this.controllerFor("ithaki-helplines.edit").get("content").rollback();
      }
    });
  });