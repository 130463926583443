define("front/controllers/pehi/delete-event", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend({

      actions: {
        "delete": function () {
          var personEvent = this.get("content.personEvent");
          var self = this;

          personEvent.destroyRecord().then(function () {
            self.send("reloadPerson");
            self.transitionToRoute("pehi-admin");
            $(".modal").modal("hide");
            Notify.success("Το συμβάν διαγράφηκε με επιτυχία", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          })["catch"](function (response) {
            Notify.warning("Το συμβάν δεν διαγράφηκε", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          });
        },

        closeModal: function () {
          this.send("removeModal");
        } }
    });
  });