define("front/routes/physfiles/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        return this.modelFor("physfiles");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var personModel = this.modelFor("person");
        controller.set("currentUser", this.controllerFor("application").get("currentUser"));
        controller.set("ucu", this.controllerFor("application").get("selectedUnit"));

        controller.set("person", personModel.person);
      }
    });
  });