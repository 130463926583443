define("front/models/form_group", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var FormGroup = DS.Model.extend({
      name: attr(),
      header: attr(),
      footer: attr(),
      code: attr(),
      category: DS.belongsTo("category"),
      subcategory: DS.belongsTo("subcategory"),
      form: DS.belongsTo("form"),
      formParameters: DS.hasMany("formParameter"),
      hashGroupId: (function () {
        return "#fg_" + this.get("id");
      }).property("id"),
      groupId: (function () {
        return "fg_" + this.get("id");
      }).property("id"),
      groupName: (function () {
        if (this.get("name").trim() == "") {
          return "-";
        }

        return this.get("name");
      }).property("name")
    });

    __exports__["default"] = FormGroup;
  });