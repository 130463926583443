define("front/lib/view", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.reopen({
      didInsertElement: function () {
        this._super();
        Ember.run.scheduleOnce("afterRender", this, this.afterRenderEvent);
      },
      afterRenderEvent: function () {}
    });
    // implement this hook in your own subclasses and run your jQuery logic there
    // Do not add code here as this fires after EVERY didInsertElement event
  });