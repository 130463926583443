define("front/controllers/person/search-form", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      needs: ["application"],
      person: null,
      actions: {
        gotoPersonForm: function (id) {
          var self = this;
          var programId = this.get("controllers.application.selectedUnit.prId") ? this.get("controllers.application.selectedUnit.prId") : null;

          $.ajax({
            url: ENV.APP.backendUrl + "/checkFormUniqueness/" + id + "?person_id=" + self.get("person.id") + "&program_id=" + programId,
            contentType: "application/json",
            type: "GET" }).then(function (response) {
            var formId = response.form_id ? response.form_id : null;
            if (formId) {
              Notify.alert("Η Φόρμα που επιλέξατε είναι μοναδική, Παρακαλώ τροποποιήστε την υπάρχουσα", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.transitionTo("person-forms", formId);
            } else {
              self.transitionTo("form-complete", id, self.get("person.id"));
            }
          }, function (response) {
            Notify.alert("Πρόβλημα με την βάση δεδομένων", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        downloadForm: function (formname) {
          var data;
          var self = this;
          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/formFile?&form_name=" + formname, {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });