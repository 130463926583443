define("front/routes/expedition-exchanges/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
      },

      deactivate: function () {
        this.controllerFor("expedition-approaches.new").set("kind", null);
        this.controllerFor("expedition-approaches.new").set("region", null);
        this.controllerFor("expedition-approaches.new").set("date", null);
        this.controllerFor("expedition-approaches.new").set("comments", null);
        this.controllerFor("expedition-approaches.new").set("total_persons", null);
      }
    });
  });