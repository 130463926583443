define("front/templates/ithaki-helplines/report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Περιοδική αναφορά για την Τηλεφωνική Γραμμή 1145</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("printReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });