define("front/controllers/form-parameter/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["form/build"],

      actions: {
        deleteFormParameter: function () {
          var self = this;
          var formParameterId = this.get("content");

          this.store.find("formParameter", formParameterId).then(function (formParameter) {
            formParameter.destroyRecord().then(function () {
              self.toggleProperty("controllers.form/build.doReRender");

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");

            })["catch"](function (response) {
              //console.log(response);
              formParameter.rollback();
              //self.send('removeModal');
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή της Παραμέτρου είναι αδύνατη!");
            });
          });
        }
      }


    });
  });