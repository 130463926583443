define("front/routes/person/search-officials", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("official_doc");
      },

      afterModel: function (model) {
        var personModel = this.modelFor("person").person;

        this.controllerFor("person.search-officials").set("person", personModel);
      }
    });
  });