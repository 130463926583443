define("front/templates/components/multiselect-checkboxes", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n  ");
      data.buffer.push(escapeExpression((helper = helpers['multiselect-checkbox-option'] || (depth0 && depth0['multiselect-checkbox-option']),options={hash:{
        'value': ("option"),
        'labelProperty': ("labelProperty"),
        'selection': ("selection"),
        'disabled': ("disabled")
      },hashTypes:{'value': "ID",'labelProperty': "ID",'selection': "ID",'disabled': "ID"},hashContexts:{'value': depth0,'labelProperty': depth0,'selection': depth0,'disabled': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "multiselect-checkbox-option", options))));
      data.buffer.push("\n");
      return buffer;
      }

      stack1 = helpers.each.call(depth0, "option", "in", "options", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });