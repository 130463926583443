define("front/templates/helplines/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      <tr>\n        <td>");
      stack1 = helpers._triageMustache.call(depth0, "code", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n        <td>");
      stack1 = helpers._triageMustache.call(depth0, "date", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n        <td>");
      stack1 = helpers._triageMustache.call(depth0, "region.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n        <td>");
      stack1 = helpers._triageMustache.call(depth0, "consultant_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n        <td>\n          <div class='action-buttons'>\n            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "helplines.edit", "id", options) : helperMissing.call(depth0, "link-to", "helplines.edit", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            <a class='red deleteCall' href='#' title='Διαγραφή' ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "deleteCall", "id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data})));
      data.buffer.push("><i class='ace-icon fa fa-trash-o bigger-130'></i></a>\n          </div>\n        </td>\n      </tr>\n    ");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n              <i class='ace-icon fa fa-search-plus bigger-130'></i>\n            ");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n<table class=\"table table-striped table-bordered table-hover\">\n  <thead>\n    <tr>\n      <th  class=\"col-sm-2\"><a href=\"#\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "sortBy", "code", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","STRING"],data:data})));
      data.buffer.push(">Αρ. Πρωτοκόλλου</a></th>\n      <th  class=\"col-sm-2\"><a href=\"#\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "sortBy", "date", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","STRING"],data:data})));
      data.buffer.push(">Ημερομηνία</a></th>\n      <th><a href=\"#\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "sortBy", "region", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","STRING"],data:data})));
      data.buffer.push(">Περιοχή</a></th>\n      <th>Σύμβουλος</th>\n      <th></th>\n    </tr>\n    <tr>\n      <th>\n        <div class=\"input-group code\" id=\"call-code\" >\n          ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'action': ("search"),
        'value': ("search_code")
      },hashTypes:{'action': "STRING",'value': "ID"},hashContexts:{'action': depth0,'value': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n          <span class=\"input-group-addon\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "search", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("><i class=\"glyphicon glyphicon-search\"></i></span>\n        </div>\n      </th>\n      <th>\n        <div class=\"input-group date\" id=\"date-today\">\n          ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("search_date")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n          <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n        </div>\n      </th>\n      <th>\n        ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("regions"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'prompt': ("Περιοχή"),
        'selection': ("search_region")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n      </th>\n      <th>\n        ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("consultants"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'prompt': ("Σύμβουλος"),
        'selection': ("search_consultant")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n      </th>\n      <th></th>\n    </tr>\n  </thead>\n  <tbody>\n    ");
      stack1 = helpers.each.call(depth0, "content", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n  </tbody>\n</table>\n\n");
      data.buffer.push(escapeExpression((helper = helpers['page-numbers'] || (depth0 && depth0['page-numbers']),options={hash:{
        'content': ("content")
      },hashTypes:{'content': "ID"},hashContexts:{'content': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "page-numbers", options))));
      data.buffer.push("\n");
      return buffer;
      
    });
  });