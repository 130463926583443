define("front/controllers/templates/new", 
  ["front/mixins/user","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var UserMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, UserMixin, {

      showSpinner: false,
      selectedCategory: null,
      canValidate: false,
      file_owner_type: 3, //1:form, 2:person, 3:template
      owid: null,

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ονομασία." }
        },
        selectedCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Κατηγορία." }
        },
        selectedSubCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Υποκατηγορία." }
        },
        filename: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Αρχείο." }
        }
      },

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);

        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
        }
      }).observes("selectedCategory"),

      actions: {
        save: function () {
          var uploaded_filename = $("#uploaded_filename").text();

          this.set("filename", uploaded_filename);

          this.set("canValidate", true);

          var newTemplate = this.store.createRecord("template", {
            name: this.get("name"),
            filename: uploaded_filename,
            category: this.get("selectedCategory"),
            subcategory: this.get("selectedSubCategory")
          });

          this.set("showSpinner", true);

          var self = this;

          this.validate().then(function () {
            newTemplate.save().then(function () {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              self.transitionToRoute("templates");
            }, function (response) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              newTemplate.deleteRecord();
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newTemplate.deleteRecord();
          });

        }
      }

    });
  });