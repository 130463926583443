define("front/routes/pehi-admin/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function (params) {
        return this.modelFor("pehi-admin");
      },

      afterModel: function (model) {
        this.transitionTo("pehi", model.get("firstObject.id"));
      } });
  });