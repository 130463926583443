define("front/routes/medical-records/create", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.store.createRecord("medical-record");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var user = this.controllerFor("application").get("currentUser");
        console.log(user);
        controller.set("currentUser", user);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("person", this.modelFor("person").person);
        controller.set("medicalRecord", model);
        controller.set("medicalRecord.transferToHosp", false);
        controller.set("doctor", user.get("lastname") + (user.get("firstname") ? "" + user.get("firstname") : ""));
      },

      deactivate: function () {
        this.controllerFor("medical-records.create").get("model").rollback();
      }
    });
  });