define("front/routes/ithaki-helplines/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        return this.modelFor("ithaki-helplines");
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("content", model);

        this.store.find("user", { unit_id: this.controllerFor("application").get("selectedUnit.id") }).then(function (response) {
          controller.set("consultants", response);
        });
      },

      deactivate: function () {
        this.controllerFor("helplines.index").set("sort", undefined);
        this.controllerFor("helplines.index").set("direction", undefined);

        this.controllerFor("helplines.index").set("search_code", null);
        this.controllerFor("helplines.index").set("search_region", null);
        this.controllerFor("helplines.index").set("search_date", null);
        this.controllerFor("helplines.index").set("search_consultant", null);
      }
    });
  });