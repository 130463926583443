define("front/routes/users/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return Ember.RSVP.hash({
          programs: this.store.find("program"),
          roles: this.store.find("role")
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));

        controller.set("units", []);
        controller.set("userRoles", []);
        controller.set("programs", model.programs);

        controller.set("password", "");
        controller.set("passwordConfirmation", "");
        controller.set("oldPassword", "");

        controller.set("roles", model.roles);
      },

      deactivate: function () {
        this.set("controller.related", []);
        this.set("controller.password", "");
      } });
  });