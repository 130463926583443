define("front/controllers/alertmessage", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({

      show: false,
      text: null,
      alertclass: null,

      setAlert: function (text, alertclass) {
        this.set("show", true);
        this.set("text", text);
        this.set("alertclass", alertclass);
      },

      clearAlert: function () {
        this.set("show", false);
        this.set("text", null);
        this.set("alertclass", null);
      }

    });
  });