define("front/controllers/form-complete/form-complete-param", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ObjectController.extend({

      hasNestLvl: (function () {
        if (this.get("nestLvl") === 1) {
          return true;
        } else {
          return false;
        }
      }).property("nestLvl"),

      isType0: (function () {
        if (this.get("question.type") === "0") {
          return true;
        } else {
          return false;
        }
      }).property("question.type"),

      isType1: (function () {
        if (this.get("question.type") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("question.type"),

      isType2: (function () {
        if (this.get("question.type") === "2") {
          return true;
        } else {
          return false;
        }
      }).property("question.type"),

      isType3: (function () {
        if (this.get("question.type") === "3") {
          return true;
        } else {
          return false;
        }
      }).property("question.type"),

      isType4: (function () {
        if (this.get("question.type") === "4") {
          return true;
        } else {
          return false;
        }
      }).property("question.type"),

      nestLvlMargin: (function () {
        if (this.get("nestLvl") === 1) {
          return "margin-left:50px";
        } else {
          return "";
        }
      }).property("nestLvl"),

      nestLvlClass: (function () {
        if (this.get("nestLvl") === 1) {
          return "col-xs-11 col-xs-offset-1";
        } else {
          return "col-xs-12";
        }
      }).property("nestLvl")

    });
  });