define("front/routes/relationships/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        var personModel = this.modelFor("person");

        return Ember.RSVP.hash({
          person: personModel.person,
          relationTypes: this.store.find("relation-type"),
          relationship: Em.Object.create({})
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", model.person);
        controller.set("relationTypes", model.relationTypes);
      },

      deactivate: function () {
        this.set("controller.selectedPerson", null);
        this.set("controller.selectedType", null);
        this.set("controller.persons", null);
      }

    });
  });