define("front/controllers/pehi-admin/edit", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      canValidate: false,

      hasStartEvent: false,
      hasEndEvent: false,

      hasEndDate: (function () {
        if (!this.get("endDate") || this.get("endDate") === "") {
          return false;
        }
        return true;
      }).property("endDate"),

      actions: {
        saveStartDate: function () {
          var self = this;
          var data = Ember.Object.create({
            pehi_id: this.get("id"),
            date: this.get("formatedStartDate")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/pehiStartDate",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            // self.set('startDate', moment(self.get('formatedStartDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

            Notify.success("Το ιστορικό άλλαξε με επιτυχία ", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.send("reloadPerson");
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        saveEndDate: function () {
          var self = this;
          var data = Ember.Object.create({
            pehi_id: this.get("id"),
            date: this.get("formatedEndDate")
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/pehiEndDate",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            //  self.set('endDate', moment(self.get('formatedEndDate'), 'DD/MM/YYYY').format('YYYY-MM-DD'));

            Notify.success("Το ιστορικό άλλαξε με επιτυχία ", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.send("reloadPerson");
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });