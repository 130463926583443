define("front/routes/helplines/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.store.find("helpline", params.call_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("helpline", model);
        controller.set("content", model);

        this.store.find("region").then(function (response) {
          controller.set("regions", response);
        });

        this.store.find("user", { unit_id: this.controllerFor("application").get("selectedUnit.id") }).then(function (response) {
          controller.set("consultants", response);
          controller.set("consultant", response.filterBy("id", model.get("consultant_id")).get("firstObject"));
        });

        var hideRadio = model.get("call_request") === 0 ? false : true;

        controller.set("hideRadio", hideRadio);
      },

      deactivate: function () {
        this.controllerFor("helplines.edit").get("model").rollback();
      }
    });
  });