define("front/views/documents/documents-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      didInsertElement: function () {
        if (this.get("controller.model")) {
          var self = this;
          this.$().on("click", ".edit-button", function () {
            var id = $(this).attr("data-value");
            self.get("controller").send("edit", id);
          });

          this.$().on("click", ".delete-button", function () {
            $("#myModal").modal("show");
          });

          var data = this.get("controller.model").getEach("data");

          this.$().dataTable({
            bProcessing: true,
            aaData: data,
            iDisplayLength: 4,
            aoColumns: [{ sTitle: "ID", mData: "id" }, { sTitle: "Τίτλος", mData: "title" }, { sTitle: "Ημ/νία", mData: "date" }, {
              mdata: null,
              mRender: function (data, type, row) {
                return "<button class='edit-button btn btn-info' data-value='" + row.id + "'>edit</button>&nbsp;<button class='delete-button btn btn-danger' data-value='" + row.id + "'>delete</button>";
              }

            }]
          });
        }
      }
    });
  });