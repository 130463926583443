define("front/routes/ithaki-helplines", 
  ["simple-auth/mixins/authenticated-route-mixin","ember-cli-pagination/remote/paged-remote-array","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    //import RouteMixin from 'ember-cli-pagination/remote/route-mixin';
    var PagedRemoteArray = __dependency2__["default"];
    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      perPage: 10,

      model: function (params) {
        return PagedRemoteArray.create({ modelName: "ithaki-helpline",
          store: this.store,
          page: params.page || 1,
          perPage: params.per_page || 15
        });
      },

      setupController: function (controller, model) {
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("content", model);
      },

      actions: {
        reloadHelplines: function () {
          this.refresh();
        },

        search: function () {
          var controller = this.controllerFor("ithaki-helplines.index");

          var region_id = controller.get("search_region.id");
          var consultant_id = controller.get("search_consultant.id");
          var date = controller.get("search_date");
          var code = controller.get("search_code");
          var page = controller.get("page");
          var sort = controller.get("sort") || "code";
          var direction = controller.get("direction") || "desc";

          PagedRemoteArray.create({ modelName: "ithaki-helpline",
            store: this.store,
            page: page || 1,
            perPage: 15,
            otherParams: {
              search_region: region_id,
              search_consultant: consultant_id,
              search_code: code,
              search_date: date ? moment(date, "DD/MM/YYYY").format("YYYY-MM-DD") : null,
              sort: sort,
              direction: direction
            }
          }).then(function (response) {
            controller.set("content", response);
            controller.set("model", response);
          });
        }

      }
    });
  });