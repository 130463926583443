define("front/views/persons-create-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.searchResults"),

      didInsertElement: function () {
        var self = this;
        this.$().on("click", ".showPerson", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("gotoPerson", id);
        });

        var data;
        if (this.get("controller.searchResults")) {
          data = this.get("controller.searchResults").getEach("data");
        }

        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          aaData: data,
          iDisplayLength: 10,
          oPaginate: {
            sFirst: "Πρώτη",
            sPrevious: "Προηγούμενη",
            sNext: "Επόμενη",
            sLast: "Τελευταία"
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          aoColumns: [{ sTitle: "Επώνυμο", mData: "lastName" }, { sTitle: "Όνομα", mData: "firstName" }, { sTitle: "Ημ.Γεν", mData: "birthDate" }, { sTitle: "Πατρώνυμο", mData: "fatherName" },
          /*{
          	"mdata": null,
          	"mRender": function ( data, type, row ) {
          			return "<button class='edit-button btn btn-info' data-value='"+row.id+"'>edit</button>&nbsp;<button class='delete-button btn btn-danger' data-value='"+row.id+"'>delete</button>";
          	}
          	},*/
          {
            mdata: null,
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue showPerson' href='#' title='Εμφάνιση' data-value='" + row.id + "'><i class='ace-icon fa fa-search-plus bigger-130'></i></a></div>";
            }

          }]
        });
      }
    });
  });