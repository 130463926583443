define("front/routes/ithaki-helplines/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return Ember.RSVP.hash({
          cities: this.store.find("region", { helpline: 1 }),
          regions: this.store.find("region", { helpline: 2 }),
          citizenships: this.store.find("tdi-citizenship"),
          substances: this.store.find("tdi-substance"),
          psychos: this.store.find("tdi-psycho")
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));

        this.store.find("user", { unit_id: this.controllerFor("application").get("selectedUnit.id") }).then(function (response) {
          controller.set("consultants", response);
        });

        controller.set("date", moment().format("DD-MM-YYYY"));
        controller.set("duration", null);
        controller.set("consultant", null);
        controller.set("caller_recontact", 9);
        controller.set("caller_gender", 9);
        controller.set("caller_age", "99");
        controller.set("caller_relation", 99);
        controller.set("caller_relation_other", null);
        controller.set("caller_city", null);
        controller.set("caller_region", null);
        controller.set("person_gender", 9);
        controller.set("person_age", null);
        controller.set("person_marital_status", 9);
        controller.set("person_marit_status_other", null);
        controller.set("person_children", 9);
        controller.set("person_city", null);
        controller.set("person_region", null);
        controller.set("person_citizenship", null);
        controller.set("person_job_status", 9);
        controller.set("person_job_status_other", null);
        controller.set("person_dependency", 9);
        controller.set("person_dependency_other", null);
        controller.set("person_substance", null);
        controller.set("person_is_psycho", 9);
        controller.set("person_psycho", null);
        controller.set("person_prison", 9);
        controller.set("person_prison_long_ago", 99);
        controller.set("service_type", 9);
        controller.set("service_type_other", null);
        controller.set("service_transfer", 9);
        controller.set("service_transfer_other", null);
        controller.set("service_remarks", null);

        controller.set("hideRadio", false);
      },

      deactivate: function () {
        var controller = this.controllerFor("ithaki-helplines.new");

        controller.set("hideRadio", false);

        controller.set("date", moment().format("DD-MM-YYYY"));
        controller.set("duration", null);
        controller.set("consultant", null);
        controller.set("caller_recontact", 9);
        controller.set("caller_gender", 9);
        controller.set("caller_age", "99");
        controller.set("caller_relation", 99);
        controller.set("caller_city", null);
        controller.set("caller_region", null);
        controller.set("person_gender", 9);
        controller.set("person_age", null);
        controller.set("person_marital_status", 9);
        controller.set("person_children", 9);
        controller.set("person_city", null);
        controller.set("person_region", null);
        controller.set("person_citizenship", null);
        controller.set("person_job_status", 9);
        controller.set("person_dependency", 9);
        controller.set("person_substance", null);
        controller.set("person_is_psycho", 9);
        controller.set("person_psycho", null);
        controller.set("person_prison", 9);
        controller.set("person_prison_long_ago", 99);
        controller.set("service_type", 9);
        controller.set("service_type_other", null);
        controller.set("service_transfer", 9);
        controller.set("service_transfer_other", null);
        controller.set("service_remarks", null);
        controller.set("person_dependency_other", null);
        controller.set("person_job_status_other", null);
        controller.set("person_marit_status_other", null);
        controller.set("caller_relation_other", null);
      }
    });
  });