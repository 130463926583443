define("front/models/relationship", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      pers1: DS.belongsTo("person"),
      pers2: DS.belongsTo("person"),
      relationtype1: DS.belongsTo("relation_type"),
      relationtype2: DS.belongsTo("relation_type") });
  });