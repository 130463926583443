define("front/models/expedition_approach", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      date: attr(),
      region: attr(),
      comments: attr(),
      total_persons: attr(),
      new_persons: attr(),
      homeless: attr(),
      male: attr(),
      female: attr(),
      trans: attr(),
      transfer: attr(),
      immigrant: attr(),
      prostitution: attr(),
      xen: attr(),
      okana: attr(),
      street: attr(),
      kind: attr(),
      unit_id: attr()
    });
  });