define("front/controllers/forms/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({

      doReRender: false,

      actions: {
        gotoForm: function (id) {
          this.transitionTo("form", id);
        }
      }
    });
  });