define("front/controllers/documents/new", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      needs: ["persons/index"],

      showSpinner: false,
      selectedCategory: null,
      canValidate: false,
      file_owner_type: 2, //1:form, 2:person, 3:template
      owid: (function () {
        return this.get("model.person.id");
      }).property("model.person.id"),

      personID: (function () {
        return this.get("model.person.id");
      }).property("model.person.id"),

      validations: {
        title: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Τίτλο." }
        },
        selectedCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Κατηγορία." }
        },
        selectedSubCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Υποκατηγορία." }
        },
        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημ/νία." }
        }
      },

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);

        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
        }
      }).observes("selectedCategory"),

      actions: {
        save: function () {
          this.set("canValidate", true);

          var filename = $("#uploaded_filename").text();

          var newDocument = this.store.createRecord("document", {
            date: this.get("date"),
            title: this.get("title"),
            remarks: this.get("remarks"),
            filename: filename,
            category: this.get("selectedCategory"),
            subcategory: this.get("selectedSubCategory"),
            person: this.get("model.person")
          });

          this.set("showSpinner", true);

          var self = this;

          this.validate().then(function () {
            newDocument.save().then(function () {
              self.set("canValidate", false);

              self.set("date", "");
              self.set("title", "");
              self.set("remarks", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              self.transitionToRoute("document", newDocument);
            }, function (response) {
              self.set("canValidate", false);

              self.set("date", "");
              self.set("title", "");
              self.set("remarks", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              newDocument.deleteRecord();
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newDocument.deleteRecord();
          });

        }
      }

    });
  });