define("front/templates/programs/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "admin/program-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n            ");
      return buffer;
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n            <div class=\"space-10\"></div>\n            <div class=\"alert alert-danger\">\n              Ο χρήστης δεν έχει δικαίωμα να δεί τα δεδομένα\n            </div>\n            ");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Κέντρα</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n        \n        <div class=\"space-8\"></div>\n        <div class=\"row\">\n          \n          <div class=\"col-xs-12\">\n            <div class=\"table-header\">Κέντρα</div>\n          </div>\n          <div class=\"col-xs-12\">\n            ");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>");
      return buffer;
      
    });
  });