define("front/views/pehi-datepicker", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.TextField.extend({
      _picker: null,

      ob: (function () {
        var picker = this.get("_picker");

        if (picker) {
          picker.datepicker("update", this.get("value"));
        }
      }).observes("value"),

      didInsertElement: function () {
        var picker = this.$().datepicker({
          format: "dd/mm/yyyy",
          language: "el"
        });

        this.set("_picker", picker);
      } });


    /*willDestroyElement: function(){
        var picker = this.get("_picker");
        if (picker) {
            console.log(picker);
            picker.destroy();
        }
        this.set("_picker", null);
    }*/
  });