define("front/serializers/form_parameter", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        question: { embedded: "always" },
        form: { embedded: "always" },
        formGroup: { embedded: "always" }
      }
    });
  });