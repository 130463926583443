define("front/views/form-groups/form-groups-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",

      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.model"),

      didInsertElement: function () {
        var self = this;

        this.$().on("click", ".showFormGroup", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("gotoFormGroup", id);
        });

        var data;
        if (this.get("controller.model.groups")) {
          data = this.get("controller.model.groups").getEach("data");
        }
        //console.log(data);
        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Όνομα", mData: "name" }, { sTitle: "Επικεφαλίδα", mData: "header" }, { sTitle: "Υποσημείωση", mData: "footer" }, { sTitle: "Κατηγορία", mData: "category._data.name" }, { sTitle: "Υποκατηγορία", mData: "subcategory._data.name" }, {
            mdata: null,
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue showFormGroup' href='#' title='Εμφάνιση' data-value='" + row.id + "'><i class='ace-icon fa fa-search-plus bigger-130'></i></a></div>";
            }

          }]
        });
      }
    });
  });