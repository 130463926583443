define("front/templates/expedition-approaches/report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n            <table class=\"table table-striped table-bordered table-hover\">\n              <tr>\n                <td><b>Συνολικά Άτομα</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.total", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Νέα Άτομα</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.new", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Άστεγοι</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.homeless", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Άντρες</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.male", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Γυναίκες</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.female", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Τρανσέξουαλ</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.trans", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Μετανάστες</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.immigrant", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Πορνεία</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.prostitute", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Χ.Ε.Ν</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.xen", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>ΟΚΑΝΑ</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.okana", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n\n              <tr>\n                <td><b>Παραπομπές</b></td>\n                <td>");
      stack1 = helpers._triageMustache.call(depth0, "report.transfer", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n              </tr>\n            </table>\n            <button class=\"btn btn-purple\"");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n          ");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Περιπατητικές Εξορμήσεις - Αναφορά</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("showReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n          <hr />\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-10 col-sm-offset-1\">\n          ");
      stack1 = helpers['if'].call(depth0, "report", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });