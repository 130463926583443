define("front/controllers/person/delete-element", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend({

      elementId: null,
      elementType: null,
      elementName: null,

      getName: (function () {
        var self = this;

        var element_id = this.get("content.id");
        var element_type = this.get("content.type");
        var element_name = this.get("content.name");

        this.set("elementName", element_name);
        this.set("elementId", element_id);
        this.set("elementType", element_type);
      }).observes("content"),

      actions: {
        deleteElement: function () {
          var self = this;

          if (this.get("elementType") === "doc") {
            this.store.find("document", this.get("elementId")).then(function (document) {
              document.destroyRecord().then(function () {
                self.send("reloadPerson");
                $(".modal").modal("hide");
                Notify.success("Η διαγραφή έγινε με επιτυχία!");

              })["catch"](function (response) {
                document.rollback();
                //self.send('removeModal');
                $(".modal").modal("hide");
                Notify.error("Η διαγραφή του Εγγράφου είναι αδύνατη!");
              });
            });
          }

          if (this.get("elementType") === "frm") {
            this.store.find("personForm", this.get("elementId")).then(function (personForm) {
              personForm.destroyRecord().then(function () {
                self.send("reloadPerson");
                $(".modal").modal("hide");
                Notify.success("Η διαγραφή έγινε με επιτυχία!");

              })["catch"](function (response) {
                personForm.rollback();
                //self.send('removeModal');
                $(".modal").modal("hide");
                Notify.error("Η διαγραφή της Φόρμας είναι αδύνατη!");
              });
            });
          }

          if (this.get("elementType") === "prm") {
            this.store.find("personForm", this.get("elementId")).then(function (personForm) {
              personForm.destroyRecord().then(function () {
                self.send("reloadPerson");
                $(".modal").modal("hide");
                Notify.success("Η διαγραφή έγινε με επιτυχία!");

              })["catch"](function (response) {
                personForm.rollback();
                //self.send('removeModal');
                $(".modal").modal("hide");
                Notify.error("Η διαγραφή της Παραμέτρου είναι αδύνατη!");
              });
            });
          }
        }
      }


    });
  });