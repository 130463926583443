define("front/templates/expedition-approaches/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n                Επιστροφή\n              ");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Δημιουργία περιπατητικής εξόρμησης</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n        <form class=\"form-horizontal\" role=\"form\">\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-form-group'] || (depth0 && depth0['date-form-group']),options={hash:{
        'label': ("Ημερομηνία"),
        'value': ("date"),
        'error': ("errors.date"),
        'name': ("date")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Τοποθεσία"),
        'value': ("region"),
        'error': ("errors.region"),
        'name': ("region")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['textarea-form-group'] || (depth0 && depth0['textarea-form-group']),options={hash:{
        'label': ("Σχόλια"),
        'value': ("comments"),
        'error': ("errors.kind"),
        'name': ("comments"),
        'rows': (4)
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING",'rows': "INTEGER"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Είδος"),
        'value': ("kind"),
        'error': ("errors"),
        'name': ("kind")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Συνολικά άτομα"),
        'value': ("total_persons"),
        'error': ("errors.total_persons"),
        'name': ("total_persons")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Νέα άτομα"),
        'value': ("new_persons"),
        'error': ("errors.new_persons"),
        'name': ("new_persons")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Άστεγοι"),
        'value': ("homeless"),
        'error': ("errors.homeless"),
        'name': ("homeless")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Άντρες"),
        'value': ("male"),
        'error': ("errors.male"),
        'name': ("male")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Γυναίκες"),
        'value': ("female"),
        'error': ("errors.female"),
        'name': ("female")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Trans"),
        'value': ("trans"),
        'error': ("errors.trans"),
        'name': ("trans")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Μετανάστες"),
        'value': ("immigrant"),
        'error': ("errors.immigrant"),
        'name': ("immigrant")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Πορνεία"),
        'value': ("prostitution"),
        'error': ("errors.prostitution"),
        'name': ("prostitution")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Χ.Ε.Ν."),
        'value': ("xen"),
        'error': ("errors.xen"),
        'name': ("xen")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("ΟΚΑΝΑ"),
        'value': ("okana"),
        'error': ("errors.okana"),
        'name': ("okana")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Συμβουλευτική"),
        'value': ("street"),
        'error': ("errors.street"),
        'name': ("street")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          ");
      data.buffer.push(escapeExpression((helper = helpers['numerical-form-group'] || (depth0 && depth0['numerical-form-group']),options={hash:{
        'label': ("Παραπομπές"),
        'value': ("transfer"),
        'error': ("errors.transfer"),
        'name': ("transfer")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "numerical-form-group", options))));
      data.buffer.push("\n\n          <div class=\"form-group\">\n            <div class=\"col-sm-1\">\n              <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n            </div>\n            <div class=\"col-sm-11\">\n              <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "expedition-approaches.index", options) : helperMissing.call(depth0, "link-to", "expedition-approaches.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n          </div>\n\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n");
      return buffer;
      
    });
  });