define("front/templates/helplines/new", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                    <div class=\"col-sm-6 col-sm-offset-3\">\n                      <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.consultant.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                    ");
      return buffer;
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                    <div class=\"col-sm-6 col-sm-offset-3\">\n                      <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.region.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                    ");
      return buffer;
      }

    function program5(depth0,data) {
      
      
      data.buffer.push("\n                      Τζόγος\n                    ");
      }

    function program7(depth0,data) {
      
      
      data.buffer.push("\n                      Άλλο\n                    ");
      }

    function program9(depth0,data) {
      
      
      data.buffer.push("\n                                  0. Παίκτης\n                                ");
      }

    function program11(depth0,data) {
      
      
      data.buffer.push("\n                                  1. Σύζυγος\n                                ");
      }

    function program13(depth0,data) {
      
      
      data.buffer.push("\n                                  2. Σύντροφος\n                                ");
      }

    function program15(depth0,data) {
      
      
      data.buffer.push("\n                                  3. Μητέρα\n                                ");
      }

    function program17(depth0,data) {
      
      
      data.buffer.push("\n                                  4. Πατέρας\n                                ");
      }

    function program19(depth0,data) {
      
      
      data.buffer.push("\n                                  5. Αδελφή/ος\n                                ");
      }

    function program21(depth0,data) {
      
      
      data.buffer.push("\n                                  6. Παππούς\n                                ");
      }

    function program23(depth0,data) {
      
      
      data.buffer.push("\n                                  7. Γιαγιά\n                                ");
      }

    function program25(depth0,data) {
      
      
      data.buffer.push("\n                                  8. Θείος/α\n                                ");
      }

    function program27(depth0,data) {
      
      
      data.buffer.push("\n                                  9. Φίλος\n                                ");
      }

    function program29(depth0,data) {
      
      
      data.buffer.push("\n                                  10. Γιος\n                                ");
      }

    function program31(depth0,data) {
      
      
      data.buffer.push("\n                                  11. Κόρη\n                                ");
      }

    function program33(depth0,data) {
      
      
      data.buffer.push("\n                                  12. Άλλο\n                                ");
      }

    function program35(depth0,data) {
      
      
      data.buffer.push("\n                                  99. Άγνωστο\n                                ");
      }

    function program37(depth0,data) {
      
      
      data.buffer.push(" 1 ");
      }

    function program39(depth0,data) {
      
      
      data.buffer.push(" 2 ");
      }

    function program41(depth0,data) {
      
      
      data.buffer.push(" 3 ");
      }

    function program43(depth0,data) {
      
      
      data.buffer.push(" 4 ");
      }

    function program45(depth0,data) {
      
      
      data.buffer.push(" 5 ");
      }

    function program47(depth0,data) {
      
      
      data.buffer.push(" 6 ");
      }

    function program49(depth0,data) {
      
      
      data.buffer.push(" 7 ");
      }

    function program51(depth0,data) {
      
      
      data.buffer.push(" 8 ");
      }

    function program53(depth0,data) {
      
      
      data.buffer.push(" 9 ");
      }

    function program55(depth0,data) {
      
      
      data.buffer.push("1. Πληροφορίες");
      }

    function program57(depth0,data) {
      
      
      data.buffer.push("2. Συμβουλευτική");
      }

    function program59(depth0,data) {
      
      
      data.buffer.push("1. Έγινε");
      }

    function program61(depth0,data) {
      
      
      data.buffer.push("2. Δεν έγινε");
      }

    function program63(depth0,data) {
      
      
      data.buffer.push("\n                    Επιστροφή\n                  ");
      }

      data.buffer.push("<div class=\"col-xs-12 kethea-helpline\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">ΚΕΘΕΑ-ΑΛΦΑ ΓΡΑΜΜΗ ΨΥΧΟΛΟΓΙΚΗΣ ΥΠΟΣΤΗΡΙΞΗΣ 1114</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n        <form class=\"form-horizontal\" role=\"form\">\n          <div class=\"row\">\n            <fieldset class=\"col-sm-6\">\n                <!-- Header Left -->\n                <div class=\"form-group\">\n                  <label class=\"col-sm-3 control-label\">Σύμβουλος</label>\n                  <div class=\"col-sm-6\">\n                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("consultants"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control"),
        'prompt': ("Σύμβουλος"),
        'selection': ("consultant")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'prompt': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'prompt': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                    </div>\n                    ");
      stack1 = helpers['if'].call(depth0, "errors.consultant.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n\n                ");
      data.buffer.push(escapeExpression((helper = helpers['date-form-group'] || (depth0 && depth0['date-form-group']),options={hash:{
        'label': ("Ημερομηνία"),
        'value': ("date"),
        'error': ("errors.date"),
        'name': ("date")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-form-group", options))));
      data.buffer.push("\n                ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Διάρκεια"),
        'value': ("duration"),
        'error': ("errors.duration"),
        'name': ("duration")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n\n                \n\n              </fieldset>\n\n              <fieldset class=\"col-sm-6\">\n                <!-- Header Right -->\n                <div class=\"form-group\">\n                  <label class=\"col-sm-3 control-label\">Περιοχή</label>\n                  <div class=\"col-sm-6\">\n                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("regions"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.name"),
        'classNames': ("form-control"),
        'selection': ("region")
      },hashTypes:{'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'classNames': "STRING",'selection': "ID"},hashContexts:{'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'classNames': depth0,'selection': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                  </div>\n                  ");
      stack1 = helpers['if'].call(depth0, "errors.region.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n\n                <div class=\"form-group\">\n                  <label for=\"call_request\" class=\"col-sm-3 control-label\">Αίτημα Κλήσης</label>\n                  <div class=\"col-sm-6\">\n                    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (0),
        'groupValue': ("call_request"),
        'changed': ("requestBehaviour")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID",'changed': "STRING"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("call_request"),
        'changed': ("requestBehaviour")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID",'changed': "STRING"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(7, program7, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                  </div>\n                </div>\n\n\n                ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-radio-button-group'] || (depth0 && depth0['alfa-radio-button-group']),options={hash:{
        'label': ("1η κλήση"),
        'groupValue': ("first"),
        'name': ("first"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-radio-button-group", options))));
      data.buffer.push("\n\n                ");
      data.buffer.push(escapeExpression((helper = helpers['small-input'] || (depth0 && depth0['small-input']),options={hash:{
        'label': ("Μήνες Πριν (1η)"),
        'value': ("months_from_first"),
        'error': ("errors.months_from_first"),
        'name': ("months_from_first")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "small-input", options))));
      data.buffer.push("\n              </fieldset>\n          </div>\n\n          <hr>\n            \n          <div class=\"row\">\n            <div class=\"col-sm-6\">\n\n              <!-- Caller -->\n              <div class=\"widget-box widget-color-blue2 no-borders\">\n                <div class=\"widget-header\">\n                  <h3 class=\"widget-title smaller-90\">Ο ΚΑΛΩΝ</h3>\n                </div>\n                <div class=\"widget-body\">\n                  <div class=\"widget-main\">\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-6\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group-class'] || (depth0 && depth0['radio-button-group-class']),options={hash:{
        'labelClass': ("bold-label col-sm-3 control-label"),
        'label': ("Φύλο:"),
        'elements': ("gender_enum"),
        'groupValue': ("caller_gender"),
        'name': ("caller_gender"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'labelClass': "STRING",'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'labelClass': depth0,'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group-class", options))));
      data.buffer.push("\n                      </div>\n                      <div class=\"col-sm-6\">\n                        ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-small-input'] || (depth0 && depth0['alfa-small-input']),options={hash:{
        'label': ("Ηλικία (99: Άγνωστο)"),
        'value': ("caller_age"),
        'error': ("errors.caller_age"),
        'name': ("caller_age")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-small-input", options))));
      data.buffer.push("\n                      </div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-12\">\n                        <div class=\"form-group\">\n                          <label ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'for': ("caller_relation")
      },hashTypes:{'for': "ID"},hashContexts:{'for': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"col-sm-2 control-label bold-label\">Ιδιότητα:</label>\n                          <div class=\"col-sm-9\">\n                            <div class=\"row\">\n                              <div class=\"col-sm-6\">\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (0),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(9, program9, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                              \n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (1),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(11, program11, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (2),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(13, program13, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (3),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(15, program15, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (4),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(17, program17, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (5),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(19, program19, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (6),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(21, program21, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                              </div>\n                              <div class=\"col-sm-6\">\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (7),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(23, program23, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (8),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(25, program25, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (9),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(27, program27, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (10),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(29, program29, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (11),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(31, program31, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (12),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(33, program33, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                                ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'class': ("col-sm-12"),
        'value': (99),
        'groupValue': ("caller_relation")
      },hashTypes:{'class': "STRING",'value': "INTEGER",'groupValue': "ID"},hashContexts:{'class': depth0,'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(35, program35, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                              </div>\n                            </div>\n                          </div>\n                        </div>\n\n                      </div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\"><h5 class=\"bold-label\">ΠΑΙΚΤΗΣ</h5></div>\n                      <div class=\"col-sm-6\"></div>\n                      <div class=\"col-sm-3\"><h5 class=\"bold-label\">ΑΛΛΟΣ</h5></div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(37, program37, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Ιδιοκτήτης Πράκτορας</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(37, program37, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (2),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(39, program39, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Εργαζόμενος Πράκτορας</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (2),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(39, program39, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (3),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(41, program41, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Ιδιοκτήτης καταστήματος VLTs</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (3),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(41, program41, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                    \n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (4),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(43, program43, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Εργαζόμενος σε καταστήμα VLTs</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (4),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(43, program43, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (5),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(45, program45, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Εργαζόμενος σε Καζίνο</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (5),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(45, program45, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (6),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(47, program47, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Εργαζόμενος σε Στοιχηματική Εταιρία</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (6),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(47, program47, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (7),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(49, program49, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Δεν εργάζεται σε χώρο τζόγου</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (7),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(49, program49, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                        \n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (8),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(51, program51, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Δεν αφορά</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (8),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(51, program51, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                    \n                    <div class=\"row\">\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (9),
        'groupValue': ("player_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(53, program53, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                      <div class=\"col-sm-6\">Άγνωστο</div>\n                      <div class=\"col-sm-3\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (9),
        'groupValue': ("relative_agent")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(53, program53, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    </div>\n                        \n                  </div>\n                </div>\n              </div>\n\n              <!-- Player -->\n              <div class=\"widget-box widget-color-blue2 no-borders\">\n                <div class=\"widget-header\">\n                  <h3 class=\"widget-title smaller-90\">Ο ΠΑΙΚΤΗΣ</h3>\n                </div>\n                <div class=\"widget-body\">\n                  <div class=\"widget-main\">\n                    <div class=\"row\">\n                      <div class=\"col-sm-6\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group-class'] || (depth0 && depth0['radio-button-group-class']),options={hash:{
        'labelClass': ("bold-label col-sm-3 control-label"),
        'label': ("Φύλο:"),
        'elements': ("gender_enum"),
        'groupValue': ("player_gender"),
        'name': ("player_gender"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'labelClass': "STRING",'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'labelClass': depth0,'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group-class", options))));
      data.buffer.push("\n                      </div>\n                      <div class=\"col-sm-6\">\n                        ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-small-input'] || (depth0 && depth0['alfa-small-input']),options={hash:{
        'label': ("Ηλικία (99: Άγνωστο)"),
        'value': ("player_age"),
        'error': ("errors.player_age"),
        'name': ("player_age")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-small-input", options))));
      data.buffer.push("\n                      </div>\n                    </div>\n\n                    <div class=\"row\">\n                      <div class=\"col-sm-6\">\n                        <div class=\"row\">\n                          <div class=\"bold-label col-sm-12\">Οικογενειακή κατάσταση:</div>\n\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-checkbox-group'] || (depth0 && depth0['alfa-checkbox-group']),options={hash:{
        'label': ("Οικογενειακή Κατάσταση"),
        'elements': ("status_enum"),
        'groupValue': ("player_status"),
        'name': ("player_status"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-checkbox-group", options))));
      data.buffer.push("\n                        </div>\n                      </div>\n\n                      <div class=\"col-sm-6\">\n                        <div class=\"row\">\n                          <div class=\"bold-label col-sm-12\">Μορφωτικό επίπεδο:</div>\n\n                          ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-checkbox-group'] || (depth0 && depth0['alfa-checkbox-group']),options={hash:{
        'label': ("Μορφωτικό επίπεδο"),
        'elements': ("education_enum"),
        'groupValue': ("player_education"),
        'name': ("player_education"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-checkbox-group", options))));
      data.buffer.push("\n                        </div>\n                      </div>\n\n                    </div>\n                    \n\n                  </div>\n                </div>\n              </div>\n\n              <div class=\"row\">\n                <div class=\"col-sm-12 bold-label\">Αίτημα:</div>\n                <div class=\"col-sm-12\">\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['radio-button-group-class'] || (depth0 && depth0['radio-button-group-class']),options={hash:{
        'elements': ("request_enum"),
        'groupValue': ("player_request"),
        'name': ("player_request"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button-group-class", options))));
      data.buffer.push("\n                </div>\n              </div>\n\n              <!-- Game -->\n              <div class=\"widget-box widget-color-blue2 no-borders\">\n                <div class=\"widget-header\">\n                  <h3 class=\"widget-title smaller-90\">ΠΑΙΧΝΙΔΙ <span class=\"pull-right\">0: Όχι   1: Ναί   9: Άγνωστο</span></h3>\n                </div>\n                <div class=\"widget-body\">\n                  <div class=\"widget-main\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Διαδίκτυο"),
        'groupValue': ("game_internet"),
        'name': ("game_internet"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("ΟΠΑΠ"),
        'groupValue': ("game_opap"),
        'name': ("game_opap"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Λέσχες"),
        'groupValue': ("game_club"),
        'name': ("game_club"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Καζίνο"),
        'groupValue': ("kind_casino"),
        'name': ("kind_cazino"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Καταστήματα VLTs"),
        'groupValue': ("game_vlt"),
        'name': ("game_vlt"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Παράνομο παιχνίδι"),
        'groupValue': ("game_illegal"),
        'name': ("kind_illegal"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n                  </div>\n                </div>\n              </div>\n\n            </div>\n\n            <div class=\"col-sm-6\">\n              <!-- Game Type -->\n              <div class=\"widget-box widget-color-blue2 no-borders\">\n                <div class=\"widget-header\">\n                  <h3 class=\"widget-title smaller-90\">ΕΙΔΟΣ ΠΑΙΧΝΙΔΙΟΥ <span class=\"pull-right\">0: Όχι   1: Ναί   9: Άγνωστο</span></h3>\n                </div>\n                <div class=\"widget-body\">\n                  <div class=\"widget-main\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Στοίχημα σε αθλήματα"),
        'groupValue': ("kind_sport_bets"),
        'name': ("kind_sport_bets"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Μηχανήματα-Virtuals"),
        'groupValue': ("kind_machines"),
        'name': ("kind_machines"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Χαρτιά"),
        'groupValue': ("kind_cards"),
        'name': ("kind_cards"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Ζάρια"),
        'groupValue': ("kind_dice"),
        'name': ("kind_dice"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("VLTs"),
        'groupValue': ("kind_vlt"),
        'name': ("kind_vlt"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("ΚΙΝΟ"),
        'groupValue': ("kind_kino"),
        'name': ("kind_kino"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("ΤΖΟΚΕΡ"),
        'groupValue': ("kind_joker"),
        'name': ("kind_joker"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("ΛΟΤΤΟ"),
        'groupValue': ("kind_lotto"),
        'name': ("kind_lotto"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("EXTRA 5"),
        'groupValue': ("kind_extra5"),
        'name': ("kind_extra5"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("SUPER 3"),
        'groupValue': ("kind_super3"),
        'name': ("kind_super3"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Scrats"),
        'groupValue': ("kind_scrats"),
        'name': ("kind_scrats"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Χρηματηστήριο"),
        'groupValue': ("kind_stock_exchange"),
        'name': ("kind_extra5"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Στοίχημα σε ζώα"),
        'groupValue': ("kind_animal_bets"),
        'name': ("kind_animal_bets"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['simple-radio-alfa'] || (depth0 && depth0['simple-radio-alfa']),options={hash:{
        'label': ("Άλλο"),
        'groupValue': ("kind_other"),
        'name': ("kind_other"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "simple-radio-alfa", options))));
      data.buffer.push("\n\n                  </div>\n                </div>\n              </div>\n\n              <!-- Stats -->\n              <div>\n                <div class=\"widget-box widget-color-blue2 no-borders col-sm-6\">\n                  <div class=\"widget-header\">\n                    <h3 class=\"widget-title smaller-90\">ΣΥΧΝΟΤΗΤΑ ΤΕΛΕΥΤΑΙΟΥ ΜΗΝΑ</h3>\n                  </div>\n                  <div class=\"widget-body\">\n                    <div class=\"widget-main\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-checkbox-group'] || (depth0 && depth0['alfa-checkbox-group']),options={hash:{
        'label': ("Συχνότητα"),
        'elements': ("freq_enum"),
        'groupValue': ("month_frequency"),
        'name': ("month_frequency"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-checkbox-group", options))));
      data.buffer.push("\n                    </div>\n                  </div>\n                </div>\n\n                <div class=\"widget-box widget-color-blue2 no-borders col-sm-6\">\n                  <div class=\"widget-header\">\n                    <h3 class=\"widget-title smaller-90\">ΕΒΔΟΜΑΔΙΑΙΟ ΠΟΣΟ</h3>\n                  </div>\n                  <div class=\"widget-body\">\n                    <div class=\"widget-main\">\n                      ");
      data.buffer.push(escapeExpression((helper = helpers['alfa-checkbox-group'] || (depth0 && depth0['alfa-checkbox-group']),options={hash:{
        'label': ("Ποσό"),
        'elements': ("amount_enum"),
        'groupValue': ("weekly_amount"),
        'name': ("weekly_amount"),
        'disabled': ("hideRadio"),
        'changed': ("onFirstChanged")
      },hashTypes:{'label': "STRING",'elements': "ID",'groupValue': "ID",'name': "STRING",'disabled': "ID",'changed': "STRING"},hashContexts:{'label': depth0,'elements': depth0,'groupValue': depth0,'name': depth0,'disabled': depth0,'changed': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "alfa-checkbox-group", options))));
      data.buffer.push("\n                    </div>\n                  </div>\n                </div>\n\n              </div>\n\n              <div class=\"widget-box widget-color-blue2 no-borders col-sm-12\">\n                <div class=\"widget-body\">\n                  <div class=\"widget-main\">\n                    <div class=\"row\">\n                    <div class=\"col-sm-4 blue-hd\">ΚΛΗΣΗ</div>\n                    <div class=\"col-sm-4\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("type")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(55, program55, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    <div class=\"col-sm-4\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (2),
        'groupValue': ("type")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(57, program57, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                  </div>\n\n                   <div class=\"row\">\n                    <div class=\"col-sm-4 blue-hd\">ΠΑΡΑΠΟΜΠΗ</div>\n                    <div class=\"col-sm-4\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("transfer")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(59, program59, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                    <div class=\"col-sm-4\">");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (2),
        'groupValue': ("transfer")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID"},hashContexts:{'value': depth0,'groupValue': depth0},inverse:self.noop,fn:self.program(61, program61, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                  </div>\n\n                  <br>\n\n                  <div class=\"row\">\n                    ");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'value': ("summary"),
        'class': ("form-control"),
        'rows': (5)
      },hashTypes:{'value': "ID",'class': "STRING",'rows': "INTEGER"},hashContexts:{'value': depth0,'class': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n                  </div>\n\n                  </div>\n                </div>\n              </div>\n              \n\n            </div>\n\n          </div>\n\n          <hr>\n          <div class=\"space-20\"></div>\n          <div class=\"row\">\n            <div class=\"col-sm-12\">\n              <div class=\"form-group\">\n                <div class=\"col-sm-1\">\n                  <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n                </div>\n                <div class=\"col-sm-11\">\n                  <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                  ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(63, program63, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.index", options) : helperMissing.call(depth0, "link-to", "helplines.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n              </div>\n            </div>\n          </div>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>");
      return buffer;
      
    });
  });