define("front/templates/medical-records", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n                        <i class=\"ace-icon fa fa-plus\"></i>\n                    ");
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                    <div class=\"profile-activity clearfix\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "goTo", "medicalRecord", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n                        <div class=\"time\">\n                            <i class=\"ace-icon fa fa-calendar bigger-110\"></i>\n                                ");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","STRING"],data:data},helper ? helper.call(depth0, "medicalRecord.datetime", "L", options) : helperMissing.call(depth0, "format-date", "medicalRecord.datetime", "L", options))));
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "medicalRecord.doctor.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                <a href=\"#\" class=\"red\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "deleteMedicalRecord", "medicalRecord", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n                                    <i class=\"ace-icon fa fa-trash-o bigger-130 pull-right\"></i>\n                                </a>\n                                <div class=\"space-10\"></div>\n                        </div>\n                        <div class=\"profile-user-info profile-user-info-striped\">\n                            <div class=\"profile-info-row\">\n\n                                <div class=\"profile-info-name\">Διάγνωση / Συμπτώματα</div>\n                                <div class=\"profile-info-value\">\n                                    ");
      stack1 = helpers._triageMustache.call(depth0, "medicalRecord.diagnosisSymptoms", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </div>\n                            </div>\n\n\n                            <div class=\"profile-info-row\">\n\n                                    <div class=\"profile-info-name\">Σχόλια</div>\n                                    <div class=\"profile-info-value\">\n                                        ");
      stack1 = helpers._triageMustache.call(depth0, "medicalRecord.comments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    </div>\n                            </div>\n\n                            <div class=\"profile-info-row\">\n\n                                <div class=\"profile-info-name\">Παραπομπή σε Νοσοκομείο</div>\n                                <div class=\"profile-info-value\">\n                                    ");
      stack1 = helpers._triageMustache.call(depth0, "medicalRecord.transferInterpretation", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </div>\n                            </div>\n\n                            ");
      stack1 = helpers['if'].call(depth0, "medicalRecord.transferToHosp", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </div>\n\n                    </div>\n                ");
      return buffer;
      }
    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                            <div class=\"profile-info-row\">\n\n                                <div class=\"profile-info-name\">Σχόλια Παραπομπής</div>\n                                <div class=\"profile-info-value\">\n                                    ");
      stack1 = helpers._triageMustache.call(depth0, "medicalRecord.tthComments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </div>\n                            </div>\n                            ");
      return buffer;
      }

      data.buffer.push("<div class=\"row\">\n    <div class=\"col-md-5\">\n        <div class=\"widget-box transparent\" id=\"medical-records-box\">\n            <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                    <i class=\"ace-icon fa fa-book\"></i>\n                    Ιατρικές Καρτέλες\n                </h4>\n                <div class=\"widget-toolbar\">\n                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "medical-records.create", options) : helperMissing.call(depth0, "link-to", "medical-records.create", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n            </div>\n            <div class=\"widget-body\">\n                <div class=\"widget-main padding-8\" id=\"medr-scrolling\">\n                ");
      stack1 = helpers.each.call(depth0, "medicalRecord", "in", "medicalRecords", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n            </div>\n            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "medr-scrolling", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n        </div>\n                      \n    </div>\n    <div class=\"col-md-7\">\n        ");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    </div>\n</div>");
      return buffer;
      
    });
  });