define("front/templates/form-groups/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n							");
      return buffer;
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n							");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n							");
      return buffer;
      }

    function program7(depth0,data) {
      
      
      data.buffer.push("\n							Επιστροφή\n							");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			\n			<h3 class=\"widget-title smaller\">Υποενότητα Φόρμας: ");
      stack1 = helpers._triageMustache.call(depth0, "model.form.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				\n				<form class=\"form-horizontal\" role=\"form\">\n\n					<div class=\"form-group\">\n						<label for=\"name\" class=\"col-sm-3 control-label\">Όνομα:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("name"),
        'id': ("name")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n						</div>\n						<div class=\"col-sm-6 col-sm-offset-3\">\n							");
      stack1 = helpers['if'].call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"header\" class=\"col-sm-3 control-label\">Επικεφαλίδα:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("header"),
        'id': ("header"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"footer\" class=\"col-sm-3 control-label\">Υποσημείωση:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("footer"),
        'id': ("footer"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"code\" class=\"col-sm-3 control-label\">Κωδικός:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("code"),
        'id': ("code")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"remarks\" class=\"col-sm-3 control-label\">Κατηγορία:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("model.categories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή κατηγορίας:"),
        'selectionBinding': ("selectedCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n						</div>\n						<div class=\"col-sm-6 col-sm-offset-3\">\n							");
      stack1 = helpers['if'].call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"remarks\" class=\"col-sm-3 control-label\">Υποκατηγορία:</label>\n						<div class=\"col-sm-6\">\n							");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("subcategories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή υποκατηγορίας:"),
        'selectionBinding': ("selectedSubCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n						</div>\n						<div class=\"col-sm-6 col-sm-offset-3\">\n							");
      stack1 = helpers['if'].call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<div class=\"col-sm-offset-3 col-sm-4\">\n							<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveGroup", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "form", "model.form", options) : helperMissing.call(depth0, "link-to", "form", "model.form", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n						<div class=\"col-sm-1\">\n								<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n						</div>\n					</div>\n					\n				</form>\n\n				<div class=\"space-8\"></div>\n				\n\n				<div class=\"\">\n					<div class=\"table-header\">Καταχωρημένες Υποενότητες</div>\n				</div>\n				<div class=\"\">\n\n					");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "form-groups/form-groups-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n				</div>\n\n			</div>\n		</div>\n	</div>\n\n</div>\n");
      return buffer;
      
    });
  });