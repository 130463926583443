define("front/controllers/person-groups/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["person-groups"],

      getName: (function () {
        var self = this;

        this.store.find("person-group", this.get("content")).then(function (response) {
          self.set("groupName", response.get("name"));
        });
      }).observes("content"),

      groupName: null,

      actions: {
        deleteGroup: function () {
          var self = this;
          var grId = this.get("content");

          this.store.find("person-group", grId).then(function (response) {
            response.one("didDelete", function () {});
            response.destroyRecord().then(function () {
              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");
            })["catch"](function () {
              response.rollback();
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή δεν πραγματοποιήθηκε!");
            });
          });
          this.transitionTo("person-groups");
        }
      }
    });
  });