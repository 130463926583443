define("front/routes/templates/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {


      model: function (params) {
        return Ember.RSVP.hash({
          categories: this.store.find("category")
        });
      },

      renderTemplate: function () {
        this.render("templates.edit", {
          controller: "templates/new"
        });
      }
    });
  });