define("front/views/application", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.View.extend({

      didInsertElement: function () {
        if (ENV.APP.kick) {
          var self = this;
          $(document).idle({
            onIdle: function () {
              if (self.get("controller.currentUser")) {
                self.get("controller").send("logout");
              }
            },
            idle: 1200000
          });
        }
      }
    });
  });