define("front/templates/expeditions", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n  <div class=\"row\">\n    <div class=\"col-xs-4 col-md-4\">\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h4 class=\"widget-title smaller\">Περιπατητικές εξορμήσεις προσέγγισης χρηστών</h4>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main center\">\n            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "expedition-approaches", options) : helperMissing.call(depth0, "link-to", "expedition-approaches", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </div>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"col-xs-4 col-md-4\">\n      <div class=\"widget-box\">\n        <div class=\"widget-header\">\n          <h4 class=\"widget-title smaller\">Εξορμήσεις Ανταλλαγής Συριγγών</h4>\n        </div>\n        <div class=\"widget-body\">\n          <div class=\"widget-main center\">\n            ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "expedition-exchanges", options) : helperMissing.call(depth0, "link-to", "expedition-exchanges", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n              Επεξεργασία\n            ");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Η Υπηρεσία αυτή δεν κάνει εξορμήσεις.\n  </div>\n");
      }

      stack1 = helpers['if'].call(depth0, "currentUnit.haveExpeditions", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });