define("front/routes/parameter-complete", 
  ["simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Ember = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
        this.store.unloadAll("formParameter");
        this.store.unloadAll("formGroup");

        return Ember.RSVP.hash({
          parameters: this.store.find("formParameter", { ques_id: params.parameter_id, onlyParameter: "true" }),
          person: this.store.find("person", params.person_id)
        });
      },

      setupController: function (controller, model) {
        this.controllerFor("form-complete").set("parameters", model.parameters);
        this.controllerFor("form-complete").set("person", model.person);
        this.controllerFor("form-complete").set("personForm", "");
        this.controllerFor("form-complete").set("groups", "");
        this.controllerFor("form-complete").set("onlyParameter", true);
      },

      renderTemplate: function () {
        this.render("form-complete", {
          controller: "form-complete"
        });
      }
    });
  });