define("front/controllers/person/withdraw", 
  ["front/config/environment","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      person_histories: null,
      user_current_unit: null });
  });