define("front/templates/pehi", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n    <div class=\"col-sm-3\">\n        <div class=\"widget-box\" id=\"person-history-box\">\n            <div class=\"widget-header widget-header-small  widget-header-blue\">\n                <h4 class=\"widget-title blue smaller\">\n                    <i class=\"ace-icon fa fa-book\"></i>\n                    Ιστορικό\n                </h4>\n            </div>\n\n            <div class=\"widget-body\">\n\n                 ");
      stack1 = helpers.each.call(depth0, "pehi", "in", "pehis", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n            </div>\n\n        </div>\n    </div>\n    <div class=\"col-sm-9\">\n\n        <div class=\"widget-box\">\n            <div class=\"widget-header\">\n            <h4 class=\"widget-title smaller\">");
      data.buffer.push(escapeExpression((helper = helpers['date-range'] || (depth0 && depth0['date-range']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data},helper ? helper.call(depth0, "startDate", "endDate", options) : helperMissing.call(depth0, "date-range", "startDate", "endDate", options))));
      data.buffer.push("</h4>\n            </div>\n\n            <div class=\"widget-body\">\n                <div class=\"widget-main\">\n\n                    <form class=\"form-horizontal\" role=\"form\">\n\n                        <div class=\"form-group\">\n                            <label for=\"startDate\" class=\"col-sm-3\">Έναρξη:</label>\n                            <div class=\"col-sm-4\">\n                                <div class=\"input-group\">\n                                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "pehi-datepicker", {hash:{
        'value': ("formatedStartDate")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                </div>\n                            </div>\n                            <button type=\"submit\" class=\"btn btn-success btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveStartDate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                            ");
      stack1 = helpers.unless.call(depth0, "hasStartEvent", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            <div class=\"col-sm-12\"></div>\n\n                            <div class=\"col-sm-6 col-sm-offset-2\">\n                                ");
      stack1 = helpers['if'].call(depth0, "errors.startDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </div>\n                            </div>\n\n                    ");
      stack1 = helpers['if'].call(depth0, "hasEndDate", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    </form>\n\n\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"row\">\n     <div class=\"col-sm-5\">\n        <div class=\"widget-box\">\n            <div class=\"widget-header\">\n            <h4 class=\"widget-title smaller\">Συμβάντα</h4>\n            </div>\n\n            <div class=\"widget-body\">\n                <div class=\"widget-main\">\n\n                    <table class=\"table table-striped table-bordered table-hover\">\n                        <thead>\n                            <tr>\n                                <th  class=\"col-sm-2\">Ημερομηνία</th>\n                                <th>Συμβάν</th>\n                                <th></th>\n                            </tr>\n                        </thead>\n                        <tbody>\n                            ");
      stack1 = helpers.each.call(depth0, "event", "in", "historyevents", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            ");
      stack1 = helpers['if'].call(depth0, "hasTransfer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        </tbody>\n                    </table>\n\n\n                </div>\n            </div>\n        </div>\n    </div>\n     <div class=\"col-sm-7\">\n        ");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(19, program19, data),fn:self.program(17, program17, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    </div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n                    <div class=\"profile-activity clearfix pehi-items\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "goToPehi", "pehi.id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n                        <div class=\"time\">\n                            <i class=\"ace-icon fa fa-calendar bigger-110\"></i>\n                            ");
      data.buffer.push(escapeExpression((helper = helpers['date-range'] || (depth0 && depth0['date-range']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["ID","ID"],data:data},helper ? helper.call(depth0, "pehi.startDate", "pehi.endDate", options) : helperMissing.call(depth0, "date-range", "pehi.startDate", "pehi.endDate", options))));
      data.buffer.push("\n                        </div>\n                    </div>\n                 ");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-warning btn-sm")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0],types:["STRING","STRING"],data:data},helper ? helper.call(depth0, "pehi.create-event", "start", options) : helperMissing.call(depth0, "link-to", "pehi.create-event", "start", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            ");
      return buffer;
      }
    function program5(depth0,data) {
      
      
      data.buffer.push("\n                                Δημιουργία Συμβάντος\n                                ");
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                                    <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.formatedStartDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                                ");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                        <div class=\"form-group\">\n                            <label for=\"startDate\" class=\"col-sm-3\">Τερματισμός:</label>\n                            <div class=\"col-sm-4\">\n                                <div class=\"input-group\">\n                                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "pehi-datepicker", {hash:{
        'value': ("formatedEndDate")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                </div>\n                            </div>\n                            <button type=\"submit\" class=\"btn btn-success btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "saveEndDate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n                            ");
      stack1 = helpers.unless.call(depth0, "hasEndEvent", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            <div class=\"col-sm-12\"></div>\n\n                            <div class=\"col-sm-6 col-sm-offset-2\">\n                                ");
      stack1 = helpers['if'].call(depth0, "errors.startDate.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </div>\n                            </div>\n                                ");
      return buffer;
      }
    function program10(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-warning btn-sm")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0],types:["STRING","STRING"],data:data},helper ? helper.call(depth0, "pehi.create-event", "end", options) : helperMissing.call(depth0, "link-to", "pehi.create-event", "end", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            ");
      return buffer;
      }

    function program12(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <tr>\n                                <td>");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "event.date", options) : helperMissing.call(depth0, "format-date", "event.date", options))));
      data.buffer.push("</td>\n                                <td>");
      stack1 = helpers._triageMustache.call(depth0, "event.comments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                                <td>");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "pehi.edit-event", "event.id", options) : helperMissing.call(depth0, "link-to", "pehi.edit-event", "event.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                            </tr>\n                            ");
      return buffer;
      }
    function program13(depth0,data) {
      
      
      data.buffer.push("<i class='ace-icon fa fa-search-plus bigger-130'></i>");
      }

    function program15(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                            <tr>\n                                <td>");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "hasTransfer.date", options) : helperMissing.call(depth0, "format-date", "hasTransfer.date", options))));
      data.buffer.push("</td>\n                                <td>");
      stack1 = helpers._triageMustache.call(depth0, "hasTransfer.comments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                                <td>Να παραμετροποιηθεί απο το ιστορικό παραπομπής (Προηγούμενο range)</td>\n                            </tr>\n                            ");
      return buffer;
      }

    function program17(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n            ");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        ");
      return buffer;
      }

    function program19(depth0,data) {
      
      
      data.buffer.push("\n            <div class=\"alert alert-danger\">\n            Ζητήστε Απο τον Υπερ-Διαχειριστή να αλλάξει τα Δεδομένα του συμβάντος\n            </div>\n        ");
      }

    function program21(depth0,data) {
      
      
      data.buffer.push("\n<div class=\"space-10\"></div>\n<div class=\"alert alert-danger\">\n  Ο χρήστης δεν έχει δικαίωμα να δεί τα δεδομένα\n</div>\n");
      }

      stack1 = helpers['if'].call(depth0, "isAdministrator", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(21, program21, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });