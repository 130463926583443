define("front/models/tdi-substance", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      code: attr(),
      text: attr()
    });
  });