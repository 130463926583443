define("front/routes/person-group/edit", 
  ["ember","simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("person-group");
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        var structureGroup = this.modelFor("person-groups").findBy("id", "100000002");

        controller.set("model", model);
        //console.log(model.get('persons'));
        controller.set("selectedPersons", model.get("persons"));
        controller.set("structurePersons", structureGroup.get("persons"));
        controller.set("structureGroup", structureGroup);
      },

      deactivate: function () {
        this.controllerFor("person-group.edit").get("model").rollback();
      },

      refreshChanged: function () {
        this.refresh();
      }
    });
  });