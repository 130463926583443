define("front/templates/person/release", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(7, program7, data),fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n  <div class=\"row\">\n    <div class=\"col-xs-12 col-md-12\">\n  \n        <div class=\"widget-box\">\n          <div class=\"widget-header\">\n            <h4 class=\"widget-title smaller\">Αποφυλάκιση - ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n          </div>\n          <div class=\"widget-body\">\n            <div class=\"widget-main\">\n\n              <form class=\"form-horizontal\" role=\"form\">\n\n            <div class=\"form-group\">\n              <label for=\"document_date\" class=\"col-sm-2 control-label\">Ημ/νία:</label>\n              <div class=\"col-sm-6\">\n                <div class=\"input-group date\" id=\"date-today\">\n                  ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("date"),
        'id': ("unit_reference_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                  <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n                </div>\n              </div>\n              <div class=\"col-sm-12\"></div>\n              <div class=\"col-sm-6 col-sm-offset-2\">\n                ");
      stack1 = helpers['if'].call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n            </div>\n\n      \n            <div class=\"form-group\">\n              <div class=\"col-sm-offset-2 col-sm-6\">\n                \n                <button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "release", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποφυλάκιση</button>\n                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            \n              </div>\n              <div class=\"col-sm-2\">\n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n              </div>\n            </div>\n\n            \n              \n            </div>\n          </div>\n        </div>\n\n    </div>\n  </div>\n");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n                ");
      return buffer;
      }

    function program5(depth0,data) {
      
      
      data.buffer.push("\n                  Ακύρωση\n                ");
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n  <div class=\"page-header\">\n    <h1>");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Αποφυλάκιση</small></h1>\n    \n  </div>\n\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Ο χρήστης δεν έχει δικαίωμα να πραγματοποιήσει την ενέργεια αυτή\n  </div>\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "user_current_unit.isPrison", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });