define("front/models/official_doc", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      name: attr(),
      filename: attr(),
      subcategory: DS.belongsTo("subcategory")
    });
  });