define("front/routes/units/new", 
  ["simple-auth/mixins/authenticated-route-mixin","ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var ENV = __dependency3__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        return this.store.createRecord("unit");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("unit", model);
        controller.set("ranks", ["0", "1", "2", "3", "4", "5", "6"]);

        this._setUnitTypes(controller);
      },

      _setUnitTypes: function (controller, model) {
        $.ajax({
          url: ENV.APP.backendUrl + "/unitTypes",
          type: "GET",
          dataType: "json",
          contentType: "application/json" }).then(function (unitTypes) {
          var types = unitTypes["unit-types"].map(function (type) {
            return { id: type.id, name: type.name };
          });
          controller.set("unitTypes", types);
          controller.set("unitType", types[0]);
        });
      },

      deactivate: function () {
        this.controller.get("unit").destroyRecord();
      },

      actions: {
        save: function (unit) {
          var _this = this;
          this.controller.set("unit.type", this.controller.get("unitType"));

          unit.save().then(function (_) {
            Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
              closeAfter: 10000
            });

            _this.send("refresh");
            _this.transitionTo("units");
          }, function (error) {
            Notify.alert(error.responseJSON.message, {
              closeAfter: 10000
            });
          });
        }
      }

    });
  });