define("front/controllers/person/person-history", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({

      actions: {
        showEvent: function (pers_id, event_id) {
          this.transitionTo("event", pers_id, event_id);
        }
      }
    });
  });