define("front/views/member-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.persons"),

      didInsertElement: function () {
        var self = this;
        this.$().on("click", "#selectMember", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("selectMember", id);
        });

        var data;
        if (this.get("controller.persons")) {
          data = this.get("controller.persons").getEach("data");
        }

        this.$().dataTable({
          bProcessing: true,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          bAutoWidth: false,
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Επώνυμο", mData: "lastName" }, { sTitle: "Όνομα", mData: "firstName" }, { sTitle: "Κωδ. Φυσ. Φακ.", mData: "displayPhysfiles" }, { sTitle: "Κωδ. ΠΡΟ.ΚΕ.ΚΑ.", mData: "ketheaCode" }, {
            mdata: null,
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue' id='selectMember' href='#' title='Επιλογή' data-value='" + row.id + "'><i class='ace-icon fa fa-hand-o-right bigger-130'></i></a></div>";
            }

          }]
        });
      }
    });
  });