define("front/controllers/relationship/edit", 
  ["ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {

      actions: {

        save: function () {
          var self = this;
          var relationship = this.get("model.relationship");

          relationship.save().then(function (response) {
            Notify.success("Η σχέση αποθηκεύτηκε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });

            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("model.person.id"));
          }, function (error) {
            Notify.error(error.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        "delete": function (model) {
          this.send("showModal", "relationship/delete-modal", model);
        }
      }
    });
  });