define("front/controllers/forms/new", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var FormNewController = Ember.Controller.extend(Ember.Validations.Mixin, {
      header: null,
      footer: null,
      code: null,
      file_owner_type: 1, //1:form, 2:person, 3:template
      owid: null,

      showSpinner: false,

      canValidate: false,

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Τίτλο." }
        },
        selectedCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Κατηγορία." }
        },
        selectedSubCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Υποκατηγορία." }
        }
      },

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);

        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
        }
      }).observes("selectedCategory"),

      actions: {


        save: function () {
          var uploaded_filename = $("#uploaded_filename").text();

          this.set("canValidate", true);

          var newForm = this.store.createRecord("form", {
            name: this.get("name"),
            header: this.get("header"),
            filename: uploaded_filename,
            footer: this.get("footer"),
            code: this.get("code"),
            subcategory: this.get("selectedSubCategory")
          });

          this.set("showSpinner", true);

          var self = this;

          this.validate().then(function () {
            newForm.save().then(function (formRecord) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");
              self.set("code", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              self.transitionToRoute("form-groups", formRecord);
            }, function (response) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");
              self.set("code", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              newForm.deleteRecord();
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newForm.deleteRecord();
          });
        }
      }

    });

    __exports__["default"] = FormNewController;
  });