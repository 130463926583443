define("front/models/subcategory", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var SubCategory = DS.Model.extend({
      name: attr(),
      category_name: attr(),
      category: DS.belongsTo("category")
    });

    __exports__["default"] = SubCategory;
  });