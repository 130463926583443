define("front/controllers/export", 
  ["front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(UserMixin, Ember.Validations.Mixin, {

      needs: ["application"],

      canValidate: false,

      canExport: (function () {
        return this.get("isAdministrator") || this.get("isSuperAdmin");
      }).property("isAdministrator", "isSuperAdmin"),

      dataLevels: [{ id: 1, name: "Κέντρου" }, { id: 2, name: "ΑΕΜΥ" }],

      validations: {
        dateFrom: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία από." }
        },
        dateTo: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία έως." }
        },
        selectedForm: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Φόρμα." }
        }
      },

      actions: {
        exportAction: function () {
          var self = this;
          this.set("canValidate", true);

          this.validate().then(function () {
            self.set("canValidate", false);
            self.send("showModal", "print-modals/print-wait");

            $.fileDownload(ENV.APP.backendUrl + "/export", {
              httpMethod: "POST",
              data: {
                Authorization: "Bearer " + self.session.get("access_token"),
                date_from: self.get("dateFrom"),
                date_to: self.get("dateTo"),
                form_id: self.get("selectedForm.id"),
                current_unit_id: self.get("controllers.application.selectedUnit.id"),
                selected_level: self.get("selectedDataLevel.id")
              }
            }).done(function () {
              $(".modal").modal("hide");
            }).fail(function () {
              $(".modal").modal("hide");
            });
          }, function () {
            self.set("canValidate", false);
          });
        }
      }
    });
  });