define("front/templates/components/labeled-radio-button", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


      data.buffer.push(escapeExpression((helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'changed': ("innerRadioChanged"),
        'disabled': ("disabled"),
        'groupValue': ("groupValue"),
        'name': ("name"),
        'required': ("required"),
        'value': ("value")
      },hashTypes:{'changed': "STRING",'disabled': "ID",'groupValue': "ID",'name': "ID",'required': "ID",'value': "ID"},hashContexts:{'changed': depth0,'disabled': depth0,'groupValue': depth0,'name': depth0,'required': depth0,'value': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options))));
      data.buffer.push("\n\n");
      stack1 = helpers._triageMustache.call(depth0, "yield", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });