define("front/serializers/user", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        units: { embedded: "always" },
        permissions: { embedded: "always" },
        roles: { embedded: "always" }
      }
    });
  });