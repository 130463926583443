define("front/templates/login", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n											<div class=\"alert alert-danger\">\n												");
      stack1 = helpers._triageMustache.call(depth0, "controllers.application.errorMessage", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n											</div>\n											");
      return buffer;
      }

      data.buffer.push("<div class=\"main-container\">\n	<div class=\"main-content\">\n		<div class=\"row\">\n			<div class=\"login-banner col-sm-12\">\n				<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("loginBanner")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" height=\"160\" width=\"470\" alt=\"Logo\">\n			</div>			\n		</div>\n		<div class=\"row\">\n			<div class=\"col-sm-12\">\n				<div class=\"login-title-container\">\n					<div class=\"login-title\">\n						<h5>Ιατροφαρμακευτική Περίθαλψη, Ψυχολογική Υποστήριξη, Κοινωνική Υποστήριξη και Υπηρεσίες Διερμηνείας με κωδικό ΟΠΣ (MIS) 5010510 «του Ταμείου Ασύλου Μετανάστευσης και Ένταξης 2014-2020»</h5>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class=\"row\">\n			<div class=\"col-sm-10 col-sm-offset-1\">\n				<div class=\"login-container\">			\n					<div class=\"position-relative\">\n						<div id=\"login-box\" class=\"login-box visible widget-box no-border\">\n							<div class=\"widget-body\">\n								<div class=\"widget-main\">\n									<h4 class=\"header blue lighter bigger\">\n										<i class=\"ace-icon fa fa-coffee green\"></i>\n										Είσοδος\n									</h4>\n\n									<div class=\"space-6\"></div>\n\n									<form ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "authenticate", {hash:{
        'on': ("submit")
      },hashTypes:{'on': "STRING"},hashContexts:{'on': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n										<fieldset>\n											<label class=\"block clearfix\">\n												<span class=\"block input-icon input-icon-right\">\n													");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'value': ("identification"),
        'type': ("text"),
        'placeholder': ("Username"),
        'classNames': ("form-control")
      },hashTypes:{'value': "ID",'type': "STRING",'placeholder': "STRING",'classNames': "STRING"},hashContexts:{'value': depth0,'type': depth0,'placeholder': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n													<i class=\"ace-icon fa fa-user\"></i>\n												</span>\n											</label>\n\n											<label class=\"block clearfix\">\n												<span class=\"block input-icon input-icon-right\">\n													");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'value': ("password"),
        'type': ("password"),
        'placeholder': ("Password"),
        'classNames': ("form-control")
      },hashTypes:{'value': "ID",'type': "STRING",'placeholder': "STRING",'classNames': "STRING"},hashContexts:{'value': depth0,'type': depth0,'placeholder': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n													<i class=\"ace-icon fa fa-lock\"></i>\n												</span>\n											</label>\n\n											");
      stack1 = helpers['if'].call(depth0, "controllers.application.errorMessage", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n											<div class=\"space\"></div>\n\n											<div class=\"clearfix\">\n\n												");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'class': ("width-35 pull-right btn btn-sm btn-primary"),
        'type': ("submit"),
        'value': ("Log In")
      },hashTypes:{'class': "STRING",'type': "STRING",'value': "STRING"},hashContexts:{'class': depth0,'type': depth0,'value': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n\n											</div>\n\n											<div class=\"space-4\"></div>\n										</fieldset>\n									</form>\n\n								</div><!-- /.widget-main -->\n\n							</div><!-- /.widget-body -->\n						</div><!-- /.login-box -->\n\n					</div><!-- /.position-relative -->\n\n\n				</div>\n			</div><!-- /.col -->\n			<div class=\"col-sm-10 col-sm-offset-1\" style=\"margin-top:15%\">\n				<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("klogoUrl")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"img-responsive center-block\" alt=\"Logo\">\n			</div>\n		</div><!-- /.row -->\n		<div class=\"row\">\n			<div class=\"col-sm-12\">\n				<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("greekFlag")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" height=\"99\" width=\"150\" class=\"pull-left\" alt=\"greek-flag\">\n				<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("aemyLogo")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" height=\"99\" width=\"150\" class=\"pull-right\" alt=\"aemy-logo\">\n			</div>\n		</div>\n	</div><!-- /.main-content -->\n</div><!-- /.main-container -->\n");
      return buffer;
      
    });
  });