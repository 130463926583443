define("front/templates/form-parameter/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n							<p class=\"form-control-static\">Ναι</p>\n							");
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n							<p class=\"form-control-static\">Όχι</p>\n							");
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n					<div class=\"form-group\">\n						<label for=\"subParameter\" class=\"col-sm-3 control-label\">Σε ποιά παράμετρο:</label>\n						<div class=\"col-sm-9\">\n							<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "subParameterInValue", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n						</div>\n					</div>\n\n					");
      return buffer;
      }

    function program7(depth0,data) {
      
      
      data.buffer.push("\n							Επιστροφή\n							");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			\n			<h3 class=\"widget-title smaller\">Παράμετρος Φόρμας</h3>\n\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				<form class=\"form-horizontal\" role=\"form\">\n					<div class=\"form-group\">\n						<label for=\"parameter\" class=\"col-sm-3 control-label\">Παράμετρος:</label>\n						<div class=\"col-sm-9\">\n							<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "model.question.text", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"form_groups\" class=\"col-sm-3 control-label\">Υποενότητα:</label>\n						<div class=\"col-sm-9\">\n							<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "selectedGroup.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"numbering\" class=\"col-sm-3 control-label\">Αρίθμηση:</label>\n						<div class=\"col-sm-9\">\n							<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "numbering", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"sn\" class=\"col-sm-3 control-label\">Α/α:</label>\n						<div class=\"col-sm-9\">\n							<p class=\"form-control-static\">");
      stack1 = helpers._triageMustache.call(depth0, "sn", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n						</div>\n					</div>\n\n					<div class=\"form-group\">\n						<label for=\"subParameterRadio\" class=\"col-sm-3 control-label\">Υποπαράμετρος:</label>\n						<div class=\"col-sm-6\">\n							");
      stack1 = helpers['if'].call(depth0, "hasSubParameter", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n\n					");
      stack1 = helpers['if'].call(depth0, "hasSubParameter", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n					<div class=\"form-group\">\n						<div class=\"col-sm-offset-3 col-sm-4\">\n							<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "updateFormParameter", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Επεξεργασία</button>\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "form.build", "model.form.id", options) : helperMissing.call(depth0, "link-to", "form.build", "model.form.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n						<div class=\"col-sm-1\">\n							<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n						</div>\n					</div>\n\n				</form>\n			</div>\n		</div>\n	</div>\n</div>\n");
      return buffer;
      
    });
  });