define("front/templates/physfiles/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n	<div class=\"alert alert-success no-margin\">\n			Το μέλος ανήκει στην υπηρεσία <u>");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</u>\n			</div>\n\n			<div class=\"space-20\"></div>\n			<div class=\"widget-box\">\n				<div class=\"widget-header\">\n					<h4 class=\"widget-title smaller\">Διαχείριση φυσικού φακέλου </h4>\n				</div>\n				<div class=\"widget-body\">\n					<div class=\"widget-main\">\n						<form class=\"form-horizontal\" role=\"form\">\n							<div class=\"form-group\">\n								<label for=\"currentUnit\" class=\"col-sm-2 control-label\">Κωδικός:\n								</label>\n								<div class=\"col-sm-6\">\n									");
      stack1 = helpers['if'].call(depth0, "isAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<div class=\"col-sm-offset-2 col-sm-4\">\n									<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n									");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "physfiles", options) : helperMissing.call(depth0, "link-to", "physfiles", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							\n								</div>\n								<div class=\"col-sm-2\">\n									<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n								</div>\n							</div>\n\n						</form>\n					</div>\n				</div>\n			</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n										");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("physfile.displaycode"),
        'id': ("displaycode")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n										<span class=\"blue\">");
      stack1 = helpers._triageMustache.call(depth0, "physfile.displaycode", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</span>\n									");
      return buffer;
      }

    function program6(depth0,data) {
      
      
      data.buffer.push("\n										Επιστροφή\n									");
      }

    function program8(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n	");
      stack1 = helpers['if'].call(depth0, "person.currentUnit", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(15, program15, data),fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      }
    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n			\n			<div class=\"alert alert-success no-margin\">\n			Το μέλος ανήκει στην υπηρεσία <u>");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</u>\n			</div>\n\n			<div class=\"space-20\"></div>\n			<div class=\"widget-box\">\n				<div class=\"widget-header\">\n					<h4 class=\"widget-title smaller\">Διαχείριση φυσικού φακέλου </h4>\n				</div>\n				<div class=\"widget-body\">\n					<div class=\"widget-main\">\n				\n						");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(13, program13, data),fn:self.program(10, program10, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</div>\n				</div>\n			</div>\n\n\n	");
      return buffer;
      }
    function program10(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							<form class=\"form-horizontal\" role=\"form\">\n								\n								<div class=\"form-group\">\n									<label for=\"currentUnit\" class=\"col-sm-2 control-label\">Κωδeeικός:\n									</label>\n									<div class=\"col-sm-6\">\n									<span class=\"lead blue\">");
      stack1 = helpers._triageMustache.call(depth0, "physfile.displaycode", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</span>\n									</div>\n								</div>\n\n								<div class=\"form-group\">\n									<div class=\"col-sm-offset-2 col-sm-4\">\n										\n										");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								\n									</div>\n									<div class=\"col-sm-2\">\n										<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n									</div>\n								</div>\n								\n							</form>\n\n							\n							\n						");
      return buffer;
      }
    function program11(depth0,data) {
      
      
      data.buffer.push("\n											Ακύρωση\n										");
      }

    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger\">\n								Το μέλος δεν ανήκει στην μονάδα ");
      stack1 = helpers._triageMustache.call(depth0, "user_current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<br>\n							</div>\n						");
      return buffer;
      }

    function program15(depth0,data) {
      
      
      data.buffer.push("\n		<div class=\"space-10\"></div>\n		<div class=\"alert alert-danger\">\n			\n		</div>\n	");
      }

      stack1 = helpers['if'].call(depth0, "isAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(8, program8, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });