define("front/controllers/expedition-exchanges/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {
      canValidate: false,

      validations: {
        region: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Περιοχή." }
        },

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        total_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        new_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        given: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        returned: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        }
      },

      actions: {
        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var new_expedition_exchange = this.store.createRecord("expedition-exchange", {
            date: moment(this.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            region: this.get("region"),
            kind: this.get("kind"),
            comments: this.get("comments"),
            total_persons: this.get("total_persons"),
            new_persons: this.get("new_persons"),
            given: this.get("given"),
            returned: this.get("returned"),
            unit_id: this.get("currentUnit.id")
          });

          this.validate().then(function () {
            new_expedition_exchange.save().then(function () {
              Notify.success("Η κλήση καταχωρήθηκε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.send("reloadExpeditions");
              self.transitionToRoute("expedition-exchanges.index");
            }, function (error) {
              self.set("canValidate", false);
              new_expedition_exchange.deleteRecord();
              self.set("showSpinner", false);

              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            new_expedition_exchange.deleteRecord();
          });
        }
      }
    });
  });