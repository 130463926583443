define("front/templates/trpr-report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">Παρουσιες Μεταβατικου Σχολείου - Αναφορά</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("showReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n          <hr />\n        </div>\n      </div>\n      <div class=\"row\">\n        <div class=\"col-sm-10 col-sm-offset-1\">\n        <table class=\"table table-striped table-bordered table-hover\">\n            <thead class=\"thin-border-bottom\">\n              <tr>\n                <td>\n                  A/A\n                </td>\n                <td>\n                  <i class=\"ace-icon fa fa-user\"></i>\n                  Ονοματεπώνυμο\n                </td>\n                <td>\n                  <i class=\"glyphicon glyphicon-barcode\"></i>\n                  Αρ. Φακέλου\n                </td>\n\n                <td>\n                  <i class=\"glyphicon glyphicon-check\"></i>\n                  Παρουσία\n                </td>\n              </tr>\n            </thead>\n            <tbody>\n              ");
      stack1 = helpers.each.call(depth0, "person", "in", "sortedList", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </tbody>\n          </table>\n\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n\n                <tr>\n                  <td>");
      stack1 = helpers._triageMustache.call(depth0, "_view.contentIndex", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                  <td>");
      stack1 = helpers._triageMustache.call(depth0, "person.pers_last_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" &nbsp; ");
      stack1 = helpers._triageMustache.call(depth0, "person.pers_first_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" </td>\n                  <td>\n                    ");
      stack1 = helpers['if'].call(depth0, "person.phys", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </td>\n                  <td>\n                  ");
      stack1 = helpers['if'].call(depth0, "person.pres_hours", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(8, program8, data),fn:self.program(6, program6, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </td>\n                </tr>\n\n              ");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      ");
      stack1 = helpers.each.call(depth0, "person.phys", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    ");
      return buffer;
      }
    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                        ");
      stack1 = helpers._triageMustache.call(depth0, "person.phys", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                      ");
      return buffer;
      }

    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                    ");
      stack1 = helpers._triageMustache.call(depth0, "person.pres_hours", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  ");
      return buffer;
      }

    function program8(depth0,data) {
      
      
      data.buffer.push("\n                    0\n                  ");
      }

    function program10(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Η συγκεκριμένη υπηρεσία (");
      stack1 = helpers._triageMustache.call(depth0, "current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("), δεν έχει μεταβατικό σχολείο\n  </div>\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "currentUnit.haveTransitionalPresences", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(10, program10, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n");
      return buffer;
      
    });
  });