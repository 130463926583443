define("front/models/program", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Program = DS.Model.extend({
      name: attr(),
      imageUrl: attr(),
      units: DS.hasMany("unit"),
      users: DS.hasMany("user"),
      href: (function () {
        return "#" + this.get("id");
      }).property("id"),
      hashProgramId: (function () {
        return "#program_" + this.get("id");
      }).property("id"),
      programId: (function () {
        return "program_" + this.get("id");
      }).property("id") });

    __exports__["default"] = Program;
    // notifications: DS.hasMany('notification')
  });