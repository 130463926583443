define("front/views/medr-scrolling", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      didInsertElement: function () {
        //	$('#medical-records-box').widget_box('toggle');
        $("#medr-scrolling").ace_scroll({
          size: 815
        });
      }
    });
  });