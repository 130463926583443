define("front/templates/form-groups/form-group-modal", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n	<div class=\"form-group\">\n		<label for=\"name\" class=\"col-sm-3 control-label\">Όνομα:</label>\n		<div class=\"col-sm-9\">\n			");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("name"),
        'id': ("name")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n		</div>\n		<div class=\"col-sm-9 col-sm-offset-3\">\n			");
      stack1 = helpers['if'].call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"header\" class=\"col-sm-3 control-label\">Επικεφαλίδα:</label>\n		<div class=\"col-sm-9\">\n			");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("header"),
        'id': ("header"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"footer\" class=\"col-sm-3 control-label\">Υποσημείωση:</label>\n		<div class=\"col-sm-9\">\n			");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("footer"),
        'id': ("footer"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n		</div>\n	</div>\n\n\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n			<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n			");
      return buffer;
      }

      stack1 = (helper = helpers['my-modal'] || (depth0 && depth0['my-modal']),options={hash:{
        'title': ("Δημιουργία Γκρουπ"),
        'okText': ("Δημιουργία"),
        'closeText': ("Κλείσιμο"),
        'ok': ("saveGroup"),
        'close': ("removeModal")
      },hashTypes:{'title': "STRING",'okText': "STRING",'closeText': "STRING",'ok': "STRING",'close': "STRING"},hashContexts:{'title': depth0,'okText': depth0,'closeText': depth0,'ok': depth0,'close': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "my-modal", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });