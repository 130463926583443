define("front/initializers/export-application-global", 
  ["ember","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var config = __dependency2__["default"];

    function initialize() {
      var application = arguments[1] || arguments[0];
      if (config.exportApplicationGlobal !== false) {
        var value = config.exportApplicationGlobal;
        var globalName;

        if (typeof value === "string") {
          globalName = value;
        } else {
          globalName = Ember.String.classify(config.modulePrefix);
        }

        if (!window[globalName]) {
          window[globalName] = application;

          application.reopen({
            willDestroy: function () {
              this._super.apply(this, arguments);
              delete window[globalName];
            }
          });
        }
      }
    }

    __exports__.initialize = initialize;
    __exports__["default"] = {
      name: "export-application-global",

      initialize: initialize
    };
  });