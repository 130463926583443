define("front/controllers/parameters/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      questionCode: null,
      question: null,
      questionRemarks: null,
      quesSingularity: false,
      quesIndepedent: false,

      answerTypesArray: [{ name: "Text", value: "1" }, { name: "Textarea", value: "2" }, { name: "Radio", value: "3" }, { name: "Multiple choice", value: "4" }, { name: "Date", value: "5" }, { name: "Number", value: "6" }],

      selectedAnswerType: null,

      possibleAnswers: [],

      possibleAnswersIndex: 0,

      multipleChoices: [{ id: 1 }, { id: 2 }],
      multipleChoiceArray: [],

      canValidate: false,

      validations: {
        question: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ερώτηση." }
        }
      },


      isText: (function () {
        if (this.get("selectedAnswerType.value") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      isTextArea: (function () {
        if (this.get("selectedAnswerType.value") === "2") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      isRadio: (function () {
        if (this.get("selectedAnswerType.value") === "3") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      isMultiple: (function () {
        if (this.get("selectedAnswerType.value") === "4") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      isDate: (function () {
        if (this.get("selectedAnswerType.value") === "5") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      isNumber: (function () {
        if (this.get("selectedAnswerType.value") === "6") {
          return true;
        } else {
          return false;
        }
      }).property("selectedAnswerType"),

      actions: {

        addMultiple: function () {
          var multipleChoices = this.get("multipleChoices");
          var length = this.get("multipleChoices.length");

          if (length < 150) {
            multipleChoices.pushObject({ id: length + 1 });
          }
        },

        addAnswer: function (answerType) {
          var possibleAnswers = this.get("possibleAnswers");
          var tmp_text_title = this.get("answerText");
          var tmp_code = this.get("answerCode");

          if (answerType === "1") {
            possibleAnswers.pushObject({ answerText: tmp_text_title, type: 1, index: this.get("possibleAnswersIndex"), code: tmp_code });
            this.incrementProperty("possibleAnswersIndex");
          }

          if (answerType === "2") {
            possibleAnswers.pushObject({ answerText: tmp_text_title, type: 2, index: this.get("possibleAnswersIndex"), code: tmp_code });
            this.incrementProperty("possibleAnswersIndex");
          }

          if (answerType === "3") {
            possibleAnswers.pushObject({ answerText: tmp_text_title, type: 3, index: this.get("possibleAnswersIndex"), code: tmp_code });
            this.incrementProperty("possibleAnswersIndex");
          }

          if (answerType === "4") {
            var length = this.get("multipleChoices.length");
            var multipleChoiceArray = this.get("multipleChoiceArray");

            if (Ember.isEmpty(multipleChoiceArray)) {
              for (var i = 1; i <= length; i++) {
                multipleChoiceArray.pushObject({ answerText: $("#answerText" + i).val(), code: $("#answerCode" + i).val() });

                $("#answerText" + i).val("");
                $("#answerCode" + i).val("");
              }

              this.set("multipleChoices", [{ id: 1 }, { id: 2 }]);

              possibleAnswers.pushObject({ answerText: multipleChoiceArray, type: 4, index: this.get("possibleAnswersIndex") });
              this.incrementProperty("possibleAnswersIndex");
            }
          }

          if (answerType === "5") {
            possibleAnswers.pushObject({ type: 5, index: this.get("possibleAnswersIndex"), code: tmp_code });
            this.incrementProperty("possibleAnswersIndex");
          }

          if (answerType === "6") {
            possibleAnswers.pushObject({ answerText: tmp_text_title, type: 6, index: this.get("possibleAnswersIndex"), code: tmp_code });
            this.incrementProperty("possibleAnswersIndex");
          }

          //console.log(possibleAnswers);
          this.set("answerText", null);
          this.set("answerCode", null);
        },

        deleteAnswer: function (index, type) {
          var possibleAnswers = this.get("possibleAnswers");
          //console.log(possibleAnswers);
          possibleAnswers.removeObject(possibleAnswers.findBy("index", index));

          if (type === "4") {
            this.set("multipleChoiceArray", []);
          }
        },

        saveParameter: function () {
          var self = this;
          this.set("canValidate", true);

          var questionType = $("#questionType").val();

          var newQuestion = this.store.createRecord("question", {
            code: this.get("questionCode"),
            text: this.get("question"),
            remarks: this.get("questionRemarks"),
            singularity: this.get("quesSingularity"),
            independent: this.get("quesIndepedent"),
            type: questionType
          });

          var possibleAnswers = this.get("possibleAnswers");
          if (possibleAnswers.length > 0) {
            possibleAnswers.forEach(function (item) {
              newQuestion.get("answers").pushObject(self.store.createRecord("answer", {
                code: item.code,
                text: item.answerText,
                type: item.type,
                remarks: item.remarks
              }));
            });
          }

          this.set("showSpinner", true);

          this.validate().then(function () {
            newQuestion.save().then(function () {
              Notify.success("Η αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });

              self.set("canValidate", false);

              $("#questionType").val("0");
              self.set("questionCode", "");
              self.set("question", "");
              self.set("questionRemarks", "");
              self.set("quesIndepedent", false);
              self.set("quesSingularity", false);
              self.set("possibleAnswers", []);
              self.set("multipleChoices", [{ id: 1 }, { id: 2 }]);
              self.set("multipleChoiceArray", []);

              self.set("showSpinner", false);
            }, function (response) {
              self.set("canValidate", false);

              self.set("questionCode", "");
              self.set("question", "");
              self.set("questionRemarks", "");
              self.set("quesIndepedent", false);
              self.set("quesSingularity", false);
              self.set("possibleAnswers", []);
              self.set("multipleChoices", [{ id: 1 }, { id: 2 }]);
              self.set("multipleChoiceArray", []);

              self.set("showSpinner", false);
              newQuestion.get("answers").forEach(function (answer) {
                answer.deleteRecord();
              });
              newQuestion.deleteRecord();
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);

            newQuestion.get("answers").forEach(function (answer) {
              answer.deleteRecord();
            });
            newQuestion.deleteRecord();
          });

        }
      }

    });
  });