define("front/routes/programs/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function () {
        return this.store.find("program");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("programs", model);
      }
    });
  });