define("front/controllers/application", 
  ["front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    var ApplicationController = Ember.Controller.extend(UserMixin, {

      selectedUnit: null,
      currentUser: null,
      currentUserName: null,
      currentUserId: null,
      counter: 1,
      showProgramDiv: true,
      logoUrl: (function () {
        return ENV.APP.baseURL + "images/logo_el-GR-min.png";
      }).property(),
      /*
      	notiHasChanged: function(){
      		var self = this;
      		this.store.find('notification',{unit_id: this.get('selectedUnit.id'), unseen: 1}).then(function(value){
      				self.set('notifications',value);
      				var meta = self.store.metadataFor("notification");
      				self.set('notifications_unseen_cnt',meta.unseen);
      			});
      	}.observes('notifications'),*/
      unitChange: (function () {
        if (this.get("counter") > 1) {
          this.send("reloadDash");
        }
        this.set("counter", this.get("counter") + 1);
      }).observes("selectedUnit"),

      canExport: (function () {
        return this.get("isAdministrator") || this.get("isSuperAdmin");
      }).property("isAdministrator", "isSuperAdmin"),


      /*program_image_url: function(){
      	if(!Ember.isEmpty(this.get('model.program'))){
      		var firstProgram = this.get('model.program').get('firstObject');
      		return firstProgram.get('imageUrl');
      	}
      }.property('model.program'),*/

      actions: {
        toggleSidebar: function () {
          this.toggleProperty("showProgramDiv");
        },

        logout: function () {
          $.ajax({
            url: ENV.APP.backendUrl + "/logout",
            type: "POST",
            data: null
          });
          this.send("invalidateSession");
        },

        transCanc: function (personId, pevnId) {
          var self = this;
          this.store.find("notification", { pevn_id: pevnId }).then(function (response) {
            var noti = response.get("firstObject");

            noti.destroyRecord().then(function () {
              self.set("notifications_unseen_cnt", self.get("notifications_unseen_cnt") - 1);
            })["catch"](function (response) {
              response.get("firstObject").rollback();
            });
          });

          self.transitionToRoute("event", personId, pevnId);
        }
      },

      canNewPerson: (function () {
        var permissions = this.get("user.permissions");
        var success = false;
        if (!Ember.isEmpty(permissions)) {
          permissions.forEach(function (item) {
            if (item.get("name") === "new_person") {
              success = true;
            }
          });
        }
        return success;
      }).property("model.user.permissions")


    });

    __exports__["default"] = ApplicationController;
  });