define("front/routes/users/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return Ember.RSVP.hash({
          user: this.store.find("user", params.user_id),
          programs: this.store.find("program"),
          roles: this.store.find("role")
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("user", model.user);
        controller.set("units", model.user.get("units"));
        controller.set("programs", model.programs);

        controller.set("password", "");
        controller.set("passwordConfirmation", "");
        controller.set("oldPassword", "");

        controller.set("roles", model.roles);

        console.log(model.user.get("roles"));
      },

      deactivate: function () {
        //this.controllerFor('users.edit').get('model').rollback();
        this.set("controller.related", []);
        this.set("controller.password", "");
      },
      actions: {
        reload: function () {
          this.refresh();
          this.controllerFor("users.edit").get("model").user.rollback();
        }
      }
    });
  });