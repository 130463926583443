define("front/routes/program", 
  ["ember","simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("program", params.program_id);
      },

      controllerSetup: function (controller, model) {
        controller.set("program", model);
      }
    });
  });