define("front/controllers/person/pdf-ascertainment", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    __exports__["default"] = Ember.ObjectController.extend({

      personHistory: null,
      person: null,

      actions: {}

    });
  });