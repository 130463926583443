define("front/controllers/pehi/create-event", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend({
      types: (function () {
        var _this = this;


        var types = [{ type: "closure", text: "Κλείσιμο", evtType: "end" }, { type: "withdrawal", text: "Αποχώρηση", evtType: "end" }, { type: "graduation", text: "Αποφοίτηση", evtType: "end" }, { type: "completion", text: "Ολοκλήρωση", evtType: "end" }, { type: "serviceInterrupt", text: "Διακοπή Παροχής Υπηρεσίας", evtType: "end" }, { type: "release", text: "Αποφυλάκιση", evtType: "end" }, { type: "transport", text: "Μεταγωγή", evtType: "end" }, { type: "transfer", text: "Παραπομπή", evtType: "end" }, { type: "assignment", text: "Ανάθεση", evtType: "start" }, { type: "serviceStatement", text: "Παροχή Υπηρεσίας", evtType: "start" }, { type: "transition", text: "Αλλαγή Φάσης", evtType: "middle" }];

        var events = types.filter(function (type) {
          return type.evtType === _this.get("model.type");
        });

        if (!this.get("obsUnit.compCanClose")) {
          events = events.reject(function (type) {
            return type.type == "completion";
          });
        }

        if (!this.get("obsUnit.isPrison")) {
          events = events.filter(function (type) {
            return type.type !== "release" && type.type !== "transport";
          });
        }

        return events;
      }).property("obsUnit", "model.type"),

      actions: {
        create: function (selectedType) {
          var event = undefined;
          var self = this;
          event = this.store.createRecord(selectedType.type, {});
          event.set("pehi_id", this.get("model.pehi.id"));
          event.set("pers_id", this.get("model.person.id"));
          event.save().then(function (response) {
            self.set("showSpinner", false);
            Notify.success("Η αποθήκευση έγινε με επιτυχία.", {
              closeAfter: 10000
            });
            self.send("reloadPerson");
            self.transitionToRoute("pehi-admin");
          }, function () {
            self.set("showSpinner", false);
            Notify.warning("Η αποθήκευση απέτυχε", {
              closeAfter: 10000
            });
          });
        }
      }
    });
  });