define("front/templates/units/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                    <div class=\"panel panel-info\">\n                        <div class=\" accordion-heading panel-heading \">\n                            <h4 class=\"panel-title bigger\">\n                                <a data-toggle=\"collapse\" data-parent=\"#accordion\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'href': ("hashProgramId")
      },hashTypes:{'href': "ID"},hashContexts:{'href': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n                                    ");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                </a>\n                            </h4>\n                        </div>\n                        <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("programId")
      },hashTypes:{'id': "ID"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"panel-collapse collapse\">\n                            <div class=\"panel-body\">\n                                <table class=\"table table-condensed table-hover\">\n                                    <tbody>\n                                        ");
      stack1 = helpers.each.call(depth0, "units", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                    </tbody>\n                                </table>\n\n                            </div>\n                        </div>\n                    </div>\n                    ");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                                        <tr>\n                                            <td>\n                                                ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "units.edit", "id", options) : helperMissing.call(depth0, "link-to", "units.edit", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                                            </td>\n                                        </tr>\n                                        ");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("<span class=\"bolder blue\">");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</span>");
      return buffer;
      }

      data.buffer.push("<div class=\"row\">\n    <div class=\"col-xs-10 col-xs-offset-1\">\n        <div class=\"widget-box widget-color-blue2\">\n            <div class=\"widget-header\">\n                <h4 class=\"widget-title lighter smaller\">Επιλέξτε κάποια Υπηρεσία απο τα Κέντρο</h4>\n            </div>\n            <div class=\"widget-body\">\n                <div class=\"panel-group\" id=\"accordion\">  \n                    ");
      stack1 = helpers.each.call(depth0, "programs", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n");
      return buffer;
      
    });
  });