define("front/controllers/ithaki-helplines/index", 
  ["front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.ArrayController.extend(UserMixin, {

      queryParams: ["page", "perPage", "sort", "direction"],

      pageBinding: "content.page",
      perPageBinding: "content.perPage",
      totalPagesBinding: "content.totalPages",

      search_consultant: null,
      search_date: null,
      search_code: null,

      page: 1,
      perPage: 15,

      searchObserver: (function () {
        this.send("search");
      }).observes("search_consultant", "search_date"),

      actions: {
        sortBy: function (sort) {
          if (sort === this.get("sort")) {
            this.set("direction", this.get("direction") === "asc" ? "desc" : "asc");
          } else {
            this.set("sort", sort);
            this.set("direction", "asc");
          }

          this.send("search");
        },
        deleteCall: function (id) {
          this.send("showModal", "ithaki-helplines/delete-modal", id);
        }
      }
    });
  });