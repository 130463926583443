define("front/models/unit", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Unit = DS.Model.extend({
      name: attr(),
      havePresences: attr("boolean"),
      canAssign: attr("boolean"),
      canComplete: attr("boolean"),
      canGraduate: attr("boolean"),
      canClose: attr("boolean"),
      compCanClose: attr("boolean"),
      haveExpeditions: attr("boolean"),
      haveTransitionalPresences: attr("boolean"),
      haveAlfaHelplines: attr("boolean"),
      haveIthakiHelplines: attr("boolean"),
      isPrison: attr("boolean"),
      isActive: attr("boolean"),
      rank: attr(),
      prefix: attr(),
      programName: attr(),
      prId: attr(),
      programCode: attr(),
      strId: attr(),
      type: attr(),
      fullTitle: (function () {
        return this.get("name") + " (" + this.get("prefix") + ")";
      }).property("name", "prefix")
    });

    __exports__["default"] = Unit;
  });