define("front/templates/person-groups", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      
      data.buffer.push("Δημιουργία νέας ομάδας");
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							");
      stack1 = helpers.unless.call(depth0, "isSystemGroup", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						");
      return buffer;
      }
    function program4(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n								<tr>\n									<td>");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n									<td>\n										");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("blue")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person-group.edit", "id", options) : helperMissing.call(depth0, "link-to", "person-group.edit", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										<a href=\"#\" class=\"red\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "deleteGroup", "id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">\n											<i class=\"ace-icon fa fa-trash-o bigger-130\"></i>\n										</a>\n									</td>\n								</tr>\n							");
      return buffer;
      }
    function program5(depth0,data) {
      
      
      data.buffer.push("\n											<i class=\"ace-icon fa fa-search-plus bigger-130\"></i>\n										");
      }

      data.buffer.push("<div class=\"row\">\n	<div class=\"col-sm-12\">\n		<div class=\"page-header\">\n			<h1>Ομάδες Μελών<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Διαχείριση</small></h1>\n		</div>\n	</div>\n</div>\n<div class=\"row\">\n	<div class=\"col-md-3 col-sm-12\">\n		");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary col-md-12 col-sm-12")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person-groups.new", options) : helperMissing.call(depth0, "link-to", "person-groups.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		<div class=\"space-20 col-sm-12 col-md-12\"></div>\n		<div class=\"widget-box widget-color-blue col-sm-12 col-md-12\" id=\"person-groups\">\n			<div class=\"widget-header\">\n				<h4 class=\"widget-title bigger lighter\">\n					Ομάδες\n				</h4>\n			</div>\n			\n			<div class=\"widget-body \">\n				<div class=\"widget-main no-padding\" id=\"group-scrolling\">\n					<table class=\"table col-xs-12 table-striped table-bordered table-hover\">\n						<tbody id=\"group-scrolling\">\n						");
      stack1 = helpers.each.call(depth0, "model", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("							\n						</tbody>\n					</table>\n				</div>\n			</div>\n		</div>\n	</div>\n	<div class=\"col-md-9 col-sm-12\">\n		");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n	</div>\n</div>\n\n");
      return buffer;
      
    });
  });