define("front/models/withdrawal_reason", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Reason = DS.Model.extend({
      name: attr()
    });

    __exports__["default"] = Reason;
  });