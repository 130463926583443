define("front/models/question", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Question = DS.Model.extend({

      code: attr(),
      text: attr(),
      type: attr(),
      remarks: attr(),
      singularity: attr(),
      independent: attr(),
      frprId: attr(),
      answers: DS.hasMany("answer")

    });

    __exports__["default"] = Question;
  });