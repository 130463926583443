define("front/templates/application", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n\n\n<!-- Static navbar -->\n<div class=\"navbar navbar-default\" role=\"navigation\" id=\"navbar\">\n\n    <div class=\"navbar-header\">\n      <button type=\"button\" class=\"navbar-toggle\" data-toggle=\"collapse\" data-target=\".navbar-collapse\">\n        <span class=\"sr-only\">Toggle navigation</span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n        <span class=\"icon-bar\"></span>\n      </button>\n      <a class=\"navbar-brand\" href=\"#\"><img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("logoUrl")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"img-responsive\" alt=\"Logo\"></a>\n    </div>\n\n    <nav role=\"navigation\" class=\"navbar-menu pull-left collapse navbar-collapse\">\n      <ul class=\"nav navbar-nav\">\n\n        <li>\n          ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("brand-home")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "dashboard", options) : helperMissing.call(depth0, "link-to", "dashboard", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </li>\n        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "about", options) : helperMissing.call(depth0, "link-to", "about", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n      </ul>\n\n      <!-- you can also have a form here -->\n    </nav>\n\n    <div class=\"navbar-buttons navbar-header pull-right\">\n      <ul class=\"nav ace-nav\">\n\n        <li class=\"dd-unit\">\n          ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'class': ("form-control navbar-form-control sbox-unit"),
        'content': ("userUnits"),
        'optionValuePath': ("content.id"),
        'optionLabelPath': ("content.fullTitle"),
        'selectionBinding': ("selectedUnit"),
        'optionGroupPath': ("programName")
      },hashTypes:{'class': "STRING",'content': "ID",'optionValuePath': "STRING",'optionLabelPath': "STRING",'selectionBinding': "STRING",'optionGroupPath': "STRING"},hashContexts:{'class': depth0,'content': depth0,'optionValuePath': depth0,'optionLabelPath': depth0,'selectionBinding': depth0,'optionGroupPath': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n      </li>\n\n      <li class=\"purple margin-4\">\n        <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" href=\"#\">\n          <i class=\"ace-icon fa fa-bell icon-animated-bell\"></i>\n          <span class=\"badge badge-important\">");
      stack1 = helpers._triageMustache.call(depth0, "notifications_unseen_cnt", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</span>\n        </a>\n  \n        <ul class=\"dropdown-menu-right dropdown-navbar navbar-pink dropdown-menu dropdown-caret dropdown-close\">\n          <li class=\"dropdown-header\">\n            <i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n            ");
      stack1 = helpers._triageMustache.call(depth0, "notifications_unseen_cnt", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" Ειδοποιήσεις\n          </li>\n          ");
      stack1 = helpers.each.call(depth0, "notifications", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n          <li class=\"dropdown-footer\">\n\n          </li>\n        </ul>\n      </li>\n      <!--\n      <li class=\"grey\">\n        <a data-toggle=\"dropdown\" class=\"dropdown-toggle\" href=\"#\">\n          <i class=\"ace-icon fa fa-tasks\"></i>\n          <span class=\"badge badge-grey\">4</span>\n        </a>\n      </li>\n      -->\n\n      <li class=\"light-blue\">\n        <a data-toggle=\"dropdown\" href=\"#\" class=\"dropdown-toggle\">\n          \n          <i class=\"ace-icon fa fa-user\"></i>\n          <span class=\"user-info\">\n            <small>Καλώς ορίσατε,</small>\n            ");
      stack1 = helpers._triageMustache.call(depth0, "currentUserName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </span>\n          <i class=\"ace-icon fa fa-caret-down\"></i>\n        </a>\n        <ul class=\"user-menu dropdown-menu dropdown-menu-right dropdown-yellow dropdown-caret dropdown-close\">\n          \n          <li><a href=\"#\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "logout", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("><i class=\"ace-icon fa fa-power-off\"></i> Αποσύνδεση</a></li>\n        </ul>\n      </li>\n    </ul>\n  </div>\n\n\n</div>\n<div class=\"main-container\" id=\"main-container\">\n <div class=\"sidebar\" id=\"sidebar\">\n  <div class=\"well\">\n    ");
      stack1 = helpers['if'].call(depth0, "showProgramDiv", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n  </div>\n\n  <ul class=\"nav nav-list\">\n\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(14, program14, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "persons.new", options) : helperMissing.call(depth0, "link-to", "persons.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(17, program17, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "templates", options) : helperMissing.call(depth0, "link-to", "templates", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n    ");
      stack1 = helpers['if'].call(depth0, "canExport", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(20, program20, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(24, program24, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "administration", options) : helperMissing.call(depth0, "link-to", "administration", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n		");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(27, program27, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person-groups.index", options) : helperMissing.call(depth0, "link-to", "person-groups.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = helpers['if'].call(depth0, "selectedUnit.haveExpeditions", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(30, program30, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = helpers['if'].call(depth0, "selectedUnit.haveAlfaHelplines", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(34, program34, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = helpers['if'].call(depth0, "selectedUnit.haveIthakiHelplines", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(38, program38, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = helpers.unless.call(depth0, "isVolunteer", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(42, program42, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(46, program46, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "appointments", options) : helperMissing.call(depth0, "link-to", "appointments", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n    ");
      stack1 = helpers['if'].call(depth0, "selectedUnit.havePresences", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(49, program49, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = helpers['if'].call(depth0, "selectedUnit.haveTransitionalPresences", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(53, program53, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n  </ul>\n\n  <div id=\"sidebar-collapse\" class=\"sidebar-toggle sidebar-collapse\">\n   <i class=\"ace-icon fa fa-angle-double-left\" data-icon1=\"ace-icon fa fa-angle-double-left\" data-icon2=\"ace-icon fa fa-angle-double-right\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "toggleSidebar", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("></i>\n </div>\n</div><!-- /.sidebar -->\n\n<div class=\"main-content\">\n\n  <div class=\"page-content\">\n    <div class=\"row\">\n      <div class=\"col-xs-12\">\n        ");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        ");
      data.buffer.push(escapeExpression((helper = helpers.outlet || (depth0 && depth0.outlet),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "modal", options) : helperMissing.call(depth0, "outlet", "modal", options))));
      data.buffer.push("\n      </div><!-- /.col -->\n    </div><!-- /.row -->\n  </div><!-- /.page-content -->\n</div><!-- /.main-content -->\n\n</div><!-- /.main-container -->\n\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("Φάκελος Ωφελούμενου");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n        ");
      }

    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n          <li class=\"noti-item\">\n            ");
      stack1 = helpers['if'].call(depth0, "isTrans", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n            ");
      stack1 = helpers['if'].call(depth0, "isTransCanc", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n          </li>\n          ");
      return buffer;
      }
    function program7(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person.accept-reference", "personId", options) : helperMissing.call(depth0, "link-to", "person.accept-reference", "personId", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            ");
      return buffer;
      }
    function program8(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"clearfix noti\">\n                  <span class=\"pull-left\">\n                    ");
      stack1 = helpers._triageMustache.call(depth0, "title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </span>\n                </div>\n              ");
      return buffer;
      }

    function program10(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n              <a href='#' ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "transCanc", "personId", "pevn_id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0,depth0],types:["STRING","ID","ID"],data:data})));
      data.buffer.push(">\n                <div class=\"clearfix noti\">\n                  <span class=\"pull-left\">\n                    ");
      stack1 = helpers._triageMustache.call(depth0, "title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </span>\n                </div>\n              </a>\n            ");
      return buffer;
      }

    function program12(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n    <img  ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("program_image_url")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" style=\"width:150px;\" alt=\"ProgramLogo\">\n\n    <div class=\"space-4\"></div>\n\n    <h5 class=\"text-info\"><span class=\"orange\">Μονάδα:</span><br>");
      stack1 = helpers._triageMustache.call(depth0, "structure_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n    <h5 class=\"text-info\"><span class=\"orange\">Υπηρεσία:</span><br>");
      stack1 = helpers._triageMustache.call(depth0, "selectedUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n    ");
      return buffer;
      }

    function program14(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(15, program15, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "persons.new", options) : helperMissing.call(depth0, "link-to", "persons.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program15(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-folder-o\"></i>\n      <span class=\"menu-text\">Νέος Φάκελος</span>");
      }

    function program17(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(18, program18, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "templates", options) : helperMissing.call(depth0, "link-to", "templates", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program18(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon glyphicon glyphicon-file pull-left\"></i>\n      <span class=\"menu-text\">Χρήση Template Εγγράφου</span>");
      }

    function program20(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(21, program21, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "export", options) : helperMissing.call(depth0, "link-to", "export", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program21(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(22, program22, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "export", options) : helperMissing.call(depth0, "link-to", "export", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program22(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-external-link\"></i>\n        <span class=\"menu-text\">Εξαγωγή Στοιχείων</span>\n      ");
      }

    function program24(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "administration", options) : helperMissing.call(depth0, "link-to", "administration", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program25(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-cogs\"></i>\n        <span class=\"menu-text\">Διαχείριση</span>\n      ");
      }

    function program27(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    	");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(28, program28, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person-groups.index", options) : helperMissing.call(depth0, "link-to", "person-groups.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n  	");
      return buffer;
      }
    function program28(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-users\"></i>\n    		<span class=\"menu-text\">Ομάδες Μελών</span>\n      ");
      }

    function program30(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(31, program31, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "expeditions", options) : helperMissing.call(depth0, "link-to", "expeditions", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program31(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(32, program32, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "expeditions", options) : helperMissing.call(depth0, "link-to", "expeditions", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program32(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-car\"></i>\n        <span class=\"menu-text\">Εξορμήσεις</span>\n      ");
      }

    function program34(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(35, program35, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines", options) : helperMissing.call(depth0, "link-to", "helplines", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program35(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(36, program36, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines", options) : helperMissing.call(depth0, "link-to", "helplines", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program36(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-phone\"></i>\n        <span class=\"menu-text\">Γραμμή 1114</span>\n      ");
      }

    function program38(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(39, program39, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "ithaki-helplines", options) : helperMissing.call(depth0, "link-to", "ithaki-helplines", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program39(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(40, program40, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "ithaki-helplines", options) : helperMissing.call(depth0, "link-to", "ithaki-helplines", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program40(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-phone\"></i>\n        <span class=\"menu-text\">Γραμμή 1145</span>\n      ");
      }

    function program42(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(43, program43, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports", options) : helperMissing.call(depth0, "link-to", "reports", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program43(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(44, program44, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports", options) : helperMissing.call(depth0, "link-to", "reports", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program44(depth0,data) {
      
      
      data.buffer.push("<i class=\"menu-icon fa fa-bar-chart-o\"></i>\n        <span class=\"menu-text\">Εκδοση Στατιστικών / Αναφορών</span>\n      ");
      }

    function program46(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(47, program47, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "appointments", options) : helperMissing.call(depth0, "link-to", "appointments", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n      ");
      return buffer;
      }
    function program47(depth0,data) {
      
      
      data.buffer.push("\n          <i class=\"menu-icon fa fa-calendar pull-left\"></i>\n          <span class=\"menu-text\">Διαχείριση Προγραμματισμού</span>\n        ");
      }

    function program49(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(50, program50, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "participations", options) : helperMissing.call(depth0, "link-to", "participations", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program50(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(51, program51, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "participations", options) : helperMissing.call(depth0, "link-to", "participations", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program51(depth0,data) {
      
      
      data.buffer.push("\n        <i class=\"menu-icon glyphicon glyphicon-check\"></i>\n        <span class=\"menu-text\">Παρουσιολόγιο</span>\n      ");
      }

    function program53(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'tagName': ("li"),
        'href': (false)
      },hashTypes:{'tagName': "STRING",'href': "BOOLEAN"},hashContexts:{'tagName': depth0,'href': depth0},inverse:self.noop,fn:self.program(54, program54, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "transitional-presences", options) : helperMissing.call(depth0, "link-to", "transitional-presences", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program54(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n      ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(55, program55, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "transitional-presences", options) : helperMissing.call(depth0, "link-to", "transitional-presences", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n    ");
      return buffer;
      }
    function program55(depth0,data) {
      
      
      data.buffer.push("\n        <i class=\"menu-icon glyphicon glyphicon-check\"></i>\n        <span class=\"menu-text\">Παρουσιολόγιο Μεταβατικού σχολείου</span>\n      ");
      }

    function program57(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n");
      data.buffer.push(escapeExpression((helper = helpers.render || (depth0 && depth0.render),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "login", "login", options) : helperMissing.call(depth0, "render", "login", "login", options))));
      data.buffer.push("\n\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "session.isAuthenticated", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(57, program57, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });