define("front/views/documents/document-upload", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.View.extend({
      templateName: "documents/document-upload",

      didInsertElement: function () {
        var file_owner_type = this.get("controller.file_owner_type");
        var owid = this.get("controller.owid");

        this.$().on("click", "#delete_file", function (event) {
          event.preventDefault();

          var filename = $("#uploaded_filename").text();
          if (filename) {
            var url = ENV.APP.backendUrl + "/upload";

            $.ajax({ url: url + "?file=" + filename + "&owid=" + owid + "&file_owner_type=" + file_owner_type,
              type: "delete",
              success: function (output) {
                $("#progress .progress-bar").css("width", "0%");
                $("#uploaded_filename").text("");
                $("#delete_file").remove();
                $("#fileupload").removeAttr("disabled");
              }
            });
          }
        });

        var url = ENV.APP.backendUrl + "/upload";

        $("#fileupload").fileupload({
          url: url,
          dataType: "json",
          formData: { file_owner_type: file_owner_type, owid: owid },
          done: function (e, data) {
            //alert(data.result.files[0].error);
            if (data.result.files[0].error) {
              $("#progress .progress-bar").css("width", "0%");
              $("#upload_error").html("<p class=\"alert alert-danger\">" + data.result.files[0].error + "</p>");
            } else {
              $("#upload_error").html("");
              $.each(data.result.files, function (index, file) {
                $("#uploaded_filename").text(file.name);
                $("<button id=\"delete_file\" class=\"btn btn-danger\" >Διαγραφή</button>").appendTo("#files");
              });
              $("#fileupload").attr("disabled", "disabled");
            }
          },
          progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $("#progress .progress-bar").css("width", progress + "%");
          },
          fail: function (e, data) {
            $("#progress .progress-bar").css("width", "0%");
          }
        });
      }
    });
  });