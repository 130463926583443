define("front/routes/units/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        return this.modelFor("units");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("programs", model.sortBy("name"));
      }
    });
  });