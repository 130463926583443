define("front/routes/forms/search-form", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("question");
      },

      setupController: function (controller, model) {
        var personModel = this.modelFor("person");
        var person = personModel.person;

        controller.set("person", person);
      }
    });
  });