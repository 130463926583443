define("front/routes/reports/istoriko-xronou", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.controllerFor("application").get("selectedUnit");
      },

      setupController: function (controller, model) {
        controller.set("currentUnit", model);
      }
    });
  });