define("front/views/persons/official-docs-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",

      didInsertElement: function () {


        var self = this;

        var data;

        this.$().on("click", ".showForm", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("gotoOfficialDoc", id);
        });

        this.$().on("click", "#downloadForm", function (event) {
          event.preventDefault();
          var formname = $(this).attr("data-formname");
          self.get("controller").send("downloadForm", formname);
        });
        console.log(this.get("controller.model"));
        if (this.get("controller.model")) {
          data = this.get("controller.model").getEach("data");
        }
        //console.log(data);
        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Τίτλος", mData: "name" }, { sTitle: "Υποκατηγορία", mData: "subcategory._data.name" }, {
            mdata: null,
            mRender: function (data, type, row) {
              //console.log(row);
              var ret1 = "<div class='action-buttons'><a class='blue showForm' href='#' title='Εμφάνιση' data-value='" + row.id + "'><i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
              var ret3 = "";
              /*if(row.filename){
                ret3 = "<span class='blue' id='downloadForm' data-formname='"+row.filename+"'><i class='ace-icon fa fa-download bigger-130'></i></span>";
              }*/
              var ret2 = "</div>";
              var ret = ret1.concat(ret3, ret2);

              return ret;
            }

          }]
        });
      }
    });
  });