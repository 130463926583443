define("front/templates/pehi/edit-event", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n    <div class=\"widget-box\">\n        <div class=\"widget-header\">\n        <h4 class=\"widget-title smaller\">Συμβάν <i class=\"ace-icon fa fa-search-plus bigger-130\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "goToEvent", "model", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push("></i></h4>\n        </div>\n\n        <div class=\"widget-body\">\n            <div class=\"widget-main\">\n                 <p style=\"font-weight: bold\">");
      stack1 = helpers._triageMustache.call(depth0, "comments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</p>\n                 <div class=\"space-20\"></div>\n                 <form  role=\"form\">\n\n					<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n                    ");
      stack1 = helpers['if'].call(depth0, "showChange", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    <div class=\"form-group row\">\n                        <label for=\"startDate\" class=\"col-sm-4\">Ημερομηνία:</label>\n                        <div class=\"col-sm-8\">\n                            ");
      stack1 = helpers['if'].call(depth0, "showChange", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                        </div>\n                    </div>\n\n                    <div class=\"form-group row\">\n                        <label for=\"startDate\" class=\"col-sm-4\">Σχόλια:</label>    \n                        <div class=\"col-sm-8\">\n                            ");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'value': ("event.comments"),
        'class': ("form-control"),
        'rows': ("4"),
        'readonly': ("readonly")
      },hashTypes:{'value': "ID",'class': "STRING",'rows': "STRING",'readonly': "ID"},hashContexts:{'value': depth0,'class': depth0,'rows': depth0,'readonly': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n                        </div>\n                        <div class=\"space-10\"></div>\n\n                    </div>\n\n             \n                    <button type=\"submit\" class=\"btn btn-info btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "updateEvent", "model", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">Αποθήκευση </button>\n             \n\n                    \n                </form>\n                 <hr/>\n                 <form  role=\"form\">\n                    ");
      stack1 = helpers['if'].call(depth0, "showChange", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n                    ");
      stack1 = helpers['if'].call(depth0, "showChange", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                    <button type=\"submit\" class=\"btn btn-danger btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "delete", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Διαγραφή</button>\n\n                </form>\n            </div>\n        </div>\n    </div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n                    <div class=\"form-group row\">\n                        <div class=\"input-group\">Για την επεξεργασία Ημερομηνίας event Έναρξης και Τερματισμού Παρακαλώ χρησημοποιήστε την απο πάνω φόρμα</div>\n                    </div>\n                    ");
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                            <div class=\"input-group\">\n                                ");
      stack1 = helpers._triageMustache.call(depth0, "formatedDate", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                            </div>\n                            ");
      return buffer;
      }

    function program6(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n                                <div class=\"input-group date\" id=\"date-today\">\n                                    ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("model.f_date"),
        'id': ("event_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                    <span class=\"input-group-addon\"><i class=\"glyphicon glyphicon-th\"></i></span>\n                                </div>\n                            ");
      return buffer;
      }

    function program8(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n                    <div class=\"form-group row\">\n                        <label for=\"reason\" class=\"col-sm-4\">Αλλαγή σε τύπο:</label>\n                        <div class=\"col-sm-8\">\n                            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("types"),
        'class': ("form-control"),
        'optionLabelPath': ("content.text"),
        'optionValuePath': ("content.type"),
        'prompt': ("Επιλογή Τύπου:"),
        'selectionBinding': ("selectedType")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n                        </div>\n                    </div>\n                    ");
      return buffer;
      }

    function program10(depth0,data) {
      
      var buffer = '';
      data.buffer.push("<button type=\"submit\" class=\"btn btn-success btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "changeType", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αλλαγή</button>");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "canBeSaved", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });