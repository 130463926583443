define("front/serializers/historyevent", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        transfer: { embedded: "always" },
        assignment: { embedded: "always" },
        completion: { embedded: "always" },
        graduation: { embedded: "always" },
        withdrawal: { embedded: "always" },
        transition: { embedded: "always" },
        transport: { embedded: "always" },
        release: { embedded: "always" },
        closure: { embedded: "always" },
        serviceStatement: { embedded: "always" },
        serviceInterrupt: { embedded: "always" }
      }
    });
  });