define("front/routes/event/change-type", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("event");
      },

      setupController: function (controller, model) {
        controller.set("user", this.controllerFor("application").get("currentUser"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        controller.set("event", model.event);
        controller.set("person", model.person);
        console.log(model.event);
      },

      actions: {
        reloadEvent: function () {
          var self = this;
          this.modelFor("event").event.reload().then(function () {
            self.refresh();
            self.send("reloadPerson");
          });
        }
      }
    });
  });