define("front/routes/person/lock", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function () {
        return this.modelFor("person").person;
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));
        /**TODO check for Admin Role **/
      }
    });
  });