define("front/routes/person-groups/new", 
  ["ember","simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return Em.Object.create({});
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var pg = this.modelFor("person-groups");
        controller.set("pg", pg);
        controller.set("name", "");
        controller.set("user_id", this.controllerFor("application").get("currentUser.id"));
      } });
  });