define("front/templates/components/date-filter", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', escapeExpression=this.escapeExpression;


      data.buffer.push("<form class=\"form-horizontal\" role=\"form\">\n  <div class=\"row\">\n        <div class=\"form-group col-sm-5\">\n          <label for=\"date_from\" class=\"col-sm-3 control-label\">Από:</label>\n          <div class=\"col-sm-9\">\n            <div class=\"input-group date\" id=\"date_from\">\n\n              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateFrom")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n              <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n            </div>\n          </div>\n\n        </div>\n\n        <div class=\"form-group col-sm-5\">\n          <label for=\"date_to\" class=\"col-sm-3 control-label\">Έως:</label>\n          <div class=\"col-sm-9\">\n            <div class=\"input-group date\" id=\"date_to\">\n\n              ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("dateTo")
      },hashTypes:{'value': "ID"},hashContexts:{'value': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n              <span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n            </div>\n          </div>\n\n        </div>\n\n        <div class=\"form-group\">\n          <div class=\"col-sm-2\">\n\n            <button class=\"btn btn-primary btn-sm\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "submit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n              Αναφορά\n            </button>\n\n          </div>\n        </div>\n  </div>\n</form>\n");
      return buffer;
      
    });
  });