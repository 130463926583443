define("front/adapters/application", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    Ember.Inflector.inflector.irregular("person", "persons");

    var ApplicationAdapter = DS.RESTAdapter.extend({
      //namespace: 'api'
      host: ENV.APP.backendUrl
    });
    __exports__["default"] = ApplicationAdapter;
  });