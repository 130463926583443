define("front/models/expedition_exchange", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      date: attr(),
      region: attr(),
      comments: attr(),
      total_persons: attr(),
      new_persons: attr(),
      given: attr(),
      returned: attr(),
      unit_id: attr(),
      kind: attr()
    });
  });