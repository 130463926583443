define("front/controllers/persons/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    var PersonNewController = Ember.Controller.extend(Ember.Validations.Mixin, {

      needs: ["persons/index", "application"],

      showSpinner: false,

      canValidate: false,

      isMember: true,
      isAddict: true,
      isFamily: false,
      isOther: false,
      crisis: false,

      genders: [{ name: "Άνδρας", id: 1 }, { name: "Γυναίκα", id: 2 }],

      defaultUnit: (function () {
        return this.get("controllers.application").get("selectedUnit");
      }).property("controllers.application.selectedUnit"),


      validations: {
        lastName: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Επώνυμο." }
        },
        firstName: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Όνομα." }
        },
        fatherName: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Πατρώνυμο." }
        },
        birthDate: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημ/νία Γεν." }
        },
        startDate: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημ/νία Ένταξης" }
        }
      },

      actions: {

        gotoPerson: function (id) {
          this.transitionTo("person", id);
        },

        findMatches: function () {
          var self = this;

          var inlastName = this.get("lastName");
          var infirstName = this.get("firstName");


          if (!Ember.isEmpty(inlastName) || !Ember.isEmpty(infirstName)) {
            this.store.find("person", { lastName: inlastName, firstName: infirstName, searchType: "2" }).then(function (persons) {
              self.set("searchResults", persons);
            }, function (reason) {
              self.set("searchResults", null);
              self.set("showSpinner", false);
            });
          }
        },


        save: function () {
          this.set("canValidate", true);
          if (Ember.isEmpty(this.get("startDate"))) {
            this.set("startDate", "null");
          }

          var newPerson = this.store.createRecord("person", {
            firstName: this.get("firstName"),
            crisis: this.get("crisis"),
            lastName: this.get("lastName"),
            firstName2: this.get("firstName2"),
            lastName2: this.get("lastName2"),
            motherName: this.get("motherName") ? this.get("motherName") : "----------",
            fatherName: this.get("fatherName"),
            birthDate: this.get("birthDate"),
            nationality: this.get("nationality"),
            gender: this.get("gender"),
            isMember: Number(this.get("isMember")),
            isAddict: 1,
            isFamily: 0,
            isOther: 0,
            currentUnit: this.get("defaultUnit"),
            startDate: this.get("startDate"),
            user_create: this.get("user_id")
          });

          newPerson.get("physfiles").addObject(this.store.createRecord("physfile", {
            code: this.get("physfile_code"),
            displaycode: this.get("defaultUnit.prefix") + "-" + this.get("physfile_code")
          }));

          this.set("showSpinner", true);

          var self = this;

          this.validate().then(function () {
            if (confirm("Το μέλος θα ανατεθεί στην υπηρεσία " + self.get("defaultUnit.name") + " Είστε σίγουροι;")) {
              newPerson.save().then(function (savedPerson) {
                self.set("canValidate", false);

                self.set("firstName", "");
                self.set("crisis", false);
                self.set("lastName", "");
                self.set("firstName2", "");
                self.set("lastName2", "");
                self.set("motherName", "");
                self.set("physfile_code", "");
                self.set("fatherName", "");
                self.set("nationality", self.get("nationalities").filterBy("id", "23").get("firstObject"));
                self.set("birthDate", "");
                self.set("isMember", false);
                self.set("isAddict", false);
                self.set("isOther", false);
                self.set("isFamily", false);
                self.set("isOther", false);
                self.set("user_create", "");
                self.set("showSpinner", false);
                self.transitionToRoute("person", savedPerson.get("id"));
              }, function (response) {
                //console.log(response);
                Notify.error(response.responseJSON.message, {
                  closeAfter: 10000 // or set to null to disable auto-hiding
                });

                self.set("canValidate", false);

                /*self.set('firstName','');
                self.set('lastName','');
                self.set('motherName','');
                self.set('nationality', self.get('nationalities').filterBy('id', '23').get('firstObject'));
                self.set('firstName2','');
                self.set('crisis',false);
                self.set('lastName2','');
                self.set('fatherName','');
                self.set('physfile_code','');
                self.set('birthDate','');
                self.set('isMember',false);
                self.set('isAddict',false);
                self.set('isFamily',false);
                self.set('isOther',false);
                	self.set('showSpinner',false);*/
                newPerson.deleteRecord();
              });
            }
            self.set("showSpinner", false);
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newPerson.deleteRecord();
          });
        },

        dummyData: function () {
          this.set("birthDate", "01/01/1900");
          this.set("motherName", "----------");
          this.set("fatherName", "----------");
        }
      }

    });

    __exports__["default"] = PersonNewController;
  });