define("front/routes/participations", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.store.find("participation", { unit_id: this.controllerFor("application").get("selectedUnit.id"), date: moment().format("DD/MM/YYYY") });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("user_name", this.controllerFor("application").get("currentUser.name"));
        controller.set("ucu", this.controllerFor("application").get("selectedUnit"));
        //controller.set('events', events);
      }

    });
  });