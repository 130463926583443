define("front/controllers/participations", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ArrayController.extend({
      itemController: "participation",

      hideRadio: false,

      dateChange: (function () {
        this.send("anotherDay", this.presenceDate);
      }).observes("presenceDate"),

      actions: {
        anotherDay: function (date) {
          var self = this;

          this.store.find("participation", { unit_id: this.get("ucu.id"), date: date }).then(function (response) {
            self.set("model", response);
          });
        },

        activeReport: function () {
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/reportPresences", {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              unit_id: self.get("ucu.id")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });