define("front/routes/person-parameters", 
  ["simple-auth/mixins/authenticated-route-mixin","ember","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];
    var Ember = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
        this.store.unloadAll("formParameter");
        this.store.unloadAll("formGroup");

        var self = this;

        var personFormPromise = this.store.find("personForm", params.person_form_id);

        return Ember.RSVP.all([personFormPromise]).then(function (personForm) {
          //console.log(personForm[0].get('form.id'));
          return Ember.RSVP.hash({
            personForm: personForm[0],
            parameters: self.store.find("formParameter", {
              onlyParameter: "true",
              person_form_id: personForm[0].get("id"),
              results: "true"
            }),
            person: self.store.find("person", personForm[0].get("person.id"))
          });
        });
      },

      setupController: function (controller, model) {
        this.controllerFor("form-complete").set("personForm", model.personForm);
        this.controllerFor("form-complete").set("person", model.person);
        this.controllerFor("form-complete").set("parameters", model.parameters);
        this.controllerFor("form-complete").set("groups", "");
        this.controllerFor("form-complete").set("onlyParameter", true);
      },

      renderTemplate: function () {
        this.render("form-complete", {
          controller: "form-complete"
        });
      },

      deactivate: function () {
        this.store.unloadAll("answer");
        this.store.unloadAll("choice");
      }


    });
  });