define("front/templates/form/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("Διαχείριση υποενοτήτων");
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("Παράμετροι φόρμας");
      }

    function program5(depth0,data) {
      
      
      data.buffer.push("Επιστροφή στη λίστα");
      }

    function program7(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n					<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("thumbHref")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" >\n					");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n						<span class=\"blue link\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "downloadForm", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("><i class=\"ace-icon fa fa-download bigger-130\"></i></span>\n					");
      return buffer;
      }

      data.buffer.push("<div class=\"col-sm-12\">\n	<div class=\"page-header\">\n		<h1>Φόρμα\n			<small><i class=\"ace-icon fa fa-angle-double-right\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>\n		</h1>\n	</div>\n</div>\n\n<div class=\"col-xs-12 col-sm-3\">\n\n	<div class=\"col-sm-12 col-xs-12 offset-col-sm-1 label label-info label-xlg\" id=\"form-sidebar-header\">\n		<b>Επιλογές</b>\n	</div>\n\n	<div id=\"form-sidebar-body\" class=\"col-sm-12 col-xs-12\">\n		<ul class=\"list-unstyled spaced\" id=\"memberDetailsMenu\">\n			<li>\n				<i class=\"ace-icon fa fa-cog blue\"></i>\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "form-groups", "id", options) : helperMissing.call(depth0, "link-to", "form-groups", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</li>\n\n			<li>\n				<i class=\"ace-icon fa fa-pencil-square-o blue\"></i>\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "form.build", "id", options) : helperMissing.call(depth0, "link-to", "form.build", "id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</li>\n\n			<li class=\"text-warning  orange\">\n				<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n				");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap text-warning  orange"),
        'id': ("form-back")
      },hashTypes:{'class': "STRING",'id': "STRING"},hashContexts:{'class': depth0,'id': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "forms", options) : helperMissing.call(depth0, "link-to", "forms", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</li>\n		</ul>\n	</div>\n</div>\n\n<div class=\"col-xs-12 col-sm-9\">\n	<div class=\"profile-user-info profile-user-info-striped\">\n\n		<div class=\"profile-info-row\">\n			<div class=\"profile-info-name\">Τίτλος</div>\n			<div class=\"profile-info-value\">\n				");
      stack1 = helpers._triageMustache.call(depth0, "name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n		</div>\n\n		<div class=\"profile-info-row\">\n			<div class=\"profile-info-name\">Περιγραφή</div>\n			<div class=\"profile-info-value\">\n				");
      stack1 = helpers._triageMustache.call(depth0, "header", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n		</div>\n\n		<div class=\"profile-info-row\">\n			<div class=\"profile-info-name\">Υποσημείωση</div>\n			<div class=\"profile-info-value\">\n				");
      stack1 = helpers._triageMustache.call(depth0, "footer", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n		</div>\n\n		<div class=\"profile-info-row\">\n			<div class=\"profile-info-name\">Κωδικός</div>\n			<div class=\"profile-info-value\">\n				");
      stack1 = helpers._triageMustache.call(depth0, "code", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n		</div>\n\n		<div class=\"profile-info-row\">\n			<div class=\"profile-info-name\">Κενή Φόρμα</div>\n			<div class=\"profile-info-value\">\n				<p class=\"form-control-static\">\n					");
      stack1 = helpers['if'].call(depth0, "thumbHref", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					");
      stack1 = helpers['if'].call(depth0, "filename", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n				</p>\n			</div>\n		</div>\n	</div>\n</div>\n\n\n");
      return buffer;
      
    });
  });