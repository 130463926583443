define("front/routes/person/unit-reference", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return Ember.RSVP.hash({
          person: this.modelFor("person").person,
          programs: this.store.find("program") });
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        var self = this;

        //Clear form
        controller.set("date", moment().format("DD/MM/YYYY"));
        controller.set("unit_to", null);
        controller.set("comment", "");

        controller.set("person", model.person);
        controller.set("user", this.controllerFor("application").get("currentUserId"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        controller.set("units", []);
        controller.set("structures", []);
        controller.set("programs", model.programs);
        //console.log(model.programs);
      },

      deactivate: function () {
        this.set("controller.selectedProgram", null);
        this.set("controller.selectedStructure", null);
        this.set("controller.selectedUnit", null);
      }
    });
    //units: this.store.find('unit'),
    //structures: this.store.find('structure')
  });