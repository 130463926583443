define("front/models/transfer", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Transfers = DS.Model.extend({
      unitTo: attr(),
      pending: attr(),
      comments: attr(),
      canceled: attr(),
      unit_name: attr(),
      approve_date: attr(),
      pevn_id: attr(),
      cancel_date: attr(),
      canr_id: attr(),
      isCanceled: (function () {
        if (this.get("canceled") === "1") {
          return true;
        }
        return false;
      }).property("canceled"),
      isApproved: (function () {
        if (this.get("canceled") === "0" && this.get("pending") === "0") {
          return true;
        }

        return false;
      }).property("canceled", "pending"),

      status: (function () {
        if (this.get("pending") === "1") {
          return "Η παραπομπή στην υπηρεσία εκρεμεί";
        }
        if (this.get("canceled") === "1") {
          return "Η παραπομπή στην υπηρεσία έχει ακυρωθεί";
        }

        return "Η παραπομπή έχει πραγματοποιηθεί";

      }).property("pending", "canceled")
    });

    __exports__["default"] = Transfers;
  });