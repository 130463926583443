define("front/controllers/expedition-approaches/edit", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {

      canValidate: false,

      validations: {
        region: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Περιοχή." }
        },

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        total_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        new_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        male: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        female: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        trans: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        transfer: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        homeless: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        immigrant: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        prostitution: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        xen: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        okana: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        street: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        }
      },

      actions: {
        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var expd = this.get("exap");
          expd.set("unit_id", this.get("currentUnit.id"));

          this.validate().then(function () {
            expd.set("date", moment(self.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"));
            expd.save().then(function () {
              self.transitionToRoute("expedition-approaches.index");
              self.send("reloadExpeditions");
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            }, function (error) {
              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }
    });
  });