define("front/templates/documents/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      
      data.buffer.push("Δημιουργία");
      }

      data.buffer.push("<div class=\"row\">\n	\n	<div class=\"col-xs-12\">\n		");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'classNames': ("btn btn-primary")
      },hashTypes:{'classNames': "STRING"},hashContexts:{'classNames': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "documents.new", options) : helperMissing.call(depth0, "link-to", "documents.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n	</div>\n</div>\n<br>\n<div class=\"row\">\n	<div class=\"col-xs-12\">\n		");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "documents/documents-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n	</div>\n</div>\n\n<!-- modal -->\n<div class=\"modal fade\" id=\"myModal\">\n	<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\"><span aria-hidden=\"true\">&times;</span><span class=\"sr-only\">Close</span></button>\n				<h4 class=\"modal-title\">Διαγραφή</h4>\n			</div>\n			<div class=\"modal-body\">\n				<p>Να γίνει διαγραφή της εγγραφής;</p>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">Όχι</button>\n				<button type=\"button\" class=\"btn btn-primary\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "delete", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ναι</button>\n			</div>\n		</div><!-- /.modal-content -->\n	</div><!-- /.modal-dialog -->\n</div><!-- /.modal -->");
      return buffer;
      
    });
  });