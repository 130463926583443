define("front/models/structure", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Structure = DS.Model.extend({
      name: attr()
      // notifications: DS.hasMany('notification')
    });

    __exports__["default"] = Structure;
  });