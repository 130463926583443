define("front/templates/units/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


      data.buffer.push("\n  <div class=\"col-sm-6 col-xs-6\">\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n        <h3 class=\"widget-title smaller\">Υπηρεσία</h3>\n      </div>\n      <div class=\"widget-body\">\n        <div class=\"widget-main\">\n          <form class=\"form-horizontal\" role=\"form\">\n            ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Όνομα"),
        'value': ("unit.name"),
        'error': ("errors.unit.name"),
        'name': ("unit.name")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n            ");
      data.buffer.push(escapeExpression((helper = helpers['input-form-group'] || (depth0 && depth0['input-form-group']),options={hash:{
        'label': ("Πρόθεμα Φυσικού Φακέλου"),
        'value': ("unit.prefix"),
        'error': ("errors.unit.pefix"),
        'name': ("unit.prefix")
      },hashTypes:{'label': "STRING",'value': "ID",'error': "ID",'name': "STRING"},hashContexts:{'label': depth0,'value': depth0,'error': depth0,'name': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input-form-group", options))));
      data.buffer.push("\n            <div class=\"form-group\">\n                <label for=\"currentUnit\" class=\"col-sm-3 control-label\">Τύπος Υπηρεσίας:\n                </label>\n                <div class=\"col-sm-6\">\n                ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("unitTypes"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'valueBinding': ("unitType.id")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'valueBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'valueBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n\n                </div>\n                \n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n            </div>\n\n            <div class=\"form-group\">\n                <label for=\"currentUnit\" class=\"col-sm-3 control-label\">Δικαιώματα Παραπομπής:\n                </label>\n                <div class=\"col-sm-6\">\n                ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("ranks"),
        'class': ("form-control"),
        'optionLabelPath': ("content"),
        'optionValuePath': ("content"),
        'valueBinding': ("unit.rank")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'valueBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'valueBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n\n\n                </div>\n                \n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Ενεργό:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("isActive"),
        'id': ("isActive"),
        'checked': ("unit.isActive"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n             <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Υπηρεσία Φυλακής</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("isPrison"),
        'id': ("isPrison"),
        'checked': ("unit.isPrison"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <div class=\"col-sm-1\">\n                <div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n              </div>\n\n            </div>\n\n          </form>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"space-20\"></div>\n\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n        <h3 class=\"widget-title smaller\">Κέντρο - Δομή</h3>\n      </div>\n      <div class=\"widget-body\">\n        <div class=\"widget-main\">\n          <form class=\"form-horizontal\" role=\"form\">\n            ");
      data.buffer.push(escapeExpression((helper = helpers['program-structure'] || (depth0 && depth0['program-structure']),options={hash:{
        'unit': ("unit")
      },hashTypes:{'unit': "ID"},hashContexts:{'unit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "program-structure", options))));
      data.buffer.push("\n          </form>\n        </div>\n      </div>\n    </div>\n  </div>\n      \n\n  \n  <div class=\"col-sm-6 col-xs-6\">\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n        <h3 class=\"widget-title smaller\">Παράμετροι</h3>\n      </div>\n      <div class=\"widget-body\">\n        <div class=\"widget-main\">\n          <form class=\"form-horizontal\" role=\"form\">\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Ανάθεση:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.canAssign"),
        'id': ("unit.canAssign"),
        'checked': ("unit.canAssign"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Ολοκλήρωση:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.canComplete"),
        'id': ("unit.canComplete"),
        'checked': ("unit.canComplete"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Αποφοίτηση:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.canGraduate"),
        'id': ("unit.canGraduate"),
        'checked': ("unit.canGraduate"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Κλείσιμο:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.canClose"),
        'id': ("unit.canClose"),
        'checked': ("unit.canClose"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Με Ολοκλήρωση κάνει Κλείσιμο:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.compCanClose"),
        'id': ("unit.compCanClose"),
        'checked': ("unit.compCanClose"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n            <hr>\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Παρουσιολόγιο:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.havePresences"),
        'id': ("unit.havePresences"),
        'checked': ("unit.havePresences"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n            <div class=\"form-group\">\n              <label for=\"isMember\" class=\"col-sm-3 control-label\">Μεταβατικό Σχολείο:</label>\n              <div class=\"col-sm-2\">\n                ");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("unit.haveTransitionalPresences"),
        'id': ("unit.haveTransitionalPresences"),
        'checked': ("unit.haveTransitionalPresences"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n              </div>\n            </div>\n\n          </form>\n        </div>\n      </div>\n    </div>\n\n\n\n  </div>\n   <div class=\"space-20\"></div>\n  <div class=\"col-sm-12 col-xs-12\">\n    <div class=\"widget-box\">\n    <div class=\"widget-body\">\n      <div class=\"widget-main\">\n        <button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", "unit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0],types:["STRING","ID"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n        <button class=\"btn btn-danger\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "cancel", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Ακύρωση</button>\n      </div>\n    </div>\n  \n  </div>\n");
      return buffer;
      
    });
  });