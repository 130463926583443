define("front/serializers/medical_record", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        doctor: { embedded: "always" } },
      serialize: function (value) {
        var serialized = this._super(value);
        value.unit_id && (serialized.unit_id = value.unit_id);
        value.pers_id && (serialized.pers_id = value.pers_id);
        return serialized;
      }
    });
  });