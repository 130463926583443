define("front/controllers/helplines/edit", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      canValidate: false,

      gender_enum: [{ id: 1, value: "Άνδρας" }, { id: 2, value: "Γυναίκα" }, { id: 9, value: "Άγνωστο" }],

      status_enum: [{ id: 1, value: "Άγαμος" }, { id: 2, value: "Έγγαμος/η" }, { id: 3, value: "Διαζευμένος/η" }, { id: 4, value: "Χήρος/α" }, { id: 5, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],

      education_enum: [{ id: 1, value: "Αναλφάβητος" }, { id: 2, value: "Δημοτικό" }, { id: 3, value: "Γυμνάσιο" }, { id: 4, value: "Λύκειο" }, { id: 5, value: "ΤΕΙ" }, { id: 6, value: "ΑΕΙ" }, { id: 7, value: "Μεταπτυχιακό" }, { id: 8, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],

      request_enum: [{ id: 1, value: "1. Αποχή" }, { id: 2, value: "2. Έλεγχος" }, { id: 8, value: "8. Δεν αφορά" }, { id: 9, value: "9. Άγνωστο" }],


      freq_enum: [{ id: 0, value: "Καθόλου" }, { id: 1, value: "1-2 φ/εβδ" }, { id: 2, value: "3-4 φ/εβδ" }, { id: 3, value: "5-6 φ/εβδ" }, { id: 4, value: "Κάθε μέρα" }, { id: 9, value: "Άγνωστο" }],

      amount_enum: [{ id: 0, value: "Καθόλου" }, { id: 1, value: "Μέχρι 50€" }, { id: 2, value: "51-200€" }, { id: 3, value: "201-500€" }, { id: 4, value: "501-1.000€" }, { id: 5, value: "1.001-3.000€" }, { id: 6, value: "3.001-10.000€" }, { id: 7, value: "10.000€+" }, { id: 8, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],


      validations: {

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        region: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Περιοχή." }
        },

        consultant: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Σύμβουλο." }
        },

        duration: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Διάρκεια." }
        }
      },

      actions: {
        requestBehaviour: function () {
          var hideRadio = this.get("call_request") === 0 ? false : true;
          this.set("hideRadio", hideRadio);
        },

        "export": function () {
          var self = this;

          self.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/helplines/export/" + self.get("id"), {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + self.get("session.access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var helpline = this.get("content");

          this.validate().then(function () {
            helpline.set("date", moment(self.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"));
            helpline.set("consultant_id", self.get("consultant.id"));
            helpline.save().then(function () {
              self.transitionToRoute("helplines.index");
              self.send("reloadHelplines");
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000
              });
              self.set("canValidate", false);
              self.set("showSpinner", false);
            }, function (error) {
              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
              helpline.set("date", moment(self.get("date"), "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY"));
              self.set("canValidate", false);
              self.set("showSpinner", false);
            });
          }, function (error) {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }
    });
  });