define("front/routes/documents/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        return this.store.find("document", { person_id: person_id });
      }
    });
  });