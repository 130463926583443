define("front/routes/document", 
  ["ember","simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("document", params.document_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        this.controller.set("personID", person_id);
      } });
  });