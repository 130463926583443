define("front/routes/form-groups/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var formModel = this.modelFor("form");

        return Ember.RSVP.hash({
          form: formModel,
          groups: this.store.find("formGroup", { form_id: formModel.id }),
          categories: this.store.find("category")
        });
      },

      actions: {
        routeRefresh: function () {
          this.refresh();
        }
      }
    });
  });