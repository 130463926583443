define("front/controllers/users/new", 
  ["ember-notify","front/mixins/user","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];
    var ENV = __dependency3__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, UserMixin, {
      canValidate: false,

      validations: {
        password: {
          confirmation: true
        }
      },

      selectedUnits: (function () {
        return this.get("units");
      }).property("units"),

      sortedPrograms: (function () {
        return Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
          sortProperties: ["name"],
          sortAscending: true,
          content: this.get("model.programs")
        });
      }).property("model.programs"),


      actions: {



        saveData: function () {
          var self = this;

          var user = this.get("user");

          this.set("canValidate", true);
          this.set("showSpinner", true);

          this.set("canValidate", true);
          var data = {
            firstname: this.get("firstname"),
            lastname: this.get("lastname"),
            username: this.get("username"),
            password: this.get("password"),
            units: this.get("units").map(function (unit) {
              return parseInt(unit.id);
            }),
            roles: this.get("userRoles").map(function (role) {
              return parseInt(role.id);
            })
          };

          this.validate().then(function () {
            $.ajax({
              url: ENV.APP.backendUrl + "/users",
              type: "POST",
              dataType: "json",
              contentType: "application/json",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.transitionToRoute("users.edit", response.user.id);

              self.set("canValidate", false);
              self.set("showSpinner", false);
            }, function (error) {
              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });

              self.set("canValidate", false);
              self.set("showSpinner", false);
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });
        }
      }
    });
  });