define("front/models/person_form", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var PersonForm = DS.Model.extend({

      form: DS.belongsTo("form"),
      person: DS.belongsTo("person"),
      user_create: attr(),
      selfComplete: attr(),
      date: attr(),
      userId: attr()

    });

    __exports__["default"] = PersonForm;
  });