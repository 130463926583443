define("front/templates/form-complete/combo", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"input-group\">\n	<input type=\"text\" class=\"form-control\" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'name': ("setName")
      },hashTypes:{'name': "STRING"},hashContexts:{'name': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("  ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'value': ("the_answer_text")
      },hashTypes:{'value': "STRING"},hashContexts:{'value': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'id': ("setId")
      },hashTypes:{'id': "STRING"},hashContexts:{'id': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(">\n	<div class=\"input-group-btn\">\n		<button type=\"button\" class=\"form-control btn btn-default dropdown-toggle\" data-toggle=\"dropdown\" aria-expanded=\"false\"> <span class=\"caret\"></span></button>\n		<ul class=\"dropdown-menu dropdown-menu-right\" role=\"menu\">\n			<li><a ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "setInput", "setId", "setDrop1", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0,depth0],types:["STRING","ID","ID"],data:data})));
      data.buffer.push(">");
      stack1 = helpers._triageMustache.call(depth0, "setDrop1", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(". Δεν αφορά</a></li>\n			<li><a ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "setInput", "setId", "setDrop2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0,depth0],types:["STRING","ID","ID"],data:data})));
      data.buffer.push(">");
      stack1 = helpers._triageMustache.call(depth0, "setDrop2", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(". Άγνωστο</a></li>\n		</ul>\n	</div><!-- /btn-group -->\n</div>");
      return buffer;
      
    });
  });