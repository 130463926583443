define("front/controllers/form-groups/form-group-modal", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      canValidate: false,

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Όνομα." }
        }
      },

      actions: {
        saveGroup: function () {
          this.set("canValidate", true);

          var newFormGroup = this.store.createRecord("formGroup", {
            name: this.get("name"),
            header: this.get("header"),
            footer: this.get("footer")
          });

          var self = this;

          this.validate().then(function () {
            newFormGroup.save().then(function (savedGroup) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");


              self.set("showSpinner", false);
              $(".modal").modal("hide");

              //self.transitionToRoute('person', savedPerson.get('id'));
            }, function (response) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");

              self.set("showSpinner", false);
              newFormGroup.deleteRecord();
              self.send("reloadFormGroups");
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newFormGroup.deleteRecord();
          });
        }
      }
    });
  });