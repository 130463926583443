define("front/routes/documents/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {


      model: function (params) {
        // the model for this route is a new empty Ember.Object
        //return Em.Object.create({});
        var personModel = this.modelFor("person");

        return Ember.RSVP.hash({
          categories: this.store.find("category"),
          person: personModel.person
        });
      },

      renderTemplate: function () {
        this.render("document.edit", {
          controller: "documents/new"
        });
      }
    });
  });