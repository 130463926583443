define("front/routes/pehi/edit-event", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.store.find("event", params.pevn_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));

        var obsUnit = this.store.getById("unit", this.modelFor("pehi").get("unit_id"));
        controller.set("obsUnit", obsUnit);

        controller.set("canBeSaved", true);


        /*
        controller.set('element_history', model.event);
        controller.set('person', model.person);
        controller.set('user', this.controllerFor('application').get('currentUser'));
        controller.set('user_id', this.controllerFor('application').get('currentUserId'));
        controller.set('user_current_unit', this.controllerFor('application').get('selectedUnit'));
        controller.set('subcategory', model.event.get('subcategory'));
         if(!Ember.empty(model.event.get('transfer'))) {
            controller.set('transfer', model.event.get('transfer'));
            if(model.event.get('transfer.isCanceled')){
                 controller.set('cancelReasons', model.cancelReasons);
            }
        }
        if(!Ember.empty(model.event.get('completion'))) { controller.set('completion', model.event.get('completion')); }
        if(!Ember.empty(model.event.get('withdrawal'))) { controller.set('withdrawal', model.event.get('withdrawal')); }
        if(!Ember.empty(model.event.get('graduation'))) { controller.set('graduation', model.event.get('graduation')); }
        if(!Ember.empty(model.event.get('appointment'))) { controller.set('appointment', model.event.get('appointment')); }
        if(!Ember.empty(model.event.get('assignment'))) { controller.set('assignment', model.event.get('assignment')); }
        if(!Ember.empty(model.event.get('release'))) { controller.set('release', model.event.get('release')); }
        if(!Ember.empty(model.event.get('transport'))) { controller.set('transport', model.event.get('transport')); }
        if(!Ember.empty(model.event.get('transition'))) { controller.set('transition', model.event.get('transition')); }
        if(!Ember.empty(model.event.get('serviceStatement'))) { controller.set('serviceStatement', model.event.get('serviceStatement')); }
        if(!Ember.empty(model.event.get('serviceInterrupt'))) { controller.set('serviceInterrupt', model.event.get('serviceInterrupt')); }
        if(!Ember.empty(model.event.get('closure'))) { controller.set('closure', model.event.get('closure')); }
        */
      },

      actions: {
        reloadEvent: function () {
          var self = this;

          this.modelFor("pehi.edit-event").reload().then(function () {
            self.refresh();
            self.send("reloadPerson");
            self.transitionTo("pehi-admin");
          });
        } }
    });
  });