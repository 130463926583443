define("front/components/bar-chart", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({

      elementId: "BarChart",
      classNames: [""],
      name: "Bar Chart",
      text: "",
      max: 100,
      height: 500,

      dataChange: (function () {
        if (this.get("data.length")) {
          this._drawBars(this._getData(this.get("data")));
        }
      }).observes("data.length"),

      didInsertElement: function didInsertElement() {
        var _ref;
        (_ref = this)._super.apply(_ref, arguments);

        this._drawBars(this._getData(this.get("data")));
      },

      _getData: function (data) {
        return data.map(function (p) {
          return [p.key, p.value];
        });
      },

      _drawBars: function (data) {
        var self = this;
        var elId = "#" + this.get("elementId");

        $(elId).highcharts({
          chart: {
            height: self.get("height"),
            type: "column"
          },
          title: {
            text: self.get("title")
          },
          credits: {
            enabled: false
          },
          xAxis: {
            type: "category",
            labels: {
              rotation: -45,
              style: {
                fontSize: "12pt" }
            }
          },
          yAxis: {
            min: 0,
            max: self.get("max"),
            title: {
              text: self.get("yTitle")
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            column: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.y:.1f} %",
                connectorColor: "silver",
                style: {
                  fontSize: "12pt;"
                } },
              size: self.get("size")
            }
          },
          series: [{
            name: self.get("name"),
            data: data
          }]
        });
      }
    });
  });