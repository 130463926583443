define("front/views/form-complete", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({

      afterRenderEvent: function () {
        var setValue = function (inputId, optionValue, questionId) {
          var select_text = $("#" + inputId).find("option[value=\"" + optionValue + "\"]").text();
          $("input[name='question_" + questionId + "']").val(optionValue);
          $("#" + inputId).val(optionValue);
          $("input[name='question_" + questionId + "']").next("div").children("input:first").val(select_text);
        };

        var clearInput = function (inputId, questionId) {
          $("#" + inputId).val("");
          $("input[name='question_" + questionId + "']").val("");
          $("input[name='question_" + questionId + "']").next("div").children("input:first").val("");
        };

        var TDI_AutoComplete_3 = function () {
          //6a
          $("#4_135_342").change(function () {
            var value = $(this).val();

            if (value === "342") {
              $("#7_136_345").val("888");

              setValue("4_137_348", 460, 137);

              setTimeout(function () {
                $("input[name='question_144']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "344") {
              $("#7_136_345").val("999");

              setValue("4_137_348", 461, 137);

              setTimeout(function () {
                $("input[name='question_144']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_136_345").val("");
              clearInput("4_137_348", 137);
            }
          });
          //7a
          $("#4_144_462").change(function () {
            var value = $(this).val();

            if (value === "463") {
              setValue("4_145_4227", 4339, 145);

              setValue("4_147_466", 468, 147);

              setValue("4_148_470", 472, 148);

              setValue("4_149_474", 476, 149);

              setValue("4_150_478", 480, 150);

              setTimeout(function () {
                $("input[name='question_151']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "464") {
              setValue("4_145_4227", 4340, 145);

              setValue("4_147_466", 469, 147);

              setValue("4_148_470", 473, 148);

              setValue("4_149_474", 477, 149);

              var select_text10 = $("#4_150_478").find("option[value=\"481\"]").text();
              $("input[name='question_150']").val("481");
              $("#4_150_478").val("481");
              $("input[name='question_150']").next("div").children("input:first").val(select_text10);

              setTimeout(function () {
                $("input[name='question_151']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_145_4227", 145);

              clearInput("4_147_466", 147);

              clearInput("4_148_470", 148);

              clearInput("4_149_474", 149);

              clearInput("4_150_478", 150);
            }
          });
          //10a1
          $("#4_152_498").change(function () {
            var value = $(this).val();

            if (value === "498") {
              setValue("4_154_511", 513, 154);

              setTimeout(function () {
                $("input[name='question_153']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_154_511", 154);
            }
          });
          //10a2
          $("#4_153_506").change(function () {
            var value = $(this).val();

            if (value === "508" || value === "509") {
              setValue("4_154_511", 513, 154);

              setTimeout(function () {
                $("input[name='question_155']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //11a
          $("#4_155_515").change(function () {
            var value = $(this).val();

            if (value === "516") {
              setValue("4_156_2477", 2576, 156);

              setTimeout(function () {
                $("input[name='question_157']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "515") {
              setValue("4_157_2578", 2612, 157);

              setTimeout(function () {
                $("input[name='question_156']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "517") {
              setValue("4_156_2477", 2577, 156);

              setTimeout(function () {
                $("input[name='question_157']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_156_2477", 156);

              clearInput("4_157_2578", 157);
            }
          });
          //16a
          $("#4_223_5565").change(function () {
            var value = $(this).val();

            if (value === "5565") {
              setValue("4_224_6001", 6006, 224);

              setTimeout(function () {
                $("input[name='question_228']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_224_6001", 224);
            }
          });
          //17a
          $("#4_228_5670").change(function () {
            var value = $(this).val();

            if (value === "5670") {
              setValue("4_229_6008", 6013, 229);

              setTimeout(function () {
                $("input[name='question_233']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_229_6008", 229);
            }
          });
          //18a
          $("#4_233_5775").change(function () {
            var value = $(this).val();

            if (value === "5775") {
              setValue("4_234_6015", 6020, 234);

              setTimeout(function () {
                $("input[name='question_237']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_234_6015", 234);
            }
          });
          //19a
          $("#4_237_5880").change(function () {
            var value = $(this).val();

            if (value === "5880") {
              setValue("4_238_6022", 6027, 238);

              setTimeout(function () {
                $("input[name='question_240']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_238_6022", 238);
            }
          });
          //21a
          $("#4_164_538").change(function () {
            var value = $(this).val();

            if (value === "539") {
              setValue("4_165_541", 543, 165);

              setTimeout(function () {
                $("input[name='question_166']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "540") {
              setValue("4_165_541", 544, 165);

              setTimeout(function () {
                $("input[name='question_166']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_165_541", 165);
            }
          });
          //22a
          $("#4_166_545").change(function () {
            var value = $(this).val();

            if (value === "546") {
              $("#7_167_548").val("88");

              setValue("4_168_549", 551, 168);

              setTimeout(function () {
                $("input[name='question_177']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "547") {
              $("#7_167_548").val("99");

              setValue("4_168_549", 552, 168);

              setTimeout(function () {
                $("input[name='question_177']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_167_548").val("");
              clearInput("4_168_549", 168);
            }
          });
          //23a
          $("#4_177_553").change(function () {
            var value = $(this).val();

            if (value === "556") {
              $("#7_188_573").val("88");
              $("#7_189_574").val("8888");
            } else if (value === "557") {
              $("#7_188_573").val("99");
              $("#7_189_574").val("9999");
            } else {
              $("#7_188_573").val("");
              $("#7_189_574").val("");
            }
          });
          $("#4_178_558").change(function () {
            var value = $(this).val();

            if (value === "561") {
              $("#7_190_575").val("88");
              $("#7_191_576").val("8888");
            } else if (value === "562") {
              $("#7_190_575").val("99");
              $("#7_191_576").val("9999");
            } else {
              $("#7_190_575").val("");
              $("#7_191_576").val("");
            }
          });
          $("#4_179_563").change(function () {
            var value = $(this).val();

            if (value === "566") {
              $("#7_192_577").val("88");
              $("#7_193_578").val("8888");
            } else if (value === "567") {
              $("#7_192_577").val("99");
              $("#7_193_578").val("9999");
            } else {
              $("#7_192_577").val("");
              $("#7_193_578").val("");
            }
          });
          $("#4_180_568").change(function () {
            var value = $(this).val();

            if (value === "571") {
              $("#7_194_579").val("88");
              $("#7_195_580").val("8888");
            } else if (value === "572") {
              $("#7_194_579").val("99");
              $("#7_195_580").val("9999");
            } else {
              $("#7_194_579").val("");
              $("#7_195_580").val("");
            }
          });

          //23g
          $("#7_282_785").keyup(function () {
            var value = $(this).val();

            if (value === "0") {
              $("#7_283_786").val("88");

              setTimeout(function () {
                $("input[name='question_286']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_283_786").val("");
            }
          });
          //25a
          $("#4_310_817").change(function () {
            var value = $(this).val();

            if (value === "824") {
              $("#7_292_795").val("88");
              $("#7_293_796").val("88");
              $("#7_294_797").val("8888");
              $("#7_296_798").val("88");
              $("#7_297_799").val("88");

              setValue("4_300_801", 808, 300);

              $("#7_302_810").val("88");
              $("#7_303_811").val("88");
              $("#7_304_812").val("8888");
              $("#7_306_813").val("88");
              $("#7_307_814").val("88");

              setTimeout(function () {
                $("input[name='question_308']").focus();
              }, 700);
            } else {
              $("#7_292_795").val("");
              $("#7_293_796").val("");
              $("#7_294_797").val("");
              $("#7_296_798").val("");
              $("#7_297_799").val("");

              clearInput("4_300_801", 300);

              $("#7_302_810").val("");
              $("#7_303_811").val("");
              $("#7_304_812").val("");
              $("#7_306_813").val("");
              $("#7_307_814").val("");
            }
          });
          //26a
          $("#4_300_801").change(function () {
            var value = $(this).val();

            if (value === "808") {
              $("#7_302_810").val("88");
              $("#7_303_811").val("88");
              $("#7_304_812").val("8888");
              $("#7_306_813").val("88");
              $("#7_307_814").val("88");

              setTimeout(function () {
                $("input[name='question_308']").focus();
              }, 700);
            } else {
              $("#7_302_810").val("");
              $("#7_303_811").val("");
              $("#7_304_812").val("");
              $("#7_306_813").val("");
              $("#7_307_814").val("");
            }
          });
          //29a
          $("#4_324_826").change(function () {
            var value = $(this).val();

            if (value === "827") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");

              setValue("4_326_832", 834, 326);
            } else if (value === "828") {
              $("#7_331_838").val("99");
            } else if (value === "826") {
              $("#7_331_838").val("88");
            }
          });
          //29b
          $("#4_325_829").change(function () {
            var value = $(this).val();

            if (value === "830") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");

              setValue("4_326_832", 834, 326);
            } else if (value === "831") {
              $("#7_332_839").val("99");
            } else if (value === "829") {
              $("#7_332_839").val("88");
            }
          });
          //29g
          $("#4_326_832").change(function () {
            var value = $(this).val();

            if (value === "835") {
              $("#7_328_836").val("99");
              $("#7_329_837").val("99");
            } else if (value === "832" || value === "834") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");
            }
          });
          //33a
          $("#4_366_882").change(function () {
            var value = $(this).val();

            if (value === "883") {
              setValue("4_368_4134", 4150, 368);

              setTimeout(function () {
                $("input[name='question_369']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "884") {
              setValue("4_368_4134", 4151, 368);

              setTimeout(function () {
                $("input[name='question_369']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //34a
          $("#4_369_887").change(function () {
            var value = $(this).val();

            if (value === "888") {
              setValue("4_371_4152", 4168, 371);

              setTimeout(function () {
                $("input[name='question_372']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "889") {
              setValue("4_371_4152", 4169, 371);

              setTimeout(function () {
                $("input[name='question_372']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //35a
          $("#4_372_892").change(function () {
            var value = $(this).val();

            if (value === "893") {
              setValue("4_374_4189", 4206, 374);

              setValue("4_375_897", 899, 375);

              setValue("4_376_900", 903, 376);

              setValue("4_378_4208", 4225, 378);

              $("#7_379_907").val("88");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "894") {
              setValue("4_374_4189", 4207, 374);

              //var select_text6 = $("#4_375_897").find('option[value="9084"]').text();
              //$("input[name='question_375']").val('9084');
              //$("#4_375_897").val('9084');
              //$("input[name='question_375']").next("div").children("input:first").val(select_text6);

              //var select_text7 = $("#4_376_900").find('option[value="904"]').text();
              //$("input[name='question_376']").val('904');
              //$("#4_376_900").val('904');
              //$("input[name='question_376']").next("div").children("input:first").val(select_text7);

              //var select_text8 = $("#4_378_4208").find('option[value="4226"]').text();
              //$("input[name='question_378']").val('4226');
              //$("#4_378_4208").val('4226');
              //$("input[name='question_378']").next("div").children("input:first").val(select_text8);

              //$('#7_379_907').val('99');

              setTimeout(function () {
                $("input[name='question_375']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //36a
          $("#4_375_897").change(function () {
            var value = $(this).val();

            if (value === "899") {
              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "9084") {
              setValue("4_376_900", 904, 376);

              setValue("4_378_4208", 4226, 378);

              $("#7_379_907").val("99");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "898") {
              setValue("4_376_900", 903, 376);

              setValue("4_378_4208", 4225, 378);

              $("#7_379_907").val("88");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //40a
          $("#4_395_946").change(function () {
            var value = $(this).val();

            if (value === "947") {
              $("#7_396_949").val("88");
              $("#7_397_952").val("88");
              $("#7_403_969").val("88");
              $("#7_404_970").val("88");
              $("#7_406_971").val("88");
              $("#7_407_972").val("88");

              setValue("4_401_965", 967, 401);
            } else if (value === "948") {
              $("#7_396_949").val("99");
              $("#7_397_952").val("99");
              setTimeout(function () {
                $("input[name='question_398']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_396_949").val("");
              $("#7_397_952").val("");
            }
          });
          //41a
          $("#4_398_955").change(function () {
            var value = $(this).val();

            if (value === "956") {
              $("#7_399_959").val("88");
              $("#7_400_962").val("88");
              setTimeout(function () {
                $("input[name='question_401']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "958") {
              $("#7_399_959").val("99");
              $("#7_400_962").val("99");
              setTimeout(function () {
                $("input[name='question_401']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_399_959").val("");
              $("#7_400_962").val("");
            }
          });
          //42a
          $("#4_401_965").change(function () {
            var value = $(this).val();

            if (value === "966" || value === "967") {
              $("#7_403_969").val("88");
              $("#7_404_970").val("88");
              $("#7_406_971").val("88");
              $("#7_407_972").val("88");
              setTimeout(function () {
                $("input[name='question_408']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "968") {
              $("#7_403_969").val("99");
              $("#7_404_970").val("99");
              $("#7_406_971").val("99");
              $("#7_407_972").val("99");
              setTimeout(function () {
                $("input[name='question_408']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_403_969").val("");
              $("#7_404_970").val("");
              $("#7_406_971").val("");
              $("#7_407_972").val("");
            }
          });
          //44a
          $("#4_408_973").change(function () {
            var value = $(this).val();

            if (value === "974") {
              setTimeout(function () {
                $("input[name='question_410']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "975") {
              $("#3_409_977").click();

              setTimeout(function () {
                $("input[name='question_410']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //45a
          $("#4_410_978").change(function () {
            var value = $(this).val();

            if (value === "978" || value === "980") {
              setValue("4_411_982", 984, 411);

              setValue("4_412_986", 988, 412);

              setValue("4_413_990", 994, 413);

              setTimeout(function () {
                $("input[name='question_415']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "981") {
              setValue("4_411_982", 985, 411);

              setValue("4_412_986", 989, 412);

              setValue("4_413_990", 995, 413);


              setTimeout(function () {
                $("input[name='question_415']").next("div").children("input:first").focus();
              }, 700);
            }
          });

          //47a
          $("#4_415_996").change(function () {
            var value = $(this).val();

            if (value === "996" || value === "997") {
              setValue("4_416_1001", 1006, 416);

              setTimeout(function () {
                $("input[name='question_418']").next("div").children("input:first").focus();
              }, 700);
            }
          });

          //48a
          $("#4_418_1008").change(function () {
            var value = $(this).val();

            if (value === "1008" || value === "1009") {
              setValue("4_419_1013", 1018, 419);

              setTimeout(function () {
                $("#2_423_1023").focus();
              }, 700);
            }
          });
        };

        /*
         * TDI Φυλακών
         */
        var TDI_AutoComplete_24 = function () {
          //6a
          $("#4_135_342").change(function () {
            var value = $(this).val();

            if (value === "342") {
              $("#7_136_345").val("888");

              // 6-c
              setValue("4_137_348", 460, 137);

              setTimeout(function () {
                $("input[name='question_144']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "344") {
              $("#7_136_345").val("999");

              // 6-c
              setValue("4_137_348", 461, 137);

              setTimeout(function () {
                $("input[name='question_144']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("7_136_345", 137);
              clearInput("7_136_348", 137);
            }
          });
          //7a
          $("#4_2301_10157").change(function () {
            var value = $(this).val();

            if (value === "10158") {
              // 7b
              setValue("4_145_4227", 4339, 145);

              // 7c-a
              setValue("4_2302_10160", 10162, 2302);

              // 7c-b
              setValue("4_2303_10164", 10166, 2303);

              // 7c-c
              setValue("4_2304_10168", 10170, 2304);

              // 7c-d
              setValue("4_2305_10172", 10174, 2305);

              setTimeout(function () {
                $("input[name='question_151']").next("div").children("input:first").focus();
              }, 700);
            }
            // 9999. Άγνωστο
            else if (value === "10159") {
              // 7b
              setValue("4_145_4227", 4340, 145);

              // 7c-a
              setValue("4_2302_10160", 10163, 2302);

              // 7c-b
              setValue("4_2303_10164", 10167, 2303);

              // 7c-c
              setValue("4_2304_10168", 10171, 2304);

              // 7c-d
              setValue("4_2305_10172", 10175, 2305);

              setTimeout(function () {
                $("input[name='question_151']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_145_4227", 145);
              clearInput("4_2302_10160", 2302);
              clearInput("4_2303_10164", 2303);
              clearInput("4_2304_10168", 2304);
              clearInput("4_2305_10172", 2305);
            }
          });
          //10a1
          $("#4_152_498").change(function () {
            var value = $(this).val();

            if (value === "498") {
              //10b
              setValue("4_154_511", 513, 154);

              setTimeout(function () {
                $("input[name='question_153']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_154_511", 154);
            }
          });
          //10a2
          $("#4_2306_10176").change(function () {
            var value = $(this).val();

            if (value === "10178") {
              //10b
              setValue("4_154_511", 513, 154);

              setTimeout(function () {
                $("input[name='question_155']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_154_511", 154);
            }
          });
          //11a
          // TODO: Inputs are different
          $("#4_155_515").change(function () {
            var value = $(this).val();

            // 0. όχι
            if (value === "516") {
              $("#7_2307_10182").val("888");
              $("#7_2308_10282").val("");
              // setValue('4_156_2477', 2576, 156);

              setTimeout(function () {
                $("input[name='question_2308']").focus();
              }, 700);
            }
            // 1. Ναι
            else if (value === "515") {
              $("#7_2308_10282").val("888");
              $("#7_2307_10182").val("");
              // setValue('4_157_2578', 2612, 157);

              setTimeout(function () {
                $("input[name='question_2307']").focus();
              }, 700);
            } else {
              $("#7_2307_10182").val("");

              $("#7_2308_10282").val("");
            }
          });
          //16a
          $("#4_223_5565").change(function () {
            var value = $(this).val();

            if (value === "5565") {
              setValue("4_224_6001", 6006, 224);

              setTimeout(function () {
                $("input[name='question_228']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_224_6001", 224);
            }
          });
          //17a
          $("#4_228_5670").change(function () {
            var value = $(this).val();

            if (value === "5670") {
              setValue("4_229_6008", 6013, 229);

              setTimeout(function () {
                $("input[name='question_233']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_224_6008", 229);
            }
          });
          //18a
          $("#4_233_5775").change(function () {
            var value = $(this).val();

            if (value === "5775") {
              setValue("4_234_6015", 6020, 234);

              setTimeout(function () {
                $("input[name='question_237']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_234_6015", 234);
            }
          });
          //19a
          $("#4_237_5880").change(function () {
            var value = $(this).val();

            if (value === "5880") {
              setValue("4_238_6022", 6027, 238);

              setTimeout(function () {
                $("input[name='question_240']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_238_6022", 238);
            }
          });
          //21a
          $("#4_164_538").change(function () {
            var value = $(this).val();

            if (value === "539") {
              setValue("4_165_541", 543, 165);
              // var select_text = $("#4_165_541").find('option[value="543"]').text();
              // $("input[name='question_165']").val('543');
              // $("#4_165_541").val('543');
              // $("input[name='question_165']").next("div").children("input:first").val(select_text);

              setTimeout(function () {
                $("input[name='question_166']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "540") {
              setValue("4_165_541", 544, 165);

              setTimeout(function () {
                $("input[name='question_166']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_165_541", 165);
            }
          });
          //22a
          $("#4_166_545").change(function () {
            var value = $(this).val();

            if (value === "546") {
              $("#7_167_548").val("88");

              setValue("4_168_549", 551, 168);

              setTimeout(function () {
                $("input[name='question_177']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "547") {
              $("#7_167_548").val("99");

              setValue("4_168_549", 552, 168);

              setTimeout(function () {
                $("input[name='question_177']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_168_549", 168);
              $("#7_167_548").val("");
            }
          });
          //23a
          $("#4_177_553").change(function () {
            var value = $(this).val();

            if (value === "556") {
              $("#7_188_573").val("88");
              $("#7_189_574").val("8888");
            } else if (value === "557") {
              $("#7_188_573").val("99");
              $("#7_189_574").val("9999");
            } else {
              $("#7_188_573").val("");
              $("#7_189_574").val("");
            }
          });
          $("#4_178_558").change(function () {
            var value = $(this).val();

            if (value === "561") {
              $("#7_190_575").val("88");
              $("#7_191_576").val("8888");
            } else if (value === "562") {
              $("#7_190_575").val("99");
              $("#7_191_576").val("9999");
            } else {
              $("#7_190_575").val("");
              $("#7_191_576").val("");
            }
          });
          $("#4_179_563").change(function () {
            var value = $(this).val();

            if (value === "566") {
              $("#7_192_577").val("88");
              $("#7_193_578").val("8888");
            } else if (value === "567") {
              $("#7_192_577").val("99");
              $("#7_193_578").val("9999");
            } else {
              $("#7_192_577").val("");
              $("#7_193_578").val("");
            }
          });
          $("#4_180_568").change(function () {
            var value = $(this).val();

            if (value === "571") {
              $("#7_194_579").val("88");
              $("#7_195_580").val("8888");
            } else if (value === "572") {
              $("#7_194_579").val("99");
              $("#7_195_580").val("9999");
            } else {
              $("#7_194_579").val("");
              $("#7_195_580").val("");
            }
          });

          //23g
          $("#7_282_785").keyup(function () {
            var value = $(this).val();
            console.log(value);

            if (value === "0") {
              $("#7_283_786").val("88");

              setTimeout(function () {
                $("input[name='question_286']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_283_786").val("");
            }
          });
          //25a
          $("#4_310_817").change(function () {
            var value = $(this).val();

            if (value === "824") {
              $("#7_292_795").val("88");
              $("#7_293_796").val("88");
              $("#7_294_797").val("8888");
              $("#7_296_798").val("88");
              $("#7_297_799").val("88");

              setValue("4_300_801", 808, 300);

              $("#7_302_810").val("88");
              $("#7_303_811").val("88");
              $("#7_304_812").val("8888");
              $("#7_306_813").val("88");
              $("#7_307_814").val("88");

              setTimeout(function () {
                $("input[name='question_308']").focus();
              }, 700);
            } else {
              $("#7_292_795").val("");
              $("#7_293_796").val("");
              $("#7_294_797").val("");
              $("#7_296_798").val("");
              $("#7_297_799").val("");

              clearInput("4_300_801", 300);

              $("#7_302_810").val("");
              $("#7_303_811").val("");
              $("#7_304_812").val("");
              $("#7_306_813").val("");
              $("#7_307_814").val("");
            }
          });
          //26a
          $("#4_300_801").change(function () {
            var value = $(this).val();

            if (value === "808") {
              $("#7_302_810").val("88");
              $("#7_303_811").val("88");
              $("#7_304_812").val("8888");
              $("#7_306_813").val("88");
              $("#7_307_814").val("88");

              setTimeout(function () {
                $("input[name='question_308']").focus();
              }, 700);
            } else {
              $("#7_302_810").val("");
              $("#7_303_811").val("");
              $("#7_304_812").val("");
              $("#7_306_813").val("");
              $("#7_307_814").val("");
            }
          });
          //29a
          $("#4_324_826").change(function () {
            var value = $(this).val();

            if (value === "827") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");
              $("#7_331_838").val("");

              setValue("4_326_832", 834, 326);
            } else if (value === "828") {
              $("#7_331_838").val("99");
            } else if (value === "826") {
              $("#7_331_838").val("88");
            }
            // TODO: Should we clear inputs in case of other choices?
          });
          //29b
          $("#4_325_829").change(function () {
            var value = $(this).val();

            if (value === "830") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");
              $("#7_332_839").val("");

              setValue("4_326_832", 834, 326);
            } else if (value === "831") {
              $("#7_332_839").val("99");
            } else if (value === "829") {
              $("#7_332_839").val("88");
            }
          });
          //29g
          $("#4_326_832").change(function () {
            var value = $(this).val();

            if (value === "835") {
              $("#7_328_836").val("99");
              $("#7_329_837").val("99");
            } else if (value === "832" || value === "834") {
              $("#7_328_836").val("88");
              $("#7_329_837").val("88");
            }
          });
          //33a
          $("#4_366_882").change(function () {
            var value = $(this).val();

            if (value === "883") {
              setValue("4_368_4134", 4150, 368);

              setTimeout(function () {
                $("input[name='question_369']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "884") {
              setValue("4_368_4134", 4151, 368);

              setTimeout(function () {
                $("input[name='question_369']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_368_4134", 368);
            }
          });
          //34a
          $("#4_369_887").change(function () {
            var value = $(this).val();

            if (value === "888") {
              setValue("4_371_4152", 4168, 371);

              setTimeout(function () {
                $("input[name='question_372']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "889") {
              setValue("4_371_4152", 4169, 371);

              setTimeout(function () {
                $("input[name='question_372']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_371_4152", 371);
            }
          });
          //35a
          $("#4_372_892").change(function () {
            var value = $(this).val();

            if (value === "893") {
              setValue("4_374_4189", 4206, 374);

              setValue("4_2310_10339", 10343, 2310);

              setValue("4_2311_10345", 10362, 2311);

              $("#7_2312_10364").val("88");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "894") {
              setValue("4_374_4189", 4207, 374);

              //var select_text6 = $("#4_375_897").find('option[value="9084"]').text();
              //$("input[name='question_375']").val('9084');
              //$("#4_375_897").val('9084');
              //$("input[name='question_375']").next("div").children("input:first").val(select_text6);

              //var select_text7 = $("#4_376_900").find('option[value="904"]').text();
              //$("input[name='question_376']").val('904');
              //$("#4_376_900").val('904');
              //$("input[name='question_376']").next("div").children("input:first").val(select_text7);

              //var select_text8 = $("#4_378_4208").find('option[value="4226"]').text();
              //$("input[name='question_378']").val('4226');
              //$("#4_378_4208").val('4226');
              //$("input[name='question_378']").next("div").children("input:first").val(select_text8);

              //$('#7_379_907').val('99');

              setTimeout(function () {
                $("input[name='question_375']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //36a
          $("#4_2310_10339").change(function () {
            var value = $(this).val();

            if (value === "10343") {
              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "10344") {


              setValue("4_2311_10345", 10363, 2311);

              $("#7_2312_10364").val("99");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "10340") {
              setValue("4_2311_10345", 10362, 2311);

              $("#7_2312_10364").val("88");

              setTimeout(function () {
                $("input[name='question_380']").next("div").children("input:first").focus();
              }, 700);
            }
          });
          //40a
          $("#4_2313_10365").change(function () {
            var value = $(this).val();

            if (value === "10368") {
              setValue("4_2314_10369", 10371, 2314);
            } else {
              clearInput("4_2314_10369", 2314);
            }
          });
          //43a
          $("#4_2326_10377").change(function () {
            var value = $(this).val();

            if (value === "10377") {
              $("#7_2327_10379").val("88");
              $("#7_2330_10380").val("88");
              $("#7_2331_10381").val("88");
            } else {
              $("#7_2327_10379").val("");
              $("#7_2330_10380").val("");
              $("#7_2331_10381").val("");
            }
          });

          // 49a
          $("#4_2343_10397").change(function () {
            var value = $(this).val();

            if (value === "10397") {
              //49b
              setValue("4_2344_10401", 10403, 2344);
              //50a
              setValue("4_2345_10405", 10407, 2345);
              //50b
              setValue("4_2346_10409", 10413, 2346);
            }
          });

          // 51a
          $("#4_2347_10415").change(function () {
            var value = $(this).val();

            if (value === "10415" || value === "10416") {
              setValue("4_2348_10420", 10425, 2348);
            } else {
              clearInput("4_2348_10420", 2348);
              setTimeout(function () {
                $("input[name='question_2348']").next("div").children("input:first").focus();
              }, 700);
            }
          });

          // 52a
          $("#4_2349_10437").change(function () {
            var value = $(this).val();

            if (value === "10437" || value === "10438") {
              setValue("4_2350_10442", 10447, 2350);
            } else {
              clearInput("4_2350_10442", 2350);
              setTimeout(function () {
                $("input[name='question_2350']").next("div").children("input:first").focus();
              }, 700);
            }
          });
        };

        //scl

        var nvalid = function (x) {
          var count = 0;
          //console.log(x);
          for (var i = 0; i < x.length; i++) {
            if (x[i] !== "") {
              //console.log(x[i]);
              count++;
            }
          }

          return count;
        };

        var sum = function (x) {
          var s = 0;
          for (var i = 0; i < x.length; i++) {
            if (x[i] !== "") {
              s += parseInt(x[i].slice(0, 1));
            }
          }

          return s;
        };

        var psi = function (x) {
          var count = 0;
          //console.log(x);
          for (var i = 0; i < x.length; i++) {
            if (x[i] !== "") {
              var val = parseInt(x[i].slice(0, 1));
              if (val > 0) {
                count++;
              }
            }
          }

          return count;
        };

        $(".fg-accordion a:first").trigger("click");
        $("input:radio").mousedown(function (event) {
          var $self = $(this);
          if ($self.is(":checked")) {
            var uncheck = function () {
              setTimeout(function () {
                $self.removeAttr("checked");
              }, 0);
            };
            var unbind = function () {
              $self.unbind("mouseup", up);
            };
            var up = function () {
              uncheck();
              unbind();
            };
            $self.bind("mouseup", up);
            $self.one("mouseout", unbind);
          }
        });

        $(".radio-inline").mousedown(function (event) {
          var $self = $(this);
          var radio = $(this).find("input[type=radio]:first-child");

          if (radio.is(":checked")) {
            var uncheck = function () {
              setTimeout(function () {
                radio.removeAttr("checked");
              }, 0);
            };
            var unbind = function () {
              $self.unbind("mouseup", up);
            };
            var up = function () {
              uncheck();
              unbind();
            };
            $self.bind("mouseup", up);
            $self.one("mouseout", unbind);
          }
        });

        //select box to combobox!
        $(".combobox").combobox();

        //Ember.$.getScript('forms_custom_js/form_'+this.get('controller.form.id')+'.js');

        //TDI
        if (this.get("controller.form.id") === "3") {
          TDI_AutoComplete_3();
        } else if (this.get("controller.form.id") === "24") {
          TDI_AutoComplete_24();
        }





        if (this.get("controller.form.id") === "10") {
          $("select.combobox").change(function () {
            var q1 = $("#4_243_625 option:selected").text();
            var q2 = $("#4_244_630 option:selected").text();
            var q3 = $("#4_245_635 option:selected").text();
            var q4 = $("#4_246_640 option:selected").text();
            var q5 = $("#4_247_645 option:selected").text();
            var q6 = $("#4_248_650 option:selected").text();
            var q7 = $("#4_249_655 option:selected").text();
            var q8 = $("#4_250_660 option:selected").text();
            var q9 = $("#4_251_665 option:selected").text();
            var q10 = $("#4_252_670 option:selected").text();
            var q11 = $("#4_253_675 option:selected").text();
            var q12 = $("#4_254_680 option:selected").text();
            var q13 = $("#4_255_685 option:selected").text();
            var q14 = $("#4_256_690 option:selected").text();
            var q15 = $("#4_257_695 option:selected").text();
            var q16 = $("#4_258_700 option:selected").text();
            var q17 = $("#4_259_705 option:selected").text();
            var q18 = $("#4_260_710 option:selected").text();
            var q19 = $("#4_261_715 option:selected").text();
            var q20 = $("#4_262_720 option:selected").text();
            var q21 = $("#4_263_725 option:selected").text();
            var q22 = $("#4_264_730 option:selected").text();
            var q23 = $("#4_265_735 option:selected").text();
            var q24 = $("#4_266_740 option:selected").text();
            var q25 = $("#4_267_745 option:selected").text();
            var q26 = $("#4_268_750 option:selected").text();
            var q27 = $("#4_269_755 option:selected").text();
            var q28 = $("#4_270_760 option:selected").text();
            var q29 = $("#4_271_765 option:selected").text();
            var q30 = $("#4_272_770 option:selected").text();
            var q31 = $("#4_273_775 option:selected").text();
            var q32 = $("#4_274_780 option:selected").text();
            var q33 = $("#4_463_4341 option:selected").text();
            var q34 = $("#4_464_4346 option:selected").text();
            var q35 = $("#4_465_4351 option:selected").text();
            var q36 = $("#4_466_4356 option:selected").text();
            var q37 = $("#4_467_4361 option:selected").text();
            var q38 = $("#4_468_4366 option:selected").text();
            var q39 = $("#4_469_4371 option:selected").text();
            var q40 = $("#4_470_4376 option:selected").text();
            var q41 = $("#4_471_4381 option:selected").text();
            var q42 = $("#4_472_4386 option:selected").text();
            var q43 = $("#4_473_4391 option:selected").text();
            var q44 = $("#4_474_4396 option:selected").text();
            var q45 = $("#4_475_4401 option:selected").text();
            var q46 = $("#4_476_4406 option:selected").text();
            var q47 = $("#4_477_4411 option:selected").text();
            var q48 = $("#4_478_4416 option:selected").text();
            var q49 = $("#4_479_4421 option:selected").text();
            var q50 = $("#4_480_4426 option:selected").text();
            var q51 = $("#4_481_4431 option:selected").text();
            var q52 = $("#4_482_4436 option:selected").text();
            var q53 = $("#4_483_4441 option:selected").text();
            var q54 = $("#4_484_4446 option:selected").text();
            var q55 = $("#4_485_4451 option:selected").text();
            var q56 = $("#4_486_4456 option:selected").text();
            var q57 = $("#4_487_4461 option:selected").text();
            var q58 = $("#4_488_4466 option:selected").text();
            var q59 = $("#4_489_4471 option:selected").text();
            var q60 = $("#4_490_4476 option:selected").text();
            var q61 = $("#4_491_4481 option:selected").text();
            var q62 = $("#4_492_4486 option:selected").text();
            var q63 = $("#4_493_4491 option:selected").text();
            var q64 = $("#4_494_4496 option:selected").text();
            var q65 = $("#4_495_4501 option:selected").text();
            var q66 = $("#4_496_4506 option:selected").text();
            var q67 = $("#4_497_4511 option:selected").text();
            var q68 = $("#4_498_4516 option:selected").text();
            var q69 = $("#4_499_4521 option:selected").text();
            var q70 = $("#4_500_4526 option:selected").text();
            var q71 = $("#4_501_4531 option:selected").text();
            var q72 = $("#4_502_4536 option:selected").text();
            var q73 = $("#4_503_4541 option:selected").text();
            var q74 = $("#4_520_4626 option:selected").text();
            var q75 = $("#4_504_4546 option:selected").text();
            var q76 = $("#4_505_4551 option:selected").text();
            var q77 = $("#4_506_4556 option:selected").text();
            var q78 = $("#4_507_4561 option:selected").text();
            var q79 = $("#4_508_4566 option:selected").text();
            var q80 = $("#4_509_4571 option:selected").text();
            var q81 = $("#4_510_4576 option:selected").text();
            var q82 = $("#4_511_4581 option:selected").text();
            var q83 = $("#4_512_4586 option:selected").text();
            var q84 = $("#4_513_4591 option:selected").text();
            var q85 = $("#4_514_4596 option:selected").text();
            var q86 = $("#4_515_4601 option:selected").text();
            var q87 = $("#4_516_4606 option:selected").text();
            var q88 = $("#4_517_4611 option:selected").text();
            var q89 = $("#4_518_4616 option:selected").text();
            var q90 = $("#4_519_4621 option:selected").text();

            var som = sum([q1, q4, q12, q27, q40, q42, q48, q49, q52, q53, q56, q58]) / nvalid([q1, q4, q12, q27, q40, q42, q48, q49, q52, q53, q56, q58]);
            if (isNaN(som)) {
              som = 0;
            } else {
              som = som.toFixed(2);
            }

            $("#6_3026_13933").val(som);

            var oc = sum([q3, q9, q10, q28, q38, q45, q46, q51, q55, q65]) / nvalid([q3, q9, q10, q28, q38, q45, q46, q51, q55, q65]);
            if (isNaN(oc)) {
              oc = 0;
            } else {
              oc = oc.toFixed(2);
            }

            $("#6_3015_13922").val(oc);

            var is = sum([q6, q21, q34, q36, q37, q41, q61, q69, q73]) / nvalid([q6, q21, q34, q36, q37, q41, q61, q69, q73]);
            if (isNaN(is)) {
              is = 0;
            } else {
              is = is.toFixed(2);
            }

            $("#6_3016_13923").val(is);

            var dep = sum([q5, q14, q15, q20, q22, q26, q29, q30, q31, q32, q54, q71, q79]) / nvalid([q5, q14, q15, q20, q22, q26, q29, q30, q31, q32, q54, q71, q79]);
            if (isNaN(dep)) {
              dep = 0;
            } else {
              dep = dep.toFixed(2);
            }

            $("#6_3017_13924").val(dep);

            var anx = sum([q2, q17, q23, q33, q39, q57, q72, q78, q80, q86]) / nvalid([q2, q17, q23, q33, q39, q57, q72, q78, q80, q86]);
            if (isNaN(anx)) {
              anx = 0;
            } else {
              anx = anx.toFixed(2);
            }

            $("#6_3018_13925").val(anx);

            var hos = sum([q11, q24, q63, q67, q74, q81]) / nvalid([q11, q24, q63, q67, q74, q81]);
            if (isNaN(hos)) {
              hos = 0;
            } else {
              hos = hos.toFixed(2);
            }

            $("#6_3019_13926").val(hos);

            var phob = sum([q13, q25, q47, q50, q70, q75, q82]) / nvalid([q13, q25, q47, q50, q70, q75, q82]);
            if (isNaN(phob)) {
              phob = 0;
            } else {
              phob = phob.toFixed(2);
            }

            $("#6_3020_13927").val(phob);

            var par = sum([q8, q18, q43, q68, q76, q83]) / nvalid([q8, q18, q43, q68, q76, q83]);
            if (isNaN(par)) {
              par = 0;
            } else {
              par = par.toFixed(2);
            }

            $("#6_3021_13928").val(par);

            var psy = sum([q7, q16, q35, q62, q77, q84, q85, q87, q88, q90]) / nvalid([q7, q16, q35, q62, q77, q84, q85, q87, q88, q90]);
            if (isNaN(psy)) {
              psy = 0;
            } else {
              psy = psy.toFixed(2);
            }

            $("#6_3022_13929").val(psy);

            var gsi = sum([q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, q62, q63, q64, q65, q66, q67, q68, q69, q70, q71, q72, q73, q74, q75, q76, q77, q78, q79, q80, q81, q82, q83, q84, q85, q86, q87, q88, q89, q90]) / nvalid([q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, q62, q63, q64, q65, q66, q67, q68, q69, q70, q71, q72, q73, q74, q75, q76, q77, q78, q79, q80, q81, q82, q83, q84, q85, q86, q87, q88, q89, q90]);
            if (isNaN(gsi)) {
              gsi = 0;
            } else {
              gsi = gsi.toFixed(2);
            }

            $("#6_3023_13930").val(gsi);

            var psi_val = psi([q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, q62, q63, q64, q65, q66, q67, q68, q69, q70, q71, q72, q73, q74, q75, q76, q77, q78, q79, q80, q81, q82, q83, q84, q85, q86, q87, q88, q89, q90]);

            if (isNaN(psi_val)) {
              psi_val = 0;
            } else {
              psi_val = psi_val.toFixed(2);
            }

            $("#6_3024_13931").val(psi_val);

            var psdi = sum([q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, q62, q63, q64, q65, q66, q67, q68, q69, q70, q71, q72, q73, q74, q75, q76, q77, q78, q79, q80, q81, q82, q83, q84, q85, q86, q87, q88, q89, q90]) / psi_val;

            if (isNaN(psdi)) {
              psdi = 0;
            } else {
              psdi = psdi.toFixed(2);
            }

            $("#6_3025_13932").val(psdi);
          });
        }

        //erotimatologio alkool
        if (this.get("controller.form.id") === "22") {
          $("#3_1759_7929").change(function () {
            if ($(this).val() === "answer_7929") {
              $("#3_1760_7930").prop("checked", true);
              $("#3_1761_7932").prop("checked", true);
              $("#3_1762_7934").prop("checked", true);
              $("#3_1763_7936").prop("checked", true);
              $("#3_1764_7938").prop("checked", true);
              $("#3_1765_7940").prop("checked", true);
              $("#3_1766_7942").prop("checked", true);
              $("#3_1767_7944").prop("checked", true);
            }
          });

          $("#3_1768_7948").change(function () {
            if ($(this).val() === "answer_7948") {
              $("#3_1769_7949").prop("checked", true);
              $("#3_1770_7951").prop("checked", true);
              $("#3_1771_7953").prop("checked", true);
              $("#3_1772_7955").prop("checked", true);
              $("#3_1773_7957").prop("checked", true);
              $("#3_1774_7959").prop("checked", true);
              $("#3_1775_7961").prop("checked", true);
              $("#3_1776_7963").prop("checked", true);
            }
          });
        }

        //TDI tzogou
        if (this.get("controller.form.id") === "20") {
          //5
          $("#4_1202_6138").change(function () {
            var value = $(this).val();

            if (value === "6138") {
              $("#7_1206_6159").val("88");
              $("#7_1215_6180").val("88");

              setTimeout(function () {
                $("input[name='question_1221']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_1206_6159").val("");
              $("#7_1215_6180").val("");
            }
          });

          //38
          $("#3_1446_6773").change(function () {
            if ($(this).val() === "answer_6773") {
              $("#3_1447_6776").prop("checked", true);
              $("#3_1448_6778").prop("checked", true);
              $("#3_1449_6780").prop("checked", true);
              $("#3_1450_6782").prop("checked", true);
              $("#3_1451_6784").prop("checked", true);
              $("#3_1452_6786").prop("checked", true);
              $("#3_1453_6788").prop("checked", true);
            }
          });

          //41
          $("#3_1513_6857").change(function () {
            if ($(this).val() === "answer_6857") {
              $("#3_1515_6860").prop("checked", true);
              $("#3_1518_6868").prop("checked", true);
              $("#3_1521_6870").prop("checked", true);
              $("#3_1524_6873").prop("checked", true);
              $("#3_1525_6875").prop("checked", true);
              $("#3_1526_6877").prop("checked", true);
              $("#3_1527_6879").prop("checked", true);
            }
          });

          //42
          $("#4_1543_6897").change(function () {
            var value = $(this).val();

            if (value === "6898") {
              $("#7_1548_6900").val("888");
            } else {
              $("#7_1548_6900").val("");
            }
          });

          //44
          $("#3_1562_6901").change(function () {
            if ($(this).val() === "answer_6901") {
              $("#3_1565_6904").prop("checked", true);
              $("#3_1608_6943").prop("checked", true);
              $("#3_1610_6948").prop("checked", true);
              $("#3_1576_6908").prop("checked", true);
              $("#3_1578_6910").prop("checked", true);
              $("#3_1582_6912").prop("checked", true);
              $("#3_1585_6914").prop("checked", true);
            }
          });
        }

        //TDI alkool
        if (this.get("controller.form.id") === "19") {
          //5
          $("#4_1193_6111").change(function () {
            var value = $(this).val();

            if (value === "6112") {
              $("#7_1196_6126").val("88");
              $("#7_1197_6127").val("88");

              setValue("4_1194_6114", 6123, 1194);

              setTimeout(function () {
                $("input[name='question_1199']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_1196_6126").val("");
              $("#7_1197_6127").val("");

              clearInput("4_1194_6114", 1194);
            }
          });


          $("#4_1201_6135").change(function () {
            var value = $(this).val();

            if (value === "6136") {
              setValue("4_1204_6147", 6156, 1204);

              setTimeout(function () {
                $("input[name='question_1207']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_1204_6147", 1204);
            }
          });

          //14
          $("#3_1253_6283").change(function () {
            if ($(this).val() === "answer_6283") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1254_6285").change(function () {
            if ($(this).val() === "answer_6285") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1255_6287").change(function () {
            if ($(this).val() === "answer_6287") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1256_6289").change(function () {
            if ($(this).val() === "answer_6289") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1257_6291").change(function () {
            if ($(this).val() === "answer_6291") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1258_6293").change(function () {
            if ($(this).val() === "answer_6293") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1259_6295").change(function () {
            if ($(this).val() === "answer_6295") {
              $("#3_1263_6314").prop("checked", true);
            }
          });
          $("#3_1260_6297").change(function () {
            if ($(this).val() === "answer_6297") {
              $("#3_1263_6314").prop("checked", true);
            }
          });

          //
          setValue("4_1322_6510", 6510, 1322);

          //
          $("#4_1327_6566").change(function () {
            var value = $(this).val();

            if (value === "6570") {
              $("#7_1345_6596").val("888");

              setTimeout(function () {
                $("input[name='question_1427']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_1345_6596").val("");
            }
          });

          //deutereuousa 1
          $("#4_1427_8659").change(function () {
            var value = $(this).val();

            if (value === "8659") {
              setValue("4_1430_8764", 8764, 1430);

              setValue("4_1433_8869", 8869, 1433);

              setValue("4_1435_8974", 8974, 1435);

            } else {
              clearInput("4_1430_8764", 1430);

              clearInput("4_1433_8869", 1433);

              clearInput("4_1435_8974", 1435);
            }
          });

          //deutereuousa 2
          $("#4_1430_8764").change(function () {
            var value = $(this).val();

            if (value === "8764") {
              setValue("4_1433_8869", 8869, 1433);

              setValue("4_1435_8974", 8974, 1435);

            } else {
              clearInput("4_1433_8869", 1433);

              clearInput("4_1435_8974", 1435);
            }
          });

          //deutereuousa 3
          $("#4_1433_8869").change(function () {
            var value = $(this).val();

            if (value === "8869") {
              setValue("4_1435_8974", 8974, 1435);

            } else {
              clearInput("4_1435_8974", 1435);
            }
          });

          //22
          $("#3_1478_6794").change(function () {
            if ($(this).val() === "answer_6794") {
              $("#3_1492_6824").prop("checked", true);
              $("#3_1494_6829").prop("checked", true);
              $("#3_1496_6834").prop("checked", true);
              $("#3_1498_6839").prop("checked", true);
              $("#3_1501_6846").prop("checked", true);
              $("#3_1488_6816").prop("checked", true);
              $("#3_1489_6819").prop("checked", true);
            }
          });

          //23
          $("#4_1517_6861").change(function () {
            var value = $(this).val();
            if (value === "6861") {
              $("#3_1530_6882").prop("checked", true);
            } else {
              $("#3_1530_6882").prop("checked", false);
            }
            if (value === "6862") {
              $("#3_1531_6885").prop("checked", true);
            } else {
              $("#3_1531_6885").prop("checked", false);
            }
          });

          //
          $("#4_1603_6932").change(function () {
            var value = $(this).val();

            if (value === "6933") {
              $("#7_1604_6935").val("88");
              $("#7_1605_6936").val("88");

              setTimeout(function () {
                $("input[name='question_1606']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_1604_6935").val("");
              $("#7_1605_6936").val("");
            }
          });

          //29
          $("#4_1609_9087").change(function () {
            var value = $(this).val();

            if (value === "9088") {
              $("#3_1612_6953").prop("checked", true);
              $("#3_1613_6956").prop("checked", true);
              $("#3_1614_6959").prop("checked", true);
              $("#3_1615_6962").prop("checked", true);
              $("#3_1616_6965").prop("checked", true);
              $("#3_1617_6968").prop("checked", true);
              $("#3_1618_6971").prop("checked", true);

            }
          });
        } //end form 19

        //ASI
        if (this.get("controller.form.id") === "11") {
          //8
          $("#4_1144_5372").change(function () {
            var value = $(this).val();

            if (value === "5372") {
              $("#7_1145_5380").val("88");

              setTimeout(function () {
                $("#6_527_1244").focus();
              }, 700);
            } else if (value === "5379") {
              $("#7_1145_5380").val("99");

              setTimeout(function () {
                $("#6_527_1244").focus();
              }, 700);
            }
          });

          //1
          $("#6_527_1244").keyup(function () {
            var value = $(this).val();

            if (value === "0") {
              $("#7_650_1484").val("88");
              $("#7_651_1485").val("88");

              setTimeout(function () {
                $("input[name='question_529']").next("div").children("input:first").focus();
              }, 700);
            }
          });

          //5
          $("#4_531_1253").change(function () {
            var value = $(this).val();

            if (value === "1253") {
              $("#7_532_1257").val("88");

              setValue("4_533_1258", 1262, 533);

              setTimeout(function () {
                $("input[name='question_534']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "1255") {
              $("#7_532_1257").val("99");

              setValue("4_533_1258", 1260, 533);

              setTimeout(function () {
                $("input[name='question_534']").next("div").children("input:first").focus();
              }, 700);
            } else if (value === "1256") {
              $("#7_532_1257").val("99");

              setValue("4_533_1258", 1261, 533);

              setTimeout(function () {
                $("input[name='question_534']").next("div").children("input:first").focus();
              }, 700);
            }
          });

          //1
          $("#7_581_1337").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_582_1338").val("88");
              $("#7_583_1339").val("88");

              setValue("4_584_1546", 1552, 584);

              setTimeout(function () {
                $("input[name='question_586']").focus();
              }, 700);
            } else {
              $("#7_582_1338").val("");
              $("#7_583_1339").val("");

              clearInput("4_584_1546", 584);
            }
          });

          //2
          $("#7_586_1340").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_587_1341").val("88");
              $("#7_588_1342").val("88");

              setValue("4_589_1554", 1560, 589);

              setTimeout(function () {
                $("input[name='question_591']").focus();
              }, 700);
            } else {
              $("#7_587_1341").val("");
              $("#7_588_1342").val("");

              clearInput("4_589_1554", 589);
            }
          });

          //3
          $("#7_591_1343").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_592_1344").val("88");
              $("#7_593_1345").val("88");

              setValue("4_594_1562", 1568, 594);

              setTimeout(function () {
                $("input[name='question_596']").focus();
              }, 700);
            } else {
              $("#7_592_1344").val("");
              $("#7_593_1345").val("");

              clearInput("4_594_1562", 594);
            }
          });

          //4
          $("#7_596_1346").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_597_1347").val("88");
              $("#7_598_1348").val("88");

              setValue("4_599_1570", 1576, 599);

              setTimeout(function () {
                $("input[name='question_601']").focus();
              }, 700);
            } else {
              $("#7_597_1347").val("");
              $("#7_598_1348").val("");

              clearInput("4_599_1570", 599);
            }
          });

          //5
          $("#7_601_1349").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_602_1350").val("88");
              $("#7_603_1351").val("88");

              setValue("4_604_1578", 1584, 604);

              setTimeout(function () {
                $("input[name='question_606']").focus();
              }, 700);
            } else {
              $("#7_602_1350").val("");
              $("#7_603_1351").val("");

              clearInput("4_604_1578", 604);
            }
          });

          //6
          $("#7_606_1352").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_607_1353").val("88");
              $("#7_608_1354").val("88");

              setValue("4_609_1586", 1592, 609);

              setTimeout(function () {
                $("input[name='question_611']").focus();
              }, 700);
            } else {
              $("#7_607_1353").val("");
              $("#7_608_1354").val("");

              clearInput("4_609_1586", 609);
            }
          });

          //7
          $("#7_611_1355").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_612_1356").val("88");
              $("#7_613_1357").val("88");

              setValue("4_614_1594", 1600, 614);

              setTimeout(function () {
                $("input[name='question_616']").focus();
              }, 700);
            } else {
              $("#7_612_1356").val("");
              $("#7_613_1357").val("");

              clearInput("4_614_1594", 614);
            }
          });

          //8
          $("#7_616_1358").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_617_1359").val("88");
              $("#7_618_1360").val("88");

              setValue("4_619_1602", 1608, 619);

              setTimeout(function () {
                $("input[name='question_621']").focus();
              }, 700);
            } else {
              $("#7_617_1359").val("");
              $("#7_618_1360").val("");

              clearInput("4_619_1602", 619);
            }
          });

          //9
          $("#7_621_1361").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_622_1362").val("88");
              $("#7_623_1363").val("88");

              setValue("4_624_1610", 1616, 624);

              setTimeout(function () {
                $("input[name='question_626']").focus();
              }, 700);
            } else {
              $("#7_622_1362").val("");
              $("#7_623_1363").val("");

              clearInput("4_624_1610", 624);
            }
          });

          //10
          $("#7_626_1364").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_627_1365").val("88");
              $("#7_628_1366").val("88");

              setValue("4_629_1618", 1624, 629);

              setTimeout(function () {
                $("input[name='question_631']").focus();
              }, 700);
            } else {
              $("#7_627_1365").val("");
              $("#7_628_1366").val("");

              clearInput("4_629_1618", 629);
            }
          });

          //11
          $("#7_631_1367").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_632_1368").val("88");
              $("#7_633_1369").val("88");

              setValue("4_634_1626", 1632, 634);

              setTimeout(function () {
                $("input[name='question_636']").focus();
              }, 700);
            } else {
              $("#7_632_1368").val("");
              $("#7_633_1369").val("");

              clearInput("4_634_1626", 634);
            }
          });

          //12
          $("#7_636_1370").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_637_1371").val("88");
              $("#7_638_1372").val("88");

              setValue("4_639_1634", 1640, 639);

              setTimeout(function () {
                $("input[name='question_641']").focus();
              }, 700);
            } else {
              $("#7_637_1371").val("");
              $("#7_638_1372").val("");

              clearInput("4_639_1634", 639);
            }
          });

          //13
          $("#7_641_1373").on("change focus", function () {
            var value = $(this).val();

            if (value === "88") {
              $("#7_642_1374").val("88");
              $("#7_643_1375").val("88");

              setValue("4_644_1642", 1648, 644);

              setTimeout(function () {
                $("input[name='question_645']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_642_1374").val("");
              $("#7_643_1375").val("");

              clearInput("4_644_1642", 644);
            }
          });

          //14
          $("#4_645_1376").change(function () {
            var value = $(this).val();

            if (value === "1376") {
              $("#7_686_1650").val("88");
              $("#7_687_1651").val("88");
              $("#7_688_1652").val("88");
              $("#7_689_1653").val("88");

              setValue("4_691_1654", 1657, 691);

              setTimeout(function () {
                $("#6_693_1659").focus();
              }, 700);
            } else if (value === "1378") {
              $("#7_686_1650").val("99");
              $("#7_687_1651").val("99");
              $("#7_688_1652").val("99");
              $("#7_689_1653").val("99");

              setValue("4_691_1654", 1658, 691);

              setTimeout(function () {
                $("#6_693_1659").focus();
              }, 700);
            } else {
              $("#7_686_1650").val("");
              $("#7_687_1651").val("");
              $("#7_688_1652").val("");
              $("#7_689_1653").val("");

              clearInput("4_691_1654", 691);
            }
          });

          //19
          $("#4_746_1701").change(function () {
            var value = $(this).val();

            if (value === "1701") {
              var select_text = $("#4_747_1704").find("option[value=\"1705\"]").text();
              //$("input[name='question_747']").val('1705');
              $("#4_747_1704").val("1705");
              $("input[name='question_747']").next("div").children("input:first").val(select_text);

              setTimeout(function () {
                $("#6_749_1707").focus();
              }, 700);
            } else {
              clearInput("4_747_1704", 747);
            }
          });

          //12
          $("#6_783_1787").keyup(function () {
            var value = $(this).val();

            if (value === "0") {
              $("#7_784_1789").val("88");

              setValue("4_785_1791", 1799, 785);

              setTimeout(function () {
                $("input[name='question_787']").next("div").children("input:first").focus();
              }, 700);
            } else {
              $("#7_784_1789").val("");

              clearInput("4_785_1791", 785);
            }
          });

          //15
          $("#4_787_1811").change(function () {
            var value = $(this).val();

            if (value === "1811") {
              setValue("4_786_1801", 1809, 786);

              setTimeout(function () {
                $("#6_788_1814").focus();
              }, 700);
            } else {
              clearInput("4_786_1801", 786);
            }
          });

          //aderfos 1
          $("#4_1152_5505").change(function () {
            var value = $(this).val();

            if (value === "5507") {
              setValue("4_1153_5509", 5511, 1153);

              setValue("4_1154_5513", 5515, 1154);

              setTimeout(function () {
                $("input[name='question_1157']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_1153_5509", 1153);

              clearInput("4_1154_5513", 1154);
            }
          });


          //aderfos 2
          $("#4_1157_5517").change(function () {
            var value = $(this).val();

            if (value === "5519") {
              setValue("4_1158_5521", 5523, 1158);

              setValue("4_1159_5525", 5527, 1159);

              setTimeout(function () {
                $("input[name='question_1162']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_1158_5521", 1158);

              clearInput("4_1159_5525", 1159);
            }
          });

          //aderfh 1
          $("#4_1162_5529").change(function () {
            var value = $(this).val();

            if (value === "5531") {
              setValue("4_1163_5533", 5535, 1163);

              setValue("4_1164_5537", 5539, 1164);

              setTimeout(function () {
                $("input[name='question_1167']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_1163_5533", 1163);

              clearInput("4_1164_5537", 1164);
            }
          });

          //aderfh 2
          $("#4_1167_5541").change(function () {
            var value = $(this).val();

            if (value === "5543") {
              setValue("4_1168_5545", 5547, 1168);

              setValue("4_1169_5549", 5551, 1169);

              setTimeout(function () {
                $("input[name='question_1172']").next("div").children("input:first").focus();
              }, 700);
            } else {
              clearInput("4_1168_5545", 1168);

              clearInput("4_1169_5549", 1169);
            }
          });

          //thetos aderfos
          $("#4_1172_5553").change(function () {
            var value = $(this).val();

            if (value === "5555") {
              setValue("4_1173_5557", 5559, 1173);

              setValue("4_1174_5563", 5563, 1174);

              setTimeout(function () {
                $("#2_875_1982").focus();
              }, 700);
            } else {
              clearInput("4_1173_5557", 1173);

              clearInput("4_1174_5563", 1174);
            }
          });
        } //end form 11
      }

    });
  });