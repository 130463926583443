define("front/app", 
  ["ember","ember/resolver","ember/load-initializers","front/config/environment","ember-notify","front/lib/view","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __dependency5__, __dependency6__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var Resolver = __dependency2__["default"];
    var loadInitializers = __dependency3__["default"];
    var config = __dependency4__["default"];
    var Notify = __dependency5__["default"];
    //view::afterRenderEvent
    var View = __dependency6__["default"];

    Ember.MODEL_FACTORY_INJECTIONS = true;

    var App = Ember.Application.extend({
      modulePrefix: config.modulePrefix,
      podModulePrefix: config.podModulePrefix,
      Resolver: Resolver
    });

    Notify.useBootstrap();
    loadInitializers(App, config.modulePrefix);

    __exports__["default"] = App;
  });