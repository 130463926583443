define("front/routes/pehi/create-event", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return Ember.RSVP.hash({
          pehi: this.modelFor("pehi"),
          person: this.modelFor("person").person,
          type: params.type
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var obsUnit = this.store.getById("unit", model.pehi.get("unit_id"));
        controller.set("obsUnit", obsUnit);
      }
    });
  });