define("front/views/date-picker-base", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.TextField.extend({
      classNames: "form-control",
      didInsertElement: function () {
        this.$().datepicker({
          format: "dd/mm/yyyy",
          language: "el",
          autoclose: true
        });
      }
    });
  });