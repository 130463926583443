define("front/models/element_history", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var ElementHistory = DS.Model.extend({
      type: attr(),
      date: attr(),
      code: attr(),
      name: attr(),
      filename: attr(),
      fileHref: attr(),
      pers_id: attr(),
      event_type: attr(),
      event_id: attr(),
      user_create: attr(),

      isEvn: (function () {
        if (this.get("code") === "EVN") {
          return true;
        } else {
          return false;
        }
      }).property("code"),

      isDoc: (function () {
        if (this.get("code") === "DOC") {
          return true;
        } else {
          return false;
        }
      }).property("code"),

      isForm: (function () {
        if (this.get("code") === "FRM") {
          return true;
        } else {
          return false;
        }
      }).property("code"),

      isParam: (function () {
        if (this.get("code") === "PRM") {
          return true;
        } else {
          return false;
        }
      }).property("code"),

      isGroup: (function () {
        if (this.get("code") === "GRP") {
          return true;
        } else {
          return false;
        }
      }).property("code")

    });

    __exports__["default"] = ElementHistory;
  });