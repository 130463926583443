define("front/templates/components/ithaki-radio-button-group", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n      Ναί\n    ");
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n      Όχι\n    ");
      }

    function program5(depth0,data) {
      
      
      data.buffer.push("\n      Άγνωστο\n    ");
      }

      data.buffer.push("<div class=\"form-group\">\n  <label ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'for': ("name")
      },hashTypes:{'for': "ID"},hashContexts:{'for': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" class=\"col-sm-3 control-label\">");
      stack1 = helpers._triageMustache.call(depth0, "label", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</label>\n  <div class=\"col-sm-6\">\n    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (1),
        'groupValue': ("groupValue"),
        'changed': ("changed")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID",'changed': "ID"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (0),
        'groupValue': ("groupValue"),
        'changed': ("changed")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID",'changed': "ID"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(3, program3, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n    ");
      stack1 = (helper = helpers['radio-button'] || (depth0 && depth0['radio-button']),options={hash:{
        'value': (9),
        'groupValue': ("groupValue"),
        'changed': ("changed")
      },hashTypes:{'value': "INTEGER",'groupValue': "ID",'changed': "ID"},hashContexts:{'value': depth0,'groupValue': depth0,'changed': depth0},inverse:self.noop,fn:self.program(5, program5, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "radio-button", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n  </div>\n</div>\n");
      return buffer;
      
    });
  });