define("front/models/medical_record", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      diagnosisSymptoms: attr(),
      curePlan: attr(),
      transferToHosp: attr("boolean"),
      tthComments: attr(),
      datetime: attr(),
      formatedDatetime: attr(),
      comments: attr(),
      userId: attr(),
      doctor: DS.belongsTo("doctor"),
      transferInterpretation: (function () {
        return this.get("transferToHosp") ? "Ναί" : "Όχι";
      }).property("transferToHosp")

    });
  });