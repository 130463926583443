define("front/routes/relationship/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.modelFor("relationship");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("model", model);
      },

      deactivate: function () {
        this.controllerFor("relationship").get("model.relationship").rollback();
      } });
  });