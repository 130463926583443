define("front/controllers/appointments/new-modal", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {
      needs: ["application", "appointments"],
      filteredMembers: null,
      person: null,
      persons: null,
      hasMember: false,
      selectedUser: (function () {
        return this.get("controllers.appointments.currentUser");
      }).property("controllers.appointments.currentUser"),

      //canValidate: false,
      checked: (function () {
        if (!this.get("hasMember")) {
          this.set("person_id", null);
          this.set("filteredMembers", null);
        }
      }).observes("hasMember"),

      validations: {
        title: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Τίτλο." } }
      },

      actions: {
        filterWithXHR: function (autocomplete, term) {
          var self = this;
          this.store.find("person", { searchString: term }).then(function (persons) {
            self.set("filteredMembers", persons);
            //self.set('searchString',null);
          }, function (reason) {});
        },

        search: function () {
          var self = this;

          this.set("showSpinner", true);
          this.store.find("person", { searchString: this.get("searchString") }).then(function (persons) {
            self.set("persons", persons);
            self.set("searchString", null);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("persons", null);
            self.set("searchString", null);
            self.set("showSpinner", false);
          });
        },

        selectMember: function (id) {
          var self = this;

          this.store.find("person", id).then(function (person) {
            self.set("person", person);
          });
          return false;
        },

        closeModal: function () {
          this.set("title", null);
          this.set("persons", null);
          this.set("comments", null);
          this.set("person", null);
          this.set("person_id", null);
          this.set("filteredMembers", null);
          this.set("firstContact", null);
          this.set("hasMember", false);
          this.set("userConcern", null);
          this.set("selectedUser", this.get("controllers.appointments.currentUser"));
          this.send("removeModal");
        },

        saveAppointment: function () {
          //this.set('content.default',true);
          var newAppointment = this.store.createRecord("appointment", {
            user_id: this.get("controllers.application.currentUserId"),
            unit_id: this.get("controllers.application.selectedUnit.id"),
            title: this.get("title"),
            comments: this.get("comments"),
            canceled: 0,
            pending: 1,
            userConcern: this.get("selectedUser.id"),
            firstContact: this.get("firstContact"),
            start: this.get("content.start"),
            end: this.get("content.end"),
            person: this.get("person")
          });

          var self = this;

          this.validate().then(function () {
            newAppointment.save().then(function (savedGroup) {
              self.set("canValidate", false);

              self.set("title", "");
              self.set("comments", "");
              self.set("hasMember", false);
              self.set("userConcern", null);
              self.set("selectedUser", null);

              self.set("showSpinner", false);

              $("#appointments").fullCalendar("refetchEvents");

              $(".modal").modal("hide");

            }, function (response) {
              self.set("canValidate", false);

              self.get("hasMember", false);
              self.set("title", "");
              self.set("comments", "");
              self.set("userConcern", null);
              self.set("selectedUser", null);

              self.set("showSpinner", false);
              newAppointment.deleteRecord();
              self.send("reloadAppointments");
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newAppointment.deleteRecord();
          });
        }
      }
    });
    //self.set('searchString',null);
  });