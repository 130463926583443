define("front/templates/person/search-officials", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', escapeExpression=this.escapeExpression;


      data.buffer.push("<div class=\"col-xs-12\">\n    <div class=\"widget-box\">\n        <div class=\"widget-header\">\n            <h3 class=\"widget-title smaller\">Βεβαιώσεις / Πιστοποιητικά</h3>\n        </div>\n        <div class=\"widget-body\">\n            <div class=\"widget-main\">\n\n                <div class=\"space-8\"></div>\n                <div class=\"row\">\n\n                    <div class=\"col-xs-12\">\n                        <div class=\"table-header\">Βεβαιώσεις / Πιστοποιητικά</div>\n                    </div>\n                    <div class=\"col-xs-12\">\n                        ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "persons/official-docs-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n");
      return buffer;
      
    });
  });