define("front/controllers/ithaki-helplines/report", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      actions: {
        printReport: function () {
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/ithakiHelplines/printReport", {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              date_from: self.get("dateFrom"),
              date_to: self.get("dateTo")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        showReport: function () {}
      }
    });
  });