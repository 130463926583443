define("front/controllers/person/unit-reference", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, Ember.Validations.Mixin, {
      person: null,
      user: null,
      units: null,
      canValidate: false,
      user_current_unit: null,
      programs: null,

      selectedProgram: null,
      selectedUnit: null,

      onSelectCat: (function () {
        this.set("selectedUnit", "");
        this.set("selectedStructure", "");

        var self = this;

        var selectedProgramId = this.get("selectedProgram.id");
        var myprogram = this.store.getById("program", selectedProgramId);

        if (myprogram !== null) {
          this.store.find("structure", { program_id: myprogram.get("id") }).then(function (structures) {
            self.set("structures", structures);
          });
          //this.set('units', units.rejectBy('id', this.get('user_current_unit.id')));
        } else {
          this.set("units", "");
          this.set("structures", "");
          this.set("selectedUnit", "");
        }
      }).observes("selectedProgram"),

      onSelectStruc: (function () {
        this.set("selectedUnit", "");

        var self = this;

        var selectedStructureId = this.get("selectedStructure.id");
        var mystructure = this.store.getById("structure", selectedStructureId);


        if (mystructure !== null) {
          if (this.get("user_current_unit.rank") === "0") {
            this.store.find("unit", { structure_id: mystructure.get("id") }).then(function (units) {
              self.set("units", units);
            });
          } else {
            this.store.find("unit", { structure_id: mystructure.get("id") }).then(function (units) {
              units = units.rejectBy("id", self.get("user_current_unit.id"));
              var result = units.filter(function (item) {
                if (item.get("rank") >= self.get("user_current_unit.rank") || item.get("rank") === "0") {
                  return true;
                }
              });
              self.set("units", result);
            });
          }
          //this.set('units', units.rejectBy('id', this.get('user_current_unit.id')));
        } else {
          this.set("units", "");
          this.set("structures", "");
          this.set("selectedUnit", "");
        }
      }).observes("selectedProgram", "selectedStructure"),
      /*
          getUnit: function() {
      
              var selectedProgramId = this.get('selectedProgram.id');
              var myprogram = this.store.getById('program', selectedProgramId);
              if (myprogram !== null) {
      			var units = myprogram.get('units');
      			//if (!Ember.empty(units)){
      				this.set('units', units.rejectBy('id', this.get('user_current_unit.id')));
      			//}
                  //this.set('units', myprogram.get('units'));
      
              } else {
                  this.set('selectedUnit', '');
            
              }
      
          }.observes('selectedUnit'),*/

      validations: {
        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία παραπομπής" }
        },
        selectedUnit: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε υπηρεσία παραπομπής" }
        }
      },


      actions: {
        refer: function () {
          this.set("canValidate", true);

          var data = Ember.Object.create({
            user_id: this.get("user"),
            pers_id: this.get("model.person.id"),
            date: this.get("date"),
            comments: this.get("comment"),
            unit_id: this.get("selectedUnit.id")
          });

          var self = this;
          this.validate().then(function () {
            $.ajax({
              url: ENV.APP.backendUrl + "/unitReference",
              type: "POST",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Το μέλος παραπέμφθηκε στην υπηρεσία " + self.get("selectedUnit.name") + " με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("canValidate", false);
              self.send("reloadPerson");
              self.transitionToRoute("person", self.get("model.person.id"));
            }, function (response) {
              Notify.alert(response.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("unit_to", null);

              self.set("canValidate", false);
              data.deleteRecord();
            }, function () {
              self.set("unit_to", null);

              self.set("canValidate", false);
              data.deleteRecord();
            });
          });
        }

      }
    });
  });