define("front/views/group-scrolling", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      didInsertElement: function () {
        $("#group-scrolling").ace_scroll({
          size: 300
        });
      }
    });
  });