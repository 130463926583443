define("front/templates/physfiles/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("Νέος Φάκελος");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			<h3 class=\"widget-title smaller\">Διαχείριση Φυσικού φακέλου</h3>\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				<div class=\"row\">\n					\n					<div class=\"col-xs-12\">\n						<div class=\"table-header\">Φυσικοί φάκελοι εξυπηρετούμενου</div>\n					</div>\n					\n					<div class=\"col-xs-12\">\n						");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "physical-files-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n                                                                    <div class=\"space-20\"></div>\n                                                                    ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-primary")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "physfiles.new", options) : helperMissing.call(depth0, "link-to", "physfiles.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });