define("front/templates/helplines/caller-report", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n            <div class=\"range\"><h3>");
      stack1 = helpers._triageMustache.call(depth0, "dateFrom", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "dateTo", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3></div>\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Φύλο\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  ");
      stack1 = helpers._triageMustache.call(depth0, "caller_age_phrase", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </p>\n                <p>\n                  ");
      data.buffer.push(escapeExpression((helper = helpers['pie-chart'] || (depth0 && depth0['pie-chart']),options={hash:{
        'data': ("caller_gender")
      },hashTypes:{'data': "ID"},hashContexts:{'data': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "pie-chart", options))));
      data.buffer.push("\n                </p>\n                <p>\n                <div class=\"row\">\n                <div class=\"col-sm-6 col-sm-offset-3\">\n                  <table class=\"table age\">\n                  <thead>\n                    <tr>\n                      <th>Φύλο</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "cg", "in", "caller_gender", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n                </div>\n                </div>\n                </p>\n              </div>\n\n            </div>\n          ");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "cg.label", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "cg.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Ηλικία\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                Ο μέσος όρος ηλικίας των ατόμων οι οποίοι κάλεσαν στην Τηλεφωνική Γραμμή για τον Τζόγο είναι ");
      stack1 = helpers._triageMustache.call(depth0, "report.caller_age.caller_age_avg", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών. Η μικρότερη ηλικία είναι ");
      stack1 = helpers._triageMustache.call(depth0, "report.caller_age.caller_min_age", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών και η μεγαλύτερη ");
      stack1 = helpers._triageMustache.call(depth0, "report.caller_age.caller_max_age", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" ετών.\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Σχέση με τον Παίκτη\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Στον παρακάτω πίνακα, παρουσιάζεται η σχέση των καλούντων με τους παίκτες τυχερών παιχνιδιών\n                </p>\n                <div class=\"row\">\n                <div class=\"col-sm-6 col-sm-offset-3\">\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Σχέση με τον Παίκτη</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "caller_relation", "in", "caller_relations", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n                </div>\n                </div>\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Περιοχή Διαμονής Καλούντων\n                </h4>\n                <p>Ο τόπος διαμονής των καλούντων που απευθύνθηκαν στην τηλεφωνική γραμμή, φαίνεται στον παρακάτω πίνακα</p>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n              <div class=\"row\">\n                <div class=\"col-sm-6 col-sm-offset-3\">\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Περιοχή</th>\n                      <th>%</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "caller_region", "in", "caller_regions", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n                </div>\n                </div>\n              </div>\n            </div>\n\n            <div class=\"space-10\"></div>\n\n            <div class=\"widget-box transparent\">\n              <div class=\"widget-header widget-header-small\">\n                <h4 class=\"widget-title blue smaller\">\n                  Προηγούμενη Επικοινωνία\n                </h4>\n                <div class=\"widget-toolbar\">\n                  <a href=\"#\" data-action=\"collapse\">\n                    <i class=\"ace-icon fa fa-plus\" data-icon-show=\"fa-plus\" data-icon-hide=\"fa-minus\"></i>\n                  </a>\n                </div>\n              </div>\n\n              <div class=\"widget-body\">\n                <p>\n                  Οι καλούντες που έχουν καλέσει στο παρελθόν στην τηλεφωνική γραμμή δηλώνουν το διάστημα από την τελευταία κλήση τους, ως εξής\n                </p>\n                <div class=\"row\">\n                <div class=\"col-sm-6 col-sm-offset-3\">\n                <table class=\"table\">\n                  <thead>\n                    <tr>\n                      <th>Μήνες</th>\n                      <th>Αριθμός Ατόμων</th>\n                    </tr>\n                  </thead>\n                  <tbody>\n                    ");
      stack1 = helpers.each.call(depth0, "caller_recontact", "in", "caller_recontacts", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                  </tbody>\n                </table>\n                </div>\n                </div>\n              </div>\n            </div>\n\n          ");
      return buffer;
      }
    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "caller_relation.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "caller_relation.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td>");
      stack1 = helpers._triageMustache.call(depth0, "caller_region.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "caller_region.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                      <tr>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "caller_recontact.key", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                        <td class=\"center\">");
      stack1 = helpers._triageMustache.call(depth0, "caller_recontact.value", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</td>\n                      </tr>\n                    ");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n  <div class=\"widget-box\">\n    <div class=\"widget-header\">\n      <h3 class=\"widget-title smaller\">ΓΡΑΜΜΗ ΨΥΧΟΛΟΓΙΚΗΣ ΥΠΟΣΤΗΡΙΞΗΣ ΓΙΑ ΤΟΝ ΤΖΟΓΟ 1114 - Αναφορά του Καλούντος</h3>\n    </div>\n    <div class=\"widget-body\">\n      <div class=\"row\">\n        <div class=\"space-10\"></div>\n        <div class=\"col-sm-12\">\n          ");
      data.buffer.push(escapeExpression((helper = helpers['date-filter'] || (depth0 && depth0['date-filter']),options={hash:{
        'dateFrom': ("dateFrom"),
        'dateTo': ("dateTo"),
        'submit': ("showReport")
      },hashTypes:{'dateFrom': "ID",'dateTo': "ID",'submit': "STRING"},hashContexts:{'dateFrom': depth0,'dateTo': depth0,'submit': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "date-filter", options))));
      data.buffer.push("\n        </div>\n        <div class=\"space-10\"></div>\n          <div class=\"col-sm-10 col-sm-offset-1\">\n            <button class=\"btn btn-purple\"");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printReport", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Εκτύπωση</button>\n          </div>\n        <div class=\"space-10\"></div>\n      </div>\n      <div class=\"row\" id=\"printable\">\n        <style  TYPE=\"text/css\">\n\n            @media print {\n                /* Your styles here */\n               .widget-header {\n                  background: none;\n                  border-width: 0;\n                  border-bottom: 1px solid #DCE8F1;\n                  color: #4383B4;\n                  padding-left: 3px;\n              }\n\n                #printable h4{\n                  color: #478fca !important;\n                }\n\n           \n\n                table{\n\n                  page-break-inside:avoid;\n                  width:50% !important;\n                  margin-left: auto;\n                  margin-right: auto;\n                }\n\n                table tr{\n                  border: solid thin #333;\n                  page-break-inside:avoid; page-break-after:auto \n                }\n\n                table, th, td\n                {\n                  border-collapse:collapse;\n                  border: 1px solid black;\n                 \n                }\n                \n                td{\n                  width: 50%;\n                }\n\n                td{\n                  width: 50%;\n                }\n\n                td.center{\n                  text-align: center;\n                }               \n\n            }\n          </style>\n          \n        <div class=\"col-sm-10 col-sm-offset-1\">\n\n          ");
      stack1 = helpers['if'].call(depth0, "caller_gender", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n          <div class=\"space-10\"></div>\n\n          ");
      stack1 = helpers['if'].call(depth0, "report", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </div>\n      </div>\n    </div>\n  </div>\n</div>");
      return buffer;
      
    });
  });