define("front/views/date-picker", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.TextField.extend({
      classNames: "form-control",
      didInsertElement: function () {
        $(".date").datepicker({
          format: "dd/mm/yyyy",
          language: "el",
          endDate: new Date(),
          startDate: new Date(1899, 1, 1),
          autoclose: true
        });

        $(".date").datepicker().on("changeDate", function (e) {
          //console.log(e.date);
          var today = new Date();
          if (e.date > today) {
            $(".date").datepicker("update", today);
          }
        });
      },
      willDestroyElement: function () {
        $(".date").datepicker("destroy");
      }
    });
  });