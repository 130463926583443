define("front/controllers/users/index", 
  ["front/mixins/user","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var UserMixin = __dependency1__["default"];

    __exports__["default"] = Ember.ArrayController.extend(UserMixin, {
      actions: {
        goToUser: function (id) {
          this.transitionToRoute("users.edit", id);
        }
      }
    });
  });