define("front/models/relation_type", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      name: attr(),
      code: attr()
    });
  });