define("front/templates/templates/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;

    function program1(depth0,data) {
      
      
      data.buffer.push("<i class=\"ace-icon glyphicon glyphicon-file  align-top bigger-125 icon-on-left\"></i> \n						Νέο Template");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			<h3 class=\"widget-title smaller\">Χρήση Template Εγγράφου</h3>\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				<div class=\"row\">\n					<div class=\"col-xs-12\">\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-info")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "templates.new", options) : helperMissing.call(depth0, "link-to", "templates.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						\n					</div>\n				</div>\n				<div class=\"space-8\"></div>\n				<div class=\"row\">\n					\n					<div class=\"col-xs-12\">\n						<div class=\"table-header\">Καταχωρημένα Templates</div>\n					</div>\n					<div class=\"col-xs-12\">\n\n						");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "templates-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });