define("front/controllers/form-groups/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      code: null,
      header: null,
      footer: null,

      canValidate: false,

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Όνομα." }
        },
        selectedCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Κατηγορία." }
        },
        selectedSubCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Υποκατηγορία." }
        }
      },

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);

        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
        }
      }).observes("selectedCategory"),

      actions: {
        saveGroup: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);

          var newFormGroup = this.store.createRecord("formGroup", {
            name: this.get("name"),
            header: this.get("header"),
            footer: this.get("footer"),
            code: this.get("code"),
            subcategory: this.get("selectedSubCategory"),
            form: this.get("model.form")
          });

          var self = this;

          this.validate().then(function () {
            newFormGroup.save().then(function (savedGroup) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");
              self.set("code", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");


              self.set("showSpinner", false);
              self.send("routeRefresh");

              //self.transitionToRoute('person', savedPerson.get('id'));
            }, function (response) {
              self.set("canValidate", false);

              self.set("name", "");
              self.set("header", "");
              self.set("footer", "");
              self.set("code", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              newFormGroup.deleteRecord();
              self.send("routeRefresh");
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newFormGroup.deleteRecord();
          });
        }
      }
    });
  });