define("front/models/ithaki_helpline", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      code: attr(),
      consultant_id: attr(),
      consultant_name: attr(),
      date: attr(),
      duration: attr(),
      caller_recontact: attr(),
      caller_gender: attr(),
      caller_age: attr(),
      caller_relation: attr(),
      caller_city: DS.belongsTo("region"),
      caller_region: DS.belongsTo("region"),
      person_gender: attr(),
      person_age: attr(),
      person_marital_status: attr(),
      person_children: attr(),
      person_city: DS.belongsTo("region"),
      person_region: DS.belongsTo("region"),
      person_citizenship: DS.belongsTo("tdi-citizenship"),
      person_job_status: attr(),
      person_dependency: attr(),
      person_substance: DS.belongsTo("tdi-substance"),
      person_is_psycho: attr(),
      person_psycho: DS.belongsTo("tdi-psycho"),
      person_prison: attr(),
      person_prison_long_ago: attr(),
      service_type: attr(),
      service_type_other: attr(),
      service_transfer: attr(),
      service_transfer_other: attr(),
      service_remarks: attr(),
      caller_relation_other: attr(),
      person_marit_status_other: attr(),
      person_job_status_other: attr(),
      person_dependency_other: attr() });
  });