define("front/models/event", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      comments: attr(),
      pers_id: attr(),
      date: attr(),
      f_date: attr(),
      filename: attr(),
      fileHref: attr(),
      thumbHref: attr(),
      user_create: attr(),
      transfer: DS.belongsTo("transfer"),
      assignment: DS.belongsTo("assignment"),
      completion: DS.belongsTo("completion"),
      transport: DS.belongsTo("transport"),
      transition: DS.belongsTo("transition"),
      release: DS.belongsTo("release"),
      graduation: DS.belongsTo("graduation"),
      withdrawal: DS.belongsTo("withdrawal"),
      serviceStatement: DS.belongsTo("serviceStatement"),
      closure: DS.belongsTo("closure"),
      serviceInterrupt: DS.belongsTo("serviceInterrupt"),
      subcategory: DS.belongsTo("subcategory"),
      appointment: DS.belongsTo("appointment") });
  });