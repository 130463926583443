define("front/views/users/user-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",

      didInsertElement: function () {
        var self = this;

        var data;

        this.$().on("click", ".showUser", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("goToUser", id);
        });

        this.$().on("click", ".deleteExp", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("showModal", "users/delete-modal", id);
        });

        if (this.get("controller.model")) {
          data = this.get("controller.model").getEach("_data");
        }
        //console.log(data);
        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          aaData: data,
          iDisplayLength: 10,
          aoColumns: [{ sTitle: "Username", mData: "username" }, { sTitle: "Όνομα", mData: "firstname" }, { sTitle: "Επώνυμο", mData: "lastname" }, {
            mdata: null,
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue showUser' href='#' title='Εμφάνιση' data-value='" + row.id + "'><i class='ace-icon fa fa-search-plus bigger-130'></i></a><a class='red deleteExp' href='#' title='Διαγραφή' data-value='" + row.id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
              //return "<div class='action-buttons'><a class='blue showForm' href='#' title='Εμφάνιση' data-value='"+row.id+"'><i class='ace-icon fa fa-search-plus bigger-130'></i></a><a class='red deleteForm' href='#' title='Διαγραφή' data-value='"+row.id+"'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
            }

          }],
          aaSorting: [],
          order: []
        });
      }
    });
  });