define("front/controllers/event/index", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend({

      element_history: null,
      person: null,
      user_id: null,
      user: null,
      user_current_unit: null,
      transfer: null,
      completion: null,
      assignment: null,
      graduation: null,
      withdrawal: null,
      transport: null,
      transition: null,
      release: null,
      subcategory: null,
      appointment: null,
      cancelReasons: null,
      withdrawalReason: null,
      withdrawalReasons: null,
      serviceStatement: null,
      serviceInterrupt: null,
      closure: null,

      isEndPehiType: (function () {
        return this.get("closure") || this.get("withdrawal") || this.get("graduation") || this.get("completion") || this.get("release");
      }).property("closure", "withdrawal", "graduation", "completion", "release", "release"),


      readonly: (function () {
        return !(this.get("user.isAdmin") || this.get("user.id") === this.get("model.event.user_create"));
      }).property("model.event", "user"),

      displayTrCancReason: (function () {
        if (this.get("transfer.canr_id")) {
          return this.get("cancelReasons").filterBy("id", this.get("transfer.canr_id")).get("fistObject");
        }
        return null;
      }).property("transfer"),

      containsWithdraw: (function () {
        if (this.get("withdrawal.type") === "1") {
          if (this.get("withdrawal")) {
            var self = this;
            this.store.findAll("withdrawal_reason").then(function (reasons) {
              console.log(reasons);
              self.set("withdrawalReasons", reasons);
              self.set("withdrawalReason", reasons.filterBy("id", self.get("withdrawal.reason_id")).get("firstObject"));
            });
          }
        }
      }).observes("withdrawal"),

      witdSimple: (function () {
        if (this.get("withdrawal.type") === "1") {
          return true;
        }

        return false;
      }).property("withdrawal.type"),

      witdOutKethea: (function () {
        if (this.get("withdrawal.out_kethea") === "1") {
          return true;
        }

        return false;
      }).property("withdrawal.out_kethea"),

      file_owner_type: 2, //1:form, 2:person, 3:template
      owid: (function () {
        return this.get("person.id");
      }).property("person.id"),


      personIdentity: (function () {
        if (this.get("person.id") === this.get("model.event.pers_id")) {
          return true;
        } else {
          return false;
        }
      }).property("person.id", "model.event.pers_id"),

      title: (function () {
        if (this.get("transfer")) {
          return "Παραπομπή Μέλους";
        } else if (this.get("completion")) {
          return "Ολοκλήρωση παρακολούθησης";
        } else if (this.get("graduation")) {
          return "Αποφοίτηση";
        } else if (this.get("assignment")) {
          return "Ανάθεση";
        } else if (this.get("appointment")) {
          return "Ραντεβού";
        } else if (this.get("transport")) {
          return "Μεταγωγή";
        } else if (this.get("transition")) {
          return "Αλλαγή φάσης";
        } else if (this.get("withdrawal")) {
          return "Διακοπή";
        } else if (this.get("release")) {
          return "Αποφυλάκιση";
        } else if (this.get("serviceStatement")) {
          return "Παροχή Υπηρεσίας";
        } else if (this.get("serviceInterrupt")) {
          return "Διακοπή Παροχής Υπηρεσίας";
        } else if (this.get("closure")) {
          return "Κλείσιμο Παροχής Υπηρεσίας";
        }
      }).property("transfer", "completion", "withdraw", "appointment", "graduation", "transport", "transition", "release", "serviceStatement", "serviceInterrupt", "closure"),

      actions: {
        downloadFile: function () {
          var data;
          var self = this;
          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/personFile?pers_id=" + self.get("person.id") + "&doc_name=" + self.get("model.event.filename"), {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        print: function () {
          var data;
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/reports/printEvent/" + self.get("model.event.id"), {
            httpMethod: "POST",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              person: self.get("person.fullName"),
              title: self.get("title"),
              date: moment(self.get("model.event.date")).format("DD/MM/YYYY"),
              description: self.get("model.event.comments"),
              subcategory: self.get("subcategory.name"),
              category: self.get("subcategory.category_name"),
              comments: self.get("transfer.comments") || self.get("assignment.comments") || self.get("graduation.comments") || self.get("completion.comments") || self.get("withdrawal.comments"),
              witdTypeDescr: self.get("withdrawal.typeDescr"),
              witdReason: self.get("withdrawalReason.name"),
              witdEstimation: self.get("withdrawal.estimation")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        save: function () {
          var eventType = {};

          var filename = $("#uploaded_filename").text();

          if (this.get("transfer")) {
            eventType = {
              transfer: {
                id: this.get("transfer.id"),
                comments: this.get("transfer.comments"),
                canr_id: this.get("transfer.canr_id"),
                filename: filename
              }
            };
          }

          if (this.get("graduation")) {
            eventType = {
              graduation: {
                id: this.get("graduation.id"),
                comments: this.get("graduation.comments"),
                filename: filename
              }
            };
          }

          if (this.get("transport")) {
            eventType = {
              transport: {
                id: this.get("transport.id"),
                comments: this.get("transport.comments"),
                filename: filename
              }
            };
          }

          if (this.get("transition")) {
            eventType = {
              transition: {
                id: this.get("transition.id"),
                comments: this.get("transition.comments"),
                filename: filename
              }
            };
          }

          if (this.get("release")) {
            eventType = {
              release: {
                id: this.get("release.id"),
                comments: this.get("release.comments"),
                filename: filename
              }
            };
          }

          if (this.get("serviceStatement")) {
            eventType = {
              serviceStatement: {
                id: this.get("serviceStatement.id"),
                comments: this.get("serviceStatement.comments"),
                filename: filename
              }
            };
          }

          if (this.get("serviceInterrupt")) {
            eventType = {
              serviceInterrupt: {
                id: this.get("serviceInterrupt.id"),
                comments: this.get("serviceInterrupt.comments"),
                filename: filename
              }
            };
          }

          if (this.get("completion")) {
            eventType = {
              completion: {
                id: this.get("completion.id"),
                comments: this.get("completion.comments"),
                filename: filename
              }
            };
          }

          if (this.get("closure")) {
            eventType = {
              closure: {
                id: this.get("closure.id"),
                comments: this.get("closure.comments"),
                filename: filename
              }
            };
          }

          if (this.get("withdrawal")) {
            var out_kethea = null;
            if (this.get("witdOutKethea")) {
              out_kethea = 1;
            }

            if (this.get("withdrawal.type") === "1") {
              out_kethea = null;
            }

            eventType = {
              withdrawal: {
                id: this.get("withdrawal.id"),
                estimation: this.get("withdrawal.estimation"),
                comments: this.get("withdrawal.comments"),
                reason_id: this.get("withdrawalReason.id"),
                out_kethea: out_kethea,
                filename: filename
              }
            };
          }

          if (this.get("appointment")) {
            eventType = {
              appointment: {
                id: this.get("appointment.id"),
                filename: filename
              }
            };
          }

          if (this.get("assignment")) {
            eventType = {
              assignment: {
                id: this.get("assignment.id"),
                crisis: this.get("assignment.crisis"),
                comments: this.get("assignment.comments"),
                filename: filename
              }
            };
          }

          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/saveEvent",
            type: "POST",
            data: JSON.stringify(eventType),
            contentType: "application/json; charset=utf-8",
            dataType: "json" }).then(function (response) {
            eventType = {};
            self.send("reloadEvent");
            self.transitionToRoute("person", self.get("person.id"));
            Notify.success("Η ενημέρωση ολοκληρώθηκε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          }, function () {
            eventType = {};

            Notify.error("Η ενημέρωση απέτυχε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });