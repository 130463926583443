define("front/controllers/person/lock", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    __exports__["default"] = Ember.Controller.extend({
      user_id: null,
      isAdmin: false,

      actions: {
        lockMember: function () {
          var self = this;

          // Ajax Data Object
          var data = Ember.Object.create({
            pers_id: this.get("model.id"),
            user_id: this.get("user_id"),
            lock: 1
          });

          $.ajax({
            url: ENV.APP.backendUrl + "/lockMember",
            type: "POST",
            data: JSON.stringify(data)
          }).then(function (response) {
            Notify.success("Ο φάκελος του χρήστη είναι πλέον κλειδωμένος.", {
              closeAfter: 10000
            });
            self.send("reloadPerson");
          }, function (error) {
            Notify.warning("Το κλείδωμα του φακέλου απέτυχε.", {
              closeAfter: 10000
            });
          });
        }
      }
    });
  });