define("front/controllers/forms/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["forms/index"],

      getName: (function () {
        var self = this;

        this.store.find("Form", this.get("content")).then(function (form) {
          self.set("formName", form.get("name"));
        });
      }).observes("content"),

      formName: null,

      actions: {
        deleteForm: function () {
          var self = this;
          var formId = this.get("content");

          this.store.find("form", formId).then(function (form) {
            form.destroyRecord().then(function () {
              self.toggleProperty("controllers.forms/index.doReRender");

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");

            })["catch"](function (response) {
              form.rollback();
              //self.send('removeModal');
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή της Φόρμας είναι αδύνατη!");
            });
          });
        }
      }


    });
  });