define("front/models/physfile", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      code: attr(),
      pers_id: attr(),
      displaycode: attr(),
      date: attr() });
  });