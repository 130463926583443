define("front/routes/document/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        //alert(this.modelFor('document'));
        var personModel = this.modelFor("person");

        return Ember.RSVP.hash({
          categories: this.store.find("category"),
          person: personModel.person,
          document: this.modelFor("document")
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        console.log(model.document.get("category.id"));
        controller.set("editMode", true);
        controller.set("selectedCategory", model.document.get("category"));
        controller.set("selectedSubCategory", model.document.get("subcategory"));
        controller.set("user", this.controllerFor("application").get("currentUser"));
      } });
  });