define("front/controllers/helplines/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {
      canValidate: false,

      gender_enum: [{ id: 1, value: "1.Άνδ" }, { id: 2, value: "2.Γυν" }, { id: 9, value: "9.Άγν" }],

      status_enum: [{ id: 1, value: "Άγαμος" }, { id: 2, value: "Έγγαμος/η" }, { id: 3, value: "Διαζευμένος/η" }, { id: 4, value: "Χήρος/α" }, { id: 5, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],

      education_enum: [{ id: 1, value: "Αναλφάβητος" }, { id: 2, value: "Δημοτικό" }, { id: 3, value: "Γυμνάσιο" }, { id: 4, value: "Λύκειο" }, { id: 5, value: "ΤΕΙ" }, { id: 6, value: "ΑΕΙ" }, { id: 7, value: "Μεταπτυχιακό" }, { id: 8, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],

      request_enum: [{ id: 1, value: "1. Αποχή" }, { id: 2, value: "2. Έλεγχος" }, { id: 8, value: "8. Δεν αφορά" }, { id: 9, value: "9. Άγνωστο" }],

      freq_enum: [{ id: 0, value: "Καθόλου" }, { id: 1, value: "1-2 φ/εβδ" }, { id: 2, value: "3-4 φ/εβδ" }, { id: 3, value: "5-6 φ/εβδ" }, { id: 4, value: "Κάθε μέρα" }, { id: 9, value: "Άγνωστο" }],

      amount_enum: [{ id: 0, value: "Καθόλου" }, { id: 1, value: "Μέχρι 50€" }, { id: 2, value: "51-200€" }, { id: 3, value: "201-500€" }, { id: 4, value: "501-1.000€" }, { id: 5, value: "1.001-3.000€" }, { id: 6, value: "3.001-10.000€" }, { id: 7, value: "10.000€+" }, { id: 8, value: "Άλλο" }, { id: 9, value: "Άγνωστο" }],


      validations: {

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        region: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Περιοχή." }
        },

        consultant: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Σύμβουλο." }
        },

        caller_age: {
          numericality: { allowBlank: true, "if": "canValidate", messages: { numericality: "Παρακαλώ μόνο αριθμούς." } }
        },

        player_age: {
          numericality: { allowBlank: true, "if": "canValidate", messages: { numericality: "Παρακαλώ μόνο αριθμούς." } } },

        duration: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Διάρκεια." }
        }
      },

      actions: {
        requestBehaviour: function () {
          var hideRadio = this.get("call_request") === 0 ? false : true;
          this.set("hideRadio", hideRadio);
        },

        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var new_helpline = this.store.createRecord("helpline", {
            date: moment(this.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            region: this.get("region"),
            consultant_id: this.get("consultant.id"),
            call_request: this.get("call_request"),
            duration: this.get("duration"),
            first: this.get("first"),
            months_from_first: this.get("months_from_first"),
            caller_gender: this.get("caller_gender"),
            caller_age: this.get("caller_age"),
            caller_relation: this.get("caller_relation"),
            player_agent: this.get("player_agent"),
            relative_agent: this.get("relative_agent"),
            player_gender: this.get("player_gender"),
            player_age: this.get("player_age"),
            player_status: this.get("player_status"),
            player_education: this.get("player_education"),
            player_request: this.get("player_request"),
            game_internet: this.get("game_internet"),
            game_opap: this.get("game_opap"),
            game_club: this.get("game_club"),
            game_vlt: this.get("game_vlt"),
            game_illegal: this.get("game_illegal"),
            kind_sport_bets: this.get("kind_sport_bets"),
            kind_casino: this.get("kind_casino"),
            kind_kino: this.get("kind_kino"),
            kind_joker: this.get("kind_joker"),
            kind_lotto: this.get("kind_lotto"),
            kind_extra5: this.get("kind_extra5"),
            kind_super3: this.get("kind_super3"),
            kind_machines: this.get("kind_machines"),
            kind_cards: this.get("kind_cards"),
            kind_dice: this.get("kind_dice"),
            kind_stock_exchange: this.get("kind_stock_exchange"),
            kind_vlt: this.get("kind_vlt"),
            kind_scrats: this.get("kind_scrats"),
            kind_animal_bets: this.get("kind_animal_bets"),
            kind_other: this.get("kind_other"),
            month_frequency: this.get("month_frequency"),
            weekly_amount: this.get("weekly_amount"),
            type: this.get("type"),
            transfer: this.get("transfer"),
            summary: this.get("summary")

          });

          this.validate().then(function () {
            new_helpline.save().then(function () {
              self.transitionToRoute("helplines.index");
              self.send("reloadHelplines");
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000
              });
            }, function (error) {
              self.set("canValidate", false);
              new_helpline.deleteRecord();
              self.set("showSpinner", false);

              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            new_helpline.deleteRecord();
          });
        }
      }
    });
  });