define("front/views/element-history-table", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",
      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.content.[]"),

      didInsertElement: function () {
        var self = this;

        this.$().on("click", "#showEvent", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var pers_id = $(this).attr("data-person");
          self.get("controller").transitionTo("event", pers_id, event_id);
          //self.get('controller').send('showEvent', pers_id, event_id);
        });

        this.$().on("click", "#downloadFile", function (event) {
          event.preventDefault();
          var docname = $(this).attr("data-docname");
          var pers_id = $(this).attr("data-person");

          self.get("controller").send("downloadFile", pers_id, docname);
        });

        this.$().on("click", "#showDoc", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var pers_id = $(this).attr("data-person");
          self.get("controller").transitionTo("document.edit", pers_id, event_id);
        });

        this.$().on("click", "#deleteDoc", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "doc", name);
        });

        this.$().on("click", "#showFrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-forms", event_id);
        });

        this.$().on("click", "#deleteFrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "frm", name);
        });

        this.$().on("click", "#showPrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-parameters", event_id);
        });

        this.$().on("click", "#deletePrm", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          var name = $(this).attr("data-name");
          self.get("controller").send("showDeleteModal", event_id, "prm", name);
        });

        this.$().on("click", "#showGrp", function (event) {
          event.preventDefault();
          var event_id = $(this).attr("data-event");
          self.get("controller").transitionTo("person-form-groups", event_id);
        });

        var data = [];
        if (this.get("controller.content")) {
          data = this.get("controller.content").getEach("_data");
        }

        this.$().on("click", "#showMedr", function (event) {
          event.preventDefault();
          var eventId = $(this).attr("data-event");
          var controller = self.get("controller");

          controller.get("store").find("medical-record", { pers_id: controller.get("person.id"), event_id: eventId }).then(function (records) {
            self.get("controller").transitionTo("medical-record.edit", records.get("firstObject.id"));
          }, function () {
            Notify.error("Δεν έχετε δικαίωμα να δείτε αυτην την ιατρική καρτέλα", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        });

        this.$().dataTable({
          bProcessing: true,
          aaSorting: [],
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
          bAutoWidth: false,
          aaData: data,
          iDisplayLength: 10,
          fnRowCallback: function (nRow, aData) {
            var $nRow = $(nRow); // cache the row wrapped up in jQuery

            if (aData.name.indexOf("***") !== -1) {
              $("td:eq(2)", nRow).addClass("crisis");
            }
            return nRow;
          },

          aoColumns: [{ sTitle: "Τύπος", mData: "type" }, { sTitle: "Ημερομηνία", mData: "date" }, { sTitle: "Περιγραφή", mData: "name" }, {
            mdata: null,
            mRender: function (data, type, row) {
              var readonly = !(self.get("controller.currentUser.isAdmin") || self.get("controller.currentUser.id") === row.user_create);
              var result = "<div class='action-buttons'>";
              switch (row.code) {

                case "EVN":
                  // var class = row.name.indexOf("***") !=-1 ? 'crisis' : '';
                  if (row.event_type === "medical_record") {
                    result += "<a class='blue' id='showMedr' href='#' title='Εμφάνιση Ιατρικής Καρτέλας' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                    result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";

                    result += "</div>";
                  } else {
                    result += "<a class='blue' id='showEvent' href='#' title='Εμφάνιση Γεγονότος' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                    result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                    if (row.filename) {
                      result += "<a class='blue' id='downloadFile' data-docname='" + row.filename + "' data-person='" + row.pers_id + "'><i class='ace-icon fa fa-download bigger-130'></i></a>";
                    }
                    result += "</div>";
                  }
                  break;
                case "DOC":
                  result += "<a class='blue' id='showDoc' href='#' title='Εμφάνιση Εγγράφου' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#' class='red' id='deleteDoc' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "FRM":
                  result += "<a class='blue' id='showFrm' href='#' title='Εμφάνιση Φόρμας' data-name='" + row.name + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#'' class='red' id='deleteFrm' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "PRM":
                  result += "<a class='blue' id='showPrm' href='#' title='Εμφάνιση Παραμέτρου' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a>";
                  if (!readonly) {
                    result += "<a href='#'' class='red' id='deletePrm' data-name='" + row.name + "' data-event='" + row.event_id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
                  }
                  break;
                case "GRP":
                  result += "<a class='blue' id='showGrp' href='#' title='Εμφάνιση Γκρούπ' data-person='" + row.pers_id + "' data-event='" + row.event_id + "'>";
                  result += "<i class='ace-icon fa fa-search-plus bigger-130'></i></a></div>";
                  break;
              }
              return result;
            }
          }]
        });
      }
    });
  });