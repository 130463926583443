define("front/templates/person-groups/new", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n						");
      return buffer;
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("Επιστροφή");
      }

      data.buffer.push("<div class=\"widget-box widget-color-blue\">\n	<div class=\"widget-header\">\n		<h4 class=\"widget-title smaller\">Νέα Ομάδα</h4>\n	</div>\n	<div class=\"widget-body\">\n		<div class=\"widget-main\">\n			<form class=\"form-horizontal\" role=\"form\">\n				\n				<div class=\"form-group\">\n					<label for=\"name\" class=\"col-sm-3 control-label\">Όνομα:</label>\n					\n					<div class=\"col-sm-5\">\n						");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("name"),
        'id': ("name")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n					</div>\n					\n					<div class=\"col-sm-6 col-sm-offset-3\">\n						");
      stack1 = helpers['if'].call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</div>\n				</div>\n\n				<div class=\"form-group\">\n					<div class=\"col-sm-offset-2 col-sm-1\">\n						<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n					</div>\n					<div class=\"col-sm-6\">\n						\n						<button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n						\n						");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person-groups", options) : helperMissing.call(depth0, "link-to", "person-groups", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						\n					</div>\n				</div>\n\n			</form>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });