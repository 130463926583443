define("front/routes/person/unit-assignment", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    __exports__["default"] = Ember.Route.extend({
      model: function () {
        return this.modelFor("person");
      },
      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", model.person);
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));

        var physfiles = model.person.get("physfiles");

        controller.set("prefix", this.controllerFor("application").get("selectedUnit.prefix"));
      },

      deactivate: function () {
        this.controllerFor("person.unit-assignment").get("person").rollback();
        this.controllerFor("person.unit-assignment").set("crisis", false);
        this.controllerFor("person.unit-assignment").set("physfile_code", "");
        this.controllerFor("person.unit-assignment").set("property", null);
      }
    });
  });