define("front/models/permission", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Permission = DS.Model.extend({
      name: attr(),
      displayName: attr()
    });

    __exports__["default"] = Permission;
  });