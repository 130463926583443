define("front/routes/medical-record", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.store.find("medical-record", params.medr_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);
      } });
  });