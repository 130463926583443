define("front/controllers/transitional-presence", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend({
      hour_dis: (function () {
        if (this.get("presence_type") === "0") {
          return true;
        }
        return false;
      }).property("presence_type"),

      aa: (function () {
        return this.parentController.indexOf(this);
      }).property("parentController.[]"),

      actions: {
        "delete": function () {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/transitionalPresences",
            type: "DELETE",
            data: { pevn_id: this.get("pevn_id") }
          }).then(function (response) {
            self.set("presence_hours", null);
            Notify.success("Απουσία", {
              closeAfter: 1000 // or set to null to disable auto-hiding
            });
          }, function (error) {
            self.set("presence_type", "1");
          });
        },

        save: function () {
          var date = this.parentController.get("presenceDate");
          var self = this;

          if (!this.get("presence_id")) {
            $.ajax({
              url: ENV.APP.backendUrl + "/transitionalPresences",
              type: "POST",
              data: { day: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"), pehi_id: this.get("pehi_id"), presence_hours: this.get("presence_hours") }
            }).then(function (response) {
              Notify.success("Παρουσία", {
                closeAfter: 1000 // or set to null to disable auto-hiding
              });
            }, function (response) {
              Notify.alert(response.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              if (!self.get("pevn_id")) {
                self.set("presence_type", "0");
                self.set("presence_hours", null);
              } else {
                self.rollback();
              }
            });
          } else {
            $.ajax({
              url: ENV.APP.backendUrl + "/transitionalPresences",
              type: "PUT",
              data: { day: moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"), unit_id: this.parentController.get("current_unit.id"), presence_hours: this.get("presence_hours"), pevn_id: this.get("pevn_id"), presence_id: this.get("presence_id") }
            }).then(function (response) {
              Notify.success("Παρουσία", {
                closeAfter: 1000 // or set to null to disable auto-hiding
              });
            }, function (response) {
              Notify.alert(response.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.model.rollback();
            });
          }
        }
      }
    });
  });