define("front/models/form", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Form = DS.Model.extend({
      name: attr(),
      header: attr(),
      footer: attr(),
      code: attr(),
      filename: attr(),
      fileHref: attr(),
      thumbHref: attr(),
      hasGroups: attr(),
      isOfficial: attr(),
      isUnique: attr("boolean"),
      category: DS.belongsTo("category"),
      subcategory: DS.belongsTo("subcategory")
    });

    __exports__["default"] = Form;
  });