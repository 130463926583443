define("front/controllers/form-parameter/index", 
  ["front/config/environment","ember-notify","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend({

      hasSubParameter: (function () {
        if (this.get("subParameterSwitch") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("subParameterSwitch")

    });
  });