define("front/controllers/expedition-approaches/report", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {
      report: null,

      actions: {
        printReport: function () {
          var self = this;

          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/expeditions/expeditionApproachReport/1", {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token"),
              date_from: self.get("dateFrom"),
              date_to: self.get("dateTo")
            }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        showReport: function () {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/expeditions/expeditionApproachReport",
            data: { date_from: self.get("dateFrom"), date_to: self.get("dateTo"), unit_id: self.get("currentUnit.id") }
          }).then(function (report) {
            //self.set('content', report.helplineReport);
            self.set("report", report.ExpeditionApproachReport);
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });