define("front/controllers/physfiles/edit", 
  ["ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, Ember.Validations.Mixin, {

      person: null,
      user: null,
      canValidate: false,
      user_current_unit: null,
      physfile: null,
      isAdmin: false,


      actions: {
        save: function () {
          this.set("canValidate", true);
          var physfile = this.get("physfile");

          var self = this;

          this.validate().then(function () {
            physfile.save().then(function (response) {
              self.set("canValidate", false);
              Notify.success("Ο φυσικός φάκελος αποθηκεύτηκε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.send("reloadPhysfile");
              self.transitionToRoute("physfiles");
            }, function (error) {
              Notify.warning(error.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("canValidate", false);
            });
          }, function () {
            self.get("physfile").rollback();
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });

        } }

    });
  });