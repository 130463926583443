define("front/routes/medical-record/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("medical-record");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        var user = this.controllerFor("application").get("currentUser");
        controller.set("currentUser", user);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("person", this.modelFor("person").person);
        controller.set("medicalRecord", model);
      },

      deactivate: function () {
        this.controllerFor("medical-record.edit").get("model").rollback();
      }
    });
  });