define("front/models/person", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Person = DS.Model.extend({
      firstName: attr(),
      lastName: attr(),
      firstName2: attr(),
      lastName2: attr(),
      combinedLastName: attr(),
      combinedFirstName: attr(),
      fatherName: attr(),
      motherName: attr(),
      birthDate: attr(),
      ketheaCode: attr(),
      code: attr(),
      crisis: attr(),
      startDate: attr(),
      isMember: attr(),
      locked: attr(),
      gender: attr(),
      userLocked: attr(),
      isAddict: attr(),
      isFamily: attr(),
      isOther: attr(),
      displayPhysfiles: attr(),
      physfiles: DS.hasMany("physfile"),
      personGroups: DS.hasMany("personGroup"),
      currentUnit: DS.belongsTo("unit", { async: false }),
      nationality: DS.belongsTo("nationality", { async: false }),
      user_create: attr(),
      domId: (function () {
        return "person" + this.get("id");
      }).property("id"),
      fullName: (function () {
        var firstName = this.get("firstName");
        var lastName = this.get("lastName");

        return firstName + " " + lastName;
      }).property("firstName", "lastName"),
      member: (function () {
        if (this.get("isMember") === 1) {
          return true;
        } else {
          return false;
        }
      }).property("isMember"),
      Addict: (function () {
        if (this.get("isAddict") === 1) {
          return true;
        }
        return false;
      }).property("isAddict"),
      Family: (function () {
        if (this.get("isFamily") === 1) {
          return true;
        }
        return false;
      }).property("isAddict"),
      Other: (function () {
        if (this.get("isOther") === 1) {
          return true;
        }
        return false;
      }).property("isOther") });

    __exports__["default"] = Person;
    /*displayPhysfiles: function(){
      var output = '';
       if(this.get('physfiles').content.length === 0) { return 'Δεν υπάρχουν φυσικοί φακέλοι'; }
      this.get('physfiles').forEach(function(item){
        output += item.get('code') + ', ';
      });
       return output.slice(0,-2);
    }.property('physfiles')*/
  });