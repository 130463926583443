define("front/routes/person/accomplishment", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("person");
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", model.person);
        controller.set("person_histories", model.person_histories);
        controller.set("user", this.controllerFor("application").get("currentUserId"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
      } });
  });