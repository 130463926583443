define("front/models/pehi_event", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      startDate: attr(),
      endDate: attr(),
      type: attr(),
      unit_name: attr(),
      unit_id: attr(),
      unit_comp_can_close: attr(),
      structure_name: attr(),
      program_name: attr(),
      program_id: attr(),
      historyevents: DS.hasMany("historyevent"),
      /* formatedStartDate: function(){
         return moment(this.get('startDate')).format('DD/MM/YYYY');
       }.property('startDate'),
       formatedEndDate: function(){
         if( this.get('endDate')){
             return moment(this.get('endDate')).format('DD/MM/YYYY');
         }
         return '';
       }.property('endDate'),*/
      formatedStartDate: attr(),
      formatedEndDate: attr(),
      status: (function () {
        if (this.get("type") === "1") {
          return "Μέλος";
        } else if (this.get("type") === "2") {
          return "Υπηρεσία";
        }
      }).property("type")
    });
  });