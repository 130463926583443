define("front/routes/helplines/caller-report", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {


      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("report", undefined);
      }
    });
  });