define("front/routes/appointments", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      /*model: function(){
      	var ucu = this.controllerFor('application').get('selectedUnit.id');
      	
      	return this.store.find('appointment', {unit_id: ucu, start: moment().startOf('week').format('DD/MM/YYYY'), end: moment().endOf('week').format('DD/MM/YYYY')});
      },
      */
      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("user_name", this.controllerFor("application").get("currentUser.name"));
        controller.set("currentUser", this.controllerFor("application").get("currentUser"));
        controller.set("ucu", this.controllerFor("application").get("selectedUnit"));
        //controller.set('events', events);
      },

      actions: {
        reloadAppointments: function () {
          this.refresh();
        }
      }

    });
  });