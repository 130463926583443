define("front/routes/person/accept-reference", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        return this.modelFor("person");
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("transfers", model.transfer);
        controller.set("transfer", model.transfer.get("firstObject"));
        controller.set("person", model.person);
        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("user_current_unit", this.controllerFor("application").get("selectedUnit"));
        controller.set("prefix", this.controllerFor("application").get("selectedUnit.prefix"));
      },
      deactivate: function () {
        this.controllerFor("person.unit-assignment").set("physfile_code", null);
      }
    });
  });