define("front/routes/person/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.modelFor("person").person;
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", model);

        controller.set("user_id", this.controllerFor("application").get("currentUserId"));
        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));

        this.store.find("nationality").then(function (response) {
          controller.set("nationalities", response);
          controller.set("selectedNationality", model.get("nationality"));
        });
        controller.set("editMode", true);
        console.log(model.get("isAddict"));
        if (model.get("isAddict")) {
          controller.set("property", "isAddict");
        }
        if (model.get("isFamily")) {
          controller.set("property", "isFamily");
        }
        if (model.get("isOther")) {
          controller.set("property", "isOther");
        }
      },

      deactivate: function () {
        this.controllerFor("person.edit").get("model").rollback();
        this.controllerFor("person.edit").set("selectedNationality", this.controllerFor("person.edit").get("model.nationality"));
      }
    });
  });