define("front/controllers/physfiles/new", 
  ["ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, Ember.Validations.Mixin, {

      person: null,
      user: null,
      canValidate: false,
      user_current_unit: null,
      isAdmin: false,

      validations: {

        displaycode: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        } },


      actions: {
        save: function () {
          this.set("canValidate", true);

          var self = this;
          var physfile = this.store.createRecord("physfile", {
            pers_id: this.get("person.id"),
            displaycode: this.get("displaycode")
          });

          this.validate().then(function () {
            physfile.save().then(function (response) {
              self.set("canValidate", false);
              Notify.success("Ο φυσικός φάκελος αποθηκεύτηκε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.send("reloadPerson");
              self.transitionToRoute("physfiles");
            }, function (error) {
              Notify.warning(error.responseJSON.message, {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("canValidate", false);
            });
          }, function () {
            //physfile.rollback();
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });

        } }

    });
  });