define("front/controllers/person-groups/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {

      unitGroup: null,
      persons: null,
      userCurrentUnit: null,

      showSpinner: false,
      canValidate: false,

      validations: {
        name: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε το όνομα της ομάδας" }
        }
      },

      actions: {
        save: function () {
          this.set("canValidate", true);

          var newPersonGroup = this.store.createRecord("personGroup", {
            name: this.get("name"),
            user_id: this.get("user_id") });

          this.set("showSpinner", true);

          var self = this;

          this.validate().then(function () {
            newPersonGroup.save().then(function (response) {
              self.set("canValidate", false);
              self.set("name", "");
              self.set("showSpinner", false);
              Notify.success("Η ομάδα δημιουργήθηκε με επιτυχία", {
                closeAfter: 10000
              });
              self.set("name", "");

              self.get("pg").pushObject(response);
              //console.log(response.id);
              self.transitionToRoute("person-group.edit", response.id);
            }, function () {
              self.set("canValidate", false);
              self.set("name", "");
              self.set("showSpinner", false);
              newPersonGroup.deleteRecord();
              Notify.warning("Η δημιουργία ομάδας απέτυχε", {
                closeAfter: 10000
              });
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            newPersonGroup.deleteRecord();
          });
        }
      }

    });
  });