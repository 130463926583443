define("front/templates/document/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n				<h3 class=\"widget-title smaller\">Επεξεργασία Εγγράφου ");
      stack1 = helpers._triageMustache.call(depth0, "document.title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h3>\n				");
      return buffer;
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n				<h3 class=\"widget-title smaller\">Δημιουργία Νέου Εγγράφου (Document)</h3>\n				");
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n										");
      stack1 = helpers['if'].call(depth0, "readonly", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(8, program8, data),fn:self.program(6, program6, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n											");
      stack1 = helpers._triageMustache.call(depth0, "document.date", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n										");
      return buffer;
      }

    function program8(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n											");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "date-picker", {hash:{
        'value': ("document.date"),
        'id': ("document_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n										");
      return buffer;
      }

    function program10(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "today-datepicker", {hash:{
        'value': ("date"),
        'id': ("document_date")
      },hashTypes:{'value': "ID",'id': "STRING"},hashContexts:{'value': depth0,'id': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n									");
      return buffer;
      }

    function program12(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n								");
      return buffer;
      }

    function program14(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("document.title"),
        'id': ("title"),
        'readonly': ("readonly")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'readonly': "ID"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'readonly': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								");
      return buffer;
      }

    function program16(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n								");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("title"),
        'id': ("title")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n								");
      return buffer;
      }

    function program18(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.title.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n								");
      return buffer;
      }

    function program20(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n								");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("document.remarks"),
        'id': ("remarks"),
        'rows': ("4"),
        'readonly': ("readonly")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING",'readonly': "ID"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0,'readonly': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n								");
      return buffer;
      }

    function program22(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n								");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("remarks"),
        'id': ("remarks"),
        'rows': ("4")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n								");
      return buffer;
      }

    function program24(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Κατηγορία:</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("model.categories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή κατηγορίας:"),
        'selectionBinding': ("selectedCategory"),
        'disabled': ("true")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING",'disabled': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0,'disabled': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-9 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Υποκατηγορία:</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("subcategories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή υποκατηγορίας:"),
        'selectionBinding': ("selectedSubCategory"),
        'disabled': ("true")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING",'disabled': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0,'disabled': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-9 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(27, program27, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n						");
      return buffer;
      }
    function program25(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n								");
      return buffer;
      }

    function program27(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n								<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n								");
      return buffer;
      }

    function program29(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Κατηγορία:</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("model.categories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή κατηγορίας:"),
        'selectionBinding': ("selectedCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-9 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(25, program25, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Υποκατηγορία:</label>\n							<div class=\"col-sm-9\">\n								");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("subcategories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή υποκατηγορίας:"),
        'selectionBinding': ("selectedSubCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-9 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(27, program27, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n						");
      return buffer;
      }

    function program31(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Αρχείο:</label>\n							<div class=\"col-sm-9\">\n								<p class=\"form-control-static\">\n									");
      stack1 = helpers['if'].call(depth0, "document.thumbHref", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(32, program32, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									<span class=\"blue link\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "downloadFile", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(" >");
      stack1 = helpers._triageMustache.call(depth0, "document.filename", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" <i class=\"ace-icon fa fa-download bigger-130\"></i></span>\n								</p>\n							</div>\n						</div>\n						");
      return buffer;
      }
    function program32(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									<img ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'src': ("document.thumbHref")
      },hashTypes:{'src': "ID"},hashContexts:{'src': depth0},contexts:[],types:[],data:data})));
      data.buffer.push(" >\n									");
      return buffer;
      }

    function program34(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Αρχείο:</label>\n							<div class=\"col-sm-6\">\n								");
      stack1 = helpers.unless.call(depth0, "readonly", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(35, program35, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n						");
      return buffer;
      }
    function program35(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "documents/document-upload", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n								");
      return buffer;
      }

    function program37(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n								<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "edit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(" ");
      data.buffer.push(escapeExpression((helper = helpers.bindAttr || (depth0 && depth0.bindAttr),options={hash:{
        'disabled': ("readonly")
      },hashTypes:{'disabled': "STRING"},hashContexts:{'disabled': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "bindAttr", options))));
      data.buffer.push(">Αποθήκευση</button>\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(38, program38, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "personID", options) : helperMissing.call(depth0, "link-to", "person", "personID", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								");
      return buffer;
      }
    function program38(depth0,data) {
      
      
      data.buffer.push("\n								Επιστροφή\n								");
      }

    function program40(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n								<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αποθήκευση</button>\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(38, program38, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "personID", options) : helperMissing.call(depth0, "link-to", "person", "personID", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								");
      return buffer;
      }

      data.buffer.push("<div class=\"row\">\n	<div class=\"col-xs-12\">\n		<div class=\"widget-box\">\n			<div class=\"widget-header\">\n				");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n			</div>\n			<div class=\"widget-body\">\n				<div class=\"widget-main\">\n					<form class=\"form-horizontal\" role=\"form\">\n\n						<div class=\"form-group\">\n							<label for=\"document_date\" class=\"col-sm-3 control-label\">Ημ/νία:</label>\n							<div class=\"col-sm-6\">\n								<div class=\"input-group date\" id=\"date-today\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(10, program10, data),fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n									<span class=\"input-group-addon\"><i class=\"fa fa-calendar\"></i></span>\n								</div>\n							</div>\n							<div class=\"col-sm-12\"></div>\n							<div class=\"col-sm-6 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.date.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"title\" class=\"col-sm-3 control-label\">Τίτλος:</label>\n							<div class=\"col-sm-9\">\n								");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(16, program16, data),fn:self.program(14, program14, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-9 col-sm-offset-3\">\n								");
      stack1 = helpers['if'].call(depth0, "errors.title.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(18, program18, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n\n						<div class=\"form-group\">\n							<label for=\"remarks\" class=\"col-sm-3 control-label\">Σημειώσεις:</label>\n							<div class=\"col-sm-9\">\n								");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(22, program22, data),fn:self.program(20, program20, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						</div>\n						");
      stack1 = helpers['if'].call(depth0, "readonly", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(29, program29, data),fn:self.program(24, program24, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n						");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(34, program34, data),fn:self.program(31, program31, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n						<div class=\"form-group\">\n							<div class=\"col-sm-offset-3 col-sm-6\">\n								");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(40, program40, data),fn:self.program(37, program37, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n							<div class=\"col-sm-2\">\n								<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n							</div>\n						</div>\n					</form>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });