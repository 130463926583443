define("front/models/template", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    var Template = DS.Model.extend({
      name: attr(),
      filename: attr(),
      category: DS.belongsTo("category"),
      subcategory: DS.belongsTo("subcategory") });

    __exports__["default"] = Template;
  });