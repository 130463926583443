define("front/models/answer", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Answer = DS.Model.extend({

      code: attr(),
      text: attr(),
      remarks: attr(),
      type: attr(),
      quesId: attr(),
      quesSingularity: attr(),
      is_the_answer: attr(),
      the_answer_text: attr(),
      choices: DS.hasMany("choice")

    });

    __exports__["default"] = Answer;
  });