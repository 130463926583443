define("front/serializers/custom_event", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      serialize: function (value) {
        var serialized = this._super(value);
        value.pehi_id && (serialized.pehi_id = value.pehi_id);
        value.pers_id && (serialized.pers_id = value.pers_id);
        return serialized;
      }
    });
  });