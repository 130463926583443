define("front/controllers/documents/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({

      editMode: false,

      actions: {

        edit: function (id) {
          this.set("editMode", true);
          this.transitionTo("document.edit", id);
        },

        "delete": function () {
          $("#myModal").modal("hide");
        }
      }

    });
  });