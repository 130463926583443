define("front/views/history-scrolling", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      didInsertElement: function () {
        $("#person-history-box").widget_box("toggle");
        $("#history-scrolling").ace_scroll({
          size: 500
        });
      }
    });
  });