define("front/templates/helplines", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n<div class=\"row\">\n  <div class=\"col-xs-12 col-md-12\">\n    <div class=\"page-header\">\n      <h1>\n        ΑΛΦΑ ΓΡΑΜΜΗ ΨΥΧΟΛΟΓΙΚΗΣ ΥΠΟΣΤΗΡΙΞΗΣ\n        <small><i class=\"ace-icon fa fa-angle-double-right\"> ");
      stack1 = helpers._triageMustache.call(depth0, "currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</i></small>\n      </h1>\n    </div>\n    <div class=\"row\">\n       <div class=\"col-md-3 col-sm-6\">\n          <div class=\"col-sm-12 col-xs-12 offset-col-sm-1 label label-info label-xlg\">\n            <b>Επιλογές</b>\n          </div>\n          <div class=\"space-20\"></div>\n          <ul class=\"list-unstyled spaced\" id=\"memberDetailsMenu\">\n            <li>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.index", options) : helperMissing.call(depth0, "link-to", "helplines.index", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </li>\n            <li>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.new", options) : helperMissing.call(depth0, "link-to", "helplines.new", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </li>\n            <li>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.report", options) : helperMissing.call(depth0, "link-to", "helplines.report", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </li>\n            <li>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.caller-report", options) : helperMissing.call(depth0, "link-to", "helplines.caller-report", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </li>\n\n            <li>\n              ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-link wrap")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "helplines.player-report", options) : helperMissing.call(depth0, "link-to", "helplines.player-report", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </li>\n          </ul>\n        </div>\n        <div class=\"col-md-9 col-sm-6\">\n          ");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n        </div>\n  </div>\n</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("Λίστα Φορμών");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("Δημιουργία Φόρμας");
      }

    function program6(depth0,data) {
      
      
      data.buffer.push("Αναφορά");
      }

    function program8(depth0,data) {
      
      
      data.buffer.push("Αναφορά του Καλούντος");
      }

    function program10(depth0,data) {
      
      
      data.buffer.push("Αναφορά του Παίκτη");
      }

    function program12(depth0,data) {
      
      
      data.buffer.push("\n  <div class=\"space-10\"></div>\n  <div class=\"alert alert-danger\">\n    Η Υπηρεσία αυτή δεν δέχεται κλήσεις ψυχολογικής υποστήριξης.\n  </div>\n");
      }

      stack1 = helpers['if'].call(depth0, "currentUnit.haveAlfaHelplines", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(12, program12, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });