define("front/routes/person/termination", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return Ember.RSVP.hash({
          person: this.modelFor("person"),
          units: this.store.find("unit"),
          withdrawalReasons: this.store.find("withdrawalReason")
        });
      },
      setupController: function (controller, model) {
        this._super(controller, model);
        var current_unit = this.controllerFor("application").get("selectedUnit");

        /** XETRATO CAUTION !!!! **/
        // No 10 reason in alfa
        var alfa_units = [8, 9, 10, 11, 182, 208];
        if (alfa_units.indexOf(parseInt(current_unit.get("id"))) >= 0) {
          controller.set("withdrawalReasons", model.withdrawalReasons.filter(function (item, index, self) {
            return parseInt(item.id) !== 10;
          }));
        } else {
          controller.set("withdrawalReasons", model.withdrawalReasons);
        }

        controller.set("remarks", null);
        controller.set("estimation", null);
        controller.set("selectedReason", null);

        controller.set("person", model.person.person);
        controller.set("person_histories", model.person.person_histories);
        controller.set("user_current_unit", current_unit);
        controller.set("user", this.controllerFor("application").get("currentUserId"));
      }
    });
  });