define("front/views/today-datepicker", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.TextField.extend({
      classNames: "form-control",
      didInsertElement: function () {
        $("#date-today").datepicker({
          format: "dd/mm/yyyy",
          language: "el",
          autoclose: true,
          endDate: new Date(),
          startDate: new Date(1899, 1, 1)
        });

        $("#date-today").datepicker("update", moment().format("DD/MM/YYYY"));
      }
    });
  });