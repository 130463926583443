define("front/controllers/medical-records/create", 
  ["ember-notify","front/config/environment","front/mixins/person","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __dependency4__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];
    var UserMixin = __dependency4__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, PersonMixin, UserMixin, {
      needs: ["application"],

      actions: {
        save: function () {
          var _this = this;
          this.set("medicalRecord.pers_id", this.get("person.id"));
          this.set("medicalRecord.unit_id", this.get("currentUnit.id"));

          this.get("medicalRecord").save().then(function (medicalRecord) {
            Notify.success("Η ιατρική καρτέλα αποθηκεύτηκε με επιτυχία", {
              closeAfter: 10000
            });
            _this.send("reloadPerson");
            _this.send("routeRefresh");
            _this.transitionToRoute("medical-record.edit", medicalRecord.get("id"));
          }, function (error) {
            Notify.error(error.responseJSON.error.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        },

        print: function () {}
      }
    });
  });