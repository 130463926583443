define("front/routes/forms/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        return Ember.RSVP.hash({
          categories: this.store.find("category")
        });
      },

      renderTemplate: function () {
        this.render("form.edit", {
          controller: "forms/new"
        });
      }
    });
  });