define("front/templates/form/edit", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Επεξεργασία Φόρμας</h3>\n			");
      }

    function program3(depth0,data) {
      
      
      data.buffer.push("\n			<h3 class=\"widget-title smaller\">Δημιουργία Φόρμας</h3>\n			");
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program7(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.code.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program9(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program11(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program13(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n									<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.filename.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n									");
      return buffer;
      }

    function program15(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									<button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "edit", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Επεξεργασία</button>\n									");
      return buffer;
      }

    function program17(depth0,data) {
      
      var buffer = '';
      data.buffer.push("\n									<button class=\"btn btn-success\" type=\"submit\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "save", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Συνέχεια</button>\n									");
      return buffer;
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n				<form class=\"form-horizontal\" role=\"form\">\n					<div class=\"row\">\n						<div class=\"col-sm-10\">\n\n							<div class=\"form-group\">\n								<label for=\"name\" class=\"col-sm-3 control-label\">Τίτλος:</label>\n								<div class=\"col-sm-9\">\n									\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("name"),
        'id': ("name")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									\n								</div>\n								<div class=\"col-sm-9 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.name.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n\n							<div class=\"form-group\">\n								<label for=\"header\" class=\"col-sm-3 control-label\">Περιγραφή:</label>\n								<div class=\"col-sm-9\">\n									\n									");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("header"),
        'id': ("header"),
        'rows': ("5")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n									\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"footer\" class=\"col-sm-3 control-label\">Υποσημείωση:</label>\n								<div class=\"col-sm-9\">\n									\n									");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'classNames': ("form-control"),
        'value': ("footer"),
        'id': ("footer"),
        'rows': ("5")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING",'rows': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0,'rows': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n									\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"code\" class=\"col-sm-3 control-label\">Κωδικός:</label>\n								<div class=\"col-sm-6\">\n									\n									");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("code"),
        'id': ("code")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n									\n								</div>\n								<div class=\"col-sm-6 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.code.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(7, program7, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"remarks\" class=\"col-sm-3 control-label\">Κατηγορία:</label>\n								<div class=\"col-sm-6\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("model.categories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή κατηγορίας:"),
        'selectionBinding': ("selectedCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-6 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.selectedCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(9, program9, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"remarks\" class=\"col-sm-3 control-label\">Υποκατηγορία:</label>\n								<div class=\"col-sm-6\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("subcategories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Επιλογή υποκατηγορίας:"),
        'selectionBinding': ("selectedSubCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-6 col-sm-offset-3\">\n									");
      stack1 = helpers['if'].call(depth0, "errors.selectedSubCategory.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(11, program11, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n							<div class=\"form-group\">\n								<label for=\"\" class=\"col-sm-3 control-label\">Αρχείο:</label>\n								<div class=\"col-sm-6\">\n									");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "documents/document-upload", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n									");
      stack1 = helpers['if'].call(depth0, "errors.filename.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(13, program13, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n							</div>\n\n\n							<div class=\"form-group\">\n								<div class=\"col-sm-offset-3 col-sm-2\">\n									");
      stack1 = helpers['if'].call(depth0, "editMode", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(17, program17, data),fn:self.program(15, program15, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n								</div>\n								<div class=\"col-sm-2\">\n									<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n								</div>\n							</div>\n						</div>\n\n					</div>\n				</form>\n			</div>\n		</div>\n	</div>\n</div>");
      return buffer;
      
    });
  });