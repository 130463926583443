define("front/controllers/event/change-type", 
  ["front/config/environment","ember-notify","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var UserMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(UserMixin, {
      type: (function () {
        if (!Ember.empty(this.get("event.closure"))) {
          return "closure";
        }
        if (!Ember.empty(this.get("event.withdrawal"))) {
          return "withdrawal";
        }
        if (!Ember.empty(this.get("event.graduation"))) {
          return "graduation";
        }
        if (!Ember.empty(this.get("event.completion"))) {
          return "completion";
        }
        if (!Ember.empty(this.get("event.release"))) {
          return "release";
        }

        return null;
      }).property("event.closure", "event.withdrawal", "event.graduation", "event.completion", "event.release"),

      title: (function () {
        if (this.get("type") === "closure") {
          return "Κλείσιμο";
        }
        if (this.get("type") === "withdrawal") {
          return "Αποχώρηση";
        }
        if (this.get("type") === "graduation") {
          return "Αποφοίτηση";
        }
        if (this.get("type") === "completion") {
          return "Ολοκλήρωση";
        }
        if (this.get("type") === "release") {
          return "Αποφυλάκιση";
        }

        return null;
      }).property("type"),

      types: (function () {
        var _this = this;
        var types = [{ type: "closure", text: "Κλείσιμο" }, { type: "withdrawal", text: "Αποχώρηση" }, { type: "graduation", text: "Αποφοίτηση" }, { type: "completion", text: "Ολοκλήρωση" }, { type: "release", text: "Αποφυλάκιση" }];

        return types.filter(function (type) {
          return type.type !== _this.get("type");
        });
      }).property("type"),

      actions: {
        change: function () {
          var data = {
            event_id: this.get("event.id"),
            event_to_type: this.get("selectedType.type"),
            event_from_type: this.get("type")
          };
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/changeEventType",
            type: "POST",
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json" }).then(function (response) {
            self.send("reloadEvent");
            self.transitionToRoute("person", self.get("person.id"));
            Notify.success("Η ενημέρωση ολοκληρώθηκε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          }, function () {
            Notify.error("Η ενημέρωση απέτυχε", {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });