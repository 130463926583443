define("front/controllers/helplines/player-report", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend({
      labels: ["%"],

      yKeys: ["value"],

      player_gender: (function () {
        var player = this.get("report") ? this.get("report.player_gender") : null;

        if (player) {
          var total = this.get("report").count;

          var data = [{ label: "Άνδρες", value: (player.male / total * 100).toFixed(1) }, { label: "Γυναίκες", value: (player.female / total * 100).toFixed(1) }, { label: "Άγνωστο", value: (player.uknown_gender / total * 100).toFixed(1) }];

          return data;
        }

        return [];
      }).property("report"),

      player_age_phrase: (function () {
        var player = this.get("report") ? this.get("report.player_gender") : null;

        if (player) {
          var total = this.get("report").count;

          var males = (player.male / total * 100).toFixed(1);
          var females = (player.female / total * 100).toFixed(1);

          if (females > males) {
            return "Οι παίκτες για τους οποίους έγινε τηλεφωνική επικοινωνία με τη Γραμμή Τηλεφωνικής Υποστήριξης για τα τυχερά παιχνίδια είναι  γυναίκες σε ποσοστό " + females + "%, έναντι " + males + "% των ανδρών.";
          } else if (males > females) {
            return "Οι παίκτες για τους οποίους έγινε τηλεφωνική επικοινωνία με τη Γραμμή Τηλεφωνικής Υποστήριξης για τα τυχερά παιχνίδια είναι  άντρες σε ποσοστό " + males + "%, έναντι " + females + "% των γυναικών.";
          } else {
            return "Οι παίκτες για τους οποίους έγινε τηλεφωνική επικοινωνία με τη Γραμμή Τηλεφωνικής Υποστήριξης για τα τυχερά παιχνίδια είναι το ίδιο Ανδρες: " + males + "% Γυναίκες: " + females + "%";
          }
        }
      }).property("report"),

      call_type: (function () {
        var call = this.get("report") ? this.get("report.call_type") : null;

        if (call) {
          var total = this.get("report").count;

          var data = [{ label: "Πληροφορίες %", value: call.info }, { label: "Συμβουλευτική %", value: call.consult }];

          return data;
        }

        return [];
      }).property("report"),

      call_transfer: (function () {
        var call = this.get("report") ? this.get("report.call_transfer") : null;

        if (call) {
          var total = this.get("report").count;

          var data = [{ label: "Έγινε Παραπομπή %", value: call.transfer }, { label: "Δεν Έγινε Παραπομπή %", value: call.no_transfer }];

          return data;
        }

        return [];
      }).property("report"),


      player_type: (function () {
        var player = this.get("report") ? this.get("report.player_type") : null;

        if (player) {
          var total = this.get("report").count;

          var data = [{ label: "Συμβουλευτική %", value: (player.male / total * 100).toFixed(1) }, { label: "Πληροφορίες %", value: (player.female / total * 100).toFixed(1) }];

          return data;
        }

        return [];
      }).property("report"),

      player_status: (function () {
        var player = this.get("report") ? this.get("report.player_status") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_education: (function () {
        var player = this.get("report") ? this.get("report.player_education") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_game: (function () {
        var player = this.get("report") ? this.get("report.player_game") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_game_internet: (function () {
        var player = this.get("report") ? this.get("report.player_game_internet") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_kind: (function () {
        var player = this.get("report") ? this.get("report.player_kind") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_frequency: (function () {
        var player = this.get("report") ? this.get("report.player_frequency") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),

      player_amount: (function () {
        var player = this.get("report") ? this.get("report.player_amount") : null;

        if (player) {
          return player = $.map(player, function (el) {
            return el;
          });
        }
      }).property("report"),



      actions: {
        printReport: function () {
          var w = window.open();
          w.document.write($("#printable").html());
          w.print();
          w.close();
        },

        showReport: function () {
          var self = this;

          $.ajax({
            url: ENV.APP.backendUrl + "/helplines/playerReport",
            data: { date_from: self.get("dateFrom"), date_to: self.get("dateTo") }
          }).then(function (report) {
            self.set("report", report.helplinePlayerReport);
          }, function (response) {
            Notify.alert(response.responseJSON.message, {
              closeAfter: 10000 // or set to null to disable auto-hiding
            });
          });
        }
      }
    });
  });