define("front/controllers/physfiles/index", 
  ["ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var PersonMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {
      person: null,
      currentFiles: null,

      actions: {
        goToFile: function (id) {
          this.transitionToRoute("physfiles.edit", id);
        }
      }
    });
  });