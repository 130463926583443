define("front/routes/person/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        var user_unit_id = this.controllerFor("application").get("selectedUnit.id");
        //var pers_unit_id = personModel.person_histories.filterBy('endDate', null).filterBy('type', '1').get('firstObject.unit_id');

        return Ember.RSVP.hash({
          user_prog: this.store.find("program", { unit_id: user_unit_id }),
          person_histories: personModel.person_histories,
          element_history: this.store.find("element_history", { pers_id: person_id })
        });
      },
      afterModel: function (model) {
        this.controllerFor("person.index").set("categories", this.store.find("category"));

        var personModel = this.modelFor("person");
        var person = personModel.person;

        this.controllerFor("person.index").set("person", person);
      },
      setupController: function (controller, model) {
        //this._super(controller, model);

        controller.set("user_prog_id", model.user_prog.get("firstObject.id"));
        controller.set("person_histories", model.person_histories);
        controller.set("currentUser", this.controllerFor("application").get("currentUser"));
        /*if (model.pers_prog){
          controller.set('pers_prog_id', model.pers_prog.get('firstObject.id'));
        }
        else{
          controller.set('pers_prog_id', '0');
        }*/

        controller.set("model", model.element_history);

        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));
      },

      deactivate: function () {
        this.controllerFor("person.index").set("documentsChecked", true);
        this.controllerFor("person.index").set("eventsChecked", true);
        this.controllerFor("person.index").set("formsChecked", true);
        this.controllerFor("person.index").set("groupsChecked", false);
        this.controllerFor("person.index").set("paramsChecked", true);
        this.controllerFor("person.index").set("presencesChecked", false);
      }

    });
  });