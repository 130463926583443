define("front/templates/person/withdraw", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							<div class=\"text-warning bigger-110 orange\">\n								<i class=\"ace-icon fa fa-exclamation-triangle\"></i>\n								Προσοχή ! Το μέλος ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" θα αποχωρήσει απο την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(". Αν είστε σίγουροι επιλέξτε τον τρόπο αποχώρησης ή πατήστε ακύρωση να γυρίσετε στον φάκελο μέλους\n							</div>\n							<div class=\"space-20\"></div>\n							<div class=\"center\">\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn btn-success"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "person.termination", options) : helperMissing.call(depth0, "link-to", "person.termination", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n\n\n								");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0,depth0],types:["STRING","ID"],data:data},helper ? helper.call(depth0, "person", "person.id", options) : helperMissing.call(depth0, "link-to", "person", "person.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							</div>\n						");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n									Διακοπή παρακολούθησης\n								");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n									Ακύρωση ενέργειας\n								");
      }

    function program6(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n							<div class=\"alert alert-danger\">\n								Το μέλος δεν ανήκει στην υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "user_current_unit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<br>\n							</div>\n						");
      return buffer;
      }

      data.buffer.push("<div class=\"row\">\n	<div class=\"col-sm-12 col-md-12 col-xs-12\">\n		<div class=\"widget-box\">\n				<div class=\"widget-header\">\n					<h4 class=\"widget-title smaller\">Αποχώρηση του μέλους ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" απο την υπηρεσία ");
      stack1 = helpers._triageMustache.call(depth0, "person.currentUnit.name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h4>\n				</div>\n				<div class=\"widget-body\">\n					<div class=\"widget-main\">\n						");
      stack1 = helpers['if'].call(depth0, "identicalUnits", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(6, program6, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n					</div>\n				</div>\n			</div>\n	</div>\n</div>\n");
      return buffer;
      
    });
  });