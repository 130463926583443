define("front/controllers/person/delete-pehi", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, {


      actions: {
        "delete": function () {
          var pehi = this.get("content.pehi");
          var self = this;


          pehi.destroyRecord().then(function () {
            self.send("reloadPerson");
            self.transitionToRoute("person", self.get("content.pehi.pers_id"));
            $(".modal").modal("hide");
            Notify.success("Το ιστορικό διαγράφηκε με επιτυχία", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
          })["catch"](function (response) {
            Notify.warning("Το ιστορικό δεν διαγράφηκε", {
              closeAfter: 3000 // or set to null to disable auto-hiding
            });
            response.get("firstObject").rollback();
          });
        },

        closeModal: function () {
          this.send("removeModal");
        } }
    });
  });