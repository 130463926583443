define("front/components/date-filter", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];

    __exports__["default"] = Ember.Component.extend({
      actions: {
        submit: function () {
          this.sendAction("submit");
        }
      }
    });
  });