define("front/routes/manage-events", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend({
      model: function () {
        var personModel = this.modelFor("person");
        var person_id = personModel.person.id;

        return this.store.find("pehi_event", { pers_id: person_id });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", this.controllerFor("person").get("person"));
        controller.set("pehi_events", model);
      }
    });
  });