define("front/controllers/appointments/error-modal", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {


      actions: {
        closeModal: function () {
          this.send("removeModal");
        }
      }
    });
  });