define("front/routes/expedition-approaches", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var unit_id = this.controllerFor("application").get("selectedUnit");
        return this.store.find("expedition-approach", { unit_id: unit_id.id });
      },

      setupController: function (controller, model) {
        //this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
      },

      actions: {
        reloadExpeditions: function () {
          /*var self = this;
          this.modelFor('expedition-approach').reload().then(function(response){
              self.refresh();
          });*/
          this.refresh();
        }
      }
    });
  });