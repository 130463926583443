define("front/routes/expedition-exchanges/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function (params) {
        return this.store.find("expedition-exchange", params.exex_id);
      },

      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("exex", model);
      },

      deactivate: function () {
        this.controllerFor("expedition-exchanges.edit").get("model").rollback();
      }
    });
  });