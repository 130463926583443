define("front/models/notification", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var Notification = DS.Model.extend({
      description: attr(),
      date: attr(),
      title: attr(),
      seen: attr(),
      personId: attr(),
      type: attr(),
      pevn_id: attr(),

      isTrans: (function () {
        if (this.get("type") === "1") {
          return true;
        }

        return false;
      }).property("type"),

      isTransCanc: (function () {
        if (this.get("type") === "2") {
          return true;
        }

        return false;
      }).property("type")
    });

    __exports__["default"] = Notification;
  });