define("front/models/appointment", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      title: attr(),
      comments: attr(),
      start: attr(),
      end: attr(),
      pending: attr(),
      canceled: attr(),
      firstContact: attr("boolean"),
      user_id: attr(),
      unit_id: attr(),
      unit_name: attr(),
      creator: attr(),
      userConcern: attr(),
      userFullName: attr(),
      person: DS.belongsTo("person"),
      color: (function () {
        if (this.get("canceled") === "1") {
          return "#d15b47";
        }

        if (this.get("canceled") === "1") {}
      }).property("canceled")
    });
  });