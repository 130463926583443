define("front/controllers/persons/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({


      editMode: false,
      flashMessage: null,
      flashMessageClass: null,

      actions: {
        edit: function (id) {
          this.set("editMode", true);
          this.transitionTo("person.edit", id);
        },

        "delete": function () {
          $("#myModal").modal("hide");
        }
      }

    });
  });