define("front/controllers/medical-record/delete-modal", 
  ["ember-notify","front/config/environment","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var ENV = __dependency2__["default"];

    __exports__["default"] = Ember.Controller.extend({


      actions: {
        deleteMedicalRecord: function () {
          var _this = this;
          var self = this;

          var medicalRecord = this.get("content.medicalRecord");

          $.ajax({
            url: ENV.APP.backendUrl + "/medicalRecords/" + medicalRecord.get("id"),
            type: "DELETE",
            contentType: "application/json",
            dataType: "json"
          }).then(function (response) {
            $(".modal").modal("hide");
            Notify.success("Η διαγραφή έγινε με επιτυχία!");
            self.send("reloadPerson");
            _this.send("routeRefresh");
            _this.transitionToRoute("medical-records.create");
          }, function () {
            medicalRecord.rollback();
            $(".modal").modal("hide");
            Notify.error("Η διαγραφή δεν πραγματοποιήθηκε!");
            _this.send("routeRefresh");
          });

        }
      }
    });
  });