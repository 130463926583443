define("front/routes/person/pdf-ascertainment", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        return this.store.find("person-history", params.pehi_id);
      },
      setupController: function (controller, model) {
        controller.set("personHistory", model);
      }
    });
  });