define("front/models/user", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var User = DS.Model.extend({
      name: attr(),
      username: attr(),
      firstname: attr(),
      lastname: attr(),
      program: DS.belongsTo("program"),
      units: DS.hasMany("unit"),
      permissions: DS.hasMany("permission"),
      roles: DS.hasMany("role"),
      isAdmin: attr("boolean")
    });



    __exports__["default"] = User;
  });