define("front/serializers/ithaki_helpline", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = DS.RESTSerializer.extend(DS.EmbeddedRecordsMixin, {
      attrs: {
        caller_city: { embedded: "always" },
        caller_region: { embedded: "always" },
        person_city: { embedded: "always" },
        person_region: { embedded: "always" },
        person_citizenship: { embedded: "always" },
        person_substance: { embedded: "always" },
        person_psycho: { embedded: "always" }
      }
    });
  });