define("front/routes/person-groups", 
  ["ember","simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    var AuthenticatedRouteMixin = __dependency2__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function () {
        var currentUserId = this.controllerFor("dashboard").get("controllers.application.currentUserId");
        var selectedUnit = this.controllerFor("application").get("selectedUnit");

        if (selectedUnit.id) {
          return this.store.find("person_group", { user_id: currentUserId, unit_id: selectedUnit.id });
        }
      }
    });
  });