define("front/routes/helplines/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      setupController: function (controller, model) {
        this._super(controller, model);
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));

        this.store.find("region", { helpline: 1 }).then(function (response) {
          controller.set("regions", response);
          controller.set("region", response.filterBy("id", "52").get("firstObject"));
        });

        this.store.find("user", { unit_id: this.controllerFor("application").get("selectedUnit.id") }).then(function (response) {
          controller.set("consultants", response);
        });

        controller.set("hideRadio", false);
        controller.set("call_request", 0);

        controller.set("player_gender", 9);
        controller.set("player_age", "");
        controller.set("player_agent", 9);
        controller.set("relative_agent", null);
        controller.set("caller_gender", 9);
        controller.set("caller_relation", 0);
        controller.set("caller_age", "");
        controller.set("player_status", 9);
        controller.set("player_education", 9);
        controller.set("player_request", 9);
        controller.set("game_internet", 9);
        controller.set("game_opap", 9);
        controller.set("game_club", 9);
        controller.set("game_vlt", 9);
        controller.set("game_illegal", 9);
        controller.set("kind_sport_bets", 9);
        controller.set("kind_casino", 9);
        controller.set("kind_kino", 9);
        controller.set("kind_joker", 9);
        controller.set("kind_lotto", 9);
        controller.set("kind_extra5", 9);
        controller.set("kind_super3", 9);
        controller.set("kind_machines", 9);
        controller.set("kind_cards", 9);
        controller.set("kind_dice", 9);
        controller.set("kind_stock_exchange", 9);
        controller.set("kind_vlt", 9);
        controller.set("kind_animal_bets", 9);
        controller.set("kind_scrats", 9);
        controller.set("kind_other", 9);
        controller.set("first", 9);
        controller.set("month_frequency", 9);
        controller.set("date", "");
        controller.set("duration", "");
        controller.set("months_from_first", "");
        controller.set("weekly_amount", 9);
        controller.set("type", 1);
        controller.set("transfer", 2);
        controller.set("summary", "");
      },

      deactivate: function () {
        var controller = this.controllerFor("helplines.new");

        controller.set("hideRadio", false);
        controller.set("call_request", 0);

        controller.set("player_gender", 9);
        controller.set("player_age", "");
        controller.set("player_agent", 9);
        controller.set("relative_agent", null);
        controller.set("caller_relation", 0);
        controller.set("caller_gender", 9);
        controller.set("caller_age", "");
        controller.set("player_status", 9);
        controller.set("player_education", 9);
        controller.set("player_request", 9);
        controller.set("game_internet", 9);
        controller.set("game_opap", 9);
        controller.set("game_club", 9);
        controller.set("game_vlt", 9);
        controller.set("game_illegal", 9);
        controller.set("kind_sport_bets", 9);
        controller.set("kind_casino", 9);
        controller.set("kind_kino", 9);
        controller.set("kind_joker", 9);
        controller.set("kind_lotto", 9);
        controller.set("kind_extra5", 9);
        controller.set("kind_super3", 9);
        controller.set("kind_machines", 9);
        controller.set("kind_cards", 9);
        controller.set("kind_dice", 9);
        controller.set("kind_stock_exchange", 9);
        controller.set("kind_scrats", 9);
        controller.set("kind_vlt", 9);
        controller.set("kind_animal_bets", 9);
        controller.set("kind_other", 9);
        controller.set("first", 9);
        controller.set("month_frequency", 9);
        controller.set("date", "");
        controller.set("duration", "");
        controller.set("months_from_first", "");
        controller.set("weekly_amount", 9);
        controller.set("type", 1);
        controller.set("transfer", 2);
        controller.set("summary", "");
      }
    });
  });