define("front/models/form_parameter", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    var FormParameter = DS.Model.extend({

      numbering: attr(),
      question: DS.belongsTo("question"),
      form: DS.belongsTo("form"),
      formGroup: DS.belongsTo("formGroup"),
      group_id: attr(),
      nestLvl: attr(),
      sn: attr(),
      parent_id: attr()

    });

    __exports__["default"] = FormParameter;
  });