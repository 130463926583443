define("front/routes/notifications/index", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var selectedUnitId = this.controllerFor("application").get("selectedUnit.id");

        return this.store.find("notification", { unit_id: selectedUnitId });
      }
    });
  });