define("front/controllers/physfiles/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["physfiles/index"],

      getName: (function () {
        var self = this;

        this.store.find("Physfile", this.get("content")).then(function (physfile) {
          self.set("physfile_code", physfile.get("code"));
        });
      }).observes("content"),

      physfile_code: null,

      actions: {
        deletePhysfile: function () {
          var self = this;
          var physfileId = this.get("content");

          this.store.find("physfile", physfileId).then(function (physfile) {
            physfile.destroyRecord().then(function () {
              self.toggleProperty("controllers.physfiles/index.doReRender");

              $(".modal").modal("hide");
              Notify.success("Η διαγραφή έγινε με επιτυχία!");
              self.send("reloadPerson");
            })["catch"](function (response) {
              physfile.rollback();
              //self.send('removeModal');
              $(".modal").modal("hide");
              Notify.error("Η διαγραφή του Φυσ. Φακέλου είναι αδύνατη!");
            });
          });
        }
      }


    });
  });