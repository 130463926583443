define("front/initializers/simple-auth-config", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = {
      name: "simple-auth-config",
      before: "simple-auth",
      initialize: function (container) {
        window.ENV = window.ENV || {};
        window.ENV["simple-auth"] = {
          authorizer: "simple-auth-authorizer:oauth2-bearer",
          //stelnei bearer mono sta hosts tis listas
          crossOriginWhitelist: [ENV.APP.hostName]
        };

        window.ENV["simple-auth-oauth2"] = {
          serverTokenEndpoint: ENV.APP.backendUrl + "/auth",
          refreshAccessTokens: false
        };
      }
    };
  });