define("front/views/add-person-grp-btn", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      tagName: "button",
      classNames: ["btn", "btn-primary"],
      click: function (e) {
        //$("#person-groups").css("background","#666!important");
        this.transitionTo("persons-group.new");
      }
    });
  });