define("front/controllers/expedition-approaches/new", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend(Ember.Validations.Mixin, {
      canValidate: false,

      validations: {
        region: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Περιοχή." }
        },

        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        },

        total_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        new_persons: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        male: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        female: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        trans: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        transfer: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        homeless: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        immigrant: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        prostitution: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        xen: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        okana: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        },

        street: {
          numericality: { "if": "canValidate", message: "Παρακαλώ μόνο αριθμούς." }
        }
      },

      actions: {
        save: function () {
          this.set("canValidate", true);
          this.set("showSpinner", true);
          var self = this;

          var new_expedition_approach = this.store.createRecord("expedition-approach", {
            date: moment(this.get("date"), "DD/MM/YYYY").format("YYYY-MM-DD HH:mm:ss"),
            region: this.get("region"),
            kind: this.get("kind"),
            comments: this.get("comments"),
            total_persons: this.get("total_persons"),
            new_persons: this.get("new_persons"),
            homeless: this.get("homeless"),
            male: this.get("male"),
            female: this.get("female"),
            trans: this.get("trans"),
            transfer: this.get("transfer"),
            immigrant: this.get("immigrant"),
            prostitution: this.get("prostitution"),
            xen: this.get("xen"),
            okana: this.get("okana"),
            street: this.get("street"),
            unit_id: this.get("currentUnit.id")
          });

          this.validate().then(function () {
            new_expedition_approach.save().then(function () {
              self.transitionToRoute("expedition-approaches.index");
              self.send("reloadExpeditions");
              Notify.success("Η Αποθήκευση έγινε με επιτυχία", {
                closeAfter: 10000
              });
            }, function (error) {
              self.set("canValidate", false);
              new_expedition_approach.deleteRecord();
              self.set("showSpinner", false);

              Notify.alert(error.responseJSON.message, {
                closeAfter: 10000
              });
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            new_expedition_approach.deleteRecord();
          });
        }
      }
    });
  });