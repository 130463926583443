define("front/templates/person/index", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push(" \n<div class=\"widget-box\">\n\n	<div class=\"widget-header widget-header-small\">\n		<h4 class=\"widget-title blue smaller\">\n			Επιλογή κατηγορίας / Αναζήτηση\n		</h4>\n	</div>\n\n	<div class=\"widget-body\">\n		<div class=\"widget-main padding-8 row\">\n			<div class=\"col-sm-4\">    \n				");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("text"),
        'action': ("getElementHistory"),
        'onEvent': ("keypress"),
        'value': ("searchString"),
        'class': ("form-control"),
        'placeholder': ("Αναζήτηση")
      },hashTypes:{'type': "STRING",'action': "STRING",'onEvent': "STRING",'value': "ID",'class': "STRING",'placeholder': "STRING"},hashContexts:{'type': depth0,'action': depth0,'onEvent': depth0,'value': depth0,'class': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n			</div>\n\n			<div class=\"col-sm-4\">\n				");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("categories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Διαλέξτε κατηγορία:"),
        'selectionBinding': ("selectedCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n			</div>\n\n			<div class=\"col-sm-4\">\n				");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "select", {hash:{
        'content': ("subcategories"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'prompt': ("Διαλέξτε υποκατηγορία:"),
        'selectionBinding': ("selectedSubCategory")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n			</div>	\n		</div>\n	</div>\n</div>\n\n<div class=\"space-20\"></div>\n<div class=\"widget-box transparent\" id=\"personHistoryElements\">\n	<div class=\"widget-header widget-header-small\">\n\n		<button type=\"button\" class=\"btn btn-sm btn-purple\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "printElements", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n			<i class=\"ace-icon fa fa-print\"></i>\n			Εκτύπωση Στοιχείων\n		</button>\n\n		<div class=\"widget-toolbar no-border\">\n\n			<div data-toggle=\"buttons\" class=\"btn-group btn-overlap btn-corner\">\n				<label class=\"btn btn-sm btn-primary active\">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("documentsChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Έγγραφα\n				</label>\n				<label class=\"btn btn-sm btn-primary active\">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("eventsChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Συμβάντα\n				</label>\n				<label class=\"btn btn-sm btn-primary \">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("groupsChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Υποενότητες\n				</label>\n				<label class=\"btn btn-sm btn-primary active\">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("formsChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Φόρμες\n				</label>\n				<label class=\"btn btn-sm btn-primary active\">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("paramsChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Παράμετροι\n				</label>\n\n				<label class=\"btn btn-sm btn-primary \">\n					");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'checked': ("presencesChecked")
      },hashTypes:{'type': "STRING",'checked': "ID"},hashContexts:{'type': depth0,'checked': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push(" Παρουσίες\n				</label>\n			</div>\n		</div>	  \n	</div>\n	<div class=\"widget-body\">\n		<div class=\"widget-main no-padding\" id=\"element-scrolling\">\n			<div class=\"person-content\"> \n				");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "element-history-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("		\n			</div>\n		</div>			\n	</div>\n</div>\n");
      return buffer;
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n	<div class=\"page-header\">\n		<h1>");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("<small><i class=\"ace-icon fa fa-angle-double-right\"></i> Στοιχεία φακέλου</small></h1>\n	</div>\n\n	<div class=\"space-10\"></div>\n	<div class=\"alert alert-danger\">\n		Ο χρήστης δεν έχει δικαίωμα να δεί τα δεδομένα\n	</div>\n");
      return buffer;
      }

      stack1 = helpers['if'].call(depth0, "canViewElements", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });