define("front/routes/form-parameter/edit", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        var formParameter = this.modelFor("formParameter");

        return Ember.RSVP.hash({
          formParameter: formParameter,
          groups: this.store.find("formGroup", {
            form_id: formParameter.get("form.id")
          })
        });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        this.controller.set("numbering", model.formParameter.get("numbering"));
        this.controller.set("sn", model.formParameter.get("sn"));
        this.controller.set("selectedGroup", model.formParameter.get("formGroup"));

        if (Ember.isEmpty(model.formParameter.get("parent_id"))) {
          this.controller.set("subParameterSwitch", "0");
        } else {
          var self = this;
          this.controller.set("subParameterSwitch", "1");

          this.store.find("formParameter", model.formParameter.get("parent_id")).then(function (response) {
            self.controller.set("subParameterInValue", response.get("question.text"));
            self.controller.set("selectedSubParameterId", model.formParameter.get("parent_id"));
          });
        }
      }
    });
  });