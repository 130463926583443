define("front/controllers/form/index", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.ObjectController.extend({
      actions: {
        downloadForm: function () {
          var data;
          var self = this;
          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/formFile?&form_name=" + self.get("model.filename"), {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        }
      }
    });
  });