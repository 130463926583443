define("front/controllers/appointments/edit-modal", 
  ["ember-notify","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.ObjectController.extend(UserMixin, Ember.Validations.Mixin, {
      needs: ["application"],
      canValidate: false,
      showSpinner: false,
      hasMember: false,


      //canValidate: false,
      checked: (function () {
        if (!this.get("hasMember")) {
          this.set("person_id", null);
          this.set("filteredMembers", null);
        }
      }).observes("hasMember"),

      validations: {
        title: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Τίτλο." } } },
      is_canceled: (function () {
        if (this.get("content.appointment.canceled") === "1") {
          return true;
        }

        return false;
      }).property("content.appointment.canceled"),
      actions: {
        search: function () {
          var self = this;

          this.set("showSpinner", true);
          this.store.find("person", { searchString: this.get("searchString") }).then(function (persons) {
            self.set("persons", persons);
            self.set("searchString", null);
            self.set("showSpinner", false);
          }, function (reason) {
            self.set("persons", null);
            self.set("searchString", null);
            self.set("showSpinner", false);
          });
        },

        selectMember: function (id) {
          var self = this;

          this.store.find("person", id).then(function (person) {
            self.set("appointment.person", person);
          });
          return false;
        },

        closeModal: function () {
          this.get("content.appointment").rollback();
          this.set("hasMember", false);
          this.set("firstContact", null);
          this.send("removeModal");
        },
        goToProfile: function (id) {
          $(".modal").modal("hide");
          this.transitionToRoute("person", id);
        },
        cancelAppointment: function () {
          var appointment = this.get("content.appointment");
          appointment.set("start", moment(appointment.get("start")).format("DD/MM/YYYY HH:mm:ss"));
          appointment.set("end", moment(appointment.get("end")).format("DD/MM/YYYY HH:mm:ss"));
          appointment.set("canceled", 1);
          appointment.save().then(function (response) {
            $("#appointments").fullCalendar("refetchEvents");
            $(".modal").modal("hide");
          }, function (error) {});
        },

        revertCancelAppointment: function () {
          var appointment = this.get("content.appointment");
          appointment.set("start", moment(appointment.get("start")).format("DD/MM/YYYY HH:mm:ss"));
          appointment.set("end", moment(appointment.get("end")).format("DD/MM/YYYY HH:mm:ss"));
          appointment.set("canceled", 0);

          appointment.save().then(function (response) {
            $("#appointments").fullCalendar("refetchEvents");
            $(".modal").modal("hide");
          }, function (error) {});
        },

        deleteAppointment: function () {
          var self = this;
          var appointment = this.get("content.appointment");

          appointment.destroyRecord().then(function () {
            $("#appointments").fullCalendar("refetchEvents");
            $(".modal").modal("hide");
            Notify.success("Η διαγραφή έγινε με επιτυχία!");
          })["catch"](function (response) {
            appointment.rollback();
            $("#appointments").fullCalendar("refetchEvents");
            $(".modal").modal("hide");
            Notify.error("Η διαγραφή είναι αδύνατη!");
          });
        },

        saveAppointment: function () {
          var appointment = this.get("content.appointment");
          appointment.set("start", moment(appointment.get("start")).format("DD/MM/YYYY HH:mm:ss"));
          appointment.set("end", moment(appointment.get("end")).format("DD/MM/YYYY HH:mm:ss"));
          if (this.get("person")) {
            appointment.set("person", this.get("person"));
            appointment.set("firstContact", this.get("firstContact"));
          }



          var self = this;

          this.validate().then(function () {
            appointment.save().then(function (response) {
              self.set("canValidate", false);
              self.set("showSpinner", false);

              $("#appointments").fullCalendar("refetchEvents");
              $(".modal").modal("hide");
              self.set("hasMember", false);


            }, function (error) {
              $(".modal").modal("hide");
              self.set("hasMember", false);
              Notify.alert("Η ενέργεια δεν παραγματοποιήθηκε", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
            self.set("hasMember", false);
            //appointment.deleteRecord();
          });
        }
      }
    });
  });