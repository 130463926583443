define("front/templates/reports", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      
      data.buffer.push("\n								Σύνολα Κέντρων\n							");
      }

    function program3(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.persons-synola", options) : helperMissing.call(depth0, "link-to", "reports.persons-synola", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n							");
      return buffer;
      }
    function program4(depth0,data) {
      
      
      data.buffer.push("\n								Σύνολο Μελών\n							");
      }

    function program6(depth0,data) {
      
      
      data.buffer.push("\n								Ονομαστική Εκτύπωση Υπηρεσίας\n							");
      }

    function program8(depth0,data) {
      
      
      data.buffer.push("\n								Αναφορά Παρουσιολογίου (* Το ημερομηνιακό εύρος να μην υπερβαίνει τις 15 μέρες *)\n							");
      }

    function program10(depth0,data) {
      
      
      data.buffer.push("\n								Εκτύπωση Συμβάντων\n							");
      }

    function program12(depth0,data) {
      
      
      data.buffer.push("\n								Εκτύπωση Υπηρεσίας\n							");
      }

    function program14(depth0,data) {
      
      
      data.buffer.push("\n								Ιστορικό Χρόνου Παραμονής\n							");
      }

    function program16(depth0,data) {
      
      
      data.buffer.push("\n								Ημέρες παραμονής / Μέση δύναμη\n							");
      }

    function program18(depth0,data) {
      
      
      data.buffer.push("\n								Νέοι Ωφελούμενοι / Κέντρο\n							");
      }

      data.buffer.push("<div class=\"col-xs-12\">\n	<div class=\"widget-box\">\n		<div class=\"widget-header\">\n			<h3 class=\"widget-title smaller\">Έκδοση Στατιστικών / Αναφορών</h3>\n		</div>\n		<div class=\"widget-body\">\n			<div class=\"widget-main\">\n\n				<div class=\"row\">\n					<div class=\"col-xs-12\">\n						<div class=\"list-group\">\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.prokeka", options) : helperMissing.call(depth0, "link-to", "reports.prokeka", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.unit-general-stats", options) : helperMissing.call(depth0, "link-to", "reports.unit-general-stats", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(8, program8, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.unit-presences-stats", options) : helperMissing.call(depth0, "link-to", "reports.unit-presences-stats", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(10, program10, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.events", options) : helperMissing.call(depth0, "link-to", "reports.events", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(12, program12, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.unit", options) : helperMissing.call(depth0, "link-to", "reports.unit", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(14, program14, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.istoriko-xronou", options) : helperMissing.call(depth0, "link-to", "reports.istoriko-xronou", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(16, program16, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.imeres-paramonis", options) : helperMissing.call(depth0, "link-to", "reports.imeres-paramonis", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n							");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("list-group-item")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},inverse:self.noop,fn:self.program(18, program18, data),contexts:[depth0],types:["STRING"],data:data},helper ? helper.call(depth0, "reports.new-persons", options) : helperMissing.call(depth0, "link-to", "reports.new-persons", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n						</div>\n					</div>\n				</div>\n\n			</div>\n		</div>\n	</div>\n</div>\n\n<div class=\"col-xs-12\">\n	<div class=\"space-14\"></div>\n</div>\n\n");
      stack1 = helpers._triageMustache.call(depth0, "outlet", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      
    });
  });