define("front/controllers/document/edit", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];

    __exports__["default"] = Ember.ObjectController.extend(Ember.Validations.Mixin, {

      needs: ["application"],

      showSpinner: false,
      canValidate: false,
      user: null,

      personID: (function () {
        return this.get("model.person.id");
      }).property("model.person.id"),

      validations: {
        title: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Τίτλο." }
        },
        selectedCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Κατηγορία." }
        },
        selectedSubCategory: {
          presence: { "if": "canValidate", message: "Παρακαλώ επιλέξτε Υποκατηγορία." }
        },
        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημ/νία." }
        }
      },

      readonly: (function () {
        return !(this.get("user.isAdmin") || this.get("user.id") === this.get("model.document.user_create"));
      }).property("model.document", "user"),

      getSubCategory: (function () {
        var selectedCategoryId = this.get("selectedCategory.id");
        var mycategory = this.store.getById("category", selectedCategoryId);

        if (mycategory !== null) {
          this.set("subcategories", mycategory.get("subcategories"));
        } else {
          this.set("subcategories", "");
        }
      }).observes("selectedCategory"),

      actions: {
        downloadFile: function () {
          var data;
          var self = this;
          this.send("showModal", "print-modals/print-wait");

          $.fileDownload(ENV.APP.backendUrl + "/personFile?pers_id=" + self.get("personID") + "&doc_name=" + self.get("document.filename"), {
            httpMethod: "GET",
            data: {
              Authorization: "Bearer " + this.session.get("access_token") }
          }).done(function () {
            $(".modal").modal("hide");
          }).fail(function () {
            $(".modal").modal("hide");
          });
        },

        edit: function () {
          this.set("canValidate", true);

          var document = this.get("model.document");

          this.set("showSpinner", true);

          var self = this;

          this.set("title", this.get("model.document.title"));
          this.set("date", this.get("model.document.date"));
          this.set("model.document.subcategory", this.get("selectedSubCategory"));

          this.validate().then(function () {
            document.save().then(function () {
              self.set("canValidate", false);

              self.set("date", "");
              self.set("title", "");
              self.set("remarks", "");
              self.set("selectedCategory", "");
              self.set("selectedSubCategory", "");

              self.set("showSpinner", false);
              self.transitionToRoute("person", self.get("personID"));
            }, function (response) {
              self.set("canValidate", false);

              self.set("showSpinner", false);
            });
          }, function () {
            self.set("canValidate", false);
            self.set("showSpinner", false);
          });

        }
      }
    });
  });