define("front/routes/person/simple-merge", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {
      model: function (params) {
        /*return Ember.RSVP.hash({
        	person: this.modelFor('person').person,
        	persons: this.store.find('person')
        });*/
        return this.modelFor("person").person;
      },
      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("person", model);

        controller.set("isAdmin", this.controllerFor("application").get("currentUser.isAdmin"));
      },

      deactivate: function () {
        this.set("controller.selectedPerson", null);
        this.set("controller.persons", null);
      }
    });
  });