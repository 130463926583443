define("front/controllers/expedition-exchanges/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.ArrayController.extend({
      actions: {
        goToExpd: function (id) {
          this.transitionToRoute("expedition-exchanges.edit", id);
        }
      }
    });
  });