define("front/helpers/date-range", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Handlebars.makeBoundHelper(function (start, end) {
      var dateRange = "";
      var s = moment(start).format("DD/MM/YYYY") === "01/01/1900" ? "ΑΓΝΩΣΤΟ" : moment(start).format("DD/MM/YYYY");

      if (end) {
        var e = moment(end).format("DD/MM/YYYY") === "01/01/1900" ? "ΑΓΝΩΣΤΟ" : moment(end).format("DD/MM/YYYY");
        dateRange = s + " - " + e;
      } else {
        dateRange = s;
      }
      return dateRange;
    });
  });