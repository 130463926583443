define("front/templates/manage-events", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n	<div class=\"row\">\n  <div class=\"col-xs-12 col-md-12\">\n    <div class=\"widget-box\">\n      <div class=\"widget-header\">\n        <h4 class=\"widget-title smaller\">Ιστορικό</h4>\n      </div>\n      <div class=\"widget-body\">\n        <div class=\"widget-main\">\n          <div class=\"row\">\n            <div class=\"col-xs-12 col-sm-10 col-sm-offset-1\">      \n              ");
      stack1 = helpers.each.call(depth0, "pehi", "in", "pehi_events", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n");
      stack1 = helpers._triageMustache.call(depth0, "person", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n              <div class=\"timeline-container timeline-style2\">\n                <span class=\"timeline-label2\">\n                  <b>");
      stack1 = helpers._triageMustache.call(depth0, "pehi.program_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" / ");
      stack1 = helpers._triageMustache.call(depth0, "pehi.structure_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" / ");
      stack1 = helpers._triageMustache.call(depth0, "pehi.unit_name", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(", ");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "pehi.startDate", options) : helperMissing.call(depth0, "format-date", "pehi.startDate", options))));
      data.buffer.push("\n                   ");
      stack1 = helpers['if'].call(depth0, "pehi.endDate", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(3, program3, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" #");
      stack1 = helpers._triageMustache.call(depth0, "pehi.status", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                 </b>\n                </span>\n              \n                ");
      stack1 = helpers['if'].call(depth0, "pehi.historyevents", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(5, program5, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n              </div>\n              ");
      return buffer;
      }
    function program3(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n                    - ");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "pehi.endDate", options) : helperMissing.call(depth0, "format-date", "pehi.endDate", options))));
      data.buffer.push("\n                   ");
      return buffer;
      }

    function program5(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n                <div class=\"timeline-items\">\n                ");
      stack1 = helpers.each.call(depth0, "event", "in", "pehi.historyevents", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(6, program6, data),contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                </div>\n                ");
      return buffer;
      }
    function program6(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                  <div class=\"timeline-item clearfix\">\n                    <div class=\"timeline-info\">\n                      <span class=\"timeline-date\">");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "event.date", options) : helperMissing.call(depth0, "format-date", "event.date", options))));
      data.buffer.push("</span>\n\n                      <i class=\"timeline-indicator btn btn-info no-hover\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "showEvent", "person.id", "event.id", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0,depth0,depth0],types:["ID","ID","ID"],data:data})));
      data.buffer.push("></i>\n                    </div>\n\n                    <div class=\"widget-box transparent\">\n                      <div class=\"widget-body\">\n                        <div class=\"widget-main no-padding\">\n                          <span class=\"bigger-110\">\n                            <a href=\"#\" class=\"purple bolder\"></a>\n                            ");
      stack1 = helpers._triageMustache.call(depth0, "event.comments", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                          </span>\n                        </div>\n                      </div>\n                    </div>\n                  </div>\n                ");
      return buffer;
      }

    function program8(depth0,data) {
      
      
      data.buffer.push("\n	<div class=\"alert alert-danger\">\n		Ο χρήστης δεν έχει τα απαραίτητα δικαιώματα να πραγματοποιήσει συγχώνευση φακέλων<br>\n	</div>\n");
      }

      stack1 = helpers['if'].call(depth0, "isAdministrator", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(8, program8, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });