define("front/templates/appointments/new-modal", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n	\n	<div class=\"form-group center\">\n		<h3 class=\"blue\">");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n		<h5 class=\"green\">");
      stack1 = helpers._triageMustache.call(depth0, "content.start", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      stack1 = helpers._triageMustache.call(depth0, "content.end", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</h5>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"isMember\" class=\"col-sm-3 control-label\">Μέλος</label>\n		<div class=\"col-sm-2\">\n			");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("checkbox"),
        'name': ("hasMember"),
        'id': ("hasMember"),
        'checked': ("hasMember"),
        'classNames': ("form-control")
      },hashTypes:{'type': "STRING",'name': "STRING",'id': "STRING",'checked': "ID",'classNames': "STRING"},hashContexts:{'type': depth0,'name': depth0,'id': depth0,'checked': depth0,'classNames': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n		</div>\n	</div>\n	\n	");
      stack1 = helpers['if'].call(depth0, "hasMember", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n\n	<div class=\"form-group\">\n		<label for=\"title\" class=\"col-sm-3 control-label\">Τίτλος</label>\n		<div class=\"col-sm-6\">\n			");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'classNames': ("form-control"),
        'value': ("title"),
        'id': ("title")
      },hashTypes:{'classNames': "STRING",'value': "ID",'id': "STRING"},hashContexts:{'classNames': depth0,'value': depth0,'id': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n		</div>\n		<div class=\"col-sm-9 col-sm-offset-3\">\n			");
      stack1 = helpers['if'].call(depth0, "errors.title.firstObject", {hash:{},hashTypes:{},hashContexts:{},inverse:self.noop,fn:self.program(4, program4, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"document_date\" class=\"col-sm-3 control-label\">Σχόλια</label>\n		<div class=\"col-sm-6\">\n			<div class=\"input-group\">\n				");
      data.buffer.push(escapeExpression((helper = helpers.textarea || (depth0 && depth0.textarea),options={hash:{
        'value': ("comments"),
        'class': ("form-control in-modal")
      },hashTypes:{'value': "ID",'class': "STRING"},hashContexts:{'value': depth0,'class': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "textarea", options))));
      data.buffer.push("\n			</div>\n		</div>\n		\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"document_date\" class=\"col-sm-3 control-label\">Εργαζόμενος</label>\n		<div class=\"col-sm-6\">\n		\n			");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("content.users"),
        'class': ("form-control"),
        'optionLabelPath': ("content.name"),
        'optionValuePath': ("content.id"),
        'selectionBinding': ("selectedUser")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n			\n		</div>\n		\n	</div>\n");
      return buffer;
      }
    function program2(depth0,data) {
      
      var buffer = '', helper, options;
      data.buffer.push("\n	<div class=\"form-group\">\n		<label for=\"isMember\" class=\"col-sm-3 control-label\">Επιλογή μέλους</label>\n		<div class=\"col-sm-11\">\n			<div class=\"input-group\">\n				");
      data.buffer.push(escapeExpression((helper = helpers.input || (depth0 && depth0.input),options={hash:{
        'type': ("text"),
        'class': ("form-control"),
        'value': ("searchString"),
        'action': ("search"),
        'placeholder': ("Αναζήτηση")
      },hashTypes:{'type': "STRING",'class': "STRING",'value': "ID",'action': "STRING",'placeholder': "STRING"},hashContexts:{'type': depth0,'class': depth0,'value': depth0,'action': depth0,'placeholder': depth0},contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "input", options))));
      data.buffer.push("\n				<span class=\"input-group-btn\">\n					<button class=\"btn btn-info btn-sm\" type=\"button\" title=\"Αναζήτηση\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "search", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">\n						<span class=\"glyphicon glyphicon-search\"></span>\n					</button>\n				</span>\n			</div><!-- /input-group -->\n		</div>\n						\n			<div class=\"col-xs-1\">\n				<div ");
      data.buffer.push(escapeExpression(helpers['bind-attr'].call(depth0, {hash:{
        'class': ("showSpinner:loading-spinner")
      },hashTypes:{'class': "STRING"},hashContexts:{'class': depth0},contexts:[],types:[],data:data})));
      data.buffer.push("></div>\n			</div>\n		</div>\n\n		<div class=\"form-group\">\n			<div class=\"col-sm-12 \">\n				");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "member-table", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push("\n			</div>\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"document_date\" class=\"col-sm-3 control-label\">Πρώτη συνεδρία</label>\n		<div class=\"col-sm-2\">\n			");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Checkbox", {hash:{
        'checkedBinding': ("firstContact"),
        'classNames': ("form-control")
      },hashTypes:{'checkedBinding': "STRING",'classNames': "STRING"},hashContexts:{'checkedBinding': depth0,'classNames': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n		</div>\n	</div>\n	");
      return buffer;
      }

    function program4(depth0,data) {
      
      var buffer = '', stack1;
      data.buffer.push("\n			<div class=\"alert alert-danger validation-alert\" role=\"alert\"><i class=\"fa fa-exclamation-triangle\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "errors.title.firstObject", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</div>\n			");
      return buffer;
      }

      stack1 = (helper = helpers['my-modal'] || (depth0 && depth0['my-modal']),options={hash:{
        'title': ("Προγραμματισμος"),
        'okText': ("Αποθήκευση"),
        'closeText': ("Κλείσιμο"),
        'ok': ("saveAppointment"),
        'close': ("closeModal")
      },hashTypes:{'title': "STRING",'okText': "STRING",'closeText': "STRING",'ok': "STRING",'close': "STRING"},hashContexts:{'title': depth0,'okText': depth0,'closeText': depth0,'ok': depth0,'close': depth0},inverse:self.noop,fn:self.program(1, program1, data),contexts:[],types:[],data:data},helper ? helper.call(depth0, options) : helperMissing.call(depth0, "my-modal", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      else { data.buffer.push(''); }
      
    });
  });