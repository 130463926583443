define("front/models/participation", 
  ["exports"],
  function(__exports__) {
    "use strict";

    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      presence: attr(),
      persId: attr(),
      pres_id: attr(),
      persLastName: attr(),
      persFirstName: attr(),
      persKetheaCode: attr(),
      unit_id: attr(),
      date: attr(),
      persFullName: (function () {
        var firstName = this.get("persFirstName");
        var lastName = this.get("persLastName");

        return lastName + " " + firstName;
      }).property("persFirstName", "persFirstName"),
      presenceBoolean: (function () {
        if (this.get("presence") === "1") {
          return true;
        } else {
          return false;
        }
      }).property("presence") });
  });