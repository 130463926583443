define("front/views/formpar-table", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.View.extend({
      classNames: ["table", "table-striped", "table-bordered", "table-hover", "dataTable"],
      tagName: "table",

      viewRerender: (function () {
        $(".dt-sides").remove();
        this.rerender();
      }).observes("controller.doReRender"),

      didInsertElement: function () {
        var self = this;

        this.$().on("click", ".showFormParameter", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("gotoShowFormParam", id);
        });

        this.$().on("click", ".editFormParameter", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("gotoEditFormParam", id);
        });

        this.$().on("click", ".deleteFormParameter", function (event) {
          event.preventDefault();
          var id = $(this).attr("data-value");
          self.get("controller").send("showModal", "form-parameter/delete-modal", id);
        });

        var data;
        if (this.get("controller.model.formParams")) {
          data = this.get("controller.model.formParams").getEach("_data");
        }
        //console.log(data);
        this.$().dataTable({
          bProcessing: true,
          bAutoWidth: false,
          language: {
            sProcessing: "Επεξεργασία...",
            sLengthMenu: "Δείξε _MENU_ εγγραφές",
            sZeroRecords: "Δεν βρέθηκαν εγγραφές που να ταιριάζουν",
            sInfo: "Δείχνοντας _START_ εως _END_ από _TOTAL_ εγγραφές",
            sInfoEmpty: "Δείχνοντας 0 εως 0 από 0 εγγραφές",
            sInfoFiltered: "(φιλτραρισμένες από _MAX_ συνολικά εγγραφές)",
            sInfoPostFix: "",
            sSearch: "Αναζήτηση:",
            sUrl: "",
            oPaginate: {
              sFirst: "Πρώτη",
              sPrevious: "Προηγούμενη",
              sNext: "Επόμενη",
              sLast: "Τελευταία"
            }
          },
          aaData: data,
          iDisplayLength: 10,
          aaSorting: [],
          aoColumns: [{ sTitle: "Α/α", sWidth: "40px", mData: function (data, type, row) {
              if (data.sn) {
                return data.sn;
              } else {
                return null;
              }

            } }, { sTitle: "Παράμετρος", mData: "question._data.text" }, { sTitle: "Υποενότητα", sWidth: "100px", mData: function (data, type, row) {
              if (data.form_group) {
                return data.form_group.name;
              } else {
                return null;
              }

            } }, { sTitle: "Αρίθμηση", sWidth: "71px", mData: function (data, type, row) {
              if (data.numbering) {
                return data.numbering;
              } else {
                return null;
              }

            } }, {
            mdata: null,
            bSortable: false,
            sWidth: "50px",
            mRender: function (data, type, row) {
              return "<div class='action-buttons'><a class='blue showFormParameter' href='#' title='Εμφάνιση' data-value='" + row.id + "'><i class='ace-icon fa fa-search-plus bigger-130'></i></a><a class='green editFormParameter' href='#' title='Επεξεργασία' data-value='" + row.id + "'><i class='ace-icon fa fa-pencil bigger-130'></i></a><a class='red deleteFormParameter' href='#' title='Διαγραφή' data-value='" + row.id + "'><i class='ace-icon fa fa-trash-o bigger-130'></i></a></div>";
            }

          }]
        });
      }
    });
  });