define("front/templates/event/change-type", 
  ["ember","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Ember = __dependency1__["default"];
    __exports__["default"] = Ember.Handlebars.template(function anonymous(Handlebars,depth0,helpers,partials,data) {
    this.compilerInfo = [4,'>= 1.0.0'];
    helpers = this.merge(helpers, Ember.Handlebars.helpers); data = data || {};
      var buffer = '', stack1, helper, options, escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

    function program1(depth0,data) {
      
      var buffer = '', stack1, helper, options;
      data.buffer.push("\n                <form class=\"form-horizontal\" role=\"form\">\n                    <div class=\"form-group\">\n                        <label for=\"reason\" class=\"col-sm-2 control-label\">Αλλαγή σε τύπο:</label>\n                        <div class=\"col-sm-9\">\n                            ");
      data.buffer.push(escapeExpression(helpers.view.call(depth0, "Ember.Select", {hash:{
        'content': ("types"),
        'class': ("form-control"),
        'optionLabelPath': ("content.text"),
        'optionValuePath': ("content.type"),
        'prompt': ("Επιλογή Τύπου:"),
        'selectionBinding': ("selectedType")
      },hashTypes:{'content': "ID",'class': "STRING",'optionLabelPath': "STRING",'optionValuePath': "STRING",'prompt': "STRING",'selectionBinding': "STRING"},hashContexts:{'content': depth0,'class': depth0,'optionLabelPath': depth0,'optionValuePath': depth0,'prompt': depth0,'selectionBinding': depth0},contexts:[depth0],types:["ID"],data:data})));
      data.buffer.push("\n                        </div>\n                    </div>\n\n                    <div class=\"form-group\">\n                      <div class=\"col-sm-offset-2 col-sm-4\">\n\n                        <button class=\"btn btn-success\" ");
      data.buffer.push(escapeExpression(helpers.action.call(depth0, "change", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["STRING"],data:data})));
      data.buffer.push(">Αλλαγή</button>\n                        ");
      stack1 = (helper = helpers['link-to'] || (depth0 && depth0['link-to']),options={hash:{
        'class': ("btn btn-danger"),
        'activeClass': ("")
      },hashTypes:{'class': "STRING",'activeClass': "STRING"},hashContexts:{'class': depth0,'activeClass': depth0},inverse:self.noop,fn:self.program(2, program2, data),contexts:[depth0,depth0,depth0],types:["STRING","ID","ID"],data:data},helper ? helper.call(depth0, "event", "person.id", "event.id", options) : helperMissing.call(depth0, "link-to", "event", "person.id", "event.id", options));
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n                        \n                      </div>\n                    </div>\n                      \n                </form>\n                ");
      return buffer;
      }
    function program2(depth0,data) {
      
      
      data.buffer.push("\n                        Ακύρωση\n                        ");
      }

    function program4(depth0,data) {
      
      
      data.buffer.push("\n                <div class=\"alert alert-danger\">\n                    Ο χρήστης δεν έχει δικαίωμα να πραγματοποιήσει την ενέργεια αυτή\n                </div>\n                ");
      }

      data.buffer.push("<div class=\"row\">\n    <div class=\"col-sm-12 col-md-12 col-xs-12\">\n       \n        <div class=\"page-header\">\n            <h1>");
      stack1 = helpers._triageMustache.call(depth0, "title", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push(" - ");
      data.buffer.push(escapeExpression((helper = helpers['format-date'] || (depth0 && depth0['format-date']),options={hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data},helper ? helper.call(depth0, "event.date", options) : helperMissing.call(depth0, "format-date", "event.date", options))));
      data.buffer.push("\n                <small><i class=\"ace-icon fa fa-angle-double-right\"></i> ");
      stack1 = helpers._triageMustache.call(depth0, "person.fullName", {hash:{},hashTypes:{},hashContexts:{},contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("</small>\n            </h1>\n        </div>\n\n        <div class=\"widget-body\">\n            <div class=\"widget-main\">\n                ");
      stack1 = helpers['if'].call(depth0, "isSuperAdmin", {hash:{},hashTypes:{},hashContexts:{},inverse:self.program(4, program4, data),fn:self.program(1, program1, data),contexts:[depth0],types:["ID"],data:data});
      if(stack1 || stack1 === 0) { data.buffer.push(stack1); }
      data.buffer.push("\n            </div>\n        </div>\n    </div>\n</div>");
      return buffer;
      
    });
  });