define("front/controllers/parameters/index", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Controller.extend({

      doReRender: false,

      actions: {
        gotoParameter: function (id) {
          this.transitionTo("question", id);
        }
      }
    });
  });