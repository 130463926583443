define("front/controllers/person/pehi-admin", 
  ["front/config/environment","front/mixins/user","exports"],
  function(__dependency1__, __dependency2__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var UserMixin = __dependency2__["default"];

    __exports__["default"] = Ember.ArrayController.extend(UserMixin, {

      needs: ["application"] });
  });