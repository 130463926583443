define("front/helpers/format-date", 
  ["exports"],
  function(__exports__) {
    "use strict";
    __exports__["default"] = Ember.Handlebars.makeBoundHelper(function (date) {
      if (moment(date).format("DD/MM/YYYY") === "01/01/1900") {
        return "ΑΓΝΩΣΤΟ";
      }
      return moment(date).format("DD/MM/YYYY");
    });
  });