define("front/routes/dashboard", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var currentUserId = this.controllerFor("dashboard").get("controllers.application.currentUserId");
        var selectedUnit = this.controllerFor("application").get("selectedUnit");
        //alert(selectedUnit.id);
        if (selectedUnit.id) {
          return Ember.RSVP.hash({
            structure: this.store.find("structure", { unit_id: selectedUnit.id }),
            programs: this.store.find("program") });
        }
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        /* controller.set('firstName', '');
         controller.set('lastName', '');
         controller.set('motherName', '');
         controller.set('fatherName', '');
         controller.set('birthDate', '');
         controller.set('isAddict', false);
         controller.set('isFamily', false);
         controller.set('isOther', false);
         controller.set('code', '');
         controller.set('physfiles', '');
         controller.set('displayPhysfiles', '');
         controller.set('ketheaCode', '');
         controller.set('previousProgram', '');
         controller.set('searchResults', []);*/

        controller.set("currentUser", this.controllerFor("dashboard").get("controllers.application.currentUserId"));
        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
      }
    });
    //personGroups: this.store.find('personGroup',{user_id: currentUserId, unit_id: selectedUnit.id})
  });