define("front/controllers/person/personhistoryitem", 
  ["front/config/environment","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    __exports__["default"] = Ember.ObjectController.extend({

      isDoc: (function () {
        if (this.get("type_flag") === 2) {
          return true;
        } else {
          return false;
        }
      }).property("type_flag"),

      isEvent: (function () {
        if (this.get("type_flag") === 1) {
          return true;
        } else {
          return false;
        }
      }).property("type_flag")
    });
  });