define("front/controllers/appointments", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      start: null,
      end: null,
      obs: false,

      actions: {
        evns: function () {
          alert("ok");
          this.store.find("appointment", { start: this.get("start"), end: this.get("end") }).then(function (res) {
            return res;
          });
        }
      }
    });
  });