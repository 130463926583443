define("front/models/withdrawal", 
  ["exports"],
  function(__exports__) {
    "use strict";
    var attr = DS.attr;

    __exports__["default"] = DS.Model.extend({
      estimation: attr(),
      comments: attr(),
      reason_id: attr(),
      type: attr(),
      out_kethea: attr(),
      typeDescr: (function () {
        if (this.get("type") === "1") {
          return "Διακοπή παρακολούθησης";
        }

        if (this.get("type") === "2") {
          return "Παραπομπή εκτός πλαισίου";
        }
      }).property("type")
    });
  });