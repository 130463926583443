define("front/controllers/person/termination-reference", 
  ["front/config/environment","ember-notify","front/mixins/person","exports"],
  function(__dependency1__, __dependency2__, __dependency3__, __exports__) {
    "use strict";
    var ENV = __dependency1__["default"];
    var Notify = __dependency2__["default"];
    var PersonMixin = __dependency3__["default"];

    __exports__["default"] = Ember.Controller.extend(PersonMixin, Ember.Validations.Mixin, {

      canValidate: false,

      validations: {
        date: {
          presence: { "if": "canValidate", message: "Παρακαλώ συμπληρώστε Ημερομηνία." }
        }
      },

      actions: {
        terminate: function () {
          this.set("canValidate", true);

          var self = this;
          var data = Ember.Object.create({
            user_id: this.get("user"),
            pers_id: this.get("person.id"),
            pehi_id: this.get("activeHistory.id"),
            date: this.get("date"),
            out_kethea: 0,
            type: 2,
            estimation: this.get("estimation"),
            remarks: this.get("remarks")
          });

          this.validate().then(function () {
            $.ajax({
              url: ENV.APP.backendUrl + "/unitWithdrawal",
              type: "POST",
              data: JSON.stringify(data)
            }).then(function (response) {
              Notify.success("Το μέλος αποχώρησε με επιτυχία", {
                closeAfter: 10000 // or set to null to disable auto-hiding
              });
              self.set("canValidate", false);
              self.send("reloadPerson");
              self.transitionToRoute("person", self.get("person.id"));
            }, function (error) {
              self.set("canValidate", false);
            });
          }, function () {
            self.set("canValidate", false);
          });
        }
      }
    });
  });