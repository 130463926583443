define("front/controllers/relationship/delete-modal", 
  ["ember-notify","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var Notify = __dependency1__["default"];

    __exports__["default"] = Ember.Controller.extend({
      needs: ["relationship"],

      actions: {
        deleteGroup: function () {
          var self = this;
          var relationship = this.get("content.relationship");

          relationship.destroyRecord().then(function () {
            $(".modal").modal("hide");
            Notify.success("Η διαγραφή έγινε με επιτυχία!");
            self.send("reloadPerson");
          })["catch"](function () {
            relationship.rollback();
            $(".modal").modal("hide");
            Notify.error("Η διαγραφή δεν πραγματοποιήθηκε!");
          });


          this.transitionTo("person", this.get("content.person.id"));
        }
      }
    });
  });