define("front/routes/physfiles/new", 
  ["simple-auth/mixins/authenticated-route-mixin","exports"],
  function(__dependency1__, __exports__) {
    "use strict";
    var AuthenticatedRouteMixin = __dependency1__["default"];

    __exports__["default"] = Ember.Route.extend(AuthenticatedRouteMixin, {

      model: function () {
        var personModel = this.modelFor("person");

        return Ember.RSVP.hash({
          person: personModel.person });
      },

      setupController: function (controller, model) {
        this._super(controller, model);

        controller.set("currentUnit", this.controllerFor("application").get("selectedUnit"));
        controller.set("isMember", false);
        controller.set("displaycode", false);
        controller.set("person", model.person);
      }
    });
  });